// usersSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


export const makePayment = createAsyncThunk(
  "pay/makePayment",
  async (paymentData, thunkAPI) => {
    try {
      // Your asynchronous logic to update student here
      const response = await fetch(`https://api.way4franchise.com/api/v1/payment/plan`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(paymentData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();

      return data;
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const makeInvestorPayment = createAsyncThunk(
  "pay/makeInvestorPayment",
  async (paymentData, thunkAPI) => {
    try {
      // Your asynchronous logic to update student here
      const response = await fetch(`https://api.way4franchise.com/api/v1/payment/investor/plan`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(paymentData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();

      return data;
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const getAllFranchisePayments = createAsyncThunk(
  "staff/getAllFranchisePayments",
  async (_, thunkAPI) => {
    try {
      const response = await fetch("https://api.way4franchise.com/api/v1/payment/investor/list");

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const getAllUserPayments = createAsyncThunk(
  "staff/getAllUserPayments",
  async (_, thunkAPI) => {
    try {
      const response = await fetch("https://api.way4franchise.com/api/v1/payment/user/list");

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const rechargeWallet = createAsyncThunk(
  "pay/rechargeWallet",
  async (walletData, thunkAPI) => {
    try {
      // Your asynchronous logic to update student here
      const response = await fetch(`https://api.way4franchise.com/api/v1/payment/recharge`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(walletData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();

      return data;
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getTransactionByID = createAsyncThunk(
  "staff/getTransactionByID",
  async ({franchise_id}, thunkAPI) => {
    try {
      const response = await fetch(`https://api.way4franchise.com/api/v1/payment/transactions/${franchise_id}`);

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
const initialState = {
  order:null,
  loading: false,
  allpayments:null,
  singleinvestorTrans:null,
  alluserpayments:null,
  error: null,
  message: null,
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(makePayment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(makePayment.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.order = action.payload.order;
      })
      .addCase(makePayment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
      .addCase(makeInvestorPayment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(makeInvestorPayment.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.order = action.payload.order;
      })
      .addCase(makeInvestorPayment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
      .addCase(getAllFranchisePayments.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllFranchisePayments.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.allpayments = action.payload.allpayments;
      })
      .addCase(getAllFranchisePayments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
      .addCase(getAllUserPayments.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllUserPayments.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.alluserpayments = action.payload.alluserpayments;
      })
      .addCase(getAllUserPayments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })

      .addCase(rechargeWallet.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(rechargeWallet.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.message = action.payload.message;
      })
      .addCase(rechargeWallet.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
      .addCase(getTransactionByID.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTransactionByID.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.singleinvestorTrans = action.payload.singleinvestorTrans;
      })
      .addCase(getTransactionByID.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
  },
});

export const { clearErrors, clearMessage } = paymentSlice.actions;

export default paymentSlice.reducer;

