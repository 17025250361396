import React from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {useParams} from 'react-router-dom'
import SuccessAlert from "./SuccessAlert";
import ErrorAlert from "./ErrorAlert";

import { resetPassword } from '../redux/authSlice';
import Spinner from './Spinner';
// Action

import Footer from "../HomeFiels/Comman/Footer";
import Header from "../HomeFiels/Comman/Header";
import SwiperSlider from "../AdsComponents/SwiperSlider";
import { Ads } from "../AdsComponents/Ads";

export default function Login() {
  const { auth, auth_loading, auth_error, auth_message } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();
  const { token } = useParams();
  // Validation Schema
  const validationSchema = Yup.object({
    newPassword: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .required('New Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
      .required('Confirm Password is required'),
  });


  const handleSubmit = (values, { resetForm }) => {
    dispatch(resetPassword(values));
    resetForm(); 
  };

  const initialValues = {
    newPassword: '',
    confirmPassword: '',
    token
  };


  return (
    <>
      {auth_message && <SuccessAlert message={auth_message} />}
      {auth_error && <ErrorAlert error={auth_error} />}

      <Header />
      <div className="max-w-7xl mx-10 overflow-x-auto mt-6">
        <div className="w-full  lg:pl-0 mb-4 my-3">
          <div className="flex justify-between text-sm  ">
            <div className="text-gray-600 flex items-center pb-2 lg:pr-2  border-b-2 border-gray-600 capitalize">
              <h2 className="font-semibold  lg:text-2xl sm:text-xl text-lg text-gray-800 inline-block">
                Reset Password .
              </h2>
            </div>
          </div>
        </div>
        <div className="">
          <p className="md:text-base mb-2 text-justify lg:pr-6">
            At Way 4 Franchise, we’re committed to providing valuable resources
            for aspiring franchise owners and seasoned investors alike. By
            logging into your account, you can access a wealth of information,
            including.
          </p>
        </div>
      </div>
      <div class="grid grid-cols-1 sm:grid-cols-12 gap-4  max-w-full lg:mx-10 mx-4  mt-5">
        <div class="lg:col-span-4 col-span-12  border my-4">
          <div>
            <Ads adnum={3} />
          </div>
        </div>


        <div class="lg:col-span-4 px-5 col-span-12  border shadow-sm rounded-sm my-4">
            <div className="bg-gray-100 px-4 py-2">
              <h2 className="text-lg text-center uppercase font-bold leading-9 tracking-tight text-gray-800">
                Forgot password
              </h2>
            </div>
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          {/* New Password */}
          <div className="mb-4">
            <label htmlFor="newPassword" className="block text-sm font-medium mb-2">
              New Password
            </label>
            <Field
              type="password"
              name="newPassword"
              id="newPassword"
              className="w-full p-2 border rounded"
            />
            <ErrorMessage
              name="newPassword"
              component="div"
              className="text-red-500 text-sm mt-1"
            />
          </div>

          {/* Confirm Password */}
          <div className="mb-4">
            <label htmlFor="confirmPassword" className="block text-sm font-medium mb-2">
              Confirm Password
            </label>
            <Field
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              className="w-full p-2 border rounded"
            />
            <ErrorMessage
              name="confirmPassword"
              component="div"
              className="text-red-500 text-sm mt-1"
            />
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
          >
           {auth_loading ? <Spinner/> :"Reset Password"}
          </button>
        </Form>
      </Formik>
    </div>
        <div class="lg:col-span-4 col-span-12 border  my-4">
          {/* <SwiperSlider /> */}
          <div className="">
            {/* <img src="/loginads1.png" className="h-full"/> */}
            <Ads adnum={3} />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
