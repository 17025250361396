// import { useSelector, useDispatch } from "react-redux";
// import { useState, useEffect } from "react";
// import { Link,useParams } from "react-router-dom";
// import { getFranchiseByid } from "../redux/franchiseSlice";

// const AdminFranchiseeProfile = () => {
//     const _id = useParams();
//   const dispatch = useDispatch();

//   const [activeTab, setActiveTab] = useState('franchise'); 
//   const { franchisee, loading } = useSelector((state) => state.franchise);

//   useEffect(() => {
//     if (_id) {
//       dispatch(getFranchiseByid(_id));
//     }
//   }, [_id]);

//   return (
//     <>
//       <main>
//         <div className=" flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0 px-0 py-2">
//           <div className="flex flex-wrap justify-center space-x-2 sm:space-x-4">
//           <a href="#franchise ">
//             <button
//               className={`px-4 py-2 my-1 text-[13px] rounded-md ${
//                 activeTab === "franchise"
//                   ? "bg-gray-900 text-white"
//                   : "bg-gray-100 hover:bg-gray-200 text-gray-800"
//               }`}
//               onClick={() => setActiveTab("franchise ")}
//             >FRANCHISE</button>
//             </a>
//             <a href="#business ">
//             <button
//               className={`px-4 py-2 my-1 text-[13px] rounded-md ${
//                 activeTab === "business"
//                   ? "bg-gray-900 text-white"
//                   : "bg-gray-100 hover:bg-gray-200 text-gray-800"
//               }`}
//               onClick={() => setActiveTab("business")}
//             >BUSINESS</button>
//             </a>
          
//             <a href="#investment ">
//             <button
//               className={`px-4 py-2 my-1 text-[13px] rounded-md ${
//                 activeTab === "company"
//                   ? "bg-gray-900 text-white"
//                   : "bg-gray-100 hover:bg-gray-200 text-gray-800 "
//               }`}
//               onClick={() => setActiveTab("company")}
//             >
//               COMPANY
//             </button>
//             </a>
//             <a href="#property ">
//             <button
//               className={`px-4 py-2 my-1 text-[13px] rounded-md ${
//                 activeTab === "property"
//                   ? "bg-gray-900 text-white"
//                   : "bg-gray-100 hover:bg-gray-200 text-gray-800"
//               }`}
//               onClick={() => setActiveTab("property")}
//             >
//               PROPERTY
//             </button>
//             </a>
         
//           </div>
//         </div>

//         <div className="mx-auto max-w-7xl   lg:mb-0 mb-20">
//           <div className="mx-auto grid   grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8   ">
//             {/* {activeTab === 'business' && ( */}
//             <div  className=" px-4 py-8 shadow-md ring-1 ring-gray-900/5 sm:mx-0 sm:rounded-lg sm:px-8 sm:pb-14  lg:row-end-2 xl:px-4 xl:pb-20 xl:pt-5">
//               <h2 id="franchise" className="text-base uppercase font-semibold leading-6 text-gray-900 border-b pb-5">
//                 Franchise Details
//               </h2>

//               <dl className="grid grid-cols-1  text-sm leading-6">
               
//                   <div>
//                     <div className="py-4 flex justify-between sm:gap-4 sm:border-b sm:border-gray-200 sm:py-5">
//                       <dt className="text-[12px] font-medium text-gray-600">
//                         Brand Name
//                       </dt>
//                       <dd className="mt-1 flex text-[12px] capitalize font-medium text-gray-600  sm:col-span-2 sm:mt-0">
//                         <span className="flex-grow">
//                           {franchisee?.franchise_name}
//                         </span>
//                       </dd>
//                     </div>
//                     <div className="py-4 flex justify-between sm:gap-4 sm:border-b sm:border-gray-200 sm:py-5">
//                       <dt className="text-[12px] font-medium text-gray-600">
//                         Type
//                       </dt>
//                       <dd className="mt-1 flex text-[12px] capitalize font-medium text-gray-600  sm:col-span-2 sm:mt-0">
//                         <span className="flex-grow">{franchisee?.looking_for}</span>
//                       </dd>
//                     </div>
//                     <div className="py-4 flex justify-between sm:gap-4 sm:border-b sm:border-gray-200 sm:py-5">
//                       <dt className="text-[12px] font-medium text-gray-600">
//                         Unit BrandFee
//                       </dt>
//                       <dd className="mt-1 flex text-[12px] capitalize font-medium text-gray-600  sm:col-span-2 sm:mt-0">
//                         <span className="flex-grow">{franchisee?.unit_brandFee}</span>
//                       </dd>
//                     </div>
//                     <div className="py-4 flex justify-between sm:gap-4 sm:border-b sm:border-gray-200 sm:py-5">
//                       <dt className="text-[12px] font-medium text-gray-600">
//                         Unit Price
//                       </dt>
//                       <dd className="mt-1 flex text-[12px] capitalize font-medium text-gray-600  sm:col-span-2 sm:mt-0">
//                         <span className="flex-grow">{franchisee?.unit_price}</span>
//                       </dd>
//                     </div>
//                   </div>
               
//               </dl>
//             </div>
//             {/* )
//         } */}
//             {/* {activeTab === 'business' && ( */}
//             <div className="grid-cols-1 shadow-md  px-3 border rounded-lg">
//               <div className="mt-6">
//                 <h2 id="business" className="text-base font-semibold  uppercase leading-6 text-gray-900 border-b pb-6">
//                   Business Detail
//                 </h2>

//                 <dl className="grid grid-cols-1 text-sm leading-6">
                  
//                     <div >
//                       <div className="py-4 flex justify-between sm:gap-4 sm:border-b sm:border-gray-200 sm:py-5">
//                         <dt className="text-[12px] font-medium text-gray-600">
//                           Industry
//                         </dt>
//                         <dd className="mt-1 flex text-[12px] capitalize font-medium text-gray-600  sm:col-span-2 sm:mt-0">
//                           <span className="flex-grow">{franchisee?.industry}</span>
//                         </dd>
//                       </div>
//                       <div className="py-4 flex justify-between sm:gap-4 sm:border-b sm:border-gray-200 sm:py-5">
//                         <dt className="text-[12px] font-medium text-gray-600">
//                           Commenced
//                         </dt>
//                         <dd className="mt-1 flex text-[12px] capitalize font-medium text-gray-600  sm:col-span-2 sm:mt-0">
//                           <span className="flex-grow">{franchisee?.Commenced}</span>
//                         </dd>
//                       </div>
//                       <div className="py-4 flex justify-between sm:gap-4 sm:border-b sm:border-gray-200 sm:py-5">
//                         <dt className="text-[12px] font-medium text-gray-600">
//                           Current Outlets
//                         </dt>
//                         <dd className="mt-1 flex text-[12px] capitalize font-medium text-gray-600  sm:col-span-2 sm:mt-0">
//                           <span className="flex-grow">
//                             {franchisee?.currentOutlets}
//                           </span>
//                         </dd>
//                       </div>
//                       <div className="py-4 flex justify-between sm:gap-4 sm:border-b sm:border-gray-200 sm:py-5">
//                         <dt className="text-[12px] font-medium text-gray-600">
//                           Franchising On
//                         </dt>
//                         <dd className="mt-1 flex text-[12px] capitalize font-medium text-gray-600  sm:col-span-2 sm:mt-0">
//                           <span className="flex-grow">
//                             {franchisee?.franchisingOn}
//                           </span>
//                         </dd>
//                       </div>
//                       <div className="py-4 flex justify-between sm:gap-4 sm:border-b sm:border-gray-200 sm:py-5">
//                         <dt className="text-[12px] font-medium text-gray-600">
//                           Minimum investment range
//                         </dt>
//                         <dd className="mt-1 flex text-[12px] capitalize font-medium text-gray-600  sm:col-span-2 sm:mt-0">
//                           <span className="flex-grow">
//                             {franchisee?.investment_range}
//                           </span>
//                         </dd>
//                       </div>
//                       <div className="py-4 flex justify-between sm:gap-4 sm:border-b sm:border-gray-200 sm:py-5">
//                         <dt className="text-[12px] font-medium text-gray-600">
//                           Owned Outlets
//                         </dt>
//                         <dd className="mt-1 flex text-[12px] capitalize font-medium text-gray-600  sm:col-span-2 sm:mt-0">
//                           <span className="flex-grow">
//                             {franchisee?.ownedOutlets}
//                           </span>
//                         </dd>
//                       </div>
//                       <div className="py-4 flex justify-between sm:gap-4 sm:border-b sm:border-gray-200 sm:py-5">
//                         <dt className="text-[12px] font-medium text-gray-600">
//                           Retail Outlets
//                         </dt>
//                         <dd className="mt-1 flex text-[12px] capitalize font-medium text-gray-600  sm:col-span-2 sm:mt-0">
//                           <span className="flex-grow">
//                             {franchisee?.retailOutlets}
//                           </span>
//                         </dd>
//                       </div>
//                       <div className="py-4 flex justify-between sm:gap-4 sm:border-b sm:border-gray-200 sm:py-5">
//                         <dt className="text-[12px] font-medium text-gray-600">
//                           sector
//                         </dt>
//                         <dd className="mt-1 flex text-[12px] capitalize font-medium text-gray-600  sm:col-span-2 sm:mt-0">
//                           <span className="flex-grow">{franchisee?.sector}</span>
//                         </dd>
//                       </div>
//                       <div className="py-4 flex justify-between sm:gap-4 sm:border-b sm:border-gray-200 sm:py-5">
//                         <dt className="text-[12px] font-medium text-gray-600">
//                           Service
//                         </dt>
//                         <dd className="mt-1 flex text-[12px] capitalize font-medium text-gray-600  sm:col-span-2 sm:mt-0">
//                           <span className="flex-grow">{franchisee?.service}</span>
//                         </dd>
//                       </div>
//                     </div>
                  
//                 </dl>
//               </div>
//             </div>
//             {/* )} */}
//             <div className="mb-10">
//               <div className=" shadow-md grid-cols-1">
//                 <h2 id="company" className="sr-only">Company Detail</h2>
//                 <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
//                   <dl className="">
//                     <h2 className="text-base uppercase font-semibold leading-6 text-gray-900 border-b py-5 px-3">
//                       Company Details
//                     </h2>
//                     <dl className="divide-y divide-gray-200">
//                       <div className="py-4 flex justify-between sm:gap-4 sm:py-5 px-3">
//                         <dt className="text-[12px] font-medium text-gray-600">
//                           Company Name
//                         </dt>
//                         <dd className="mt-1 flex text-[12px] capitalize font-medium text-gray-600  sm:col-span-2 sm:mt-0">
//                           <span className="flex-grow text-end">
//                             {franchisee?.companyName}
//                           </span>
//                         </dd>
//                       </div>
//                       <div className="py-4 flex justify-between sm:gap-4 sm:py-5 sm:pt-5 px-3">
//                         <dt className="text-[12px] font-medium text-gray-600">
//                           Owner Name
//                         </dt>
//                         <dd className="mt-1 flex text-[12px] capitalize font-medium text-gray-600  sm:col-span-2 sm:mt-0">
//                           <span className="flex-grow text-end">
//                             {franchisee?.ownerName}
//                           </span>
//                         </dd>
//                       </div>
//                       <div className="py-4 flex justify-between sm:gap-4 sm:py-5 sm:pt-5 px-3">
//                         <dt className="text-[12px] font-medium text-gray-600">
//                           Email
//                         </dt>
//                         <dd className="mt-1 flex text-[12px] capitalize font-medium text-gray-600  sm:col-span-2 sm:mt-0">
//                           <span className="flex-grow text-end">
//                             {franchisee?.ownerEmail}
//                           </span>
//                         </dd>
//                       </div>
//                       <div className="py-4 flex justify-between sm:gap-4 sm:border-b sm:border-gray-200 sm:py-5 px-3">
//                         <dt className="text-[12px] font-medium text-gray-600">
//                           Phone No.
//                         </dt>
//                         <dd className="mt-1 flex text-[12px] capitalize font-medium text-gray-600  sm:col-span-2 sm:mt-0">
//                           <span className="flex-grow text-end">
//                             {" "}
//                             {franchisee?.phone}
//                           </span>
//                         </dd>
//                       </div>
//                       <div className="py-4 flex justify-between sm:gap-4 sm:border-b sm:border-gray-200 sm:py-5 px-3">
//                         <dt className="text-[12px] font-medium text-gray-600">
//                           Pin Code
//                         </dt>
//                         <dd className="mt-1 flex text-[12px] capitalize font-medium text-gray-600  sm:col-span-2 sm:mt-0">
//                           <span className="flex-grow text-end">
//                             {franchisee?.pincode}
//                           </span>
//                         </dd>
//                       </div>
//                       <div className="py-4 flex justify-between sm:gap-4 sm:border-b sm:border-gray-200 sm:py-5 px-3">
//                         <dt className="text-[12px] font-medium text-gray-600">
//                           Address
//                         </dt>
//                         <dd className="mt-1 flex text-[12px] capitalize font-medium text-gray-600  sm:col-span-2 sm:mt-0">
//                           <span className="flex-grow text-end">
//                             {franchisee?.address}
//                           </span>
//                         </dd>
//                       </div>
//                       <div className="py-4 flex justify-between sm:gap-4 sm:border-b sm:border-gray-200 sm:py-5 px-3">
//                         <dt className="text-[12px] font-medium text-gray-600">
//                           Website
//                         </dt>
//                         <dd className="mt-1 flex text-[12px] capitalize font-medium text-gray-600  sm:col-span-2 sm:mt-0">
//                           <span className="flex-grow text-end">
//                             {franchisee?.website}
//                           </span>
//                         </dd>
//                       </div>
//                     </dl>
//                     {/* ))} */}
//                   </dl>
//                 </div>
//               </div>
//               <div className="lg:col-start-3 shadow-md ">
//                 {/* Activity feed */}
//                 <div className="lg:col-start-3 lg:row-end-1 space-y-5">
//                   <h2 id="property" className="sr-only"> Property Details</h2>
//                   <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
//                     <dl className="">
//                       <h2 className="text-base uppercase font-semibold leading-6 text-gray-900 border-b py-5 px-3">
//                         Property Details
//                       </h2>
                      
//                         <dl className="divide-y divide-gray-200 ">
//                           <div className="py-4 flex justify-between sm:gap-4 sm:py-5 px-3">
//                             <dt className="text-[12px] font-medium text-gray-600">
//                               Preferred location
//                             </dt>
//                             <dd className="mt-1 flex text-[12px] capitalize font-medium text-gray-600  sm:col-span-2 sm:mt-0">
//                               <span className="flex-grow text-end">
//                                 {franchisee?.Preferred_location}
//                               </span>
//                             </dd>
//                           </div>
//                           <div className="py-4 flex justify-between sm:gap-4 sm:py-5 sm:pt-5 px-3">
//                             <dt className="text-[12px] font-medium text-gray-600">
//                               Floor Area
//                             </dt>
//                             <dd className="mt-1 flex text-[12px] capitalize font-medium text-gray-600  sm:col-span-2 sm:mt-0">
//                               <span className="flex-grow text-end">
//                                 {franchisee?.floor_Area}
//                               </span>
//                             </dd>
//                           </div>
//                           <div className="py-4 flex justify-between sm:gap-4 sm:py-5 sm:pt-5 px-3">
//                             <dt className="text-[12px] font-medium text-gray-600">
//                               Property Type Required
//                             </dt>
//                             <dd className="mt-1 flex text-sm text-end text-gray-900 sm:col-span-2 sm:mt-0">
//                               <span className="flex-grow">
//                                 {franchisee?.property_type_requried}
//                               </span>
//                             </dd>
//                           </div>
//                         </dl>
                      
//                     </dl>
//                   </div>
//                 </div>
//                 {/* New comment form */}
//               </div>
          
//             </div>
//           </div>
//         </div>
//       </main>
//     </>
//   );
// };

// export default AdminFranchiseeProfile;


import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { getFranchiseByid } from "../redux/franchiseSlice";

const AdminFranchiseeProfile = () => {
  const { id: _id } = useParams(); // Access `id` from URL params
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("franchise");
  const { franchisee, loading } = useSelector((state) => state.franchise);

  useEffect(() => {
    if (_id) {
      dispatch(getFranchiseByid(_id));
    }
  }, [_id]);

  return (
    <>
      <main>
        <div className="flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0  py-2 bg-white shadow-sm border px-2">
          <div className="flex flex-wrap justify-center space-x-2 sm:space-x-4 ">
            {["franchise", "business", "company", "property"].map((tab) => (
              <button
                key={tab}
                href={`#${tab}`}
                className={`px-4 py-2 my-1 text-[13px] rounded-md ${
                  activeTab === tab
                    ? "bg-gray-900 text-white"
                    : "bg-gray-200 hover:bg-gray-300 text-gray-800"
                }`}
                onClick={() => setActiveTab(tab)}
              >
                {tab.toUpperCase()}
              </button>
            ))}
          </div>
        </div>

        <div className="mx-auto max-w-7xl lg:mb-0 mb-20">
          {activeTab === "franchise" && (
           <div  className=" px-4 py-8 bg-white shadow-md ring-1 ring-gray-900/5 sm:mx-0 sm:rounded-b-sm sm:px-8 sm:pb-14  lg:row-end-2 xl:px-4 xl:pb-20 xl:pt-5">
           <h2 id="franchise" className="text-base uppercase font-semibold leading-6 text-gray-900 border-b pb-5">
             Franchise Details
           </h2>

           <dl className="grid grid-cols-1  text-sm leading-6">
            
               <div>
                 <div className="py-4 flex justify-between sm:gap-4 sm:border-b sm:border-gray-200 sm:py-5">
                   <dt className="text-[12px] font-medium text-gray-600">
                     Brand Name
                   </dt>
                   <dd className="mt-1 flex text-[12px] capitalize font-medium text-gray-600  sm:col-span-2 sm:mt-0">
                     <span className="flex-grow">
                       {franchisee?.franchise_name}
                     </span>
                   </dd>
                 </div>
                 <div className="py-4 flex justify-between sm:gap-4 sm:border-b sm:border-gray-200 sm:py-5">
                   <dt className="text-[12px] font-medium text-gray-600">
                     Type
                   </dt>
                   <dd className="mt-1 flex text-[12px] capitalize font-medium text-gray-600  sm:col-span-2 sm:mt-0">
                     <span className="flex-grow">{franchisee?.looking_for}</span>
                   </dd>
                 </div>
                 <div className="py-4 flex justify-between sm:gap-4 sm:border-b sm:border-gray-200 sm:py-5">
                   <dt className="text-[12px] font-medium text-gray-600">
                     Unit BrandFee
                   </dt>
                   <dd className="mt-1 flex text-[12px] capitalize font-medium text-gray-600  sm:col-span-2 sm:mt-0">
                     <span className="flex-grow">{franchisee?.unit_brandFee}</span>
                   </dd>
                 </div>
                 <div className="py-4 flex justify-between sm:gap-4 sm:border-b sm:border-gray-200 sm:py-5">
                   <dt className="text-[12px] font-medium text-gray-600">
                     Unit Price
                   </dt>
                   <dd className="mt-1 flex text-[12px] capitalize font-medium text-gray-600  sm:col-span-2 sm:mt-0">
                     <span className="flex-grow">{franchisee?.unit_price}</span>
                   </dd>
                 </div>
               </div>
            
           </dl>
         </div>
          )}

          {activeTab === "business" && (
              <div className="grid-cols-1 shadow-sm  px-3 border rounded-b-sm bg-white">
              <div className="mt-6">
                <h2 id="business" className="text-base font-semibold  uppercase leading-6 text-gray-900 border-b pb-6">
                  Business Detail
                </h2>

                <dl className="grid grid-cols-1 text-sm leading-6">
                  
                    <div >
                      <div className="py-4 flex justify-between sm:gap-4 sm:border-b sm:border-gray-200 sm:py-5">
                        <dt className="text-[12px] font-medium text-gray-600">
                          Industry
                        </dt>
                        <dd className="mt-1 flex text-[12px] capitalize font-medium text-gray-600  sm:col-span-2 sm:mt-0">
                          <span className="flex-grow">{franchisee?.industry}</span>
                        </dd>
                      </div>
                      <div className="py-4 flex justify-between sm:gap-4 sm:border-b sm:border-gray-200 sm:py-5">
                        <dt className="text-[12px] font-medium text-gray-600">
                          Commenced
                        </dt>
                        <dd className="mt-1 flex text-[12px] capitalize font-medium text-gray-600  sm:col-span-2 sm:mt-0">
                          <span className="flex-grow">{franchisee?.Commenced}</span>
                        </dd>
                      </div>
                      <div className="py-4 flex justify-between sm:gap-4 sm:border-b sm:border-gray-200 sm:py-5">
                        <dt className="text-[12px] font-medium text-gray-600">
                          Current Outlets
                        </dt>
                        <dd className="mt-1 flex text-[12px] capitalize font-medium text-gray-600  sm:col-span-2 sm:mt-0">
                          <span className="flex-grow">
                            {franchisee?.currentOutlets}
                          </span>
                        </dd>
                      </div>
                      <div className="py-4 flex justify-between sm:gap-4 sm:border-b sm:border-gray-200 sm:py-5">
                        <dt className="text-[12px] font-medium text-gray-600">
                          Franchising On
                        </dt>
                        <dd className="mt-1 flex text-[12px] capitalize font-medium text-gray-600  sm:col-span-2 sm:mt-0">
                          <span className="flex-grow">
                            {franchisee?.franchisingOn}
                          </span>
                        </dd>
                      </div>
                      <div className="py-4 flex justify-between sm:gap-4 sm:border-b sm:border-gray-200 sm:py-5">
                        <dt className="text-[12px] font-medium text-gray-600">
                          Minimum investment range
                        </dt>
                        <dd className="mt-1 flex text-[12px] capitalize font-medium text-gray-600  sm:col-span-2 sm:mt-0">
                          <span className="flex-grow">
                            {franchisee?.investment_range}
                          </span>
                        </dd>
                      </div>
                      <div className="py-4 flex justify-between sm:gap-4 sm:border-b sm:border-gray-200 sm:py-5">
                        <dt className="text-[12px] font-medium text-gray-600">
                          Owned Outlets
                        </dt>
                        <dd className="mt-1 flex text-[12px] capitalize font-medium text-gray-600  sm:col-span-2 sm:mt-0">
                          <span className="flex-grow">
                            {franchisee?.ownedOutlets}
                          </span>
                        </dd>
                      </div>
                      <div className="py-4 flex justify-between sm:gap-4 sm:border-b sm:border-gray-200 sm:py-5">
                        <dt className="text-[12px] font-medium text-gray-600">
                          Retail Outlets
                        </dt>
                        <dd className="mt-1 flex text-[12px] capitalize font-medium text-gray-600  sm:col-span-2 sm:mt-0">
                          <span className="flex-grow">
                            {franchisee?.retailOutlets}
                          </span>
                        </dd>
                      </div>
                      <div className="py-4 flex justify-between sm:gap-4 sm:border-b sm:border-gray-200 sm:py-5">
                        <dt className="text-[12px] font-medium text-gray-600">
                          sector
                        </dt>
                        <dd className="mt-1 flex text-[12px] capitalize font-medium text-gray-600  sm:col-span-2 sm:mt-0">
                          <span className="flex-grow">{franchisee?.sector}</span>
                        </dd>
                      </div>
                      <div className="py-4 flex justify-between sm:gap-4 sm:border-b sm:border-gray-200 sm:py-5">
                        <dt className="text-[12px] font-medium text-gray-600">
                          Service
                        </dt>
                        <dd className="mt-1 flex text-[12px] capitalize font-medium text-gray-600  sm:col-span-2 sm:mt-0">
                          <span className="flex-grow">{franchisee?.service}</span>
                        </dd>
                      </div>
                    </div>
                  
                </dl>
              </div>
            </div>
          )}

          {activeTab === "company" && (
            <div className=" shadow-md grid-cols-1">
            <h2 id="company" className="sr-only">Company Detail</h2>
            <div className="rounded-b-sm bg-white shadow-sm ring-1 ring-gray-900/5">
              <dl className="">
                <h2 className="text-base uppercase font-semibold leading-6 text-gray-900 border-b py-5 px-3">
                  Company Details
                </h2>
                <dl className="divide-y divide-gray-200">
                  <div className="py-4 flex justify-between sm:gap-4 sm:py-5 px-3">
                    <dt className="text-[12px] font-medium text-gray-600">
                      Company Name
                    </dt>
                    <dd className="mt-1 flex text-[12px] capitalize font-medium text-gray-600  sm:col-span-2 sm:mt-0">
                      <span className="flex-grow text-end">
                        {franchisee?.companyName}
                      </span>
                    </dd>
                  </div>
                  <div className="py-4 flex justify-between sm:gap-4 sm:py-5 sm:pt-5 px-3">
                    <dt className="text-[12px] font-medium text-gray-600">
                      Owner Name
                    </dt>
                    <dd className="mt-1 flex text-[12px] capitalize font-medium text-gray-600  sm:col-span-2 sm:mt-0">
                      <span className="flex-grow text-end">
                        {franchisee?.ownerName}
                      </span>
                    </dd>
                  </div>
                  <div className="py-4 flex justify-between sm:gap-4 sm:py-5 sm:pt-5 px-3">
                    <dt className="text-[12px] font-medium text-gray-600">
                      Email
                    </dt>
                    <dd className="mt-1 flex text-[12px] capitalize font-medium text-gray-600  sm:col-span-2 sm:mt-0">
                      <span className="flex-grow text-end">
                        {franchisee?.ownerEmail}
                      </span>
                    </dd>
                  </div>
                  <div className="py-4 flex justify-between sm:gap-4 sm:border-b sm:border-gray-200 sm:py-5 px-3">
                    <dt className="text-[12px] font-medium text-gray-600">
                      Phone No.
                    </dt>
                    <dd className="mt-1 flex text-[12px] capitalize font-medium text-gray-600  sm:col-span-2 sm:mt-0">
                      <span className="flex-grow text-end">
                        {" "}
                        {franchisee?.phone}
                      </span>
                    </dd>
                  </div>
                  <div className="py-4 flex justify-between sm:gap-4 sm:border-b sm:border-gray-200 sm:py-5 px-3">
                    <dt className="text-[12px] font-medium text-gray-600">
                      Pin Code
                    </dt>
                    <dd className="mt-1 flex text-[12px] capitalize font-medium text-gray-600  sm:col-span-2 sm:mt-0">
                      <span className="flex-grow text-end">
                        {franchisee?.pincode}
                      </span>
                    </dd>
                  </div>
                  <div className="py-4 flex justify-between sm:gap-4 sm:border-b sm:border-gray-200 sm:py-5 px-3">
                    <dt className="text-[12px] font-medium text-gray-600">
                      Address
                    </dt>
                    <dd className="mt-1 flex text-[12px] capitalize font-medium text-gray-600  sm:col-span-2 sm:mt-0">
                      <span className="flex-grow text-end">
                        {franchisee?.address}
                      </span>
                    </dd>
                  </div>
                  <div className="py-4 flex justify-between sm:gap-4 sm:border-b sm:border-gray-200 sm:py-5 px-3">
                    <dt className="text-[12px] font-medium text-gray-600">
                      Website
                    </dt>
                    <dd className="mt-1 flex text-[12px] capitalize font-medium text-gray-600  sm:col-span-2 sm:mt-0">
                      <span className="flex-grow text-end">
                        {franchisee?.website}
                      </span>
                    </dd>
                  </div>
                </dl>
                {/* ))} */}
              </dl>
            </div>
          </div>
          )}
          {activeTab === "property" && (
          <div className="lg:col-start-3 shadow-md ">
          {/* Activity feed */}
          <div className="lg:col-start-3 lg:row-end-1 space-y-5">
            <h2 id="property" className="sr-only"> Property Details</h2>
            <div className="rounded-b-sm bg-white shadow-sm ring-1 ring-gray-900/5">
              <dl className="">
                <h2 className="text-base uppercase font-semibold leading-6 text-gray-900 border-b py-5 px-3">
                  Property Details
                </h2>
                
                  <dl className="divide-y divide-gray-200 ">
                    <div className="py-4 flex justify-between sm:gap-4 sm:py-5 px-3">
                      <dt className="text-[12px] font-medium text-gray-600">
                        Preferred location
                      </dt>
                      <dd className="mt-1 flex text-[12px] capitalize font-medium text-gray-600  sm:col-span-2 sm:mt-0">
                        <span className="flex-grow text-end">
                          {franchisee?.Preferred_location}
                        </span>
                      </dd>
                    </div>
                    <div className="py-4 flex justify-between sm:gap-4 sm:py-5 sm:pt-5 px-3">
                      <dt className="text-[12px] font-medium text-gray-600">
                        Floor Area
                      </dt>
                      <dd className="mt-1 flex text-[12px] capitalize font-medium text-gray-600  sm:col-span-2 sm:mt-0">
                        <span className="flex-grow text-end">
                          {franchisee?.floor_Area}
                        </span>
                      </dd>
                    </div>
                    <div className="py-4 flex justify-between sm:gap-4 sm:py-5 sm:pt-5 px-3">
                      <dt className="text-[12px] font-medium text-gray-600">
                        Property Type Required
                      </dt>
                      <dd className="mt-1 flex text-sm text-end text-gray-900 sm:col-span-2 sm:mt-0">
                        <span className="flex-grow">
                          {franchisee?.property_type_requried}
                        </span>
                      </dd>
                    </div>
                  </dl>
                
              </dl>
            </div>
          </div>
          {/* New comment form */}
        </div>
          )}
        </div>
      </main>
    </>
  );
};

export default AdminFranchiseeProfile;
