// usersSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const addLocation = createAsyncThunk(
  "location/addLocation",
  async (formData, thunkAPI) => {
    try {
      const response = await fetch("https://api.way4franchise.com/api/v1/location/add", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);


  export const getAllLocation = createAsyncThunk(
    "location/getAllLocation",
    async (_, thunkAPI) => {
      try {
        const response = await fetch("https://api.way4franchise.com/api/v1/location/list");
  
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message);
        }
  
        const data = await response.json();
        return data;
      } catch (error) {
        // Handle error
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );
  
  export const getlocationByid = createAsyncThunk(
    "location/getlocationByid",
    async ({_id}, thunkAPI) => {
      try {
        const response = await fetch(`https://api.way4franchise.com/api/v1/location/byId/${_id}`);
  
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message);
        }
  
        const data = await response.json();
        return data;
      } catch (error) {
        // Handle error
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );
const initialState = {
  alllocation:null,
  singlelocation:null,
  loading: false,
  error: null,
  message: null,
};

const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addLocation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addLocation.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload.message;
      })
      .addCase(addLocation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
      .addCase(getAllLocation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllLocation.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.alllocation = action.payload.alllocation;
      })
      .addCase(getAllLocation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
      .addCase(getlocationByid.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getlocationByid.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.singlelocation = action.payload.singlelocation;
      })
      .addCase(getlocationByid.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
  },
});

export const { clearErrors, clearMessage } = locationSlice.actions;

export default locationSlice.reducer;

