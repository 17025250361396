// import {
//   Disclosure,
//   DisclosureButton,
//   DisclosurePanel,
//   Menu,
//   MenuButton,
//   MenuItem,
//   MenuItems,
// } from "@headlessui/react";
// import { getFranchiseByid } from "../redux/franchiseSlice";
// import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
// import { Children, useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import { signout } from "../redux/authSlice";
// import Header from "../HomeFiels/Comman/Header";
// import Footer from "../HomeFiels/Comman/Footer";

// const user = {
//   name: "Tom Cook",
//   email: "tom@example.com",
//   imageUrl:
//     "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
// };

// const navigation = [
//   { name: "Dashboard", href: "/investor/dashboard", current: true },
//   { name: "Plan", href: "/investor/plan", current: false },
//   { name: "ProKycjects", href: "/investor/Kyc/detail", current: false },
//   { name: "Client", href: "/investor/clients", current: false },
//   { name: "Profile", href: "/investor/profile", current: false },
//   { name: "Documents", to: "#", current: false },
//   { name: "Reports", to: "#", current: false },
// ];
// const userNavigation = [
//   { name: "Your Profile", href: "#" },
//   { name: "Settings", href: "#" },
//   { name: "Sign out", href: "#" },
// ];

// function classNames(...classes) {
//   return classes.filter(Boolean).join(" ");
// }

// export default function InvestorMenu({ Children }) {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const { franchisee, loading, error, message } = useSelector(
//     (state) => state.franchise
//   );
//   const [sidebarOpen, setSidebarOpen] = useState(false);
//   const [currenttab, setCurrentTab] = useState("Dashboard");
//   const { auth } = useSelector((state) => state.auth);

//   function handleSignOut() {
//     dispatch(signout());
//     navigate("/");
//   }

//   useEffect(() => {
//     if (auth?._id) {
//       const _id = auth?._id;
//       dispatch(getFranchiseByid({ _id }));
//     }
//   }, [auth?._id]);
//   return (
//     <>
//       <div className="min-h-full">
//         <div className=" pb-32">
//           <Header />
//           <header className="py-8">
//             <Disclosure as="nav" className="">
//               <div className="lg:mx-8 max-w-full sm:lg-10 mx-2">
//                 <div className="border-b border-gray-700 bg-gray-800">
//                   <div className="flex h-16 items-center justify-between px-4 sm:px-0">
//                     <div className="flex items-center">
//                       <div className="hidden md:block">
//                         <div className="ml-10 flex items-baseline space-x-4">
//                           {navigation.map((item) => (
//                             <a
//                               key={item.name}
//                               href={item.href}
//                               aria-current={item.current ? "page" : undefined}
//                               className={classNames(
//                                 item.current
//                                   ? "bg-gray-900 text-white"
//                                   : "text-gray-300 hover:bg-gray-700 hover:text-white",
//                                 "rounded-md px-3 py-2 text-sm font-medium"
//                               )}
//                             >
//                               {item.name}
//                             </a>
//                           ))}
//                         </div>
//                       </div>
//                     </div>
//                     <div className="hidden md:block">
//                       <div className="ml-4 flex items-center md:ml-6">
//                         {/* Profile dropdown */}
//                         <Menu as="div" className="relative ml-3">
//                           <MenuItems
//                             transition
//                             className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
//                           >
//                             {userNavigation.map((item) => (
//                               <MenuItem key={item.name}>
//                                 <a
//                                   href={item.href}
//                                   className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100"
//                                 >
//                                   {item.name}
//                                 </a>
//                               </MenuItem>
//                             ))}
//                           </MenuItems>
//                         </Menu>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>

//               <DisclosurePanel className="border-b border-gray-700 md:hidden">
//                 <div className="space-y-1 px-2 py-3 sm:px-3">
//                   {navigation.map((item) => (
//                     <DisclosureButton
//                       key={item.name}
//                       as="a"
//                       href={item.href}
//                       aria-current={item.current ? "page" : undefined}
//                       className={classNames(
//                         item.current
//                           ? "bg-gray-900 text-white"
//                           : "text-gray-300 hover:bg-gray-700 hover:text-white",
//                         "block rounded-md px-3 py-2 text-base font-medium"
//                       )}
//                     >
//                       {item.name}
//                     </DisclosureButton>
//                   ))}
//                 </div>
//                 <div className="border-t border-gray-700 pb-3 pt-4">
//                   <div className="flex items-center px-5">
//                     <div className="flex-shrink-0">
//                       <img
//                         alt=""
//                         src={user.imageUrl}
//                         className="h-10 w-10 rounded-full"
//                       />
//                     </div>
//                     <div className="ml-3">
//                       <div className="text-base font-medium leading-none text-white">
//                         {user.name}
//                       </div>
//                       <div className="text-sm font-medium leading-none text-gray-400">
//                         {user.email}
//                       </div>
//                     </div>
//                   </div>
//                   <div className="mt-3 space-y-1 px-2">
//                     {userNavigation.map((item) => (
//                       <DisclosureButton
//                         key={item.name}
//                         as="a"
//                         href={item.href}
//                         className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
//                       >
//                         {item.name}
//                       </DisclosureButton>
//                     ))}
//                   </div>
//                 </div>
//               </DisclosurePanel>
//             </Disclosure>
//           </header>
//         </div>

//         <main className="-mt-40">
//           <div className="mx-auto max-w-full px-4 pb-12 sm:px-6 lg:px-8">
//             <div className=" bg-white ">
//               <div className="grid grid-cols-12 gap-3 mt-4">
//                 <div className="lg:col-span-10 sm:col-span-12 col-span-12 ">
//                   {Children}
//                 </div>
//                 <div className="lg:col-span-2 sm:col-span-12 col-span-12 border shadow-lg h-[550px] ">
//                   <div className="flex justify-center ">
//                     <div className="text-white w-full  relative text-center">
//                       <h4 className="text-lg uppercase py-4 text-gray-900">
//                         {franchisee?.companyName}
//                       </h4>
//                       <div
//                         className="bg-cover bg-center h-56"
//                         style={{
//                           backgroundImage:
//                             "url('https://i.ibb.co/hHqYkBJ/bg.jpg')",
//                           clipPath: "circle(67% at 50% 16%)",
//                         }}
//                       ></div>
//                       <div className="relative">
//                         <div className="w-24 h-24 rounded-full border-4 border-white overflow-hidden absolute left-1/2 transform -translate-x-1/2 -bottom-[10px]">
//                           <img
//                             src="https://i.ibb.co/J2tyTzV/profile.jpg"
//                             alt="Profile"
//                             className="w-full h-full object-cover"
//                           />
//                         </div>
//                       </div>

//                       <h5 className="mt-5 text-xl relative text-gray-800 capitalize font-semibold border-b  pb-2">
//                         {franchisee?.ownerName}
//                         <span className="absolute block w-1/12 h-0.5  bg-white bottom-[-15px] left-1/2 transform -translate-x-1/2"></span>
//                       </h5>
//                       <div className="px-3">
//                         <div className="mt-2 mb-4 text-gray-800 flex justify-between text-[14px] font-semibold">
//                          <span className="text-[14px] font-semibold">Email </span>  {franchisee?.ownerEmail}
//                         </div>
//                         <div className="mt-2 mb-4 text-gray-800 flex justify-between text-[14px] font-semibold">
//                         <span className="text-[14px] font-semibold">Current Outlets </span> {franchisee?.currentOutlets}
//                         </div>
//                         <div className="mt-2 mb-4 text-gray-800 flex justify-between text-[14px] font-semibold">
//                           <span className="text-[14px] font-semibold">Floor Area </span> {franchisee?.floor_Area}
//                         </div>
//                         <div className="mt-2 mb-4 text-gray-800 flex justify-between text-[14px] font-semibold">
//                           <span className="text-[14px] font-semibold">Franchise Name </span> {franchisee?.franchise_name}
//                         </div>
//                         <div className="mt-2 mb-4 text-gray-800 flex justify-between text-[14px] font-semibold">
//                           <span className="text-[14px] font-semibold">Industry </span> {franchisee?.industry}
//                         </div>
//                       </div>
//                      {/* <div className="banner">
//                       <h5 className="text-gray-900 text-start text-lg font-semibold p-3  border-t ">Banner</h5>
//                       <img src="https://letsenhance.io/static/8f5e523ee6b2479e26ecc91b9c25261e/1015f/MainAfter.jpg"/>
//                      </div> */}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </main>
//         <Footer />
//       </div>
//     </>
//   );
// }

import { getFranchiseByid } from "../redux/franchiseSlice";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Children, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signout } from "../redux/authSlice";
import Header from "../HomeFiels/Comman/Header";
import Footer from "../HomeFiels/Comman/Footer";
import { VscUnverified } from "react-icons/vsc";
import { MdVerified } from "react-icons/md";
import { Ads } from "../AdsComponents/Ads";

const user = {
  name: "Tom Cook",
  email: "tom@example.com",
  imageUrl:
    "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
};

const banners = [
  {
    id: 1,
    imageUrl:
      "https://t3.ftcdn.net/jpg/06/15/49/68/360_F_615496890_W34yB8VDE6n5pehb5QCt1ek5oEvRo9qA.jpg",
    description: "Banner 1 description",
  },
  {
    id: 2,
    imageUrl:
      "https://t3.ftcdn.net/jpg/06/15/49/68/360_F_615496890_W34yB8VDE6n5pehb5QCt1ek5oEvRo9qA.jpg",
    description: "Banner 2 description",
  },
  {
    id: 3,
    imageUrl:
      "https://t3.ftcdn.net/jpg/06/15/49/68/360_F_615496890_W34yB8VDE6n5pehb5QCt1ek5oEvRo9qA.jpg",
    description: "Banner 3 description",
  },
  {
    id: 4,
    imageUrl:
      "https://t3.ftcdn.net/jpg/06/15/49/68/360_F_615496890_W34yB8VDE6n5pehb5QCt1ek5oEvRo9qA.jpg",
    description: "Banner 4 description",
  },
];

const navigation = [
  { name: "Profile", to: "/investor/profile", current: false },
  { name: "Kyc Detail", to: "/investor/Kyc/detail", current: false },
  { name: "Plan", to: "/investor/plan", current: false },
  { name: "Clients", to: "/investor/clients", current: false },
];
const userNavigation = [
  { name: "Your Profile", to: "#" },
  { name: "Settings", to: "#" },
  { name: "Sign out", to: "#" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function InvestorMenu({ Children }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { franchisee, loading, error, message } = useSelector(
    (state) => state.franchise
  );
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [currenttab, setCurrentTab] = useState("Dashboard");
  const { auth } = useSelector((state) => state.auth);

  function handleSignOut() {
    dispatch(signout());
    navigate("/");
  }

  useEffect(() => {
    if (auth?._id) {
      const _id = auth?._id;
      dispatch(getFranchiseByid({ _id }));
    }
  }, [auth?._id]);
  return (
    <>
      <div className="min-h-full">
        <div className="">
          <Header />
          <div className="lg:flex gap-x-5 w-full px-8 py-5 lg:col-span-12 sm:col-span-12 col-span-12 border shadow-lg">
            <div className="lg:w-4/5 w-full border mb-2">
              <div>{Children}</div>
            </div>
            <div className="lg:w-1/5 w-full border shadow-sm  mb-2 pb-6 ">
              <div className="grid lg:grid-cols-1 sm:grid-cols-2 grid-cols-1 ">
                <h2 className="border-b mb-3 py-2 px-3 text-lg font-semibold capitalize space-y-3">
                  Banner
                </h2>
                <div className="grid lg:grid-cols-1 grid-cols-1 ">
                  {/* <div className="relative ">
                    <div className="text-base absolute -top-3 capitalize left-0 bg-black px-4 py-2 text-white mt-3 mr-3 font-medium hover:bg-black  transition duration-500 ease-in-out">
                      ads
                    </div>
                    <img src="/investorad.png" className="w-full" />
                  </div> */}
                  {/* <div className="relative mt-4">
                    <div className="text-base absolute -top-3 capitalize -right-3 bg-black px-4 py-2 text-white mt-3 mr-3 font-medium hover:bg-black  transition duration-500 ease-in-out">
                      ads
                    </div>
                    <img src="/investoradd.png" className="w-full" />
                  </div> */}
                  <Ads adnum={1}/>
                  <Ads adnum={2}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
          
    </>
  );
}
