import { Swiper as SwiperContainer, SwiperSlide } from "swiper/react";
import "swiper/css";
import React, { useState, useEffect } from "react";
import { getAllFranchise } from "../../redux/franchiseSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "swiper/css/free-mode";
import "swiper/css/autoplay";
import { FreeMode, Autoplay } from "swiper/modules";




const PremiumOpportunities = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState();
  const dispatch = useDispatch();
  const { allfranchise, loading, error, message } = useSelector(
    (state) => state.franchise
  );

  useEffect(() => {
    dispatch(getAllFranchise());
  }, []);
  function HandleHover(bool, ind) {
    setIsHovered(bool);
    setHoveredIndex(ind);
  }
  const swiperRef = React.useRef(null);

  return (
    <>
     <section className="flex flex-col justify-center lg:pt-4 lg:px-3 max-w-full lg:mx-10 mx-6 lg:mt-2">
      <div className="w-full lg:pl-2 mb-4 px-2">
        <div className=" flex justify-between text-sm">
          <div className="text-gray-600 flex items-center pb-2 pr-2 border-b-2 border-gray-600 capitalize">
            <h2 className="font-semibold lg:text-2xl sm:text-xl text-lg text-gray-800 inline-block">
              Top Distributor item Available
            </h2>
          </div>
        </div>
      </div>
      <div className="w-full flex relative">
        <SwiperContainer
          ref={swiperRef}
          slidesPerView={4}
          freeMode={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false, 
          }}
          breakpoints={{
            100: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 4,
            },
          }}
          modules={[FreeMode, Autoplay]}
          className="mySwiper m-auto border-black"
        >
          {allfranchise?.slice(15,25)?.map((item, index) => (
            <SwiperSlide key={index} className="swiper-slider " >
              <div className="relative bg-gray-900 shadow-lg rounded-lg py-4 border overflow-hidden">
                <div className="px-4 py-1 border-b">
                  {item?.isVerified && (
                    <div className="absolute top-0 right-0">
                      <div className="w-32 h-8 absolute top-4 -right-8">
                        <div className="h-full w-full bg-amber-600 text-white text-center text-sm leading-8 font-semibold transform rotate-45">
                          Verified
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="pr-12">
                     <h3 className="font-semibold text-sm text-gray-200 mb-1">
                    {item.franchise_name}
                     </h3>
                   </div>
                  <div className="flex gap-3 items-center mb-4">
                  <div className="flex items-center text-yellow-500 ">
                    ⭐ <span className="ml-2">5 / 5</span>
                  </div>
                  <div className="text-gray-500 text-[12px]  ">
                    {item?.address}
                  </div>
                  </div>
                </div>
                <div className="p-4 mt-2">
                  <div className=" text-[12px] text-gray-200  mb-2 flex">
                    <h3 className="font-semibold pe-2 capitalize  text-[12px]">
                      Industry :
                    </h3>{" "}
                    {item?.industry}
                  </div>
                  <div className=" text-[12px] text-gray-200  mb-2 flex">
                    <h3 className="font-semibold pe-2 capitalize">Sector :</h3>{" "}
                    {item?.sector}
                  </div>
                  <div className=" text-[12px] text-gray-200  mb-2 flex justify-between items-start">
                    <h3 className="font-semibold pe-2 capitalize">
                      Investment Tier 1 :
                    </h3>{" "}
                    {item?.investment_range_tier_1}{" "}
                    <span className="text-gray-900 rounded-sm bg-green-100 shadow-xl border font-semibold px-2 text-[11px]">
                      INR
                    </span>
                  </div>
                  <div className=" text-[12px] text-gray-200  mb-2 flex justify-between items-start">
                    <h3 className="font-semibold pe-2 capitalize">
                      Investment Tier 2:
                    </h3>{" "}
                    {item?.investment_range_tier_2}{" "}
                    <span className="text-gray-900 rounded-sm bg-green-100 shadow-xl border font-semibold px-2 text-[11px]">
                      INR
                    </span>
                  </div>
                  <div className=" text-[12px] text-gray-200  mb-2 flex">
                    <h3 className="font-semibold pe-2 capitalize">Space :</h3>{" "}
                    {item.floor_Area} Sq. ft
                  </div>
                <Link to={`/franchisedetail/${item?.franchise_name.replace(/ /g, "_")}/${item?._id}`}>
                  <button className="mt-4 bg-indigo-500 text-sm text-white py-2 px-4 rounded hover:bg-indigo-600 w-full">
                    View item
                  </button></Link>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </SwiperContainer>
        <div className="absolute top-1/2 transform -translate-y-1/2 -left-6 z-10">
          <button
            className="bg-gray-200 rounded-lg shadow-md px-4 py-2 hover:bg-gray-300 hover:text-white transition-all duration-300 ease-in-out"
            onClick={() => swiperRef.current.swiper.slidePrev()}
          >
            ❮
          </button>
        </div>
        <div className="absolute top-1/2 transform -translate-y-1/2 -right-6 z-10">
          <button
            className="bg-gray-200 rounded-lg shadow-md px-4 py-2 hover:bg-gray-300 hover:text-white transition-all duration-300 ease-in-out"
            onClick={() => swiperRef.current.swiper.slideNext()}
          >
            ❯
          </button>
        </div>
      </div>
    </section>
    
 
    </>
   
  );
};

export default PremiumOpportunities;
