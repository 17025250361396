import React from "react";
import { useEffect, useState } from "react";
import { makePayment, clearErrors, clearMessage } from "../redux/paymentSlice";
import { getAllUserPlan, getUserPlanByid } from "../redux/PlanSlice";
import { addLeads} from "../redux/leadUpdateSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import SuccessAlert from "../BaseFile/SuccessAlert";
import ErrorAlert from "../BaseFile/ErrorAlert";

const UserPlan = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { order, error, message } = useSelector((state) => state.buyPayment);
  const { auth } = useSelector((state) => state.auth);
  const { alluserplan, singleuserplan } = useSelector((state) => state.plans);
  const [planId, setPlanId] = useState();
  const [planPrice, setPlanPrice] = useState();
  const [leads, setLeads] = useState();
  const [err, setErr] = useState("");
  const [msg, setMsg] = useState("");

  const handlePayment = async (plan) => {
    try {
      if (auth?._id) {
        setPlanId(plan?._id);
        setPlanPrice(plan?.price);
        setLeads(plan?.leads);
        const paymentData = {
          userId: auth?._id,
          amount: plan.price,
        };
        dispatch(makePayment(paymentData));
      } else {
        setMsg("Login First");
        return null;
      }
    } catch (error) {
      setErr("Payment initiation error:", error);
    }
  };

  useEffect(() => {
    if (order) {
      const options = {
        key: "rzp_test_5KXGllvzN3WGTt", // Add your Razorpay key ID
        amount: order.amount,
        currency: order.currency,
        name: "Way 4 Franchise ",
        description: "Test Transaction",
        order_id: order.id,
        handler: async (response) => {
          const { razorpay_order_id, razorpay_payment_id, razorpay_signature } =
            response;
          try {
            const paymentVerificationResponse = await axios.post(
              "https://api.way4franchise.com/api/v1/payment/verify/buy",
              {
                razorpay_order_id,
                razorpay_payment_id,
                razorpay_signature,
                userId: auth?._id,
                planId: planId,
                amount: planPrice,
              }
            );

            if (paymentVerificationResponse.data.success) {
              setMsg("Payment successful and verified!");
              const userId = auth?._id;        
              dispatch(addLeads({userId,leads}))
            } else {
              setErr("Payment verification failed.");
            }
          } catch (error) {
            setErr("Payment verification error.");
          }
        },
        theme: {
          color: "#3399cc",
        },
      };
      const razorpay = new window.Razorpay(options);
      razorpay.open();
    }
  }, [order]);

  useEffect(() => {
    dispatch(getAllUserPlan());
    if (auth?._id) {
      const id = auth?._id;
      dispatch(getUserPlanByid(id));
    }
    if (err) {
      const errorInterval = setInterval(() => {
        setErr(null);
      }, 3000);
      return () => clearInterval(errorInterval);
    }
    if (msg) {
      const messageInterval = setInterval(() => {
        setMsg(null);
      }, 3000);
      return () => clearInterval(messageInterval);
    }
    if (error) {
      const errorInterval = setInterval(() => {
        dispatch(clearErrors());
      }, 3000);
      return () => clearInterval(errorInterval);
    }

    if (message) {
      const messageInterval = setInterval(() => {
        dispatch(clearMessage());
      }, 3000);
      return () => clearInterval(messageInterval);
    }
  }, [dispatch, message, error, err, msg, auth?._id]);
  return (
    <>
      {message || (msg && <SuccessAlert message={message || msg} />)}
      {error || (err && <ErrorAlert error={error || err} />)}
      <div className="lg:container lg:mx-auto mt-2 ">
        <div className="flex w-full overflow-x-auto ">
          <table className="w-full border-collapse border border-gray-300">
            <thead className="bg-gray-200 text-[15px]">
              <tr>
                <th className="border border-gray-300 p-3 text-left font-semibold bg-gray-600 text-white">
                  Feature
                </th>
              </tr>
              <tr className="text-gray-900  bg-gray-100 text-[14px]">
                <th className="border border-gray-300 p-3 font-semibold text-left">
                  <p>Price</p>
                  <p>Leads</p>
                  <p>duration</p>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="text-gray-800 text-[12px]">
                <td className="border border-gray-300 p-3 font-semibold text-left">
                  Franchise item
                </td>
              </tr>
              <tr className="text-gray-800 text-[12px]">
                <td className="border text-red-500 border-gray-300 p-3 font-semibold text-left">
                  Search and View Opp.
                </td>
              </tr>
              <tr className="text-gray-800 text-[12px]">
                <td className="border border-gray-300 p-3 font-semibold text-left">
                  View Franchise Listings
                </td>
              </tr>
              <tr className="text-gray-800 text-[12px]">
                <td className="border border-gray-300 p-3 font-semibold text-left text-red-600">
                  Newsletter Access
                </td>
              </tr>
              <tr className="text-gray-800 text-[12px]">
                <td className="border border-gray-300 p-3 font-semibold text-left">
                  Matching Opportunities
                </td>
              </tr>
              <tr className="text-gray-800 text-[12px]">
                <td className="border border-gray-300 p-3 font-semibold text-left">
                  Consultant Support
                </td>
              </tr>
              <tr className="text-gray-800 text-[12px]">
                <td className="border border-gray-300 p-3 font-semibold text-left">
                  Financial Analysis
                </td>
              </tr>
              <tr className="text-gray-800 text-[12px]">
                <td className="border border-gray-300 p-3 font-semibold text-left">
                  Legal Agreement Support
                </td>
              </tr>
              <tr className="text-gray-800 text-[12px]">
                <td className="border border-gray-300 p-3 font-semibold text-left">
                  Brand Meetings & Notifi.
                </td>
              </tr>
              <tr className="text-gray-800 text-[12px]">
                <td className="border border-gray-300 p-3 font-semibold text-left">
                  Brokerage & Comm. Fees
                </td>
              </tr>
              <tr className="text-gray-800 text-[12px]">
                <td className="border border-gray-300 p-3 font-semibold text-left">
                  Action
                </td>
              </tr>
            </tbody>
          </table>
          {alluserplan?.map((item, index) => (
            <table className="w-full border-collapse border border-gray-300 items-center">
              <thead className="bg-gray-200 text-[15px]">
                <tr>
                  <th className="border border-gray-300 p-3 text-center font-semibold bg-gray-600 text-white">
                    {item?.name.includes("Free") ? "Free Plan" : item.name}
                  </th>
                </tr>
                <tr className="text-gray-900  bg-gray-100 text-[14px]">
                  <th className="border border-gray-300  font-semibold">
                    <div className=" whitespace-pre-line break-all p-3 text-center">
                      <p> Rs. {item?.price} </p>
                      <p> {item?.leads} Leads</p>
                      <p> {item?.duration} Days</p>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="text-gray-800 text-[12px]">
                  <td className="border border-gray-300 p-3 text-center ">
                    {item?.length > 0 && item?.franchiseMembership ? "✅" : "✖"}
                  </td>
                </tr>
                <tr className="text-gray-800 text-[12px]">
                  <td className="border border-gray-300 p-3 text-center text-red-600">
                    {item?.searchViewOpportunities}
                  </td>
                </tr>
                <tr className="text-gray-800 text-[12px]">
                  <td className="border border-gray-300 p-3 text-center">
                    {item?.viewFranchiseListings}
                  </td>
                </tr>
                <tr className="text-gray-800 text-[12px]">
                  <td className="border border-gray-300 p-3 text-center">
                    {item?.length > 0 && item?.newsletter ? "✅" : "✖"}
                  </td>
                </tr>
                <tr className="text-gray-800 text-[12px]">
                  <td className="border border-gray-300 p-3 text-center">
                    {" "}
                    {item?.matchingOpportunities}{" "}
                  </td>
                </tr>
                <tr className="text-gray-800 text-[12px]">
                  <td className="border border-gray-300 p-3 text-center text-red-600">
                    {item?.consultantSupport}
                  </td>
                </tr>
                <tr className="text-gray-800 text-[12px]">
                  <td className="border border-gray-300 p-3 text-center ">
                    {item?.financialAnalysis}
                  </td>
                </tr>
                <tr className="text-gray-800 text-[12px]">
                  <td className="border border-gray-300 p-3 text-center ">
                    {item?.legalAgreementSupport}
                  </td>
                </tr>
                <tr className="text-gray-800 text-[12px]">
                  <td className="border border-gray-300 p-3 text-center ">
                    {item?.brandMeetings}
                  </td>
                </tr>
                <tr className="text-gray-800 text-[12px]">
                  <td className="border border-gray-300 p-3 text-center">
                    {" "}
                    {item?.brokerageCommissionFees}
                  </td>
                </tr>
                <tr className="text-gray-800 text-[12px]">
                  <td className="border border-gray-300  text-center">
                    <div className="">
                      {singleuserplan &&
                      item?._id === singleuserplan?.planId ? (
                        <button className="inline-block w-full  py-3 bg-green-800 text-white font-semibold text-md transition-all">
                          Activated
                        </button>
                      ) : singleuserplan?.length == 0 && item?.price === 0 ? (
                        <button className="inline-block w-full  bg-green-800 text-white font-semibold text-md transition-all">
                          Activated
                        </button>
                      ) : (
                        <button
                          onClick={() => handlePayment(item)}
                          className="inline-block w-full py-3  bg-black text-white font-semibold text-md transition-all"
                        >
                          Buy Plan
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          ))}
        </div>
      </div>
    </>
  );
};

export default UserPlan;
