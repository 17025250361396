import React from 'react';
import { Link,useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";

import { getUser } from "../redux/userSlice";
const AdminUserProfile = () => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const { singleuser, loading } = useSelector((state) => state.allusers);

    useEffect(() => {
      if (id) {
        dispatch(getUser(id));
      }
    }, [id]);
  return (
    <div className="bg-gray-100 min-h-screen flex items-center justify-center p-4">
      <div className="bg-white shadow-lg rounded-lg overflow-hidden  w-full">
        {/* Cover Image */}
        <div className="relative">
          <img
            className="w-full h-64 object-cover"
            src="https://img.freepik.com/free-photo/nice-landscape-with-boat_1048-4711.jpg?size=626&ext=jpg&ga=GA1.1.1433286368.1718702777&semt=ais_hybrid" // Replace with actual cover image URL
            alt="Cover"
          />
          <div className="absolute bottom-0 left-0 px-6 py-4 flex items-center space-x-4">
            {/* Profile Image */}
            <img
              className="w-24 h-24 rounded-full border-4 border-white shadow-lg"
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcleiXMlhy7h6oAs0Xi6NUUUtbCg3CppIoXg&s" // Replace with actual profile image URL
              alt="Profile"
            />
            <div>
              <h2 className="text-2xl font-bold text-gray-800">Daenerys Targaryen</h2>
            </div>
          </div>
        </div>

        {/* Tabs */}
        <div className="border-b">
          <nav className="px-4 py-4">
            
            <a href="#about" className="text-gray-900 uppercase text-base font-semibold hover:text-blue-500 border-b-2 border-transparent hover:border-blue-500 px-2 py-1">
              About
            </a>
           
          </nav>
        </div>

        {/* Content Section */}
        <div className="p-6 grid grid-cols-1 ">
          {/* Basic Info */}
          <div>
            <h3 className="text-base font-semibold text-gray-800 mb-4">Personal Info</h3>
            <ul className="space-y-2 text-gray-600">
              <li className="flex justify-between text-[13px]">
                <span>Name</span>
                <span>{singleuser?.username}</span>
              </li>
              <li className="flex justify-between text-[13px]">
                <span>Email</span>
                <span>{singleuser?.email}</span>
              </li>
              <li className="flex justify-between text-[13px]">
                <span>Phone No.</span>
                <span>{singleuser?.phone}</span>
              </li>
              <li className="flex justify-between text-[13px]">
                <span>date</span>
                <span>10 Mar 1984</span>
              </li>
              <li className="flex justify-between text-[13px]">
                <span>Website</span>
                <span>website.com</span>
              </li>
            </ul>
          </div>
    
        </div>
      </div>
    </div>
  );
};

export default AdminUserProfile;
