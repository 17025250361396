import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon, PlusIcon } from "@heroicons/react/20/solid";
import Header from "../Comman/Header";
import Footer from "../Comman/Footer";
import React, { useState, useEffect } from "react";
import { getAllFranchise } from "../../redux/franchiseSlice";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, Link } from "react-router-dom";
import filters from "../../BaseFile/FilterData"
import { Ads } from "../../AdsComponents/Ads";
const btn = ["bg-blue-900 ", "bg-green-700", "bg-purple-700", "bg-yellow-700"];


export default function FilteredFranchise() {
  const [searchParams] = useSearchParams();
  const category = searchParams.get("category");
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState();
  const [filterData, setFilterData] = useState();
  const [selectedFilters, setSelectedFilters] = useState({
    investment_range: [],
    location: [],
    category: [],
  });
  const dispatch = useDispatch();
  const { allfranchise, loading, error, message } = useSelector(
    (state) => state.franchise
  );

  useEffect(() => {
    dispatch(getAllFranchise());
    if (
      selectedFilters.investment_range.length > 0 ||
      selectedFilters.location.length > 0
    ) {
      setFilterData(
        DisplayFranchise?.filter(
          (item) =>
            selectedFilters["investment_range"]?.includes(
              item?.investment_range_tier_1
            ) ||
            selectedFilters["location"]?.includes(
              Object.keys(item?.selectedStates)
            )
        )
      );
    }
  }, [selectedFilters.investment_range, selectedFilters.location, category]);

  const DisplayFranchise = allfranchise?.filter(
    (item) => item?.industry == category
  );

  function HandleHover(bool, ind) {
    setIsHovered(bool);
    setHoveredIndex(ind);
  }
  const handleFilter = (sectionId, optionValue) => {
    setSelectedFilters((prevState) => {
      const selectedInSection = prevState[sectionId] || [];
      let updatedFilters;

      if (selectedInSection.includes(optionValue)) {
        updatedFilters = {
          ...prevState,
          [sectionId]: selectedInSection.filter(
            (value) => value !== optionValue
          ),
        };
      } else {
        updatedFilters = {
          ...prevState,
          [sectionId]: [...selectedInSection, optionValue],
        };
      }

      applyFilters(updatedFilters);
      return updatedFilters;
    });
  };

  const applyFilters = (filters) => {
    let filteredData = DisplayFranchise;

    // Apply investment range filter
    if (filters.investment_range.length > 0) {
      filteredData = filteredData.filter((item) =>
        filters.investment_range.includes(item?.investment_range_tier_1)
      );
    }

    // Apply location filter
    if (filters.location.length > 0) {
      filteredData = filteredData.filter((item) =>
        filters.location.some((location) =>
          Object.keys(item?.selectedStates).includes(location)
        )
      );
    }

    // Apply category filter
    if (filters.category.length > 0) {
      filteredData = filteredData.filter((item) =>
        filters.category.includes(item?.category)
      );
    }

    setFilterData(filteredData);
  };

  return (
    <>
      <Header />
      <div className="bg-white">
        <div>
          <Dialog
            open={mobileFiltersOpen}
            onClose={setMobileFiltersOpen}
            className="relative z-40 lg:hidden"
          >
            <DialogBackdrop
              transition
              className="fixed inset-0 bg-black bg-opacity-25 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
            />

            <div className="fixed inset-0 z-40 flex">
              <DialogPanel
                transition
                className="relative ml-auto flex h-full w-full max-w-xs transform flex-col overflow-y-auto bg-white py-4 pb-6 shadow-xl transition duration-300 ease-in-out data-[closed]:translate-x-full"
              >
                <div className="flex items-center justify-between px-4">
                  <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                  <button
                    type="button"
                    onClick={() => setMobileFiltersOpen(false)}
                    className="-mr-2 flex h-10 w-10 items-center justify-center p-2 text-gray-400 hover:text-gray-500"
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                  </button>
                </div>

                {/* Filters */}
                {filters.map((section, index) => (
                  <Disclosure
                    key={index}
                    as="div"
                    className="border-t border-gray-200 pb-4 pt-4"
                  >
                    <fieldset>
                      <legend className="w-full px-2">
                        <DisclosureButton className="group flex w-full items-center justify-between p-2 text-gray-400 hover:text-gray-500">
                          <span className="text-sm font-medium text-gray-900">
                            {section.name}
                          </span>
                          <span className="ml-6 flex h-7 items-center">
                            <ChevronDownIcon
                              aria-hidden="true"
                              className="h-5 w-5 rotate-0 transform group-data-[open]:-rotate-180"
                            />
                          </span>
                        </DisclosureButton>
                      </legend>
                      <DisclosurePanel className="px-4 pb-2 pt-4">
                        <div className="space-y-6">
                          {section.options.map((option, optionIdx) => (
                            <div key={optionIdx} className="flex items-center">
                              <input
                                value={option.value}
                                id={`${section.id}-${optionIdx}-mobile`}
                                name={`${section.id}[]`}
                                type="checkbox"
                                checked={selectedFilters[section.id]?.includes(
                                  option.value
                                )}
                                onChange={() =>
                                  handleFilter(section.id, option.value)
                                }
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                              />
                              <label
                                htmlFor={`${section.id}-${optionIdx}-mobile`}
                                className="ml-3 text-sm text-gray-500"
                              >
                                {option.label}
                              </label>
                            </div>
                          ))}
                        </div>
                      </DisclosurePanel>
                    </fieldset>
                  </Disclosure>
                ))}
                {/* Optionally, you can log or display the selected values */}
              </DialogPanel>
            </div>
          </Dialog>
          <div className="lg:p-12 p-4">
         <Ads adnum={4}/>
          </div>
          <main className="mx-auto max-w-2xl px-4  sm:px-6  lg:max-w-full lg:px-12">
            <div className="lg:pt-12 grid grid-cols-12 gap-3">
              <div className="lg:col-span-2 md:col-span-2 col-span-12 border  p-2 rounded-md shadow-sm">
                <aside>
                  <h2 className="sr-only">Filters</h2>

                  <button
                    type="button"
                    onClick={() => setMobileFiltersOpen(true)}
                    className="inline-flex items-center lg:hidden"
                  >
                    <span className="text-sm font-medium text-gray-700">
                      Filters
                    </span>
                    <PlusIcon
                      aria-hidden="true"
                      className="ml-1 h-4 w-4 flex-shrink-0 text-gray-400 "
                    />
                  </button>

                  <div className="hidden lg:block">
                    <form className="">
                      {filters.map((section) => (
                        <Disclosure
                          key={section.name}
                          as="div"
                          className="border-b border-gray-200 pb-4 pt-2"
                        >
                          <fieldset>
                            <legend className="w-full px-2">
                              <DisclosureButton className="group flex w-full items-center justify-between  text-gray-400 hover:text-gray-500">
                                <span className="text-sm font-medium text-gray-900">
                                  {section.name}
                                </span>
                                <span className="ml-6 flex h-7 items-center">
                                  <ChevronDownIcon
                                    aria-hidden="true"
                                    className="h-5 w-5 rotate-0 transform group-data-[open]:-rotate-180"
                                  />
                                </span>
                              </DisclosureButton>
                            </legend>
                            <DisclosurePanel className="px-4 pb-2 pt-4">
                              <div className="space-y-6">
                                {section.options.map((option, optionIdx) => (
                                  <div
                                    key={option.value}
                                    className="flex items-center"
                                  >
                                    <input
                                      defaultValue={option.value}
                                      id={`${section.id}-${optionIdx}-mobile`}
                                      name={`${section.id}[]`}
                                      type="checkbox"
                                      // Set checked based on whether the option is in selected filters
                                      checked={
                                        selectedFilters[section.id]?.includes(
                                          option.value
                                        ) || false
                                      }
                                      onChange={() =>
                                        handleFilter(section.id, option.value)
                                      }
                                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                    />
                                    <label
                                      htmlFor={`${section.id}-${optionIdx}-mobile`}
                                      className="ml-3 text-sm text-gray-500"
                                    >
                                      {option.label}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </DisclosurePanel>
                          </fieldset>
                        </Disclosure>
                      ))}
                    </form>
                  </div>
                </aside>
              </div>
              {/* Product grid */}
              <div className="lg:col-span-10  col-span-12">
                <div className=" lg:col-span-2 lg:mt-0 xl:col-span-3">
                  <div className="lg:col-span-9 sm:col-span-12 col-span-12   border py-4 rounded-md  shadow-sm">
                    <div className="w-full  border-b pb-3  px-4 mb-4">
                      <div className="flex justify-between text-sm ">
                        <div className="text-gray-800 flex items-center pb-2 pr-2  capitalize">
                          <h2 className="font-semibold  lg:text-2xl sm:text-xl text-lg text-gray-800 capitalize inline-block">
                            {category ? category : "All Franchise"}
                          </h2>
                        </div>
                      </div>
                    </div>

                    {loading ? ( 
                      <div className="text-green-600 flex items-center justify-center h-32">
                     <img className="h-32" src="loading.gif"/></div>
                    ) : DisplayFranchise?.length == 0 ? (
                      <p className="text-red-600 flex items-center justify-center">No Franchise Found For This Category</p>
                    ) : (
                      <div className="max-w-full grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1">
                        {(filterData?.length > 0
                          ? filterData
                          : DisplayFranchise
                        )
                          ?.filter((item) => item.isDeleted == "no")
                          ?.map((item, index) => (
                            <div
                              key={item.index}
                              className="w-full px-2 mb-5 mt-3 "
                            >
                              <div className="bg-white rounded-md p-2  transform shadow-md hover:translate-y-2 hover:shadow-xl  transition duration-300 border">
                                <figure className="mb-2">
                                  <img
                                    src={
                                      item?.companyLogo
                                        ? `/uploads/InvestorData/${item?.companyLogo}`
                                        : "/defaultlogo.png"
                                    }
                                    alt={item.title}
                                    className="h-40 w-full"
                                    // mx-auto object-contain
                                  />
                                </figure>

                                <div
                                  className={`rounded-lg  py-2 flex flex-col `}
                                >
                                  <div className="flex justify-between">
                                    <span className="text-xs text-gray-800 capitalize">
                                      {" "}
                                      {item?.looking_for}
                                    </span>
                                    <span className="text-xs text-gray-800 mt-[0.5px] capitalize">
                                      {item?.currentOutlets}
                                    </span>
                                  </div>
                                  <h5 className=" text-base font-semibold mt-[0.5px] capitalize">
                                    {item?.franchise_name}
                                  </h5>
                                  <span className="text-xs text-gray-500 mt-[0.5px] capitalize">
                                    {item?.floor_Area}sq.ft
                                  </span>
                                  <div className=" items-center">
                                    <span className="text-[11px] text-green-600 font-medium capitalize">
                                      {item?.investment_range_tier_1} for Tier 1
                                      cities
                                    </span>
                                  </div>
                                  <div className=" items-center">
                                    <span className="text-[11px] text-green-600 font-medium capitalize">
                                      {item?.investment_range_tier_2} for Tier 2
                                      cities
                                    </span>
                                  </div>
                                </div>
                                <div className="relative">
                                <Link to={`/franchisedetail/${item?.franchise_name.replace(/ /g, "_")}/${item?._id}`}>
                                    <button
                                      className={`rounded-full text-white ${
                                        btn[index % btn.length]
                                      } hover:shadow-xl focus:outline-none w-10 h-10 flex items-center justify-center ml-auto transition-all duration-300 transform ${
                                        isHovered ? "hover:w-full" : ""
                                      }`}
                                      onMouseEnter={() =>
                                        HandleHover(true, index)
                                      }
                                      onMouseLeave={() =>
                                        HandleHover(false, null)
                                      }
                                    >
                                      {hoveredIndex == index ? (
                                        <span
                                          className="absolute left-0 right-0 text-center opacity-1 transition-opacity duration-300"
                                          // style={{ opacity: isHovered  ? "1" : "0" }}
                                        >
                                          Know More
                                        </span>
                                      ) : (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="w-6 h-6 transition-opacity duration-300"
                                          stroke="currentColor"
                                          viewBox="0 0 24 24"
                                          // style={{
                                          //   opacity: !isHovered ? "1" : "0", // Show icon on non-hover
                                          //   transition: "opacity 0.3s ease",
                                          // }}
                                        >
                                          <line
                                            x1="12"
                                            y1="5"
                                            x2="12"
                                            y2="19"
                                          />
                                          <line
                                            x1="5"
                                            y1="12"
                                            x2="19"
                                            y2="12"
                                          />
                                        </svg>
                                      )}
                                    </button>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="lg:col-span-2 col-span-12 border shadow-sm rounded-md">
                  <div className="">
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwtgZcaVRHWJD4h5gcANAMg_-5sqcJZ1jwHqFwIv_w2M9_cUj9RTrjAVTsemClWHmeTO4&usqp=CAU"
                      className="w-full"
                    />
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwtgZcaVRHWJD4h5gcANAMg_-5sqcJZ1jwHqFwIv_w2M9_cUj9RTrjAVTsemClWHmeTO4&usqp=CAU"
                      className="w-full"
                    />
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwtgZcaVRHWJD4h5gcANAMg_-5sqcJZ1jwHqFwIv_w2M9_cUj9RTrjAVTsemClWHmeTO4&usqp=CAU"
                      className="w-full"
                    />
                  </div>
                </div> */}
            </div>
          </main>
        </div>
      </div>
      <div className="lg:p-12 p-4">
        <img src="/Solutions.png" className="w-full lg:h-96" />
      </div>
      <Footer />
    </>
  );
}
