import React from "react";
import { useState ,useEffect} from "react";

import { useSelector, useDispatch } from "react-redux";
import { getAllCopupon } from "../redux/couponSlice";
import { servicePay,clearErrors,clearMessage} from "../redux/serviceSlice";
import SuccessAlert from "../BaseFile/SuccessAlert";
import ErrorAlert from "../BaseFile/ErrorAlert";
import Spinner from "../BaseFile/Spinner";

export const PayConfirmation = ({ isClose, id, amount ,service}) => {
  const dispatch = useDispatch();
  const {coupon} = useSelector((state) => state.coupon);
  const {auth} = useSelector((state) => state.auth);
  const {loading, message, error} = useSelector((state) => state.service);
  const [paidAmount, setPaidAmount] = useState(amount);
  const [ccode, setCcode] = useState("");
  const [dis, setDis] = useState("");

  useEffect(() => {
    dispatch(getAllCopupon())
    if (error) {
      const errorInterval = setInterval(() => {
        dispatch(clearErrors());
      }, 3000);
      return () => clearInterval(errorInterval);
    }
    if (message) {
      const messageInterval = setInterval(() => {
        dispatch(clearMessage());
      }, 3000);
      return () => clearInterval(messageInterval);
    }
  }, [dispatch, message, error, clearErrors, clearMessage]);

  const handlePay = () => {
    console.log(dis)
    dispatch(servicePay({id:id,amount:amount,discount:dis,paidAmount:paidAmount,franchise_id:auth?._id ,coupanname:ccode }));
  };

  const handleApplyCoupon = (a,code) => {
    setPaidAmount(amount*(100-a)/100);
    setCcode(code);
    setDis(a);
  };

  return (
    <>
      {id && (
        <div
          id="deleteModal"
          tabindex="-1"
          aria-hidden="true"
          class="overflow-y-auto overflow-x-hidden fixed flex items-center bg-[#00000094]  z-50 justify-center  w-full md:inset-0 h-modal "
        >
        <div className="relative p-4 max-w-4xl h-full">
  <div className="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      {coupon?.filter((item)=>(item.service==service))?.map((coupon) => (
        <div
          key={coupon._id}
          className={`bg-gradient-to-r text-white px-4 py-6 rounded-lg shadow-lg`}
        >
          <div className="text-sm mb-2">
            Get{" "}
            <span className="text-yellow-400 font-bold">
              {coupon?.discount}%
            </span>{" "}
            on your purchase!
          </div>
          <div className="text-base mb-2">Use coupon code</div>
          <div className="bg-white text-gray-800 rounded-lg px-4 py-2 flex items-center justify-between">
            <span className="text-sm font-semibold">{coupon?.code}</span>
            <button
            onClick={()=>handleApplyCoupon(coupon?.discount,coupon?.code)}
            className="bg-blue-800 text-sm text-white px-3 py-1 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500">
              Apply
            </button>
          </div>
        </div>
      ))}
    </div>
    <p className="my-4 text-gray-500 dark:text-gray-300">
      Are you sure you want to Pay {paidAmount} Confirm?
    </p>
    <div className="flex justify-center items-center space-x-4">
      <button
        type="button"
        onClick={isClose}
        className="py-2 px-3 text-[12px] font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
      >
        No, cancel
      </button>
      <button
        type="submit"
        onClick={()=>handlePay(coupon?.discount)}
        className="py-2 px-3 text-[12px] font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900"
      >
        {loading ? <Spinner/> : "Yes, I'm sure"}
        
      </button>
    </div>
  </div>
</div>

        </div>
      )}
    </>
  );
};
