import React, { useState } from "react";
import { Link } from "react-router-dom";
import { signoutAdmin } from "../redux/authSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MdBusinessCenter } from "react-icons/md";
import { FaHome } from "react-icons/fa";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  TransitionChild,
} from "@headlessui/react";
import { GrArticle } from "react-icons/gr";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { HomeIcon } from "@heroicons/react/20/solid";
import {
  Bars3Icon,
  Cog6ToothIcon,
  UsersIcon,
  BuildingStorefrontIcon,
  ChartBarIcon,
  IdentificationIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { AiOutlineDashboard } from "react-icons/ai";
import { SiOnlyoffice } from "react-icons/si";

const navigation = [
  {
    name: "Dashboard",
    to: "/admin/dashboard",
    icon: AiOutlineDashboard,
    current: true,
  },
  { name: "Users", to: "/admin/users", icon: UsersIcon, current: false },
  {
    name: "Investors",
    to: "/admin/investors",
    icon: SiOnlyoffice,
    current: false,
  },
  {
    name: "Kyc Request",
    to: "/admin/kycRequest",
    icon: IdentificationIcon,
    current: false,
  },
  {
    name: "Buy Detail",
    to: "/admin/buydetail",
    icon: MdBusinessCenter,
    current: false,
  },
  {
    name: "Manage Home ",
    to: "/admin/manage/home",
    icon: FaHome,
    current: false,
  },
  {
    name: "Marketing ",
    to: "/admin/marketing",
    icon: ChartBarIcon,
    current: false,
  },
  {
    name: "Manage Ads ",
    to: "/admin/manage/ads",
    icon: ChartBarIcon,
    current: false,
  },
  {
    name: "Coupon",
    to: "/admin/coupon",
    icon: ChartBarIcon,
    current: false,
  },
  {
    name: "Payment History",
    to: "/admin/payment/history",
    icon: FaMoneyCheckAlt,
    current: false,
  },
  {
    name: "Service Request",
    to: "/admin/service/request",
    icon: FaMoneyCheckAlt,
    current: false,
  },
  {
    name: "User Plans",
    to: "/admin/add/userplan",
    icon: FaMoneyCheckAlt,
    current: false,
  },
  {
    name: "Investor Plans",
    to: "/admin/add/investorplan",
    icon: FaMoneyCheckAlt,
    current: false,
  },
  
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function AdminMenu({ Children, PageName }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [tab, setTab] = useState(false);
  const pages = [
    { name: "Projects", href: "#", current: false },
    { name: "Project Nero", href: "#", current: true },
  ];
  function handleSignOut() {
    dispatch(signoutAdmin());
    navigate("/");
  }
  return (
    <>
      <div className=" h-auto">
        <Dialog
          open={sidebarOpen}
          onClose={setSidebarOpen}
          className="relative z-50 lg:hidden"
        >
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
          />

          <div className="fixed inset-0 flex">
            <DialogPanel
              transition
              className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full"
            >
              <TransitionChild>
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                  <button
                    type="button"
                    onClick={() => setSidebarOpen(false)}
                    className="-m-2.5 p-2.5"
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon
                      aria-hidden="true"
                      className="h-6 w-6 text-white"
                    />
                  </button>
                </div>
              </TransitionChild>
              {/* Sidebar component, swap this element with another sidebar if you like */}
              <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4 ring-1 ring-white/10">
                <div className="flex h-16 shrink-0 items-center">
                  <Link to="/">
                    <img
                      alt="Your Company"
                      src="/logo.png"
                      className="h-8 w-auto"
                    />
                  </Link>
                </div>
                <nav className="flex flex-1 flex-col no-scrollbar">
                  <ul role="list" className="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" className="-mx-2 space-y-1">
                        {navigation.map((item) => (
                          <li key={item.name}>
                            <Link
                              to={item.to}
                              onClick={()=>setTab(item.name)}
                              className={classNames(
                                item.name==tab
                                  ? "bg-gray-800 text-white"
                                  : "text-gray-400 hover:bg-gray-800 hover:text-white",
                                "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                              )}
                            >
                              <item.icon
                                aria-hidden="true"
                                className="h-6 w-6 shrink-0"
                              />
                              {item.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </li>

                    <li className="mt-auto">
                      <button
                        onClick={handleSignOut}
                        className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
                      >
                        <Cog6ToothIcon
                          aria-hidden="true"
                          className="h-6 w-6 shrink-0"
                        />
                        LogOut
                      </button>
                    </li>
                    <li className="mt-auto">
                      <Link
                        to="#"
                        className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
                      >
                        <Cog6ToothIcon
                          aria-hidden="true"
                          className="h-6 w-6 shrink-0"
                        />
                        Settings
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </div>
        </Dialog>

        {/* Static sidebar for desktop */}
        <div className="hidden px-4  lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4  rounded-l-2xl">
            <div className="flex h-16 shrink-0 items-center">
              <Link to="/">
                <img
                  alt="Your Company"
                  src="/logo.png"
                  className="h-8 w-auto"
                />
              </Link>
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-3">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <Link
                          to={item.to}
                          onClick={()=>setTab(item.name)}
                          className={classNames(
                            item.name==tab
                              ? " text-white bg-gradient-to-l from-gray-50 via-blue-700 to-blue-600"
                              : "text-white hover:bg-gradient-to-l hover:from-gray-50 hover:via-blue-700 hover:to-blue-600 hover:text-white",
                            "group flex gap-x-3 rounded-md p-3 text-sm leading-6 items-center"
                          )}
                        >
                          <item.icon
                            aria-hidden="true"
                            className="h-5 w-5 shrink-0"
                          />
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>

                <li className="mt-auto">
                  <button
                    onClick={handleSignOut}
                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
                  >
                    <Cog6ToothIcon
                      aria-hidden="true"
                      className="h-6 w-6 shrink-0"
                    />
                    LogOut
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-64  mx-4">
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 px-4  sm:gap-x-6 sm:px-6 lg:px-8 bg-white  border border-t-0 shadow-sm">
            <button
              type="button"
              onClick={() => setSidebarOpen(true)}
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon aria-hidden="true" className="h-6 w-6" />
            </button>

            {/* Separator */}
            <div
              aria-hidden="true"
              className="h-6 w-px bg-gray-900/10 lg:hidden"
            />

            <nav
              aria-label="Breadcrumb"
              className="flex text-gray-600 text-[14px] font-semibold"
            >
              <ol role="list" className="flex items-center space-x-3">
                <li>
                  <div>
                    <a href="#">
                      <HomeIcon
                        aria-hidden="true"
                        className="h-4 w-4 flex-shrink-0"
                      />
                      <span className="sr-only">Home</span>
                    </a>
                  </div>
                </li>
                <li>
                  <div className="flex items-center ">
                    <svg
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                      className="h-4 w-4 flex-shrink-0 "
                    >
                      <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                    </svg>
                    Admin
                  </div>
                </li>
                <li>
                  <div className="flex items-center">
                    <svg
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                      className="h-5 w-5 flex-shrink-0"
                    >
                      <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                    </svg>

                    {PageName}
                  </div>
                </li>
              </ol>
            </nav>
          </div>

          <main className="py-6">
            <div className="pl-4 ">{Children}</div>
          </main>
        </div>
      </div>
    </>
  );
}
