// usersSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


export const makePlan = createAsyncThunk(
  "Plan/makePlan",
  async (values, thunkAPI) => {

    try {      
      const response = await fetch(`https://api.way4franchise.com/api/v1/plan/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();

      return data;
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const makeInvestorPlan = createAsyncThunk(
  "Plan/makeInvestorPlan",
  async (values, thunkAPI) => {

    try {      
      const response = await fetch(`https://api.way4franchise.com/api/v1/plan/addinvestor`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();

      return data;
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);


export const getAllUserPlan = createAsyncThunk(
  "staff/getAllUserPlan",
  async (_, thunkAPI) => {
    try {
      const response = await fetch("https://api.way4franchise.com/api/v1/plan/list");

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getUserPlanByid = createAsyncThunk(
  "staff/getUserPlanByid",
  async (id, thunkAPI) => {
    try {
      const response = await fetch(`https://api.way4franchise.com/api/v1/plan/${id}`);

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getAllInvestorPlan = createAsyncThunk(
  "staff/getAllInvestorPlan",
  async (_, thunkAPI) => {
    try {
      const response = await fetch("https://api.way4franchise.com/api/v1/plan/investor/list");

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getAllInvestorPlanByid = createAsyncThunk(
  "staff/getAllInvestorPlanByid",
  async (id, thunkAPI) => {
    try {
      const response = await fetch(`https://api.way4franchise.com/api/v1/plan/investor/${id}`);

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
const initialState = {
  alluserplan:null,
  singleuserplan:null,
  allinvestorplan:null,
  singleinvestorplan:null,
  loading: false,
  error: null,
  message: null,
};

const PlanSlice = createSlice({
  name: "plan",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(makePlan.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(makePlan.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.message = action.payload.message;
      })
      .addCase(makePlan.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })

      .addCase(makeInvestorPlan.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(makeInvestorPlan.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.message = action.payload.message;
      })
      .addCase(makeInvestorPlan.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })

      .addCase(getAllUserPlan.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllUserPlan.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.alluserplan = action.payload.alluserplan;
      })
      .addCase(getAllUserPlan.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })


      .addCase(getUserPlanByid.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserPlanByid.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.singleuserplan = action.payload.singleuserplan;
      })
      .addCase(getUserPlanByid.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })


      .addCase(getAllInvestorPlan.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllInvestorPlan.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.allinvestorplan = action.payload.allinvestorplan;
      })
      .addCase(getAllInvestorPlan.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })


      .addCase(getAllInvestorPlanByid.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllInvestorPlanByid.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.singleinvestorplan = action.payload.singleinvestorplan;
      })
      .addCase(getAllInvestorPlanByid.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
  },
});

export const { clearErrors, clearMessage } = PlanSlice.actions;

export default PlanSlice.reducer;

