import React, { useState, useEffect } from "react";
import CompanyKycRequest from "./CompanyKycRequest"
import UserKycRequest from "./UserKycRequest"
import {
  HomeIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";

const tabs = [
    { name: "company Kyc Request", href: "#", icon: UsersIcon, current: false },
  { name: "User Kyc Request", href: "#", icon: HomeIcon, current: true },
];

function classNames(...classes) {
  return classes.filter(Boolean).join();
}

export default function AdminKyc() {
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState("company Kyc Request");


  const renderContent = () => {
    switch (currentTab) {
      case "company Kyc Request":
        return <CompanyKycRequest />;
      case "User Kyc Request":
        return <UserKycRequest />;
      default:
        return <CompanyKycRequest />;
    }
  };
  return (
    <>
      <div className="flex gap-12">
      <div className="w-full">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md  border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block ">
        <div className="  w-full">
          <nav aria-label="Tabs" className="-mb-px flex w-full border">
            {tabs.map((tab) => (
              <a
                key={tab.name}
                onClick={()=>setCurrentTab(tab.name)}
                aria-current={tab.current ? 'page' : undefined}
                className={classNames(
                  tab.name==currentTab
                    ? 'border-indigo-500 bg-gray-50 text-indigo-600 '
                    : 'border-transparent bg-white text-gray-500 hover:border-gray-300 hover:text-gray-700 ',
                  ' border-b-2 px-1 py-4 text-center text-sm font-medium capitalize w-1/2',
                )}
              >
                {tab.name}
              </a>
            ))}
          </nav>
        </div>
      </div>
    </div>
      </div>
        <div className=" w-full py-2">{renderContent()}</div>
    </>
  );
}
