import React from "react";
import {
  LifebuoyIcon,
  NewspaperIcon,
  PhoneIcon,
} from "@heroicons/react/20/solid";
import Header from "../Comman/Header";
import Footer from "../Comman/Footer";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";
const faqs = [
  {
    question: "FAQs (Frequently Asked Questions)",
    answer:
      " Have questions? Check out our FAQs section for answers to common inquiries about franchise opportunities, our services, and the application process.",
  },
  {
    question: "Contact Support ",
    answer:
      "If you need personalized assistance, please reach out to our support team. You can contact us through the following methods",
    contact: [
      {
        email: "info@way4franchise.com",
        phone: "+91-8053530300",
        office: "Monday to Saturday, 9 AM to 6 PM",
      },
    ],
  },
  {
    question: "How do I contact a franchisor?",
    answer:
      "Click the Contact Franchisor button on any franchise listing to send an inquiry directly through our platform.",
  },
];
const cards = [
  {
    name: "How can I list my franchise on Wayforfranchise?",
    description:
      'Visit our "List Your Franchise" page and fill out the application form. Our team will review and get back to you within 2-3 business days.',
    icon: PhoneIcon,
  },
  {
    name: "How do I update my franchise listing?",
    description:
      'Log into your franchisor account and select "Edit Listing" from your dashboard.',
    icon: LifebuoyIcon,
  },
  {
    name: "How can I respond to inquiries from potential franchisees?",
    description:
      "All inquiries will appear in your dashboard. Click on each to view details and respond.",
    icon: NewspaperIcon,
  },
];
const SupportCenter = () => {
  return (
    <>
      <Header />
      <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:pt-32 sm:pb-16 border-t">
        {/* <img
        alt=""
        src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-y=.8&w=2830&h=1500&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply"
        className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
      /> */}
        <div className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl">
          <div
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
            className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
          />
        </div>
        <div className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu">
          <div
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
            className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
          />
        </div>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-3xl lg:mx-0">
            <h2 className="text-3xl font-semibold tracking-tight text-white">
              Support Center
            </h2>
            <p className="mt-6 text-base leading-8 text-gray-300">
              We are here to help you with any questions or concerns you may
              have regarding our services and franchise opportunities. Our
              dedicated support team is committed to providing you with the help
              you need.
            </p>
          </div>
          <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-6 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-8">
            {cards.map((card) => (
              <div
                key={card.name}
                className="flex gap-x-4 rounded-xl bg-white/5 p-6 ring-1 ring-inset ring-white/10"
              >
                <card.icon
                  aria-hidden="true"
                  className="h-7 w-5 flex-none text-indigo-400"
                />
                <div className="text-base leading-7">
                  <h3 className="font-semibold text-white">{card.name}</h3>
                  <p className="mt-2 text-gray-300">{card.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="lg:mx-auto mx-2 max-w-7xl px-4 py-16">
          <div className="w-full divide-y divide-gray-900/10">
            <h2 className="text-3xl font-semibold leading-10  text-gray-900">
              How Can We Help You?
              <p className="text-base font-medium mt-1">
              How Can We Help You? Here are some common topics and resources
              that might assist you:
            </p>
            </h2>
            
            <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
        <Disclosure key={faq.question} as="div" className="pt-6">
          <dt>
            <Disclosure.Button className="group flex w-full items-start justify-between text-left text-gray-900">
              <span className="text-base font-semibold leading-7">
                {faq.question}
              </span>
              <span className="ml-6 flex h-7 items-center">
                <PlusSmallIcon
                  aria-hidden="true"
                  className="h-6 w-6 group-data-[open]:hidden"
                />
                <MinusSmallIcon
                  aria-hidden="true"
                  className="h-6 w-6 [.group:not([data-open])_&]:hidden"
                />
              </span>
            </Disclosure.Button>
          </dt>
          <Disclosure.Panel as="dd" className="mt-2 pr-12">
            <p className="text-base leading-7 text-gray-600">{faq.answer}</p>

            {faq.contact && (
              <div className="mt-2">
                <p>Email: <a href={`mailto:${faq.contact[0].email}`} className="text-blue-500">{faq.contact[0].email}</a></p>
                <p>Phone: <a href={`tel:${faq.contact[0].phone}`} className="text-blue-500">{faq.contact[0].phone}</a></p>
                <p>Office Hours: {faq.contact[0].office}</p>
              </div>
            )}
          </Disclosure.Panel>
        </Disclosure>
      ))}
            </dl>
          </div>
        </div>
      </div>

      <div className="bg-gray-50 py-10 px-6 md:px-12 lg:px-20">
        <h2 className="text-3xl font-bold text-gray-800 mb-6 text-center">
          Stay Connected
        </h2>
        <p className="text-center text-gray-600 mb-8">
          Follow us on social media for the latest updates, tips, and resources
          related to franchising!
        </p>
        <div className="flex justify-center space-x-6">
          <a
            href="https://www.facebook.com/profile.php?id=61566285699882"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-600 hover:text-blue-600 transition-colors duration-300"
          >
            <FaFacebook className="text-3xl" />
          </a>
          <a
            href="https://t.co/xDF2zfmveR"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-600 hover:text-blue-400 transition-colors duration-300"
          >
            <FaTwitter className="text-3xl" />
          </a>
          <a
            href="https://www.instagram.com/way4franchise/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-600 hover:text-pink-500 transition-colors duration-300"
          >
            <FaInstagram className="text-3xl" />
          </a>
          <a
            href="https://www.linkedin.com/company/105216713/admin/dashboard/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-600 hover:text-blue-800 transition-colors duration-300"
          >
            <FaLinkedin className="text-3xl" />
          </a>
        </div>
        <p className="text-center text-gray-500 mt-8">
          Thank you for choosing{" "}
          <span className="font-semibold">Way4Franchise</span>. We are here to
          support you every step of the way!
        </p>
      </div>

      <Footer />
    </>
  );
};

export default SupportCenter;
