import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaCheck, FaTimes } from "react-icons/fa";
import { GrEdit } from "react-icons/gr";
import { AiFillDelete } from "react-icons/ai";

import {
  getAds,
  adDelete,
  adsAction,
  clearErrors,
  clearMessage,
} from "../redux/adSlice";
import {Confirmation} from "../BaseFile/Confirmation"
import Loader from "../BaseFile/Loader";
import SuccessAlert from "../BaseFile/SuccessAlert";
import ErrorAlert from "../BaseFile/ErrorAlert";
export default function ManageAds() {
  const dispatch = useDispatch();
  const { ads, loading, error, message } = useSelector(
    (state) => state.ad
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [Allads,setAllads]=useState("")
  const [previewImage, setPreviewImage] = useState();
  const [showItem, SetShowItem] = useState("pending");
  const [editable, setEditable] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [values, setValues] = useState();
  const [deleteID, setDeleteID] = useState();


  useEffect(() => {
    dispatch(getAds());
    if (error) {
      const errorInterval = setInterval(() => {
        dispatch(clearErrors());
      }, 3000);
      return () => clearInterval(errorInterval);
    }

    if (message) {
      const messageInterval = setInterval(() => {
        dispatch(clearMessage());
      }, 3000);
      return () => clearInterval(messageInterval);
    }
  }, [dispatch, message, error, clearErrors, clearMessage]);

    useEffect(() => {
        setAllads(
            ads?.filter((item) =>
          item?.franchise_name?.toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    }, [searchQuery]);

  const handleImageClick = (fileName) => {
    const filePath = `/uploads/adData/${fileName}`;
    
    // Check if the file is a PDF
    if (fileName.endsWith('.pdf')) {
      // Trigger file download for PDF
      const link = document.createElement('a');
      link.href = filePath;
      link.download = fileName; // Set the filename for the download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      // Open image in a new tab
    setPreviewImage(`/uploads/adData/${fileName}`);

    }
  };
  
  const handleClosePreview = () => {
    setPreviewImage(null);
  };

  const handleEdit = (item) => {
    setEditable(item);
    setEditMode(true);
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    setEditable(null);
  };
  const handleSaveChange = (id) => {
    if (editable) {
      dispatch(
        adsAction({
          _id: id,
          action: values,
        })
      );
      setEditMode(false);
      setEditable(null);
    }
  };
  return (
    <>
      {message && <SuccessAlert message={message} />}
      {error && <ErrorAlert error={error} />}
      {loading ? (
        <Loader />
      ) : (
        <div className=" bg-white  p-4 border shadow-sm">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
                <h1 className="text-base capitalize font-semibold text-gray-800">
                  {showItem} ads
                </h1>
              <p className="mt-0 text-[12px] text-gray-700">
                A list of all the ads in your account including their
                name, title, email, and role.
              </p>
            </div>
            <div className="flex gap-5 mt-4 sm:ml-16 sm:mt-6 ">
                <select
                    className="border border-gray-400 px-3 py-1 rounded-sm text-sm w-full"
                    onChange={(e)=>SetShowItem(e.target.value)}
                >
                    <option value="pending">Pending</option>
                    <option value="inprogress">In Progress</option>
                    <option value="decline">Decline</option>
                    <option value="approved">Approved</option>
                </select>
              <input
                type="search"
                onChange={(e) => setSearchQuery(e.target.value)}
                className="border px-2 py-1.5 text-[12px] rounded-sm border-gray-400"
                placeholder="Search"
              />
            </div>
          </div>
          <div className="mt-6 flow-root border shadow-sm rounded-sm overflow-hidden h-[530px]">
            <div className="h-[480px] overflow-y-auto no-scrollbar pb-7">
              <div className="inline-block min-w-full align-middle">
                <div className="relative">
                  <div className="overflow-x-auto rounded-lg shadow-md">
                    <table className="min-w-full table-auto divide-y divide-gray-300 rounded-lg">
                      <thead className="text-gray-900 ">
                        <tr>
                          <th className="py-3.5 px-2 text-left uppercase text-[12px] font-semibold border-b border-indigo-400">
                            Name & Count
                          </th>
                          <th className="px-3 py-3.5 text-left text-[12px] uppercase font-semibold border-b border-indigo-400">
                            Image1 & Image2 
                          </th>
                          <th className="px-3 py-3.5 text-left text-[12px] uppercase font-semibold border-b border-indigo-400">
                          Image3 & Image4
                          </th>
                          <th className="px-3 py-3.5 text-left text-[12px] uppercase font-semibold border-b border-indigo-400">
                          Status & isVerified
                          </th>
                          <th className="px-3 py-3.5 text-left text-[12px] uppercase font-semibold border-b border-indigo-400">
                           Link &  Description
                          </th>
                          <th className="py-3.5 text-left px-3 uppercase  text-[12px] font-medium border-b border-indigo-400">
                            Amount & Used Amount
                          </th>
                          <th className="py-3.5 text-left px-3 uppercase  text-[12px] font-medium border-b border-indigo-400">
                           Days & Remaining Days
                          </th>
                          <th className="py-3.5 text-left px-3 uppercase  text-[12px] font-medium border-b border-indigo-400">
                           Created At
                          </th>
                          <th className="py-3.5 text-center px-3 uppercase  text-[12px] font-medium border-b border-indigo-400">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white ">
                        {(searchQuery ? Allads : ads)
                          ?.filter((item) => item?.is_verified === showItem)
                          ?.map((company,index) => (
                            <tr
                              key={index}
                              className="hover:bg-gray-100 transition-colors duration-150"
                            >
                              <td className="whitespace-nowrap capitalize py-4 px-2 text-[12px] text-gray-900">
                                <div className="flex items-center">
                                  <div className="h-11 w-11 flex-shrink-0 border rounded-full ">
                                    <img
                                      alt="company-logo"
                                      src={`/uploads/InvestorData/${company?.companyLogo}`}
                                      className="h-11 w-11 rounded-full"
                                    />
                                  </div>
                                  <div className="ml-4">
                                  <span className="block capitalize"> {company?.franchise_name}</span>
                                  <span className="block ">{company?.clickCount}</span>
                                  </div>
                                </div>
                              </td>
                              <td className="whitespace-nowrap px-3 capitalize py-3 text-[12px] text-gray-900">
                                <span className="block capitalize">
                                {company?.image1 ? (
                                  <button
                                    onClick={() =>
                                      handleImageClick(company?.image1)
                                    }
                                    className="text-blue-400 hover:underline"
                                  >
                                    {company?.image1.endsWith('.pdf') ? 'View image1' : 'View image1'}
                                  </button>
                                ) : (
                                  "No Gst Provided"
                                )}
                                </span>
                                <span className="block capitalize">
                                {company?.image2 ? (
                                  <button
                                    onClick={() =>
                                      handleImageClick(company?.image2)
                                    }
                                    className="text-blue-400 hover:underline"
                                  >
                                    {company?.image2.endsWith('.pdf') ? 'View image2' : 'View image2'}
                                  </button>
                                ) : (
                                  "No Gst Provided"
                                )}
                                </span>
                              </td>
                              <td className="whitespace-nowrap px-3 capitalize py-3 text-[12px] text-gray-900">
                                <span className="block capitalize">
                                {company?.image3 ? (
                                  <button
                                    onClick={() =>
                                      handleImageClick(company?.image3)
                                    }
                                    className="text-blue-400 hover:underline"
                                  >
                                    {company?.image3.endsWith('.pdf') ? 'View image3' : 'View image3'}
                                  </button>
                                ) : (
                                  "No Gst Provided"
                                )}
                                </span>
                                <span className="block capitalize">
                                {company?.image4 ? (
                                  <button
                                    onClick={() =>
                                      handleImageClick(company?.image4)
                                    }
                                    className="text-blue-400 hover:underline"
                                  >
                                    {company?.image4.endsWith('.pdf') ? 'View image4' : 'View image4'}
                                  </button>
                                ) : (
                                  "No Gst Provided"
                                )}
                                </span>
                              </td>
                              <td className="whitespace-nowrap capitalize py-4 px-2 text-[12px] text-gray-900">   
                                  <div className="ml-4">
                                  <span className="block capitalize"> {company?.status}</span>
                                  <span className="block capitalize">
                              {editMode && editable?._id === company?._id ? (
                                <select
                                    className="border-0 px-3 py-1 rounded-sm text-sm w-full"
                                    onChange={(e)=>setValues(e.target.value)}
                                    defaultValue={company?.is_verified}
                                >
                                   <option value="pending">Pending</option>
                    <option value="inprogress">In Progress</option>
                    <option value="decline">Decline</option>
                    <option value="approved">Approved</option>
                                </select>
                                ) : (
                                <div className="rounded-md  py-1 text-xs">
                                    {company?.is_verified}
                                </div>
                                )}
                              </span>
                                 
                                </div>
                              </td>
                              <td className="whitespace-nowrap capitalize py-4 px-2 text-[12px] text-gray-900">   
                                  <div className="ml-4">
                                  <span className="block capitalize"> {company?.adLink}</span>
                                  <span className="block ">{company?.adText}</span>  
                                </div>
                              </td>
                              <td className="whitespace-nowrap capitalize py-4 px-2 text-[12px] text-gray-900">   
                                  <div className="ml-4">
                                  <span className="block capitalize"> {company?.amount}</span>
                                  <span className="block ">{company?.adText}</span> 
                                </div>
                              </td>
                              <td className="whitespace-nowrap capitalize py-4 px-2 text-[12px] text-gray-900">   
                                  <div className="ml-4">
                                  <span className="block capitalize"> {company?.days}</span>
                                  <span className="block ">{company?.adText}</span> 
                                </div>
                              </td>
                              <td className="whitespace-nowrap capitalize py-4 px-2 text-[12px] text-gray-900">   
                                  <div className="ml-4">
                                  {new Date(company?.createdAt).toLocaleDateString()}
                                </div>
                              </td>
                            
                              <td className="py-4 pl-2">
                                    <div className="flex items-center space-x-2">
                                    {editMode && editable?.CompanyId === company?.CompanyId ? (
                                        <>
                                        <FaCheck
                                            className="h-4 w-4 text-green-700 cursor-pointer"
                                            onClick={() => handleSaveChange(company?._id)}
                                        />
                                        <FaTimes
                                            className="h-4 w-4 text-red-700 cursor-pointer"
                                            onClick={handleCancelEdit}
                                        />
                                        </>
                                    ) : (
                                        <>
                                        <GrEdit
                                            className="h-4 w-4 text-blue-400 cursor-pointer"
                                            onClick={() => handleEdit(company)}
                                        />
                                         <AiFillDelete
                                            className="h-6 w-4 text-red-500 cursor-pointer"
                                            onClick={() => setDeleteID(company?._id)}
                                        />
                                        </>
                                    )}
                                    </div>
                                </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    
      {previewImage && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50">
          <div className="relative">
            <img
              src={previewImage}
              alt="Preview"
              className="max-w-full max-h-screen object-contain"
            />
            <button
              onClick={handleClosePreview}
              className="absolute top-2 right-2 text-white text-xl bg-black p-2 rounded-full"
            >
              ×
            </button>
          </div>
        </div>
        
      )}
      {
        deleteID && (<Confirmation  isClose={()=>setDeleteID (false)} deletefunction={adDelete} id = {deleteID}/>)
      }
    </>
  );
}
