import { useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import { makePlan  } from "../redux/PlanSlice";
import SuccessAlert from "../BaseFile/SuccessAlert";
import ErrorAlert from "../BaseFile/ErrorAlert";
import Spinner from "../BaseFile/Spinner";

const AddUserPlan = () => {
  const dispatch = useDispatch();
  const { loading , error ,message} = useSelector((state) => state.plans);

  const [formData, setFormData] = useState({
    name: "",
    price: "",
    duration: "",
    leads: "",
    franchiseMembership: "",
    searchViewOpportunities: "",
    viewFranchiseListings: "",
    newsletter: "",
    legalAgreementSupport: "",
    matchingOpportunities: "",
    financialAnalysis: "",
    brandMeetings: "",
    brokerageCommissionFees: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(makePlan(formData));
  };
  const handleReset = () => {
    setFormData({
      name: "",
      price: "",
      duration: "",
      leads: "",
      franchiseMembership: "",
      searchViewOpportunities: "",
      viewFranchiseListings: "",
      newsletter: "",
      legalAgreementSupport: "",
      matchingOpportunities: "",
      financialAnalysis: "",
      consultantSupport:"",
      brandMeetings: "",
      brokerageCommissionFees: "",
    })
  }

  return (
    <>
     {message && <SuccessAlert message={message} />}
     {error && <ErrorAlert error={error} />}
     
    <div className="lg:container lg:mx-auto border shadow-sm rounded-sm bg-white py-4">
      <div className="">
      <h2 className="text-lg font-semibold text-[13px]  px-4 mb-4 pt-1">Membership Package Form</h2>
      </div>
      <form onSubmit={handleSubmit} className="bg-white px-4">
        <div className="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-x-3">
        <div className="mb-4">
          <label htmlFor="name" className="block text-gray-700 font-semibold text-[13px] mb-1">
            Plan Name
          </label>
          <select
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="w-full p-2 border rounded text-[13px]"
            required
          >
            <option value="" >Select Plan</option>
            <option value="Basic">Basic</option>
            <option value="Standard">Standard</option>
            <option value="Premium">Premium</option>
            <option value="Elite">Elite</option>
            <option value="Free 30 duration Membership">Free 15 duration Membership</option>
          </select>
        </div>
        <div className="mb-4">
          <label htmlFor="price" className="block text-gray-700 font-semibold  text-[13px] mb-1">
             Price
          </label>
          <input
            type="number"
            id="price"
            name="price"
            value={formData.price}
            onChange={handleChange}
            className="w-full p-2 border rounded text-[13px]"
            placeholder="Enter Plan Price"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="duration" className="block text-gray-700 font-semibold  text-[13px] mb-1">
            duration
          </label>
          <input
            type="number"
            id="duration"
            name="duration"
            value={formData.duration}
            onChange={handleChange}
            className="w-full p-2 border rounded text-[13px] "
            placeholder="Enter Plan duration"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="leads" className="block text-gray-700 font-semibold  text-[13px] mb-1">
            Leads
          </label>
          <input
            type="number"
            id="leads"
            name="leads"
            value={formData.leads}
            onChange={handleChange}
            className="w-full px-3 py-[9px] border rounded text-[13px]"
            placeholder="Enter Leads"
            required
          />
        </div>
        <div>
          <label htmlFor="franchiseVerification" className="block font-semibold text-[13px] text-gray-700">
            Franchise Membership
          </label>
          <input
            type="number"
            id="franchiseMembership"
            name="franchiseMembership"
            placeholder="Your are Interstec Franchise Membership"
            value={formData.franchiseMembership}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div>
          <label htmlFor="searchViewOpportunities" className="block font-semibold text-[13px] text-gray-700">
          Search and View Opportunities
          </label>
          <input
            type="text"
            id="searchViewOpportunities"
            name="searchViewOpportunities"
            value={formData.searchViewOpportunities}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div>
          <label htmlFor="viewFranchiseListings" className="block font-semibold text-[13px] text-gray-700">
          View Franchise Listings
          </label>
          <input
            type="text"
            id="viewFranchiseListings"
            name="viewFranchiseListings"
            value={formData.viewFranchiseListings}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div className="  ">
          <label htmlFor="consultantSupport " className="block font-semibold text-[13px] text-gray-700">
         Consultant Support
          </label>
          <input
            type="text"
            id="consultantSupport"
            name="consultantSupport"
            value={formData.consultantSupport}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div>
          <label htmlFor="franchiseVerification" className="block font-semibold text-[13px] text-gray-700">
          Newsletter Access
          </label>
          <input
            type="number"
            id="newsletter"
            name="newsletter"
            value={formData.newsletter}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div className="mt-4">
          <label htmlFor="accountManager" className="block font-semibold text-[13px] text-gray-700">
          Matching Opportunities
          </label>
          <input
            type="text"
            id="matchingOpportunities"
            name="matchingOpportunities"
            value={formData.matchingOpportunities}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div className="mt-4">
          <label htmlFor="financialAnalysis" className="block font-semibold text-[13px] text-gray-700">
          Financial Analysis
          </label>
          <input
            type="text"
            id="financialAnalysis"
            name="financialAnalysis"
            value={formData.financialAnalysis}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div className="mt-4">
          <label htmlFor="membership" className="block font-semibold text-[13px] text-gray-700">
          Legal Agreement Support
          </label>
          <input
            type="text"
            id="legalAgreementSupport"
            name="legalAgreementSupport"
            value={formData.legalAgreementSupport}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div className="mt-4">
          <label htmlFor="membership" className="block font-semibold text-[13px] text-gray-700">
          Brand Meetings & Negotiations
          </label>
          <input
            type="text"
            id="brandMeetings"
            name="brandMeetings"
            value={formData.brandMeetings}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div className="mt-4">
          <label htmlFor="Brokerage" className="block font-semibold text-[13px] text-gray-700">
          Brokerage & Commission Fees
          </label>
          <input
            type="text"
            id="brokerageCommissionFees"
            name="brokerageCommissionFees"
            value={formData.brokerageCommissionFees}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        </div>
        <div className="flex justify-end gap-3">
        <button
          type="submit"
          onClick={handleReset}
          className="bg-red-500 text-white py-2 px-7 font-semibold rounded text-sm hover:bg-red-600"
        >
          Reset
        </button>
        <button
          type="submit"
          className="bg-blue-500 text-white py-2 px-7 text-sm rounded font-semibold hover:bg-blue-600"
        >
        {loading ? <Spinner/> : 'Submit'}
        </button>
        </div>
      </form>
    </div>
    </>
  );
};

export default AddUserPlan;
