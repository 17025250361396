import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css'; // Import Quill's styling
import { FranchiseDes, getDescriptionByid } from "../redux/franchiseSlice";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../BaseFile/Spinner";

const DescriptionForm = () => {
  const dispatch = useDispatch();
  const [des, setDes] = useState(""); // This will hold the current description
  const [error, setError] = useState("");
  const { description, loading } = useSelector((state) => state.franchise);
  const { auth } = useSelector((state) => state.auth);

  useEffect(() => {
    if (auth?._id) {
      const _id = auth._id;
      dispatch(getDescriptionByid({ _id }));
    }
  }, [auth?._id, dispatch]);

  // When the description is fetched, set it as the value of the editor
  useEffect(() => {
    if (description?.description) {
      setDes(description.description); // Set fetched description to the editor
    }
  }, [description]);

  // Word count validation
  const checkWordCount = (text) => {
    const words = text.replace(/<[^>]*>/g, "").split(/\s+/).filter(Boolean);
    if (words.length > 1000) {
      setError(`Description exceeds 1000 words. Current word count: ${words.length}`);
    } else {
      setError("");
    }
  };

  const handleDescriptionChange = (value) => {
    setDes(value); // Update description state
    checkWordCount(value); // Validate word count
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(FranchiseDes({ franchise_id: auth?._id, description: des }));
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-2">
      {/* Label */}
      <label htmlFor="description" className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
        Enter your description (max 1000 words):
      </label>
  
      {/* ReactQuill Editor */}
      <div className="mb-4">
        <ReactQuill
          theme="snow"
          value={des} // Controlled value for the editor
          onChange={handleDescriptionChange} // Update on change
          placeholder="Write something..."
          className="custom-quill-height"  // Ensure custom height
        />
      </div>
  
      {/* Error message */}
      {error && <p style={{ color: "red" }}>{error}</p>}
  
      {/* Submit Button */}
      <div>
        <button 
          type="submit" 
          disabled={!!error} 
          className="mr-5 bg-indigo-600 hover:bg-indigo-700 w-20 py-1 rounded-md text-white"
        >
          {loading ? <Spinner/> : "Save"}
        </button>
      </div>
    </form>
  );
};

export default DescriptionForm;
