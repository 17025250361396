async function getCityState(pincode) {
    try {
      const response = await fetch(`https://api.postalpincode.in/pincode/${pincode}`);
      const data = await response.json();
  
      if (data[0].Status === "Success") {
        const postOffice = data[0].PostOffice[0];
        const city = postOffice.District;
        const state = postOffice.State;
  
        return { city, state };
      } else {
        throw new Error("Invalid Pincode");
      }
    } catch (error) {
      return null; 
    }
  }
export default getCityState;