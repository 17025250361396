import React from "react";
import { GrView } from "react-icons/gr";
import { MdCategory } from "react-icons/md";
const blogs = [
  { 
    title: "Travel Diaries", 
    author: "Ravi Patel", 
    blog: "Travel",
    views: 1500, 
    src:"https://thumbor.forbes.com/thumbor/fit-in/900x510/https://www.forbes.com/advisor/in/wp-content/uploads/2024/01/pexels-robert-forever-ago-18020046-scaled-Cropped.jpg",
    category: "Travel",
    description: "Exploring hidden gems and travel tips for the wanderlust soul." 
  },
  { 
    title: "Foodie Paradise", 
    author: "Sneha Kapoor", 
    blog: "Foodie",
    views: 3200, 
    src:"https://thumbor.forbes.com/thumbor/fit-in/x/https://www.forbes.com/advisor/wp-content/uploads/2022/09/brand_identity.jpg",
    category: "Food",
    description: "Delicious recipes and food reviews for food lovers." 
  },
  { 
    title: "Tech Guru", 
    author: "Amit Joshi", 
    blog: "Tech",
    views: 2100, 
    src:"https://cdn.prod.website-files.com/5e0f1144930a8bc8aace526c/65dd33d49a346d9be0b075ea_65dd12fa299e167d189f00f7-fed9c2116dfcf56370cea3063f4e88fa.jpeg",
    category: "Technology",
    description: "Latest trends and tutorials in the tech world." 
  },
  { 
    title: "Healthy Living", 
    author: "Priya Sharma", 
    blog: "Healthy",
    views: 2700, 
    src:"https://cms-api-in.myhealthcare.co/image/20220910103120.jpeg",
    category: "Health",
    description: "Tips on maintaining a healthy and balanced lifestyle." 
  },]
const BlogSection = () => {
  return (
    <>
      <div className="max-w-full mx-10 mt-12">
        <div className="border-b mb-5 flex justify-between text-sm">
          <div className="text-indigo-600 flex items-center pb-2 pr-2 border-b-2 border-indigo-600 capitalize">
            <h2  className="font-semibold  lg:text-2xl sm:text-xl text-lg text-gray-800 inline-block">
             Our Blogs
            </h2>
          </div>
          <a href="#" className="text-base text-blue-500">See All</a>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-10">
         {blogs?.map((item,map)=>(
          <div className="rounded overflow-hidden shadow-lg flex flex-col">
            <a href="#" />
            <div className="relative">
              <a href="#">
                <img
                  className="w-full"
                  src={item.src}
                  alt="Sunset in the mountains"
                />
                <div className="hover:bg-transparent transition duration-300 absolute bottom-0 top-0 right-0 left-0 bg-gray-900 opacity-25"></div>
              </a>
              <a href="#!">
                <div className="text-xs absolute top-0 right-0 bg-indigo-600 px-4 py-2 text-white mt-3 mr-3 hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
                  {item?.blog}
                </div>
              </a>
            </div>
            <div className="px-6 py-4 mb-auto">
              <a
                href="#"
                className="font-medium text-sm  hover:text-indigo-600 transition duration-500 ease-in-out inline-block "
              >
                {item.title}
              </a>
              <p className="text-gray-500 text-[12px]">
              {item.description}
              </p>
            </div>
            <div className="px-6 py-3 flex flex-row items-center justify-between bg-gray-100">
              <span
                href="#"
                className="py-1 text-xs font-regular text-gray-900 mr-1 flex flex-row items-center"
              >
                <span className=""><MdCategory className="text-indigo-500"/></span>
                <span className="ml-1">{item.category}</span>
              </span>
              <span
                href="#"
                className="py-1 text-xs font-regular text-gray-900 mr-1 flex flex-row items-center"
              >
               <span><GrView className="text-indigo-500"/> </span>
                <span className="ml-1">{item.views} Views</span>
              </span>
            </div>
          </div>
        ))}
        </div>
      </div>
    </>
  );
};

export default BlogSection;
