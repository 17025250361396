import React from "react";
import Header from "../Comman/Header";
import Footer from "../Comman/Footer";

const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <div className="w-7xl mx-10 pt-10">
        <div>
          <h1 className="text-2xl uppercase font-semibold mb-4 ">
            Privacy Policy
          </h1>
        </div>
        <div className="grid grid-cols-12 gap-3">
          <div className="lg:col-span-8 col-span-12">
            <div className="border shadow-md rounded-md">
              <div className="border-b bg-gray-200 py-2">
                <h5 className="text-lg font-semibold  px-4 capitalize">
                  {" "}
                  Privacy Policy
                </h5>
              </div>
              <p className="text-gray-700 px-4 py-3">
              At Way4franchise, we value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website, www.way4franchise.com.

              </p>
            </div>
            <div className="border shadow-md rounded-md mt-4">
              <div className="border-b bg-gray-200 py-2">
                <h5 className="text-lg font-semibold  px-4 capitalize">
                  {" "}
                  Information We Collect
                </h5>
              </div>
              <div className="py-3">
                <h5 className="text-lg font-semibold  px-4 capitalize">
                  Personal Information
                </h5>
                <p className="text-gray-700 px-4 ">
                This includes information that can identify you, such as your name, email address, phone number, and any other details you provide when filling out forms on our site.
                </p>
              </div>
              <div className="pb-4">
                <h5 className="text-lg font-semibold  px-4 capitalize">
                  {" "}
                  Non-Personal Information
                </h5>
                <p className="text-gray-700 px-4 ">
                This includes data that cannot be used to identify you, such as your IP address, browser type, operating system, and the pages you visit on our website
                </p>
              </div>
            </div>
            <div className="border shadow-md rounded-md mt-4">
              <div className="border-b bg-gray-200 py-2">
                <h5 className="text-lg font-semibold  px-4 capitalize">
                  {" "}
                  How We Use Your Information
                </h5>
              </div>
              <p className="text-gray-700 px-4 py-1">
              We may use the information we collect for the following purposes:
              </p>
              <p className="text-gray-700 px-4 py-1">
              To respond to your inquiries and provide customer support.
              </p>
              <p className="text-gray-700 px-4 py-1">
              To send you newsletters, updates, and marketing materials (you can opt-out at any time).

              </p>
            </div>
           
           
            <div className="border shadow-md rounded-md mt-4">
              <div className="border-b bg-gray-200 py-2">
                <h5 className="text-lg font-semibold  px-4 capitalize">
                  {" "}
                  Cookies and Tracking Technologies
                </h5>
              </div>
              <p className="text-gray-700 px-4 py-1">
              Our website may use cookies and similar tracking technologies to enhance your experience. Cookies are small files stored on your device that help us improve our website and understand user behavior. You can choose to accept or decline cookies through your browser settings.
              

              </p>
            </div>
            <div className="border shadow-md rounded-md mt-4">
              <div className="border-b bg-gray-200 py-2">
                <h5 className="text-lg font-semibold  px-4 capitalize">
                  {" "}
                  Security of Your Information
                </h5>
              </div>
              <p className="text-gray-700 px-4 py-1">
              We take reasonable precautions to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee its absolute security.

              </p>
            </div>
            <div className="border shadow-md rounded-md mt-4">
              <div className="border-b bg-gray-200 py-2">
                <h5 className="text-lg font-semibold  px-4 capitalize">
                  {" "}
                  Changes to Our Privacy Policy
                </h5>
              </div>
              <p className="text-gray-700 px-4 py-1">
                Wayforfranchise reserves the right to update this Privacy Policy
                at any time. We encourage users to frequently check this page
                for changes.
              </p>
            </div>
          </div>
          <div className="lg:col-span-4 col-span-12">
            <div className="border shadow-md rounded-md">
              <div className="border-b bg-gray-200 py-2">
                <h5 className="text-lg font-semibold  px-4 capitalize">
                  {" "}
                  How We Use Your Information
                </h5>
              </div>
              <ul className="px-10 py-4 list-disc ">
                <p className="py-1 text-justify font-medium">
                  Wayforfranchise uses the information we collect in the
                  following ways:
                </p>
                <li className="py-1 text-justify">
                  To Improve Our Website: We continually strive to enhance our
                  website offerings based on the information and feedback we
                  receive from you.
                </li>
                <li className="py-1 text-justify">
                  To Personalize User Experience: Your information helps us to
                  better respond to your individual needs.
                </li>
                <li className="py-1 text-justify">
                  To Send Periodic Emails: We may use your email address to send
                  you information and updates pertaining to your inquiries. It
                  may also be used to respond to your requests and/or other
                  inquiries.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
