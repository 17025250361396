import React from "react";
import Header from "../Comman/Header";
import Footer from "../Comman/Footer";

const AboutFranchise = () => {
  return (
    <>
      <Header />
      <div className="sm:flex items-center max-w-screen-xl mx-auto ">
        <div className="sm:w-1/2 px-10">
          <div className="image object-center text-center">
            <img src="https://i.imgur.com/WbQnbas.png" />
          </div>
        </div>
        <div className="sm:w-1/2 p-5">
          <div className="text">
            <span className="text-gray-500 border-b-2 border-indigo-600 uppercase">
              About Way for franchise
            </span>
            <h2 className="my-4 font-bold text-3xl  sm:text-4xl ">
              Our <span className="text-indigo-600">Mission</span>
            </h2>
            <p className="text-gray-700">
            Welcome to Way4franchise! We are here to help you find the best franchise opportunities. Our goal is to support people who want to start their own businesses and to connect them with great franchise options. We believe that owning a franchise can be a rewarding journey, and we are dedicated to making it as easy and successful as possible for you. With our expertise and resources, we strive to provide you with the knowledge and confidence needed to take that important step toward entrepreneurship.

            </p>
          </div>
        </div>
      </div>
      
    <div className="bg-gray-900 px-6 py-8 lg:px-8">
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-3xl font-bold tracking-tight text-white">Who We Are
        </h2>
        <p className="mt-6 text-lg leading-8 text-gray-300">
        Way4franchise is made up of a team of friendly experts who know a lot about franchises. We love helping people like you understand what it takes to start and run a franchise. With our experience, we can guide you through the process and make it easier for you.

        </p>
      </div>
    </div>
 

      <div className="bg-gray-200 px-2 py-10">
        <div id="features" className="lg:mx-10 mx-2 max-w-7xl">
          {/* <p className=" text-base font-semibold leading-7 text-primary-500">
          Our Values
          </p> */}
          <div className="max-w-3xl text-center mx-auto">
          <h2 className=" font-display text-3xl text-center font-bold tracking-tight text-slate-900 ">
          Why Choose Us?
          </h2>
          <p className="text-base text-center ">Join us at Way4franchise and take the first step toward starting your dream business. Browse our website, connect with our team, and discover the many exciting possibilities that franchising offers!</p>
          </div>
          <ul className="mt-8 grid grid-cols-1 gap-6 text-center text-slate-700 lg:grid-cols-3 sm:grid-cols-2">
            <li className="rounded-xl bg-white px-6 py-8 shadow-sm">
              <img
                src="https://www.svgrepo.com/show/530438/ddos-protection.svg"
                alt
                className="mx-auto h-10 w-10"
              />
              <h3 className="my-3 font-display font-medium">Helpful Guidance</h3>
              <p className="mt-1.5 text-sm leading-6 text-secondary-500">
              Our team is here to offer friendly advice and personal support at every step of your franchise journey.

              </p>
            </li>
            <li className="rounded-xl bg-white px-6 py-8 shadow-sm">
              <img
                src="https://www.svgrepo.com/show/530442/port-detection.svg"
                alt
                className="mx-auto h-10 w-10"
              />
              <h3 className="my-3 font-display font-medium">Wide Network</h3>
              <p className="mt-1.5 text-sm leading-6 text-secondary-500">
              We have built strong relationships with many popular franchise brands, giving you access to unique opportunities.
              </p>
            </li>
            <li className="rounded-xl bg-white px-6 py-8 shadow-sm">
              <img
                src="https://www.svgrepo.com/show/530444/availability.svg"
                alt
                className="mx-auto h-10 w-10"
              />
              <h3 className="my-3 font-display font-medium">Learning Resources</h3>
              <p className="mt-1.5 text-sm leading-6 text-secondary-500">
              We want to help you succeed. That’s why our website includes helpful articles, guides, and tips about everything from getting financing to running your business smoothly.

              </p>
            </li>
            {/* <li className="rounded-xl bg-white px-6 py-8 shadow-sm">
              <a href="/pricing" className="group">
                <img
                  src="https://www.svgrepo.com/show/530440/machine-vision.svg"
                  alt
                  className="mx-auto h-10 w-10"
                />
                <h3 className="my-3 font-display font-medium group-hover:text-primary-500">
                  What We Offer
                </h3>
                <p className="mt-1.5 text-sm leading-6 text-secondary-500">
                  We are committed to offering the best quality service and
                  information to our
                </p>
              </a>
            </li> */}
          </ul>
        </div>
      </div>
      <div className="w-7xl lg:mx-10 mx-2 mt-10 px-2">
        <div className="grid grid-cols-12 gap-3">
          <div className="col-span-12 lg:col-span-6">
            <div className="border shadow-md rounded-md ">
              <div className="border-b bg-gray-200 py-2">
                <h5 className="text-lg font-semibold  px-4 capitalize">
                  {" "}
                  What We Offer
                </h5>
              </div>
              <p className=" text-gray-600 px-6 text-justify py-3">
              On our website, you will find many different franchise opportunities in various fields. Whether you want to invest in a food franchise, a retail business, or a service company, we have something for everyone. We work hard to help you find the right franchise that fits your interests and goals.

              </p>
            </div>
          </div>
          <div className="col-span-12 lg:col-span-6">
            <div className="border shadow-md rounded-md lg:mt-0 mt-4">
              <div className="border-b bg-gray-200 py-2">
                <h5 className="text-lg font-semibold  px-4 capitalize">
                  {" "}
                  Our Vision

                </h5>
              </div>
              <p className=" text-gray-600 px-6 text-justify py-3">
              We want Way4franchise to be the top place where people can find all they need to know about franchising. We believe that everyone should have easy access to information and support when making important business decisions. Our website is designed to be user-friendly so that you can easily explore, compare, and choose franchises that are right for you.
                {/* <p  className=" text-gray-600  text-justify mt-2">[Call-to-Action Button: "Explore Franchise
                Opportunities"]</p> */}
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default AboutFranchise;
