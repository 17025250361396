import React from "react";
import Header from "../Comman/Header";
import Footer from "../Comman/Footer";
const FranchiseDisclosure = () => {
  return (
    <>
      <Header />
      <section className="bg-gray-100">
        <div className="container mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-8">
            <div className="max-w-lg">
              <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                What is an FDD?
              </h2>
              <p className="mt-4 text-gray-600 text-lg">
                A Franchise Disclosure Document (FDD) is a legal document that
                franchisors are required to provide to prospective franchisees
                before they make an investment. This document contains crucial
                information about the franchisor, the franchise system, and the
                terms of the franchise agreement.
              </p>
            </div>
            <div className="mt-12 md:mt-0">
              <img
                src="https://images.unsplash.com/photo-1531973576160-7125cd663d86"
                alt="About Us Image"
                className="object-cover rounded-lg shadow-md"
              />
            </div>
          </div>
        </div>
      </section>

      <div className="bg-gray-200 px-2 py-10">
        <div id="features" className="lg:mx-10 mx-2 max-w-7xl">
          <p className="text-center text-base font-semibold leading-7 text-primary-500">
          Why is the FDD Important?
          </p>
          <h2 className="text-center font-display text-3xl font-bold tracking-tight text-slate-900 md:text-4xl">
          The FDD serves several important purposes
          </h2>
          <ul className="mt-8 grid grid-cols-1 gap-6 text-center text-slate-700 lg:grid-cols-4 sm:grid-cols-2">
            <li className="rounded-xl bg-white px-6 py-8 shadow-sm">
              <img
                src="https://www.svgrepo.com/show/530438/ddos-protection.svg"
                alt
                className="mx-auto h-10 w-10"
              />
              <h3 className="my-3 font-display font-medium">
              Transparency
              </h3>
              <p className="mt-1.5 text-sm leading-6 text-secondary-500">
              It provides comprehensive information about the franchise opportunity, helping potential franchisees make informed decisions.
              </p>
            </li>
            <li className="rounded-xl bg-white px-6 py-8 shadow-sm">
              <img
                src="https://www.svgrepo.com/show/530442/port-detection.svg"
                alt
                className="mx-auto h-10 w-10"
              />
              <h3 className="my-3 font-display font-medium">Legal Compliance</h3>
              <p className="mt-1.5 text-sm leading-6 text-secondary-500">
              In many countries, including the United States, providing an FDD is a legal requirement for franchisors.
              </p>
            </li>
            <li className="rounded-xl bg-white px-6 py-8 shadow-sm">
              <img
                src="https://www.svgrepo.com/show/530444/availability.svg"
                alt
                className="mx-auto h-10 w-10"
              />
              <h3 className="my-3 font-display font-medium">Risk Assessment</h3>
              <p className="mt-1.5 text-sm leading-6 text-secondary-500">
              It helps prospective franchisees evaluate the risks and potential rewards of the franchise opportunity.
              </p>
            </li>
            <li className="rounded-xl bg-white px-6 py-8 shadow-sm">
              <a href="/pricing" className="group">
                <img
                  src="https://www.svgrepo.com/show/530440/machine-vision.svg"
                  alt
                  className="mx-auto h-10 w-10"
                />
                <h3 className="my-3 font-display font-medium group-hover:text-primary-500">
                Comparison Tool
                </h3>
                <p className="mt-1.5 text-sm leading-6 text-secondary-500">
                FDDs allow potential franchisees to compare different franchise opportunities on a like-for-like basis.

                </p>
              </a>
            </li>
          </ul>
        </div>
        <div />
      </div>
     
<div className="w-7xl lg:mx-10 mx-2 px-2">
      <div className="grid grid-cols-12 gap-3 mt-10">
          <div className="lg:col-span-8 col-span-12">
            <div className="border shadow-md rounded-md">
              <div className="border-b bg-gray-200 py-2">
                <h5 className="text-lg font-semibold  px-4 capitalize">
                  {" "}
                  FDD in India

                </h5>
              </div>
              <div className="py-3"> 
              <p className="text-gray-700 px-4 py-1">
              While the FDD is a legal requirement in some countries like the United States, it's important to note that India does not currently have specific laws mandating the use of FDDs. However, many reputable franchisors in India voluntarily provide similar documents to ensure transparency and build trust with potential franchisees.

              </p>
              <p className="text-gray-700 px-4 py-1">
              At Wayforfranchise, we encourage all franchisors listed on our platform to provide comprehensive information similar to what would be found in an FDD. This helps ensure that our users have access to the information they need to make informed decisions about franchise opportunities.


              </p>
              <p className="text-gray-700 px-4 py-1">
              For specific legal requirements and best practices in Indian franchising, we recommend consulting with a legal professional specializing in franchise law.

              </p>
              </div>
            </div>
            
          </div>
          <div className="lg:col-span-4 col-span-12">
            <div className="border shadow-md rounded-md">
              <div className="border-b bg-gray-200 py-2">
                <h5 className="text-lg font-semibold  px-4 capitalize">
                  {" "}
                  Key Components of an FDD

                </h5>
              </div>
              <ul className="px-10 py-4 list-disc ">
                <li className="py-1 text-justify">
                The Franchisor and any Parents, Predecessors, and Affiliates

                </li>
                <li className="py-1 text-justify">
                Business Experience
                </li>
                <li className="py-1 text-justify">
                  To upload or transmit viruses or any other type of malicious
                  code.
                </li>
                <li className="py-1 text-justify">
                Litigation
                </li>
                <li className="py-1 text-justify">
                Bankruptcy
                </li>
                <li className="py-1 text-justify">
                Bankruptcy
                </li>
                <li className="py-1 text-justify">
                Initial Fees
                </li>
                <li className="py-1 text-justify">
                Other Fees
                </li>
                <li className="py-1 text-justify">
                Estimated Initial Investment

                </li>
                <li className="py-1 text-justify">
                Restrictions on Sources of Products and Services
                </li>
                <li className="py-1 text-justify">
                Franchisee's Obligations
                </li>
                <li className="py-1 text-justify">
                Financing
                </li>
                <li className="py-1 text-justify">
                Franchisor's Assistance, Advertising, Computer Systems, and Training
                </li>
                <li className="py-1 text-justify">
                Territory

                </li>
                <li className="py-1 text-justify">
                Patents, Copyrights, and Proprietary Information
                </li>
                <li className="py-1 text-justify">
                Obligation to Participate in the Actual Operation of the Franchise Business
                Restrictions on What the Franchisee May Sell
                </li>
               <li className="py-1 text-justify">
                Restrictions on What the Franchisee May Sell
                </li>
                <li className="py-1 text-justify">
                Renewal, Termination, Transfer, and Dispute Resolution
                </li>
                <li className="py-1 text-justify">
                Public Figures

                </li>
                <li className="py-1 text-justify">
                Financial Performance Representations
                </li>
                <li className="py-1 text-justify">
                Outlets and Franchisee Information
                </li>
                <li className="py-1 text-justify">
                nancial Statements
                </li>
                <li className="py-1 text-justify">
                Contracts

                </li>
                <li className="py-1 text-justify">
                Receipts
                </li>
                


              </ul>
            </div>

            <div className="border shadow-md rounded-md">
              <div className="border-b bg-gray-200 py-2">
                <h5 className="text-lg font-semibold  px-4 capitalize">
                  {" "}
                  How to Use the FDD

                </h5>
              </div>
              <ul className="px-10 py-4 list-disc ">
                <li className="py-1 text-justify">
                Read it thoroughly and carefully


                </li>
                <li className="py-1 text-justify">
                Seek professional advice from a lawyer and accountant
                </li>
                <li className="py-1 text-justify">
                Ask questions about anything you don't understand
                </li>
                <li className="py-1 text-justify">
                Compare it with FDDs from other franchise opportunities you're considering
                </li>
                <li className="py-1 text-justify">
                Use it as a basis for further due diligence
                </li>
              </ul>
              <p className="py-1 text-justify px-6" >Remember, while the FDD provides valuable information, it should be just one part of your overall evaluation of a franchise opportunity.
              </p>
            </div>
          </div>
        </div>
        </div>


      <Footer />
    </>
  );
};

export default FranchiseDisclosure;
