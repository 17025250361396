import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFranchiseByid } from "../redux/franchiseSlice";
import Loader from "../BaseFile/Loader";

export default function InvestorKycDetail() {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("franchise");
  const { franchisee, loading } = useSelector((state) => state.franchise);
  const { auth } = useSelector((state) => state.auth);
  const [previewImage, setPreviewImage] = useState(null);

  useEffect(() => {
    if (auth?._id) {
      const _id = auth?._id;
      dispatch(getFranchiseByid({ _id }));
    }
  }, [auth?._id]);

  const handleImageClick = (file) => {
    if (file.source?.endsWith('.pdf')) {
      // PDFs should show a download option
      const link = document.createElement('a');
      link.href = `/uploads/InvestorData/${file.source}`;
      link.download = file.source;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      // Images should be shown in a preview modal
      setPreviewImage(`/uploads/InvestorData/${file.source}`);
    }
  };

  const handleClosePreview = () => {
    setPreviewImage(null);
  };

  const files = [
    { source: franchisee?.image1, title: "GST Certificate" },
    { source: franchisee?.image2, title: "Aadhar Card Front" },
    { source: franchisee?.image9, title: "Aadhar Card Back" },
    { source: franchisee?.image3, title: "Registration" },
    { source: franchisee?.image4, title: "PAN Card" },
    { source: franchisee?.image5, title: "Other Document" },
  ];

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto border-b px-5 pt-2">
              <h1 className="text-lg uppercase font-semibold text-gray-800">KYC Detail</h1>
              <p className="capitalize text-sm text-gray-700 mb-2">A list of your KYC Documents.</p>
            </div>
          </div>

          <ul
            role="list"
            className="grid mt-3 grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8 pl-4 pb-4"
          >
            {files.map((file, index) => (
              <li key={index} className="relative">
                <div className="group h-52 w-52 block overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                  {file.source?.endsWith('.pdf') ? (
                    <div className="flex flex-col justify-center items-center h-full">
                      <img src="/pdf-icon.png" alt="PDF Icon" className="h-12 w-12" />
                      <button
                        onClick={() => handleImageClick(file)}
                        className="mt-2 px-4 py-2 bg-blue-600 text-white rounded-lg"
                      >
                        Download {file.title}
                      </button>
                    </div>
                  ) : (
                    <img
                      alt={file.title}
                      src={file.source ? `/uploads/InvestorData/${file.source}` : '/notfound.jpg'}
                      className="pointer-events-none object-cover group-hover:opacity-75 max-w-full h-full"
                      onClick={() => handleImageClick(file)}
                    />
                  )}
                </div>
                <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">
                  {file.title}
                </p>
              </li>
            ))}
          </ul>
        </div>
      )}

      {previewImage && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50">
          <div className="relative">
            <img
              src={previewImage}
              alt="Preview"
              className="max-w-full max-h-screen object-contain"
            />
            <button
              onClick={handleClosePreview}
              className="absolute top-2 right-2 text-white text-xl bg-black p-2 rounded-full"
            >
              ×
            </button>
          </div>
        </div>
      )}
    </>
  );
}
