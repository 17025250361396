
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBuyDetailByfranchiseId } from "../redux/ownFranchiseSlice";
import getCityState from "../BaseFile/getCityState";
import { Link } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function InvestorClient() {
  const dispatch = useDispatch();
  const { fanchise_own_user, loading, error, message } = useSelector(
    (state) => state.ownFranchises
  );
  const { auth } = useSelector((state) => state.auth);
  const [searchQuery, setSearchQuery] = useState("");
  const [allFranchise, setAllFranchise] = useState("");
  const [cityStateData, setCityStateData] = useState({}); // To store city and state for each franchise

  useEffect(() => {
    if (auth?._id) {
      const fanchiseId = auth?._id;
      dispatch(fetchBuyDetailByfranchiseId(fanchiseId));
    }
  }, [auth?._id]);

  useEffect(() => {
    setAllFranchise(
      fanchise_own_user?.filter((item) =>
        item?.franchiseId?.franchise_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, fanchise_own_user]);

  useEffect(() => {
    // Fetch city and state for each franchise based on pincode
    const fetchCityStateData = async () => {
      const data = {};
      for (const buy of fanchise_own_user) {
        if (buy?.pincode) {
          const cityState = await getCityState(buy.pincode);
          data[buy.pincode] = cityState || { city: "N/A", state: "N/A" }; // Fallback to 'N/A' if not found
        }
      }
      setCityStateData(data); // Update the state with city/state info for all franchises
    };
    if (fanchise_own_user?.length > 0) {
      fetchCityStateData();
    }
  }, [fanchise_own_user]);

  return (
    <div className="border-gray-300 mt-2">
      <div className="sm:flex sm:items-center p-4">
        <div className="sm:flex-auto">
          <h1 className=" font-semibold text-gray-900">Clients Detail</h1>
          <p className="mt-1  text-gray-700">
            A list of all the companies in your account including their name,
            title, email and role.
          </p>
        </div>
        <div className="mt-4 sm:flex-none">
          <input
            type="search"
            onChange={(e) => setSearchQuery(e.target.value)}
            className="border px-2 py-1.5 text-[12px] border-gray-400"
            placeholder="Search"
          />
        </div>
      </div>
      <div className="mt-8 flow-root px-3">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="relative">
              <div className="overflow-x-auto  shadow-md">
                <table className="min-w-full text-sm table-auto divide-y divide-gray-300 rounded-lg">
                  <thead className="bg-gray-900 text-white ">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left font-semibold text-gray-100 text-[13px] uppercase"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left font-semibold text-gray-100 text-[13px] uppercase"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left font-semibold text-gray-100 text-[13px] uppercase"
                      >
                        Phone
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left font-semibold text-gray-100 text-[13px] uppercase"
                      >
                        Investment Range
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left font-semibold text-gray-100 text-[13px] uppercase"
                      >
                        Address
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left font-semibold text-gray-100 text-[13px] uppercase"
                      >
                        State
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left font-semibold text-gray-100 text-[13px] uppercase"
                      >
                        City
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left font-semibold text-gray-100 text-[13px] uppercase"
                      >
                        Pincode
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left font-semibold text-gray-100 text-[13px] uppercase"
                      >
                        At
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white capitalize">
                    {(searchQuery ? allFranchise : fanchise_own_user)?.map(
                      (buy, index) => (
                        <tr
                          key={index}
                          className="hover:bg-gray-100 transition-colors duration-150"
                        >
                          <td className="whitespace-nowrap px-6 py-4 text-gray-800">
                            <Link
                              to={`/investor/client/profile/${buy?.userId?._id}`}
                              className="hover:text-indigo-600"
                            >
                              {buy?.name}
                            </Link>
                          </td>
                          <td className="whitespace-nowrap px-6 py-4 text-gray-800">
                            {buy?.userId?.email}
                          </td>
                          <td className="whitespace-nowrap px-6 py-4 text-gray-800">
                            {buy?.phone}
                          </td>
                          <td className="whitespace-nowrap px-6 py-4 text-gray-800">
                            {buy?.investment}
                          </td>
                          <td className="whitespace-nowrap px-6 py-4 text-gray-800">
                            {buy?.address}
                          </td>
                          <td className="whitespace-nowrap px-6 py-4 text-gray-800">
                            {cityStateData[buy?.pincode]?.state || "Loading..."}
                          </td>
                          <td className="whitespace-nowrap px-6 py-4 text-gray-800">
                            {cityStateData[buy?.pincode]?.city || "Loading..."}
                          </td>
                          <td className="whitespace-nowrap px-6 py-4 text-gray-800">
                            {buy?.pincode}
                          </td>
                          <td className="whitespace-nowrap px-6 py-4 text-gray-800">
                            {new Date(buy?.createdAt).toLocaleDateString(
                              "en-US",
                              {
                                year: "numeric",
                                month: "short", 
                                day: "2-digit",
                              }
                            )}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
         
    </div>
  );
}
