import React from "react";
import Header from "../Comman/Header";
import Footer from "../Comman/Footer";
import {
  ArrowPathIcon,
  CloudArrowUpIcon,
  Cog6ToothIcon,
  FingerPrintIcon,
  LockClosedIcon,
  ServerIcon,
} from "@heroicons/react/20/solid";

const features = [
  {
    name: "Evaluating Your Business",
    description:
      "Determine if your business model is suitable for franchising. Consider factors like profitability, replicability, and market demand.",
    icon: CloudArrowUpIcon, // You can replace the icon with a relevant one
  },
  {
    name: "Legal Considerations",
    description:
      "Develop your Franchise Disclosure Document (FDD) and franchise agreement. These documents outline the terms of your franchise offering and are required by law in many jurisdictions.",
    icon: LockClosedIcon,
  },
  {
    name: "Operations Manual",
    description:
      "Create a comprehensive guide detailing how to run your business. This manual will be the blueprint for your franchisees.",
    icon: ArrowPathIcon,
  },
  {
    name: "Financial Planning",
    description:
      "Determine your franchise fee structure, royalties, and other financial aspects of your franchise system.",
    icon: FingerPrintIcon,
  },
  {
    name: "Marketing and Branding",
    description:
      "Develop a strong brand identity and marketing strategy that can be consistently applied across all franchise locations.",
    icon: Cog6ToothIcon,
  },
  {
    name: "Franchisee Recruitment",
    description:
      "Create a plan for attracting and selecting qualified franchisees who align with your brand values.",
    icon: ServerIcon,
  },
  {
    name: "Training and Support",
    description:
      "Establish robust training programs and ongoing support systems for your franchisees.",
    icon: CloudArrowUpIcon, // You can replace the icon with a relevant one
  },
];

const FranchiseDevelopment = () => {
  return (
    <>
      <Header />
      <div className="relative bg-gray-900">
        <div className="relative h-80 overflow-hidden bg-indigo-600 md:absolute md:left-0 md:h-full md:w-1/3 lg:w-1/2">
          <img
            alt=""
            src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&blend=6366F1&sat=-100&blend-mode=multiply"
            className="h-full w-full object-cover"
          />
          <svg
            viewBox="0 0 926 676"
            aria-hidden="true"
            className="absolute -bottom-24 left-24 w-[57.875rem] transform-gpu blur-[118px]"
          >
            <path
              d="m254.325 516.708-90.89 158.331L0 436.427l254.325 80.281 163.691-285.15c1.048 131.759 36.144 345.144 168.149 144.613C751.171 125.508 707.17-93.823 826.603 41.15c95.546 107.978 104.766 294.048 97.432 373.585L685.481 297.694l16.974 360.474-448.13-141.46Z"
              fill="url(#60c3c621-93e0-4a09-a0e6-4c228a0116d8)"
              fillOpacity=".4"
            />
            <defs>
              <linearGradient
                id="60c3c621-93e0-4a09-a0e6-4c228a0116d8"
                x1="926.392"
                x2="-109.635"
                y1=".176"
                y2="321.024"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#776FFF" />
                <stop offset={1} stopColor="#FF4694" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="relative mx-auto max-w-7xl py-24 sm:py-32 lg:px-8 lg:py-40">
          <div className="pl-6 pr-6 md:ml-auto md:w-2/3 md:pl-16 lg:w-1/2 lg:pl-24 lg:pr-0 xl:pl-32">
            <h2 className="text-base font-semibold leading-7 text-indigo-400">
              Development
            </h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Franchise Development
            </p>
            <p className="mt-6 text-base leading-7 text-gray-300">
              Franchise development is the strategic process of expanding a
              successful business model through franchising. It involves
              creating a replicable system that allows others to operate under
              your brand name and business model. This section will guide you
              through the key aspects of franchise development.
            </p>
          </div>
        </div>
      </div>
      <div className="bg-white py-16">
        <div className="lg:mx-10 mx-2 max-w-full px-2">
          <div className="mx-auto max-w-2xl sm:text-center">
            <h2 className="text-base font-semibold leading-7 text-indigo-600">
              {" "}
              Franchise Development
            </h2>
            <p className="mt-0 text-xl font-semibold tracking-tight text-gray-900 sm:text-3xl">
              Understanding Franchise Development
            </p>
            <p className="mt-0 text-lg leading-8 text-gray-600">
              Franchise development encompasses several crucial steps:
            </p>
          </div>
        </div>
        <div className="relative overflow-hidden pt-6">
          <div className="lg:mx-10 mx-2 max-w-full px-6 lg:px-8">
            <img
              alt="App screenshot"
              src="https://www.shutterstock.com/shutterstock/videos/1092376359/thumb/10.jpg?ip=x480"
              width={2432}
              height={1442}
              className="mb-[-6%] rounded-xl shadow-2xl ring-1 ring-gray-900/10"
            />
            <div aria-hidden="true" className="relative">
              <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[7%]" />
            </div>
          </div>
        </div>
        <div className="lg:mx-10 mx-2 max-w-full mt-16 px-6 sm:mt-20 md:mt-24 ">
          <dl className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-6 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 ">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-9">
                <dt className="inline font-semibold text-gray-900">
                  <feature.icon
                    aria-hidden="true"
                    className="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                  />
                  {feature.name}
                </dt>{" "}
                <dd className="inline">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>

      <div className="bg-gray-200 px-2 py-10">
        <div id="features" className="lg:mx-10 mx-2 max-w-full">
          <p className="text-center text-base font-semibold leading-7 text-primary-500">
            Benefits
          </p>
          <h2 className="text-center font-display text-xl lg:text-3xl font-semibold tracking-tight text-slate-900 ">
            Key Benefits of Franchise Development
          </h2>
          <ul className="mt-8 grid grid-cols-1 gap-6 text-center text-slate-700 sm:grid-cols-2 lg:grid-cols-4">
            <li className="rounded-xl bg-white px-6 py-8 shadow-sm">
              <img
                src="https://www.svgrepo.com/show/530438/ddos-protection.svg"
                alt
                className="mx-auto h-10 w-10"
              />

              <p className="mt-1.5 text-sm leading-6 text-secondary-500">
                Rapid business expansion with reduced capital investment.
              </p>
            </li>
            <li className="rounded-xl bg-white px-6 py-8 shadow-sm">
              <img
                src="https://www.svgrepo.com/show/530442/port-detection.svg"
                alt
                className="mx-auto h-10 w-10"
              />
              <p className="mt-1.5 text-sm leading-6 text-secondary-500">
                Increased brand recognition and market presence.
              </p>
            </li>
            <li className="rounded-xl bg-white px-6 py-8 shadow-sm">
              <img
                src="https://www.svgrepo.com/show/530444/availability.svg"
                alt
                className="mx-auto h-10 w-10"
              />
              <p className="mt-1.5 text-sm leading-6 text-secondary-500">
                Additional revenue streams through franchise fees and royalties.
              </p>
            </li>
            <li className="rounded-xl bg-white px-6 py-8 shadow-sm">
              <a href="/pricing" className="group">
                <img
                  src="https://www.svgrepo.com/show/530440/machine-vision.svg"
                  alt
                  className="mx-auto h-10 w-10"
                />

                <p className="mt-1.5 text-sm leading-6 text-secondary-500">
                  Leveraging local market knowledge of franchisees.
                </p>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <section className>
        <div className=" space-y-12 lg:px-0 px-2 lg:mx-10 mx-2 max-w-full">
          <div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center py-12">
            <div>
              <div className="mt-0 space-y-6">
                <div>
                  <h2 className="text-xl pl-2 pb-4 font-semibold  sm:text-3xl">
                    Challenges in Franchise Development
                  </h2>
                </div>
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center w-12 h-12 rounded-md">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="lucide lucide-rocket"
                      >
                        <path d="M4.5 16.5c-1.5 1.26-2 5-2 5s3.74-.5 5-2c.71-.84.7-2.13-.09-2.91a2.18 2.18 0 0 0-2.91-.09z"></path>
                        <path d="m12 15-3-3a22 22 0 0 1 2-3.95A12.88 12.88 0 0 1 22 2c0 2.72-.78 7.5-6 11a22.35 22.35 0 0 1-4 2z"></path>
                        <path d="M9 12H4s.55-3.03 2-4c1.62-1.08 5 0 5 0" />
                        <path d="M12 15v5s3.03-.55 4-2c1.08-1.62 0-5 0-5" />
                      </svg>
                    </div>
                  </div>

                  <div className="ml-3">
                    <h4 className="text-lg font-medium leadi ">Maintaining</h4>
                    <p className="mt-0 ">
                      Maintaining Consistent quality across all franchise
                      locations.
                    </p>
                  </div>
                </div>
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center w-12 h-12 rounded-md">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="lucide lucide-bookmark-plus"
                      >
                        <path d="m19 21-7-4-7 4V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v16z" />
                        <line x1={12} x2={12} y1={7} y2={13} />
                        <line x1={15} x2={9} y1={10} y2={10} />
                      </svg>
                    </div>
                  </div>
                  <div className="ml-3">
                    <h4 className="text-lg font-medium leadi ">Balancing</h4>
                    <p className=" ">Control with franchisee autonomy.</p>
                  </div>
                </div>
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center w-12 h-12 rounded-md">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="lucide lucide-video"
                      >
                        <path d="m22 8-6 4 6 4V8Z" />
                        <rect
                          width={14}
                          height={12}
                          x={2}
                          y={6}
                          rx={2}
                          ry={2}
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="ml-3">
                    <h4 className="text-lg font-medium leadi ">Managing</h4>
                    <p className="mt-0 ">
                      relationships with multiple franchisees.
                    </p>
                  </div>
                </div>
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center w-12 h-12 rounded-md">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="lucide lucide-file-question"
                      >
                        <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
                        <path d="M10 10.3c.2-.4.5-.8.9-1a2.1 2.1 0 0 1 2.6.4c.3.4.5.8.5 1.3 0 1.3-2 2-2 2" />
                        <path d="M12 17h.01" />
                      </svg>
                    </div>
                  </div>
                  <div className="ml-3">
                    <h4 className="text-lg font-medium leadi ">Adapting </h4>
                    <p className="mt-0 ">
                      To different local markets while maintaining brand
                      integrity.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div aria-hidden="true" className="mt-10 lg:mt-0">
              <img
                width={600}
                height={600}
                src="https://images.unsplash.com/photo-1516542076529-1ea3854896f2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NzEyNjZ8MHwxfHNlYXJjaHwxNHx8Y29tcHV0ZXJ8ZW58MHwwfHx8MTY5OTE3MDk1N3ww&ixlib=rb-4.0.3&q=80&w=1080"
                className="mx-auto rounded-lg shadow-lg dark-bg-gray-500"
                style={{ color: "transparent" }}
              />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default FranchiseDevelopment;
