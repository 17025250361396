// authSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getAllFranchise = createAsyncThunk(
  "staff/getAllFranchise",
  async (_, thunkAPI) => {
    try {
      const response = await fetch("https://api.way4franchise.com/api/v1/franchise/list");

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getFranchiseByid = createAsyncThunk(
  "staff/getFranchiseByid",
  async ({_id}, thunkAPI) => {
    try {
      const response = await fetch(`https://api.way4franchise.com/api/v1/franchise/${_id}`);

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const deleteFranchise = createAsyncThunk(
  "staff/deleteFranchise",
  async ({id,action}, thunkAPI) => {
    try {
      // Your asynchronous logic to delete student here
      const response = await fetch(`https://api.way4franchise.com/api/v1/franchise/delete/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({action}),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }
      const data = await response.json();
      return { Id: id, message: data.message };
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const franchiseKycAction = createAsyncThunk(
  "staff/franchiseKycAction",
  async ({ CompanyId, action }, thunkAPI) => {
    try {
      // Your asynchronous logic to delete student here
      const response = await fetch(
        `https://api.way4franchise.com/api/v1/franchise/update/kyc`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ CompanyId, action }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }
      const data = await response.json();
      return { Id: CompanyId, message: data.message };
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const franchiseUploads = createAsyncThunk(
  "auth/franchiseUploads",
  async (formData, thunkAPI) => {
    try {
      // Your asynchronous logic to authenticate user here
      const response = await fetch(
        "https://api.way4franchise.com/api/v1/franchise/uploads",
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const EditFranchiseProfile = createAsyncThunk(
  "auth/EditFranchiseProfile",
  async (userData, thunkAPI) => {
    try {
      // Your asynchronous logic to authenticate user here
      const response = await fetch(
        "https://api.way4franchise.com/api/v1/franchise/edit/investor",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userData }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const promotFranchise = createAsyncThunk(
  "auth/promotFranchise",
  async ({id,status}, thunkAPI) => {
    try {
      // Your asynchronous logic to authenticate user here
      const response = await fetch(
        "https://api.way4franchise.com/api/v1/franchise/promoted",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id ,status}),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
       
        throw new Error(errorData.message);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const getAllPromoted = createAsyncThunk(
  "staff/getAllPromoted",
  async (_, thunkAPI) => {
    try {
      const response = await fetch("https://api.way4franchise.com/api/v1/franchise/promoted/list");

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getMarketingDetail = createAsyncThunk(
  "staff/getMarketingDetail",
  async ({_id}, thunkAPI) => {
    try {
      const response = await fetch(`https://api.way4franchise.com/api/v1/franchise/marketing/detail/${_id}`);

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const FranchiseDes = createAsyncThunk(
  "auth/FranchiseDes",
  async ({franchise_id, description }, thunkAPI) => {
    try {
      // Your asynchronous logic to authenticate user here
      const response = await fetch(
        "https://api.way4franchise.com/api/v1/franchise/update/description",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ franchise_id, description  }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getDescriptionByid = createAsyncThunk(
  "staff/getDescriptionByid",
  async ({_id}, thunkAPI) => {
    try {
      const response = await fetch(`https://api.way4franchise.com/api/v1/franchise/description/${_id}`);

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);


export const getPopularFranchiseById = createAsyncThunk(
  "staff/getPopularFranchiseById",
  async ( _id , thunkAPI) => {
    try {
      const response = await fetch(`https://api.way4franchise.com/api/v1/franchise/popularSearch/${_id}`);

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);


const initialState = {
  allfranchise:null,
  franchisee:null,
  franchiseMarketingDetails:null,
  description:null,
  allpromoted:null,
  loading: false,
  error: null,
  message: null,
};

const franchiseSlice = createSlice({
  name: "franchise",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllFranchise.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllFranchise.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.allfranchise = action.payload.allfranchise;
      })
      .addCase(getAllFranchise.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
      .addCase(getFranchiseByid.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getFranchiseByid.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.franchisee = action.payload.franchisee;
      })
      .addCase(getFranchiseByid.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
      .addCase(deleteFranchise.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteFranchise.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.message = action.payload.message;
      })
      .addCase(deleteFranchise.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
      .addCase(franchiseKycAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(franchiseKycAction.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.message = action.payload.message;
      })
      .addCase(franchiseKycAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
      .addCase(franchiseUploads.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(franchiseUploads.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.message = action.payload.message;
      })
      .addCase(franchiseUploads.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
      .addCase(EditFranchiseProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(EditFranchiseProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.message = action.payload.message;
      })
      .addCase(EditFranchiseProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
      .addCase(promotFranchise.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(promotFranchise.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.message = action.payload.message;
      })
      .addCase(promotFranchise.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
      .addCase(getAllPromoted.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllPromoted.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.allpromoted = action.payload.allpromoted;
      })
      .addCase(getAllPromoted.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
      .addCase(getMarketingDetail.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMarketingDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.franchiseMarketingDetails = action.payload.franchiseMarketingDetails;
      })
      .addCase(getMarketingDetail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
      .addCase(FranchiseDes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(FranchiseDes.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.message = action.payload.message;
      })
      .addCase(FranchiseDes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
      .addCase(getDescriptionByid.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDescriptionByid.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.description = action.payload.description;
      })
      .addCase(getDescriptionByid.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
      .addCase(getPopularFranchiseById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPopularFranchiseById.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.message = action.payload.message;
      })
      .addCase(getPopularFranchiseById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
  },
});

export const { clearErrors, clearMessage } = franchiseSlice.actions;

export default franchiseSlice.reducer;
