import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const uplaodAd = createAsyncThunk(
  "auth/uploadAd",
  async (adData, thunkAPI) => {
    try {

      // Create a FormData object
      const formData = new FormData();
      formData.append("franchise_id", adData.franchise_id);
      formData.append("adText", adData.adText);
      formData.append("adLink", adData.adLink);
      formData.append("adType", adData.adType);
      formData.append("amount", adData.amount);
      formData.append("days", adData.days);

      // Append files
      Object.keys(adData.images).forEach((key) => {
        formData.append(key, adData.images[key].file); // Assuming each image object has a file property
      });

      const response = await fetch("https://api.way4franchise.com/api/v1/ad/upload", {
        method: "POST",
        body: formData, // Use FormData as the body
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getAds = createAsyncThunk(
  "auth/getAds",
  async (_, thunkAPI) => {
  try {
    const response = await fetch("https://api.way4franchise.com/api/v1/ad/ads");
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to fetch ads");
    }
    const data = await response.json();
    return data; // `data` field from response
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const getAdsByid = createAsyncThunk(
  "auth/getAdsByid",
  async (id, thunkAPI) => {
  try {
    const response = await fetch(`https://api.way4franchise.com/api/v1/ad/adbyid/${id}`);
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to fetch ads");
    }
    const data = await response.json();
    return data; // `data` field from response
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const adsAction = createAsyncThunk(
  "staff/adsAction",
  async ({ _id, action }, thunkAPI) => {
    try {
      // Your asynchronous logic to delete student here
      const response = await fetch(
        `https://api.way4franchise.com/api/v1/ad/update/action`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ _id, action }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }
      const data = await response.json();
      return { Id: _id, message: data.message };
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue(error.message);

    }
  }
);

export const adsStatus = createAsyncThunk(
  "staff/adsStatus",
  async ({ _id, action }, thunkAPI) => {
    try {
      // Your asynchronous logic to delete student here
      const response = await fetch(
        `https://api.way4franchise.com/api/v1/ad/update/status`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ _id, action }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }
      const data = await response.json();
      return { Id: _id, message: data.message };
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue(error.message);

    }
  }
);

export const adDelete = createAsyncThunk(
  "staff/adDelete",
  async ( {id, action} , thunkAPI) => {
    try {
      // Your asynchronous logic to delete student here
      const response = await fetch(
        `https://api.way4franchise.com/api/v1/ad/delete/${id}`,
        {
          method: "delete",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }
      const data = await response.json();
      return { Id: id, message: data.message };
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue(error.message);

    }
  }
);

export const setAdCount = createAsyncThunk(
  "staff/setAdCount",
  async ( _id , thunkAPI) => {
    try {
      const response = await fetch(`https://api.way4franchise.com/api/v1/ad/count/${_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const initialState = {
 loading: false,
 error: null,
 message: null,
 ads: null,
 investorad: null,
};

const adSlice = createSlice({
  name: "ad",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uplaodAd.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(uplaodAd.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.message = action.payload.message;
      })
      .addCase(uplaodAd.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
      .addCase(getAds.pending, (state) => {
        state.loading = true;
        state.error = null; 
      })
      .addCase(getAds.fulfilled, (state, action) => {
        state.loading = false;
        state.ads = action.payload.data;
      })
      .addCase(getAds.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(adsAction.pending, (state) => {
        state.loading = true;
        state.error = null; 
      })
      .addCase(adsAction.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload.message;
      })
      .addCase(adsAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(adsStatus.pending, (state) => {
        state.loading = true;
        state.error = null; 
      })
      .addCase(adsStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload.message;
      })
      .addCase(adsStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getAdsByid.pending, (state) => {
        state.loading = true;
        state.error = null; 
      })
      .addCase(getAdsByid.fulfilled, (state, action) => {
        state.loading = false;
        state.investorad = action.payload.data;
      })
      .addCase(getAdsByid.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(adDelete.pending, (state) => {
        state.loading = true;
        state.error = null; 
      })
      .addCase(adDelete.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload.message;
      })
      .addCase(adDelete.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(setAdCount.pending, (state) => {
        state.loading = true;
        state.error = null; 
      })
      .addCase(setAdCount.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload.message;
      })
      .addCase(setAdCount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  },
});

export const { clearErrors, clearMessage } = adSlice.actions;

export default adSlice.reducer;
