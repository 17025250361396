import React, { useState, useEffect } from 'react';

const walkthroughScreens = [
  {
    title: "Product Intro Walkthrough",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    image: "/devOps.png",
     add: "Ad"
  },
  {
    title: "Explore the Features",
    description: "Discover all the amazing features we offer.",
    image: "/banner.jpg",
    add: "Ad"
  },
//   {
//     title: "Connect with Friends",
//     description: "Easily connect with friends and family.",
//     image: "https://s3.amazonaws.com/jebbles-codepen/icon.png",
//   },
 
];

export default function BannerSlider() {
  const [currentScreen, setCurrentScreen] = useState(0);

  const nextScreen = () => {
    setCurrentScreen((prev) => (prev < walkthroughScreens.length - 1 ? prev + 1 : 0));
  };

  useEffect(() => {
    const interval = setInterval(nextScreen, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
   < div className="p-2">
    <div className="relative w-full  bg-white  overflow-hidden">
      <div className="flex-col items-center text-center p-4">
        <img
          className="w-full h-[300px] bg-cover transition-transform duration-300 hover:scale-105"
          src={walkthroughScreens[currentScreen].image}
          alt={walkthroughScreens[currentScreen].title}
        />
        <h2 className="text-xl font-semibold mb-2 mt-6 text-gray-800">
          {walkthroughScreens[currentScreen].title}
        </h2>
        <p className="text-sm text-gray-600 mb-8 mx-4">
          {walkthroughScreens[currentScreen].description}
        </p>
        <p className='absolute top-4 bg-black uppercase text-white font-semibold px-3 py-1 text-[14px] '> {walkthroughScreens[currentScreen].add}</p>
      </div>

      {/* Pagination Dots */}
      <div className="absolute bottom-6 left-0 right-0 flex justify-center items-center">
        <div className="flex space-x-2">
          {walkthroughScreens.map((_, index) => (
            <span
              key={index}
              className={`h-2 w-2 rounded-full transition-all duration-300 ${index === currentScreen ? 'bg-blue-600' : 'bg-gray-300'}`}
            ></span>
          ))}
        </div>
      </div>
    </div>
   </div>
  
  
  
  );
}
