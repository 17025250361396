import { Description } from "@headlessui/react";
import React from "react";

const BestBusinessOpportunities = () => {
  const cardData = [
    {
      id: 1,
      text: "Car Bazar Ludhiana",
      Description: "Best Kids Readymade Garment",
      logo: "/CarBazarLudhiana.png",
    },
    { id: 2, text: "Zodiac Clothing", Description: "Ludhiana", logo: "/ZodiacClothingLudhiana.png" },
    { id: 3, text: "Benazir By Neha Ralhan", Description: "Ludhiana ", logo: "/Benazir.png" },
    { id: 4, text: "Pawan Garments", Description: "Ludhiana", logo: "/PawanGarmentsLudhiana.png" },
    { id: 5, text: "WildMark Fashion", Description: "Ludhiana", logo: "/WildMark.png" },
    { id: 6, text: "Men Winter Wear", Description: "Ludhiana", logo: "/WinterWear.png" },
    { id: 7, text: "Zodiac", Description: "Ludhiana", logo: "/ZodiacClothingLudhiana.png" },
    { id: 8, text: "National Automobiles", Description: "Ludhiana", logo: "/National.png" },
    { id: 9, text: "Top Hosiery Manufacturers", Description: "Ludhiana", logo: "/TopHosie.png" },
    { id: 10, text: "Lakshita, Civil Lines", Description: "Ludhiana", logo: "/Lakshita.png" },
    { id: 11, text: "Bottle Label Manufacturers", Description: "Ludhiana", logo: "/Bottle.png" },
    { id: 12, text: "Firstlove", Description: "Ludhiana", logo: "/Firstlove.png" },
    { id: 13, text: "Home and kitchen", Description: "Ludhiana", logo: "/Homekitchen.png" },
    { id: 14, text: "BCH Store", Description: "Ludhiana", logo: "/Bch.png" },
    { id: 15, text: "The Hemisphere", Description: "Ludhiana", logo: "/Hemisphere.png" },
    { id: 16, text: "Zodiac", Description: "Ludhiana", logo: "/ZodiacClothingLudhiana.png" },
    { id: 17, text: "Top Hosiery Manufacturers", Description: "Ludhiana", logo: "/TopHosie.png" },
    { id: 18, text: "Pawan Garments", Description: "Ludhiana", logo: "/PawanGarmentsLudhiana.png" },
    // { id: 19, text: "", Description: "", logo: "" },
    // { id: 20, text: "", Description: "", logo: "" },
    // { id: 21, text: "", Description: "", logo: "" },
    // { id: 22, text: "", Description: "", logo: "" },
    // { id: 23, text: "", Description: "", logo: "" },
    // { id: 24, text: "", Description: "", logo: "" },
    // { id: 25, text: "", Description: "", logo: "" },
    // { id: 26, text: "", Description: "", logo: "" },
    // { id: 27, text: "", Description: "", logo: "" },
    // { id: 28, text: "", Description: "", logo: "" },
    // { id: 29, text: "", Description: "", logo: "" },
    // { id: 30, text: "", Description: "", logo: "" },
    // { id: 31, text: "", Description: "", logo: "" },
    // { id: 32, text: "", Description: "", logo: "" },
    // { id: 33, text: "", Description: "", logo: "" },
    // { id: 34, text: "", Description: "", logo: "" },
    // { id: 35, text: "", Description: "", logo: "" },
    // { id: 36, text: "", Description: "", logo: "" },
    // { id: 37, text: "", Description: "", logo: "" },
    // { id: 38, text: "", Description: "", logo: "" },
    // { id: 39, text: "", Description: "", logo: "" },
    // { id: 40, text: "", Description: "", logo: "" },
    // { id: 41, text: "", Description: "", logo: "" },
    // { id: 42, text: "", Description: "", logo: "" },
  ];

  return (
    <div className="max-w-full mx-10 px-3 mt-12">
      <div className="border rounded-md shadow-sm ">
        <div className="border-b pb-3 pt-4 px-4 mb-6">
          <div className="flex justify-between text-sm ">
            <div className="text-gray-800 flex items-center pb-2 pr-2  capitalize">
              <h2 className="font-semibold  lg:text-2xl sm:text-xl text-lg text-gray-800 inline-block">
                Pupular Franchise Brand
              </h2>
            </div>
          </div>
        </div>
        <div className="grid lg:grid-cols-6 md:grid-cols-5 sm:grid-cols-4  grid-cols-1 gap-4 px-4 pb-7">
          {cardData.map((card) => (
            <div
              key={card.id}
              className="relative group h-20  shadow hover:shadow-lg border overflow-hidden"
            >
              <div className="absolute bottom-0 left-0 w-full h-full bg-gray-500 flex items-center justify-center transform translate-y-full group-hover:translate-y-0 transition-transform duration-300 ease-in-out">
                <div className="">
                  <span className="text-white text-[12px] font-semibold block ">
                    {card.text}
                  </span>
                  <span className="text-white text-[12px]">
                    {card.Description}
                  </span>
                </div>
              </div>
              <div className="h-full flex items-center justify-center">
                <img src={card.logo} className="h-full w-full bg-cover" />
                {/* <div className="h-full w-full">
                <div
                  className="h-20 w-full bg-center bg-cover"
                  style={{
                    backgroundImage: `url(${card.logo})`
                  }}
                />
              </div> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BestBusinessOpportunities;
