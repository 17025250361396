import { useDispatch, useSelector } from "react-redux";
import { getTransactionByID } from "../redux/paymentSlice";
import { useEffect, useState } from "react";
import { FaLock } from "react-icons/fa";
import { FaLockOpen } from "react-icons/fa";

export default function InvestorTrans() {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state.auth);
  const { singleinvestorTrans, loading } = useSelector(
    (state) => state.buyPayment
  );

  useEffect(() => {
    if (auth?._id) {
      const franchise_id = auth?._id;
      dispatch(getTransactionByID({ franchise_id }));
    }
  }, [auth?._id]);
  return (
    <>
      <div className="border-gray-300 mt-2">
        <div className="sm:flex sm:items-center p-4">
          <div className="sm:flex-auto">
            <h1 className=" font-semibold text-gray-900">Transaction Detail</h1>
            <p className="mt-1  text-gray-700">
              A list of all the transaction .
            </p>
          </div>
          <div className="mt-4 sm:flex-none">
            <input
              type="search"
              // onChange={(e) => setSearchQuery(e.target.value)}
              className="border px-2 py-1.5 text-[12px] border-gray-400"
              placeholder="Search"
            />
          </div>
        </div>
        <div className="mt-8 flow-root px-3">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full align-middle">
              <div className="relative">
                <div className="overflow-x-auto  shadow-md">
                  <table className="min-w-full text-sm table-auto divide-y divide-gray-300 rounded-lg">
                    <thead className="bg-gray-900 text-white ">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left font-semibold text-gray-100 text-[13px] uppercase"
                        >
                          ID
                        </th>

                        <th
                          scope="col"
                          className="px-6 py-3 text-left font-semibold text-gray-100 text-[13px] uppercase"
                        >
                          Amount
                        </th>

                        <th
                          scope="col"
                          className="px-6 py-3 text-left font-semibold text-gray-100 text-[13px] uppercase"
                        >
                          Type
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left font-semibold text-gray-100 text-[13px] uppercase"
                        >
                          Status
                        </th>

                        <th
                          scope="col"
                          className="px-6 py-3 text-center font-semibold text-gray-100 text-[13px] uppercase"
                        >
                          Action
                        </th>

                        <th
                          scope="col"
                          className="px-6 py-3 text-left font-semibold text-gray-100 text-[13px] uppercase"
                        >
                          At
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white capitalize">
                      {singleinvestorTrans
                        ?.slice()
                        .reverse()
                        .map((buy, index) => (
                          <tr
                            key={index}
                            className="hover:bg-gray-100 transition-colors duration-150"
                          >
                            <td className="whitespace-nowrap px-6 text-[12px] py-4 text-gray-800">
                              {buy?._id}
                            </td>
                            <td className="whitespace-nowrap px-6 text-[12px] py-4 text-gray-800">
                              {buy?.amount} Rs
                            </td>
                            <td className="whitespace-nowrap text-[12px] px-6 py-4 text-gray-800">
                              {buy?.transaction_type}
                            </td>
                            <td className="whitespace-nowrap px-6 py-4 text-gray-800 text-[12px]">
                              {buy?.status}
                            </td>
                            <td className="whitespace-nowrap px-6 py-4 text-gray-800 text-center capitalize">
                              <div
                                className={`capitalize p-1 text-[12px] ${
                                  (buy?.action || "").toLowerCase() ===
                                  "ad refund" ? "bg-blue-200 text-blue-600"
                                    : (buy?.action || "").toLowerCase() === "credit"
                                    ? "bg-green-200 text-green-600" : (buy?.action || "").toLowerCase() ==="debit"
                                    ? "bg-red-200 text-red-600"
                                    : "bg-transparent"
                                }`}
                              >
                                {buy?.action}
                              </div>
                            </td>
                            <td className="whitespace-nowrap px-6 py-4 text-gray-800 text-[12px">
                              {new Date(buy?.createdAt).toLocaleDateString()}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
           
      </div>
    </>
  );
}
