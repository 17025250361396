// authSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (values, thunkAPI) => {
    try {
      const response = await fetch("https://api.way4franchise.com/api/v1/auth/signin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const loginAdmin = createAsyncThunk(
  "auth/loginAdmin",
  async (values, thunkAPI) => {
    try {
      // Your asynchronous logic to authenticate user here
      const response = await fetch(
        "https://api.way4franchise.com/api/v1/auth/adminsignin",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const signUpUser = createAsyncThunk(
  "auth/signUpUser",
  async (userData, thunkAPI) => {
    try {
      // Your asynchronous logic to authenticate user here
      const response = await fetch(
        "https://api.way4franchise.com/api/v1/auth/register",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userData),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const signUpInvestor = createAsyncThunk(
  "auth/signUpInvestor",
  async (formData, thunkAPI) => {
    try {
      // Your asynchronous logic to authenticate user here
      const response = await fetch(
        "https://api.way4franchise.com/api/v1/auth/brand-register",
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const signout = createAsyncThunk("auth/signout", async (_, thunkAPI) => {
  try {
    localStorage.removeItem("auth");
    const response = await fetch("https://api.way4franchise.com/api/v1/auth/signout");
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});
export const signoutAdmin = createAsyncThunk(
  "auth/signoutAdmin",
  async (_, thunkAPI) => {
    try {
      localStorage.removeItem("admin");
      const response = await fetch("https://api.way4franchise.com/api/v1/auth/signout");
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const editUser = createAsyncThunk(
  "auth/editUser",
  async (userData, thunkAPI) => {
    try {
      const response = await fetch(
        "https://api.way4franchise.com/api/v1/auth/edit/user",
        {
          method: "put",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userData),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const sendOtp = createAsyncThunk(
  "auth/sendOtp",
  async (email, thunkAPI) => {
    try {
      const response = await fetch(
        "https://api.way4franchise.com/api/v1/auth/sendotp",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async ({ email, role }, thunkAPI) => {
    try {
      const response = await fetch(
        "https://api.way4franchise.com/api/v1/auth/forgot-password",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, role }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const verifyOtp = createAsyncThunk(
  "auth/verifyOtp",
  async ({ email, otp }, thunkAPI) => {
    try {
      const response = await fetch(
        "https://api.way4franchise.com/api/v1/auth/verifyotp",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, otp }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (values, thunkAPI) => {
    try {
      const response = await fetch(
        "https://api.way4franchise.com/api/v1/auth/reset-password",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const initialState = {
  auth: null,
  admin: null,
  auth_loading: false,
  auth_error: null,
  auth_message: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearAuthErrors: (state) => {
      state.auth_error = null;
    },
    clearAuthMessage: (state) => {
      state.auth_message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.auth_loading = true;
        state.auth_error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.auth_loading = false;
        state.auth_error = null;
        state.auth = action.payload.auth;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.auth_loading = false;
        state.auth_error = action.payload.error;
      })
      .addCase(loginAdmin.pending, (state) => {
        state.auth_loading = true;
        state.auth_error = null;
      })
      .addCase(loginAdmin.fulfilled, (state, action) => {
        state.auth_loading = false;
        state.auth_error = null;
        state.admin = action.payload.admin;
      })
      .addCase(loginAdmin.rejected, (state, action) => {
        state.auth_loading = false;
        state.auth_error = action.payload.error;
      })
      .addCase(signUpUser.pending, (state) => {
        state.auth_loading = true;
        state.auth_error = null;
      })
      .addCase(signUpUser.fulfilled, (state, action) => {
        state.auth_loading = false;
        state.auth_error = null;
        state.auth_message = action.payload.message;
      })
      .addCase(signUpUser.rejected, (state, action) => {
        state.auth_loading = false;
        state.auth_error = action.payload.error;
      })

      .addCase(editUser.pending, (state) => {
        state.auth_loading = true;
        state.auth_error = null;
      })
      .addCase(editUser.fulfilled, (state, action) => {
        state.auth_loading = false;
        state.auth_error = null;
        state.auth_message = action.payload.message;
      })
      .addCase(editUser.rejected, (state, action) => {
        state.auth_loading = false;
        state.auth_error = action.payload.error;
      })

      .addCase(signUpInvestor.pending, (state) => {
        state.auth_loading = true;
        state.auth_error = null;
      })
      .addCase(signUpInvestor.fulfilled, (state, action) => {
        state.auth_loading = false;
        state.auth_error = null;
        state.auth_message = action.payload.message;
      })
      .addCase(signUpInvestor.rejected, (state, action) => {
        state.auth_loading = false;
        state.auth_error = action.payload.error;
      })

      .addCase(signout.pending, (state) => {
        state.auth_loading = true;
        state.auth_error = null;
      })
      .addCase(signout.fulfilled, (state, action) => {
        state.auth_loading = false;
        state.auth_error = null;
        state.auth = null;
      })
      .addCase(signout.rejected, (state, action) => {
        state.auth_loading = false;
        state.auth_error = action.payload.error;
      })
      .addCase(signoutAdmin.pending, (state) => {
        state.auth_loading = true;
        state.auth_error = null;
      })
      .addCase(signoutAdmin.fulfilled, (state, action) => {
        state.auth_loading = false;
        state.auth_error = null;
        state.admin = null;
      })
      .addCase(signoutAdmin.rejected, (state, action) => {
        state.auth_loading = false;
        state.auth_error = action.payload.error;
      })

      .addCase(sendOtp.pending, (state) => {
        state.auth_loading = true;
        state.auth_error = null;
      })
      .addCase(sendOtp.fulfilled, (state, action) => {
        state.auth_loading = false;
        state.auth_error = null;
        state.auth_message = action.payload.message;
      })
      .addCase(sendOtp.rejected, (state, action) => {
        state.auth_loading = false;
        state.auth_error = action.payload.error;
      })
      .addCase(forgotPassword.pending, (state) => {
        state.auth_loading = true;
        state.auth_error = null;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.auth_loading = false;
        state.auth_error = null;
        state.auth_message = action.payload.message;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.auth_loading = false;
        state.auth_error = action.payload.error;
      })
      .addCase(resetPassword.pending, (state) => {
        state.auth_loading = true;
        state.auth_error = null;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.auth_loading = false;
        state.auth_error = null;
        state.auth_message = action.payload.message;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.auth_loading = false;
        state.auth_error = action.payload.error;
      })
      .addCase(verifyOtp.pending, (state) => {
        state.auth_loading = true;
        state.auth_error = null;
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        state.auth_loading = false;
        state.auth_error = null;
        state.auth_message = action.payload.message;
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        state.auth_loading = false;
        state.auth_error = action.payload.error;
      })
    

    // .addCase(ChangePassword.pending, (state) => {
    //   state.auth_loading = true;
    //   state.auth_error = null;
    // })
    // .addCase(ChangePassword.fulfilled, (state, action) => {
    //   state.auth_loading = false;
    //   state.auth_error = null;
    //   state.auth_message = action.payload.message;
    // })
    // .addCase(ChangePassword.rejected, (state, action) => {
    //   state.auth_loading = false;
    //   state.auth_error = action.payload.error;
    // })
  },
});

export const { clearAuthErrors, clearAuthMessage } = authSlice.actions;

export default authSlice.reducer;
