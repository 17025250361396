import React from 'react'

const About = () => {
  return (
    <>
 
    <div className="px-2 max-w-full mx-6 lg:mx-10 mt-10">
      <section className="mb-16 ">
        <div className="w-full sm:pl-5 lg:pl-0 mb-4 ">
          <div className=" flex justify-between text-sm ">
            <div className="text-indigo-600 flex items-center pb-2 pr-2 border-b-2 border-indigo-600 capitalize">
              <h2 className="font-semibold  lg:text-2xl sm:text-lg text-lg text-gray-800 inline-block">
              About Way4Franchise
              </h2>
            </div>
          </div>
        </div>
        <p className=" md:text-base mb-4 text-justify">
        At Way4Franchise, we understand that starting a business can be daunting, and that's where we come in. Our platform provides a wealth of resources, including detailed franchise profiles, industry insights, and expert advice, to help you make informed decisions and navigate the franchising landscape with confidence.
        </p>
        <p className=" md:text-base mb-4 text-justify">
        Whether you're a seasoned entrepreneur looking to expand your business through franchising or a newcomer eager to start your own venture, we have something to offer. Our user-friendly interface and comprehensive search features make it easy to find the perfect franchise opportunity that aligns with your goals and interests.
        </p>
        <p className=" md:text-base mb-4 text-justify">
        In addition to connecting investors and franchisors, we also provide valuable tools and services to support both sides of the equation. For franchisors, we offer marketing and branding solutions, recruitment assistance, and operational support. For investors, we provide due diligence services, financial analysis, and negotiation guidance.

        </p>
        <p className=" md:text-base mb-4 text-justify">
        With our commitment to excellence and our deep understanding of the franchising industry, Way for Franchise is your trusted partner in achieving your business goals.

        </p>
        {/* <p className=" md:text-base text-justify">
          Way 4 Franchise.com consistently attracts a steady flow of traffic and visitors to the site. Advertisers can also take
          advantage of the extensive collection of books and reports available in the Way 4 Franchise Bookstore section.
        </p> */}
      </section>
    </div>
  


    </>
  )
}

export default About;
