import {  useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { getAllUsers,deleteUsers,clearErrors,clearMessage } from "../redux/userSlice";
import { PencilIcon, TrashIcon, EyeIcon } from "@heroicons/react/24/outline";
import { RiDeviceRecoverLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import {Confirmation} from "../BaseFile/Confirmation"
import SuccessAlert from "../BaseFile/SuccessAlert";
import ErrorAlert from "../BaseFile/ErrorAlert";

export default function AdminUsers() {
  const dispatch = useDispatch();
  const { allusers ,error ,message} = useSelector((state) => state.allusers);
  const [deletedId, setDeletedId] = useState();
  const [AllUser, setAllUser] = useState(allusers);
  const [searchQuery, setSearchQuery] = useState("");
  const [recoverId, setRecoverId] = useState();
  const [showRecycleItem, SetShowRecycleItem] = useState('no');


  useEffect(() => {
    dispatch(getAllUsers());
    if (error) {
      const errorInterval = setInterval(() => {
        dispatch(clearErrors());
      }, 3000);
      return () => clearInterval(errorInterval);
    }
    if (message) {
      const messageInterval = setInterval(() => {
        dispatch(clearMessage());
      }, 3000);
      return () => clearInterval(messageInterval);
    }
  }, [message, error]);

  function isClose(){
    setDeletedId(null)
    setRecoverId(null)

  }
  function handleDelete(id){
    setDeletedId(id)
  }
  function handleRecover(id){
    setRecoverId(id)
  }

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setAllUser(
      allusers?.filter((p) => p.username?.toLowerCase().includes(searchTerm))
    );
    setSearchQuery(e.target.value);
  };
  return (
    <>
    {message && <SuccessAlert message={message} />}
    {error && <ErrorAlert error={error} />}
    <div className="overflow-x-auto bg-white pl-2 pe-4">
      <div className="sm:flex sm:items-center ">
        <div className="sm:flex-auto pt-4">
          {showRecycleItem == "no" ? (
          <h1 className="text-base font-semibold text-gray-800"> Users</h1>
          ):(
          <h1 className="text-base font-semibold text-gray-800">Deleted Users</h1>
          )}
           
          <p className="mt-2 text-sm text-gray-600">
            A list of all the users in your account, including their name,
            email, phone, and address.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-6 sm:flex-none">
          {showRecycleItem == "no" ? (
          <button onClick={()=>SetShowRecycleItem('yes')} className="bg-black text-white text-[12px] py-1.5 px-2 mr-3 rounded"> Recycle Bin </button>
          ):(
          <button onClick={()=>SetShowRecycleItem('no')} className="bg-black text-white text-[12px] py-1.5 px-2 mr-3 rounded"> Users </button>
          )}
          <input type="search" value={searchQuery} onChange={(e) => handleSearch(e)} className='border px-2 py-1.5 text-[12px] rounded-md border-gray-400' placeholder='Search'/>
        </div>
      </div>

      <div className="mt-5 flow-root">
        <div className="inline-block min-w-full py-2 align-middle">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-sm">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className=" text-gray-900">
                <tr>
                  <th className="px-6 py-3 text-left text-[12px] uppercase font-semibold">
                    Name & Email
                  </th>
                  <th className="px-6 py-3 text-left text-[12px] uppercase font-semibold">
                    Phone & Address
                  </th>
                  <th className="px-6 py-3 text-left text-[12px] uppercase font-semibold">
                    Verified &  Created At
                  </th>
                  <th className="relative px-6 py-3 text-right text-[12px] uppercase font-semibold">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {(searchQuery ? AllUser : allusers)?.filter((item)=>item.role!=='admin' && item?.isDeleted == showRecycleItem)?.map((person) => (
                  <tr key={person.email} className="hover:bg-gray-50">
                    <td className="whitespace-nowrap px-6 py-2 text-[12px] font-medium text-gray-900">
                    <span className="block capitalize">{person?.username}</span>
                    <span className="block">{person?.email}</span>
                    </td>
                    <td className="whitespace-nowrap px-6 py-2 text-[12px] text-gray-500">
                      <span className="block">{person?.phone}</span>
                      <span className="block capitalize"> {person?.address}</span>
                      
                    </td>
                    <td className="whitespace-nowrap px-6 py-2 text-[12px] text-gray-500">
                      <span className="block capitalize">{person?.isVerified}</span>
                      <span className="block">{new Date(person?.created_at).toLocaleDateString()}</span>
                    </td>
                    <td className="whitespace-nowrap px-6 py-2 text-right text-[12px] font-medium space-x-2">
                      <td className="relative whitespace-nowrap py-5 pl-3 pr-2 text-right text-[12px] font-medium ">
                        <div className="flex gap-2">
                          <Link
                            to={`/admin/company/edit/${person._id}`}
                            className="text-indigo-600 hover:text-indigo-900"
                          >
                            <PencilIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </Link>
                          {showRecycleItem == "no" ? (<button
                            onClick={() => handleDelete(person._id)}
                            className="text-red-600 hover:text-red-900"
                          >
                            <TrashIcon className="h-5 w-5" aria-hidden="true" />
                          </button>

                         ):(<button
                            onClick={() => handleRecover(person._id)}
                            className="text-red-600 hover:text-red-900"
                          >
                            <RiDeviceRecoverLine className="h-5 w-5" aria-hidden="true" />
                          </button>)}
                          <Link
                            to={`/admin/userprofile/${person?._id}`}
                            className="text-gray-600 hover:text-gray-900"
                          >
                            <EyeIcon className="h-5 w-5" aria-hidden="true" />
                          </Link>
                        </div>
                      </td>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  {deletedId && (
    <Confirmation isClose={isClose} deletefunction={deleteUsers} id={deletedId} action={"yes"} />
  )}
  {recoverId && (
    <Confirmation isClose={isClose} deletefunction={deleteUsers} id={recoverId} action={"no"} />
  )}
    </>
  );
}
