import { useState, useEffect } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { getHotLeads } from "../../redux/leadUpdateSlice";
import { useDispatch, useSelector } from "react-redux";
export default function Notifications({ open, setOpen }) {
  const dispatch = useDispatch();
  const { hotleads } = useSelector((state) => state.leads);
  const { auth } = useSelector((state) => state.auth);
  useEffect(() => {
    if (auth?._id) {
      const franchise_id = auth?._id;
      dispatch(getHotLeads(franchise_id));
    }
  }, [auth?.id]);
  return (
    <>
      
      <div
        className={`absolute lg:right-5 right-3 z-50 w-64 lg:top-28 top-[515px] mt-2 overflow-hidden origin-top-right bg-white rounded-md shadow-lg sm:w-80  transition-all duration-100 ease-out transform scale-100 opacity-100`}
        onClick={() => setOpen(false)}
      >
        <div className="">
          <a
            href="#"
            className="flex items-center px-4 py-3 -mx-2 transition-colors duration-300 transform border-b border-gray-100  hover:bg-gray-200"
          >
            <span className="inline-block h-8 border w-8 overflow-hidden rounded-full bg-gray-200">
              <svg
                fill="currentColor"
                viewBox="0 0 24 24"
                className="h-full w-full text-gray-400"
              >
                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
            </span>
            <p className="mx-2 text-sm text-gray-600 ">
              {hotleads?.length || 0} user view your franchsie profile
            </p>
          </a>
          {/* <a
            href="#"
            className="flex items-center px-4 py-3 -mx-2 transition-colors duration-300 transform border-b border-gray-100 hover:bg-gray-50 dark:hover:bg-gray-700 dark:border-gray-700"
          >
            <img
              className="flex-shrink-0 object-cover w-8 h-8 mx-1 rounded-full"
              src="https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80"
              alt="avatar"
            />
            <p className="mx-2 text-sm text-gray-600 dark:text-white">
              <span className="font-bold">Slick Net</span> started following
              you. 45m
            </p>
          </a>
          <a
            href="#"
            className="flex items-center px-4 py-3 -mx-2 transition-colors duration-300 transform border-b border-gray-100 hover:bg-gray-50 dark:hover:bg-gray-700 dark:border-gray-700"
          >
            <img
              className="flex-shrink-0 object-cover w-8 h-8 mx-1 rounded-full"
              src="https://images.unsplash.com/photo-1450297350677-623de575f31c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80"
              alt="avatar"
            />
            <p className="mx-2 text-sm text-gray-600 dark:text-white">
              <span className="font-bold">Jane Doe</span> liked your reply on{" "}
              <span className="text-blue-500 hover:underline">
                Test with TDD
              </span>{" "}
              article. 1h
            </p>
          </a>
          <a
            href="#"
            className="flex items-center px-4 py-3 -mx-2 transition-colors duration-300 transform hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <img
              className="flex-shrink-0 object-cover w-8 h-8 mx-1 rounded-full"
              src="https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=398&q=80"
              alt="avatar"
            />
            <p className="mx-2 text-sm text-gray-600 dark:text-white">
              <span className="font-bold">Abigail Bennett</span> started
              following you. 3h
            </p>
          </a> */}
        </div>
        {/* <a
          href="#"
          className="block py-2 font-semibold text-center text-white bg-gray-800 dark:bg-gray-700 hover:underline"
        >
          See all notifications
        </a> */}
      </div>
    </>
  );
}
