import React, { useState, useEffect } from "react";
import { getAds } from "../redux/adSlice";
import { useSelector, useDispatch } from "react-redux";
import {setAdCount } from "../redux/adSlice";
import { useNavigate } from "react-router-dom";
export const Ads = ({ adnum }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { ads, error, message } = useSelector((state) => state.ad);
  const { auth } = useSelector((state) => state.auth);
  const [leagle_ads ,setLeagle_ads] = useState(null)
  const [adsData, setAdsData] = useState({
    ad1: { image: null, desc: null ,_id:null,franchise_name:null,franchise_id:null},
    ad2: { image: null, desc: null ,_id:null,franchise_name:null,franchise_id:null},
    ad3: { image: null, desc: null ,_id:null,franchise_name:null,franchise_id:null},
    ad4: { image: null, desc: null ,_id:null,franchise_name:null,franchise_id:null},
  });

  useEffect(() => {
    dispatch(getAds());
  }, [dispatch]);

  useEffect(() => {
    if (ads && auth?.role === "user" && auth?.selectedStates) {
      const authKeys = Object.keys(auth?.selectedStates);
      
      setLeagle_ads(
        ads.filter((item) => 
          item?.is_verified === 'approved' && 
          (item?.amount > 5 || item?.days > 0) &&
          authKeys.some((key) => key in item?.selectedStates)
        )
      );
    } else {
      setLeagle_ads(
        ads?.filter((item) =>
          item?.is_verified === 'approved' && 
          (item?.amount > 5 || item?.days > 0)
        )
      );
    }
  }, [ads, auth]);
  
  const getRandomAdsData = () => {
    if (!leagle_ads || leagle_ads.length === 0) return;

    setAdsData({
      ad1: (() => {
        const ad = leagle_ads[Math.floor(Math.random() * leagle_ads.length)];
        return { image: ad.image1, desc: ad.adText ,_id:ad._id , franchise_name:ad.franchise_name , franchise_id:ad.franchise_id};
      })(),
      ad2: (() => {
        const ad = leagle_ads[Math.floor(Math.random() * leagle_ads.length)];
        return { image: ad.image2, desc: ad.adText ,_id:ad._id , franchise_name:ad.franchise_name , franchise_id:ad.franchise_id};
      })(),
      ad3: (() => {
        const ad = leagle_ads[Math.floor(Math.random() * leagle_ads.length)];
        return { image: ad.image3, desc: ad.adText,_id:ad._id  , franchise_name:ad.franchise_name , franchise_id:ad.franchise_id};
      })(),
      ad4: (() => {
        const ad = leagle_ads[Math.floor(Math.random() * leagle_ads.length)];
        return { image: ad.image4, desc: ad.adText,_id:ad._id , franchise_name:ad.franchise_name , franchise_id:ad.franchise_id };
      })(),
    });
  };

  useEffect(() => {
    getRandomAdsData();
    const intervalId = setInterval(getRandomAdsData, 3000);
    return () => clearInterval(intervalId);
  }, [leagle_ads]);


  function handleAdCount (ad){
    const _id=ad?._id
    dispatch(setAdCount(_id))
    navigate(`/franchisedetail/${ad.franchise_name.replace(" ","_")}/${ad?.franchise_id}`)
  }

  return (
    <>
      {adnum === 1 && (
        <div className="relative">
          <div className="text-base absolute top-0 capitalize right-0 bg-black px-4 py-2 text-white mt-3 mr-3 font-medium hover:bg-black  transition duration-500 ease-in-out">
            ads
          </div>
          {adsData?.ad1?.image && (
            <img  onClick={()=>handleAdCount(adsData?.ad1)} src={`/uploads/adData/${adsData?.ad1?.image}`} className="w-full" />
          )}
          <p className="text-left mt-2 text-[12px] capitalize">{adsData?.ad1?.desc}</p>
        </div>
      )}

      {adnum === 2 && (
        <div className="relative ">
          <>
            {adsData?.ad2?.image && (
              <img onClick={()=>handleAdCount(adsData?.ad2)}
                src={`/uploads/adData/${adsData?.ad2?.image}`}
                className="w-full h-40   bg-cover relative"
              />
              
            )}
           <p className="text-left mt-2 text-[12px] capitalize">{adsData?.ad2?.desc}</p>
            <div className="text-xs absolute -top-3 -right-3 bg-gray-900 px-4 py-2 text-white mt-3 mr-3 hover:bg-indigo-500  transition duration-500 ease-in-out">
              Ad
            </div>
          </>
        </div>
      )}
      {adnum === 3 &&
        adsData?.ad3?.image && (
          <>
          <div className="">
          <img onClick={()=>handleAdCount(adsData?.ad3)}
            src={`/uploads/adData/${adsData?.ad3?.image}`}
            alt="Fashion"
            className="w-full h-full shadow-lg rounded-lg border"
          />
          <p className="text-left mt-2 text-[12px] capitalize">{adsData?.ad3?.desc}</p>
          </div>
          </>
        )
      }

      {adnum === 4 && (
        <div className="">
          {adsData?.ad4?.image && (
            <img onClick={()=>handleAdCount(adsData?.ad4)}
              src={`/uploads/adData/${adsData?.ad4?.image}`}
              className="w-full lg:h-96 "
            />

          )}
          <p className="text-left mt-2 text-[12px] capitalize">{adsData?.ad4?.desc}</p>
        </div>
      )}
    </>
  );
};
