const industriesData = {
    Automotive: {
      sectors: [
        "Automobile Related",
        "Commercial Vehicles",
        "Four Wheeler",
        "Two Wheeler",
        "Electric Vehicles",
        "Luxury Vehicles",
        "Vehicle Rentals",
        "Vehicle Insurance",
        "Automobile Finance",
        "Vehicle Technology",
      ],
      products: [
        "Automobile Accessories",
        "Automobile Garage Related",
        "Automobile Maintenance Related",
        "Automobile Spares / Tyre",
        "Charging Stations",
        "Road Safety Equipment",
        "Security & Helpline Services",
        "Web Based/Online Platform",
        "Car Audio Systems",
        "Vehicle Safety Equipment",
      ],
    },
    "Business Services": {
      sectors: [
        "Consulting Services",
        "Financial Services",
        "Legal Services",
        "Marketing & Advertising",
        "Human Resources",
        "IT Services",
        "Logistics & Supply Chain",
        "Business Training & Development",
        "Security Services",
        "Environmental Services",
      ],
      products: [
        "Business Consulting",
        "Accounting Software",
        "Legal Documentation Services",
        "Digital Marketing Services",
        "HR Management Systems",
        "Cloud Computing Services",
        "Freight & Shipping Solutions",
        "Leadership Training Programs",
        "Security Systems",
        "Waste Management Services",
      ],
    },
    "Dealers & Distributors": {
      sectors: [
        "Wholesale Distribution",
        "Retail Distribution",
        "Consumer Goods",
        "Industrial Goods",
        "Franchise Distribution",
        "Electronic Distribution",
        "Pharmaceutical Distribution",
        "Automotive Parts Distribution",
        "Food & Beverage Distribution",
        "Logistics Distribution",
      ],
      products: [
        "Retail Products",
        "Industrial Equipment",
        "Electronic Appliances",
        "Pharmaceutical Products",
        "Automotive Spare Parts",
        "Food & Beverage Products",
        "Consumer Packaged Goods",
        "Office Supplies",
        "Wholesale Electronics",
        "Medical Equipment",
      ],
    },
    Education: {
      sectors: [
        "Primary Education",
        "Secondary Education",
        "Higher Education",
        "Online Education",
        "Vocational Training",
        "Skill Development",
        "Tutoring Services",
        "Education Technology",
        "Special Education",
        "Test Preparation",
      ],
      products: [
        "E-Learning Platforms",
        "Textbooks & Materials",
        "Tutoring Services",
        "Classroom Technologies",
        "Education Software",
        "Student Management Systems",
        "Online Course Platforms",
        "Education Consulting",
        "Special Needs Resources",
        "Testing & Assessment Tools",
      ],
    },
    Fashion: {
      sectors: [
        "Clothing",
        "Footwear",
        "Accessories",
        "Luxury Goods",
        "Fashion Technology",
        "Fast Fashion",
        "Ethical Fashion",
        "Fashion Retail",
        "E-Commerce Fashion",
        "Sustainable Fashion",
      ],
      products: [
        "Apparel",
        "Footwear",
        "Handbags",
        "Jewelry",
        "Watches",
        "Eyewear",
        "Fashion Accessories",
        "Outerwear",
        "Swimwear",
        "Fashion Tech Gadgets",
      ],
    },
   "Food And Beverage": {
    "sectors": [
        "Cafe",
        "Restaurants",
        "Catering Services",
        "Food Processing",
        "Beverage Production",
        "Food Delivery Services",
        "Organic Foods",
        "Food Tech",
        "Packaged Foods",
        "Alcoholic Beverages",
        "Non-Alcoholic Beverages",
        "Food Trucks",
        "Gourmet Food Retail",
        "Vegan & Plant-Based Foods",
        "Farm-to-Table",
        "Specialty Foods",
        "Health & Wellness Foods",
        "Meal Prep Services",
        "Snack Production",
        "Spices & Condiments",
        "Food Waste Management"
    ],
    "products": [
        "Baked Goods",
        "Dairy Products",
        "Packaged Snacks",
        "Organic Produce",
        "Beverages",
        "Meat & Poultry",
        "Canned Goods",
        "Confectionery",
        "Prepared Meals",
        "Frozen Foods",
        "Health Supplements",
        "Gluten-Free Products",
        "Dips & Sauces",
        "Ready-to-Drink Beverages",
        "Artisanal Cheeses",
        "Jams & Preserves",
        "Honey & Spreads",
        "Fermented Foods",
        "Herbal Teas",
        "Nutritional Bars",
        "Protein Shakes & Powders"
    ]
},
    "Home Based Business": {
      sectors: [
        "Consulting",
        "Marketing",
        "Online Business",
        "Freelancing",
        "Tutoring",
        "Crafts & Handicrafts",
        "Food Services",
        "Digital Marketing",
        "E-Commerce",
        "Handmade Products",
      ],
      products: [
        "Consulting Services",
        "Crafted Products",
        "Baked Goods",
        "Handmade Crafts",
        "Online Courses",
        "Marketing Services",
        "Virtual Assistance",
        "Web Development",
        "Graphic Design Services",
        "Freelance Writing",
      ],
    },
    "Hotel, Travel & Tourism": {
      sectors: [
        "Hotels & Resorts",
        "Travel Agencies",
        "Tour Operators",
        "Airlines",
        "Cruise Lines",
        "Online Travel Services",
        "Tourism Boards",
        "Hospitality Management",
        "Adventure Travel",
        "Luxury Travel",
      ],
      products: [
        "Hotel Services",
        "Travel Packages",
        "Flight Tickets",
        "Tourism Experiences",
        "Hotel Booking Platforms",
        "Resort Stays",
        "Car Rentals",
        "Cruise Packages",
        "Vacation Rentals",
        "Tourism Apps",
      ],
    },
    Retail: {
      sectors: [
        "Apparel Retail",
        "Food & Beverage Retail",
        "Electronics Retail",
        "Home Goods Retail",
        "E-Commerce",
        "Convenience Stores",
        "Department Stores",
        "Pharmacy Retail",
        "Luxury Retail",
        "Automotive Retail",
      ],
      products: [
        "Apparel",
        "Footwear",
        "Electronics",
        "Groceries",
        "Furniture",
        "Cosmetics",
        "Home Appliances",
        "Pharmaceuticals",
        "Automobiles",
        "Sports Equipment",
      ],
    },
    "Sports, Fitness & Entertainment": {
      sectors: [
        "Sports Equipment",
        "Fitness Centers",
        "Personal Training",
        "Sports Coaching",
        "Event Management",
        "Sports Technology",
        "Entertainment Production",
        "Recreational Activities",
        "Fitness Apps",
        "Sports Broadcasting",
      ],
      products: [
        "Sportswear",
        "Exercise Equipment",
        "Fitness Accessories",
        "Sports Gear",
        "Personal Training Programs",
        "Fitness Supplements",
        "Event Planning Services",
        "Sports Streaming Platforms",
        "Fitness Apps",
        "Entertainment Content Production",
      ],
    },
    "Beauty & Health": {
      sectors: [
        "Personal Care",
        "Health Services",
        "Skincare",
        "Haircare",
        "Cosmetics",
        "Fitness & Wellness",
        "Beauty Salons",
        "Spa Services",
        "Organic Beauty Products",
        "Nutritional Supplements",
      ],
      products: [
        "Skincare Products",
        "Haircare Products",
        "Makeup",
        "Beauty Tools",
        "Health Supplements",
        "Fitness Products",
        "Personal Hygiene Products",
        "Organic Beauty Products",
        "Spa & Wellness Services",
        "Medical Services",
      ],
    },
  };

  export default industriesData;