import React from "react";
import { ScaleIcon } from "@heroicons/react/24/outline";
import { CiShop } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { MdOutlinePendingActions } from "react-icons/md";
import { getAllFranchise, getAllPromoted } from "../redux/franchiseSlice";
import { getAllUsers } from "../redux/userSlice";
import { FaUsers } from "react-icons/fa6";
import { MdVerifiedUser } from "react-icons/md";
import { getAllUserKycData } from "../redux/userSlice";
import { getAllFranchisePayments } from "../redux/paymentSlice";
const getRandomColorClass = () => {
  const colors = [
    "bg-red-500",
    "bg-blue-500",
    "bg-green-500",
    "bg-yellow-500",
    "bg-purple-500",
    "bg-pink-500",
    "bg-orange-500",
  ];

  const randomIndex = Math.floor(Math.random() * colors.length);
  return colors[randomIndex];
};

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const { allfranchise, allpromoted } = useSelector((state) => state.franchise);
  const { allusers, userKyc } = useSelector((state) => state.allusers);
  const { allpayments, alluserpayments, loading } = useSelector(
    (state) => state.buyPayment
  );
  useEffect(() => {
    dispatch(getAllFranchise());
    dispatch(getAllUsers());
    dispatch(getAllUserKycData());
    dispatch(getAllPromoted());
    dispatch(getAllFranchisePayments());
  }, [dispatch]);

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth(); // Note: Month is 0-indexed (0 = January, 11 = December)

  const verifiedFranchiseCount = allfranchise?.filter(
    (franchise) => franchise?.isVerified === "yes"
  ).length;

  const promotedFranchises = allfranchise?.filter((franchise) =>
    allpromoted?.includes(franchise._id)
  );

  const franchiseWithImagesAndNotVerifiedCount = allfranchise?.filter(
    (franchise) =>
      franchise.is_verified === "no" && // Check if not verified
      franchise.image2 &&
      franchise.image3 &&
      franchise.image4
  ).length;

  const pendingUserKycCount = userKyc?.filter(
    (kyc) => kyc?.status === "pending"
  ).length;

  const verifiedUserCount = allusers?.filter(
    (user) => user?.isVerified === "yes"
  ).length;

  const franchisesCreatedThisMonth = allfranchise?.filter((franchise) => {
    const createdAt = new Date(franchise?.createdAt);
    return (
      createdAt.getFullYear() === currentYear &&
      createdAt.getMonth() === currentMonth
    );
  }).length;

  const usersCreatedThisMonth = allusers?.filter((user) => {
    const createdAt = new Date(user?.created_at);
    return (
      createdAt.getFullYear() === currentYear &&
      createdAt.getMonth() === currentMonth
    );
  }).length;

  const cards = [
    {
      name: "Total franchise",
      amount: allfranchise?.length,
      detail: `${franchisesCreatedThisMonth} in this month`,
      icon: CiShop,
      bgColor: "bg-gray-900", // Background color for the icon
      detailColor: "text-red-600", // Text color for card detail
    },
    {
      name: "Total User",
      amount: allusers?.length,
      detail: `${usersCreatedThisMonth} in this month`,
      icon: FaUsers,
      bgColor: "bg-blue-800",
      detailColor: "text-green-600",
    },
    {
      name: "Verified Franchise",
      amount: verifiedFranchiseCount,
      detail: `${franchiseWithImagesAndNotVerifiedCount} Pending franchise KYC`,
      icon: MdOutlinePendingActions,
      bgColor: "bg-green-600",
      detailColor: "text-yellow-600",
    },
    {
      name: "Verified Users",
      amount: verifiedUserCount,
      detail: `${pendingUserKycCount} pending user KYC`,
      icon: MdVerifiedUser,
      bgColor: "bg-pink-600",
      detailColor: "text-blue-600",
    },
  ];

  return (
    <>
      <div className=" max-w-7xl">
        <div className="mt-2 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 ">
          {cards.map((card) => (
            <div key={card.name} className="rounded-xl">
              <div className="rounded-sm bg-white shadow relative">
                <div
                  className={`py-3 ${card.bgColor} w-[50px] flex justify-center items-center rounded-lg absolute -top-[25px] left-[18px]`}
                >
                  <card.icon
                    aria-hidden="true"
                    className="h-6 w-6 text-white"
                  />
                </div>
                <div className="p-5">
                  <div className="flex items-center">
                    <div className="ml-5 w-0 flex-1 text-right">
                      <dl>
                        <dt className="truncate text-[12px] mt-1 tracking-wide text-gray-700">
                          {card.name}
                        </dt>
                        <dd>
                          <div className="text-xl font-medium text-gray-900">
                            {card.amount}
                          </div>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
                <div className=" px-5 py-3 border-t rounded-xl">
                  <div className="text-xs">
                    <a
                      className={`font-medium hover:text-gray-900 ${card.detailColor}`}
                    >
                      {card.detail}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="grid grid-cols-12 gap-x-4 ">
        <div className="lg:col-span-8 col-span-12">
          <div className="mt-8 bg-white py-5 px-3 rounded-sm shadow-md border">
            <div className=" pb-2">
              <h2 className="text-base font-semibold uppercase text-gray-900">
                Promoted Franchise
              </h2>
            </div>
            <div className="mt-3 border border-gray-200  overflow-hidden h-[370px] ">
              <div className="h-[480px] overflow-y-auto no-scrollbar">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr className="">
                      <th className="px-4 py-3 text-left text-[11px] font-semibold text-gray-900 uppercase tracking-wider">
                        Franchise Name
                      </th>
                      <th className="px-4 py-3 text-left text-[11px] font-semibold text-gray-900 uppercase tracking-wider">
                        Logo
                      </th>
                      <th className="px-4 py-3 text-left text-[11px] font-semibold text-gray-900 uppercase tracking-wider">
                        Investment Range
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {promotedFranchises?.map((project, index) => (
                      <tr key={index} className="hover:bg-gray-50">
                        <td className="px-4 py-3 flex items-center space-x-3">
                          <span
                            aria-hidden="true"
                            className={`${getRandomColorClass()} h-2.5 w-2.5 flex-shrink-0 rounded-full`}
                          />
                          <span className="truncate text-xs font-medium leading-6 ">
                            {project?.franchise_name}{" "}
                            <span className="font-normal text-gray-500">
                              is franchise of {project?.companyName}
                            </span>
                          </span>
                        </td>
                        <td className="px-4 py-3">
                          <div className="flex items-center justify-center">
                            <img
                              src={`/uploads/InvestorData/${project?.companyLogo}`}
                              alt={project?.companyName}
                              className="h-6 w-6 rounded-full ring-2 ring-white"
                            />
                          </div>
                        </td>
                        <td className="px-4 py-3">
                          <span className="text-xs font-medium leading-6">
                            {project?.investment_range_tier_1} to{" "}
                            {project?.investment_range_tier_2}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:col-span-4 col-span-12">
          <div className="mt-8 bg-white py-5 px-3 rounded-sm shadow-md border">
            <div className=" pb-2">
              <h2 className="text-base font-semibold uppercase text-gray-900">
                Payments Details
              </h2>
            </div>

            <ul className="bg-white shadow overflow-hidden sm:rounded-md border max-w-sm mx-auto pl-2 list-inside h-[381px] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200">
              {allpayments?.map((data) => (
                <li className="relative pl-3 before:absolute before:left-1 border-b before:top-1/2 before:-translate-y-1/2 before:h-2 before:w-2 before:rounded-full before:bg-blue-500 hover:bg-gray-100">
                  <div className="px-4 py-2">
                    <div className="flex items-center justify-between">
                      <h3 className="text-[12px] leading-6 font-medium text-gray-900">
                        {data?.franchise_name}
                      </h3>
                      <p className="mt-1 max-w-2xl text-[12px] text-gray-500">
                        {data?.planDetails.price}
                      </p>
                    </div>
                    <div className="mt-2 flex items-center justify-between">
                      <p className="text-[12px] font-medium">
                        Status :
                        <span
                          className={
                            data.status === "captured"
                              ? "text-green-600 pl-1 text-[12px] font-medium capitalize"
                              : "text-red-600 capitalize text-[12px] font-medium"
                          }
                        >
                          {data.status}
                        </span>
                      </p>
                      <a
                        href="#"
                        className="font-medium text-indigo-600 text-[12px] hover:text-indigo-500"
                      >
                        {new Date(data?.createdAt).toLocaleDateString()}
                      </a>
                    </div>
                  </div>
                </li>
              ))}
              {alluserpayments?.map((data) => (
                <li className="relative pl-3 before:absolute before:left-1  border-b before:top-1/2 before:-translate-y-1/2 before:h-2 before:w-2 before:rounded-full before:bg-blue-500 hover:bg-gray-100">
                  <div className="px-4 py-2">
                    <div className="flex items-center justify-between">
                      <h3 className="text-[12px] leading-6 font-medium text-gray-900">
                        {data?.username}
                      </h3>
                      <p className="mt-1 max-w-2xl text-[12px] text-gray-500">
                        {data?.planDetails.price}
                      </p>
                    </div>
                    <div className="mt-2 flex items-center justify-between">
                      <p className="text-[12px] font-medium">
                        Status :
                        <span
                          className={
                            data.status === "captured"
                              ? "text-green-600 pl-1 text-[12px] font-medium capitalize"
                              : "text-red-600 capitalize text-[12px] font-medium"
                          }
                        >
                          {data.status}
                        </span>
                      </p>
                      <a
                        href="#"
                        className="font-medium text-indigo-600 text-[12px] hover:text-indigo-500"
                      >
                        {new Date(data?.createdAt).toLocaleDateString()}
                      </a>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
