import React, { useState, useEffect } from "react";
import {
updateService,
  getServiceRequest,
  clearErrors,
  clearMessage,
} from "../redux/serviceSlice";
import { useSelector, useDispatch } from "react-redux";
import SuccessAlert from "../BaseFile/SuccessAlert";
import ErrorAlert from "../BaseFile/ErrorAlert";
import Spinner from "../BaseFile/Spinner";
import { FaRegEdit } from "react-icons/fa";
import { ImCancelCircle } from "react-icons/im";
import { ImCheckmark } from "react-icons/im";
export default function AdminServiceRequest() {
  const [editId, setEditId] = useState(null);
  const [updatedAmount, setUpdatedAmount] = useState("");
  const dispatch = useDispatch();
  const { services, loading, error, message } = useSelector(
    (state) => state.service
  );
  useEffect(() => {
    dispatch(getServiceRequest());
    if (error) {
      const errorInterval = setInterval(() => {
        dispatch(clearErrors());
      }, 3000);
      return () => clearInterval(errorInterval);
    }

    if (message) {
      const messageInterval = setInterval(() => {
        dispatch(clearMessage());
      }, 3000);
      return () => clearInterval(messageInterval);
    }
  }, [dispatch, error, message, clearErrors, clearMessage]);
 
  const handleSave = async () => {
      dispatch(updateService({ id:editId, amount: updatedAmount }));
    setEditId(null);

  };

  const handleCancel = () => {
    setEditId(null); 
  };

  const handleEdit = (id) => {
    setEditId(id);
  };

  return (
    <>
      {message && <SuccessAlert message={message} />}
      {error && <ErrorAlert error={error} />}
      <div className="overflow-x-auto p-4">
      <table className="min-w-full bg-white border border-gray-200 text-sm">
        <thead className="bg-gray-100 font-light text-[12px]">
          <tr>
            <th className="px-4 py-2 border-b text-left">
              Franchise & Service Name
            </th>
            <th className="px-4 py-2 border-b text-left">
              Owner & Manager Email
            </th>
            <th className="px-4 py-2 border-b text-left">
              Phone No. & Duration (Months)
            </th>
            <th className="px-4 py-2 border-b text-left">Amount & Discount</th>
            <th className="px-4 py-2 border-b text-left"> Completion Time & status</th>
            <th className="px-4 py-2 border-b text-left">Description</th>
            <th className="px-4 py-2 border-b text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {services?.map((service, index) => (
            <tr
              key={index}
              className="odd:bg-white text-[12px] even:bg-gray-50"
            >
              <td className="px-4 py-2 border-b">
                <div className="flex gap-3">
                  <img
                    src={`/uploads/InvestorData/${service?.companyLogo}`}
                    alt={`${service?.franchise_name} logo`}
                    className="w-10 h-10 object-cover rounded-full"
                  />
                  <div>
                    <span className="block font-medium">
                      {service?.franchise_name}
                    </span>
                    <span className="block text-gray-600">
                      Service : {service?.service}
                    </span>
                  </div>
                </div>
              </td>
              <td className="px-4 py-2 border-b">
                <span className="block font-medium">{service?.ownerEmail}</span>
                <span className="block font-medium">
                  {service?.managerEmail}
                </span>
              </td>
              <td className="px-4 py-2 border-b">
                <span className="block font-medium">{service?.phone}</span>
                <span className="block font-medium">
                  Duration : {service?.duration}
                </span>
              </td>
              <td className="px-4 py-2 border-b">
              <span className="block">
              {editId === service?._id ? (
                <input
                  type="text"
                  value={updatedAmount}
                  onChange={(e) => setUpdatedAmount(e.target.value)}
                  className="border p-1 rounded w-20"
                />
              ) : service?.amount !== "0" ? (
                `Rs. ${service?.amount}`
              ) : (
                "N/A"
              )}
              </span>
              <span className="block">Dis. : {service?.discount}</span>
            </td>

              <td className="px-4 py-2 border-b">
              <span className="block">{service?.completionTime} month</span>
              <span className="block bg-emerald-100 px-2 py-1 capitalize text-center rounded-full">{service?.status}</span>
              </td>
              <td className="px-4 py-2 border-b">{service?.description}</td>
              <td className="px-4 py-2 border-b">
                <div className="flex gap-2">
                  {editId === service?._id ? (
                    <>
                      <button
                        onClick={() => handleSave()}
                        className="px-2 py-1"
                      >
                     <ImCheckmark className="w-5 h-5 text-green-500 hover:text-green-600" />
                      </button>
                      <button
                        onClick={handleCancel}
                        className="px-2 py-1"
                      >
                      <ImCancelCircle className="w-5 h-5 text-red-500 hover:text-red-600"/>
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        onClick={() => handleEdit(service?._id)}
                        className="px-2 py-1 "
                      >
                       <FaRegEdit className="w-5 h-5 text-indigo-500 hover:text-indigo-600"/>
                      </button>
                      {/* <button
                        onClick={() => handleDelete(service.id)}
                        className="px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600"
                      >
                        Delete
                      </button> */}
                    </>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </>
  );
}
