import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserKycById ,clearErrors,clearMessage} from "../redux/userSlice";
import Loader from "../BaseFile/Loader";
import KycModel from "../HomeFiels/Pages/KycModel";
import SuccessAlert from "../BaseFile/SuccessAlert";
import ErrorAlert from "../BaseFile/ErrorAlert";
export default function UserKycDetail() {
  const dispatch = useDispatch();
  const { singleKyc, loading ,error,message } = useSelector((state) => state.allusers);
  const { auth } = useSelector((state) => state.auth);
  const [previewImage, setPreviewImage] = useState();
  const [kycUploadModel, setkycUploadModel] = useState(false);

  useEffect(() => {
    if (auth?._id) {
      const userId = auth?._id;
      dispatch(getUserKycById(userId));
    }
    if (error) {
      const errorInterval = setInterval(() => {
        dispatch(clearErrors());
      }, 3000);
      return () => clearInterval(errorInterval);
    }

    if (message) {
      const messageInterval = setInterval(() => {
        dispatch(clearMessage());
      }, 3000);
      return () => clearInterval(messageInterval);
    }
  }, [dispatch,message, error,clearErrors,clearMessage]);


  const handleImageClick = (imageName) => {
    setPreviewImage(`/uploads/userKyc/${imageName}`);
  };

  const handleClosePreview = () => {
    setPreviewImage(null);
  };
  const closeModal = () => {
    setkycUploadModel(null);
  };

  const files = [
    {
      source: singleKyc?.[0]?.adharCard,
      title: "Adhar Card",
    },
    {
      source: singleKyc?.[0]?.panCard,
      title: "Pan Card",
    },
    {
      source: singleKyc?.[0]?.otherDocument,
      title: "Other",
    },
   
  ];

  return (
    <>
    {message && <SuccessAlert message={message} />}
    {error && <ErrorAlert error={error} />}
    {loading ? (
      <Loader />
    ) : (

      <div className="">
        <div className="sm:flex sm:items-center ">
          <div className="sm:flex-auto border-b  px-5 py-2">
            <div className="flex justify-between">
            <h1 className="text-lg uppercase font-semibold text-gray-800">
              Kyc Detail
            </h1>
          
            <button className="mr-5  hover:bg-indigo-700 bg-indigo-600 w-20 py-1 rounded-md text-white" onClick={()=>setkycUploadModel(true)}> Upload</button>
            </div>
          </div>
        </div>

        <ul
          role="list"
          className="grid mt-3 grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8 pl-4 pb-4"
        >
          {files?.map((file, index) => (
            <li key={index} className="relative" onClick={()=>handleImageClick(file?.source)}>
              <div className="group h-52 w-52 block  overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
              <img
                  alt=""
                  src={file.source ? `/uploads/userKyc/${file.source}` : '/notfound.jpg'}
                  className="pointer-events-none object-cover group-hover:opacity-75 max-w-full"
                />

                <button
                  type="button"
                  className="absolute inset-0 focus:outline-none"
                >
                  <span className="sr-only">
                    View details for {file.title}
                  </span>
                </button>
              </div>
              <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">
                {file.title}
              </p>
            </li>
          ))}
        </ul>
      </div>
    )}

    {previewImage && (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50">
        <div className="relative">
          <img
            src={previewImage}
            alt="Preview"
            className="max-w-full max-h-screen object-contain"
          />
          <button
            onClick={handleClosePreview}
            className="absolute top-2 right-2 text-white text-xl bg-black p-2 rounded-full"
          >
            ×
          </button>
        </div>
      </div>
    )}

    {kycUploadModel && <KycModel closeModal={closeModal}/> }
  </>

  );
}
