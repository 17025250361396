import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllFranchise ,deleteFranchise,clearErrors,clearMessage} from "../redux/franchiseSlice";
import { PencilIcon, TrashIcon, EyeIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import Loader from "../BaseFile/Loader"
import {Confirmation} from "../BaseFile/Confirmation"
import { RiDeviceRecoverLine } from "react-icons/ri";
import SuccessAlert from "../BaseFile/SuccessAlert";
import ErrorAlert from "../BaseFile/ErrorAlert";
export default function AdminFranchise() {
  const dispatch = useDispatch();
  const { allfranchise, loading, error, message } = useSelector((state) => state.franchise);
  const [searchQuery,setSearchQuery]=useState("")
  const [AllFranchise,setAllFranchise]=useState("")
  const [deletedId, setDeletedId] = useState();
  const [recoverId, setRecoverId] = useState();
  const [showRecycleItem, SetShowRecycleItem] = useState('no');

  useEffect(() => {
    dispatch(getAllFranchise());
    if (error) {
      const errorInterval = setInterval(() => {
        dispatch(clearErrors());
      }, 3000);
      return () => clearInterval(errorInterval);
    }

    if (message) {
      const messageInterval = setInterval(() => {
        dispatch(clearMessage());
      }, 3000);
      return () => clearInterval(messageInterval);
    }
  }, [dispatch,message, error,clearErrors,clearMessage]);



  useEffect(() => {
    setAllFranchise(
      allfranchise?.filter((item) =>
        item?.franchise_name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery]);

  function isClose(){
    setDeletedId(null)
    setRecoverId(null)
  }
  function handleDelete(id){
    setDeletedId(id)
  }
  function handleRecover(id){
    setRecoverId(id)
  }
  return (
    <>
        {message && <SuccessAlert message={message} />}
        {error && <ErrorAlert error={error} />}
    {loading ? (<Loader/>) : (
    <div className="bg-white shadow-sm border px-4 rounded-sm">
      <div className="sm:flex sm:items-center pt-3">
        <div className="sm:flex-auto">
        {showRecycleItem == "no" ? (
          <h1 className="text-base font-semibold text-gray-800"> Franchise</h1>
          ):(
          <h1 className="text-base font-semibold text-gray-800">Deleted Franchise</h1>
          )}
          <p className="mt-2 text-[12px] text-gray-700">
            A list of all the companies in your account including their name,
            title, email, and role.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-4 sm:flex-none">
        {showRecycleItem == "no" ? (
          <button onClick={()=>SetShowRecycleItem('yes')} className="bg-black text-white text-[12px] py-1.5 px-2 mr-3 rounded"> Recycle Bin </button>
          ):(
          <button onClick={()=>SetShowRecycleItem('no')} className="bg-black text-white text-[12px] py-1.5 px-2 mr-3 rounded"> Company </button>
          )}
          <input
            type="search"
            onChange={(e)=>setSearchQuery(e.target.value)}
            className="border px-2 py-1.5 text-[12px] rounded-md border-gray-400"
            placeholder="Search"
          />
        </div>
      </div>
      <div className="mt-6 flow-root border shadow-sm rounded-sm overflow-hidden h-[530px]">
      <div className="h-[480px] overflow-y-auto no-scrollbar pb-7">
          <div className="inline-block min-w-full align-middle">
            <div className="relative">
              <div className="overflow-x-auto rounded-lg shadow-md">
                <table className="min-w-full border table-auto divide-y divide-gray-300 rounded-lg">
                  <thead className=" text-gray-900 border shadow-sm">
                    <tr>
                      <th className="py-3.5 px-2 text-left uppercase text-[12px] font-semibold border-b ">
                        Name &  Title
                      </th>
                      <th className="px-3 py-3.5 text-left text-[12px] uppercase font-semibold border-b ">
                        Email & Phone
                      </th>
                      <th className="px-3 py-3.5 text-left text-[12px] uppercase font-semibold border-b ">
                        Website & Status
                      </th>
                      {/* <th className="px-3 py-3.5 text-left text-[12px] uppercase font-semibold border-b ">
                        Franchisee
                      </th> */}
                      <th className="py-3.5 text-center px-3 uppercase  text-[12px] font-medium border-b ">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white ">
                  {
                      (searchQuery ? AllFranchise : allfranchise)
                        ?.filter((item) => item?.isDeleted === showRecycleItem || "")
                        ?.map((company) => (
                      <tr
                        key={company?.email}
                        className="hover:bg-gray-100 transition-colors duration-150"
                      >
                        <td className="whitespace-nowrap capitalize py-4 px-2 text-[12px] text-gray-900">
                          <div className="flex items-center">
                            <div className="h-11 w-11 flex-shrink-0 border rounded-full">
                              <img
                                alt="company-logo"
                                src={`/uploads/InvestorData/${company?.companyLogo}`}
                                className="h-11 w-11 rounded-full"
                              />
                            </div>
                            <div className="ml-4">
                            <span className="block capitalize"> {company?.companyName}</span>
                            <span className="block capitalize">{company?.ownerName}</span>
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 capitalize py-3 text-[12px] text-gray-900">
                         
                          <span className="block "> {company?.ownerEmail}</span>
                          <span className="block capitalize">{company?.phone}</span>
                        </td>
                        <td className="whitespace-nowrap px-3 py-3 capitalize text-[12px] text-gray-900">
                         
                          <span className="block capitalize"> {company?.website}</span>
                          <span className="block capitalize">  {company?.isVerified}</span>
                        </td>
                        {/* <td className="whitespace-nowrap px-3 py-3 capitalize text-[12px] text-gray-900">
                          {company?.franchise_name}
                        </td> */}
                        <td className="relative whitespace-nowrap py-3 pl-3 pr-2 text-right text-[12px] font-medium ">
                          <div className="flex space-x-4 justify-end">
                            <Link
                              to={`/admin/company/edit/${company._id}`}
                              className="text-indigo-600 hover:text-indigo-900"
                            >
                              <PencilIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </Link>
                            {showRecycleItem == "no" ? (<button
                            onClick={() => handleDelete(company._id)}
                            className="text-red-600 hover:text-red-900"
                          >
                            <TrashIcon className="h-5 w-5" aria-hidden="true" />
                          </button>

                         ):(<button
                            onClick={() => handleRecover(company._id)}
                            className="text-red-600 hover:text-red-900"
                          >
                            <RiDeviceRecoverLine className="h-5 w-5" aria-hidden="true" />
                          </button>)}
                            <Link
                              to={`/admin/franchsieprofile/${company?._id}`}
                              className="text-gray-600 hover:text-gray-900"
                            >
                              <EyeIcon className="h-5 w-5" aria-hidden="true" />
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>)}
    {deletedId && (
    <Confirmation isClose={isClose} deletefunction={deleteFranchise} id={deletedId} action={"yes"} />
  )}
  {recoverId && (
    <Confirmation isClose={isClose} deletefunction={deleteFranchise} id={recoverId} action={"no"} />
  )}
    
    </>
  );
}
