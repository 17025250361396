import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../BaseFile/Spinner";
import Header from "../HomeFiels/Comman/Header";
import {
  franchiseUploads,
  clearErrors,
  clearMessage,
} from "../redux/franchiseSlice";
import SuccessAlert from "../BaseFile/SuccessAlert";
import ErrorAlert from "../BaseFile/ErrorAlert";
import Footer from "../HomeFiels/Comman/Footer";

const InvestorUpload = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state.auth);
  const {loading, error, message } = useSelector((state) => state.franchise);
  const [companyLogo, setCompnayLogo] = useState([]);
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState(null);
  const [image4, setImage4] = useState(null);
  const [image5, setImage5] = useState(null);
  const [image6, setImage6] = useState(null);
  const [image7, setImage7] = useState(null);
  const [image8, setImage8] = useState(null);
  const [image9, setImage9] = useState(null);
  const [videoLink, setVideoLink] = useState("");

  useEffect(() => {
    if (error) {
      const errorInterval = setInterval(() => {
        dispatch(clearErrors());
      }, 3000);
      return () => clearInterval(errorInterval);
    }

    if (message) {
      const messageInterval = setInterval(() => {
        dispatch(clearMessage());
      }, 3000);
      return () => clearInterval(messageInterval);
    }
  }, [error, message, clearErrors, clearMessage]);

  const handleSave = () => {
    const formData = new FormData();
    formData.append("videoLink", videoLink);
    formData.append("companyLogo", companyLogo);
    formData.append("_id", auth?._id);

    [image1, image2, image3, image4, image5, image6, image7, image8,image9].forEach(
      (image, index) => {
        if (image) formData.append(`image${index + 1}`, image);
      }
    );
    dispatch(franchiseUploads(formData))
        .then(() => {
            setImage1(null);
            setImage2(null);
            setImage3(null);
            setImage4(null);
            setImage5(null);
            setImage6(null);
            setImage7(null);
            setImage8(null);
            setImage9(null);
        })
        .catch((error) => {
            console.error("Error during dispatch:", error);
        });
  };

  return (
    <>
      {message && <SuccessAlert message={message} />}
      {error && <ErrorAlert error={error} />}
      <div className="max-w-7xl mx-auto py-10">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 px-5 ">
            <div className="bg-white  shadow-sm border rounded-sm pb-8">
              <div className="bg-gray-100">
                <h2 className="text-base font-semibold mb-4 border-b py-3 px-6">
                  Company Uploads
                </h2>
              </div>
              <div className="mt-3 px-6 grid lg:grid-cols-3 gap-x-6 gap-y-4 md:grid-cols-2 grid-cols-1">
                <div className="  ">
                  <label
                    htmlFor="companyLogo"
                    className="block  leading-6  text-[12px] text-gray-900"
                  >
                    Company Logo
                  </label>
                  <div className="mt-1">
                    <div className="flex rounded-sm shadow-sm px-1 ring-1 ring-inset ring-gray-300  focus-within:ring-inset focus-within:ring-indigo-600 md:max-w-md">
                      <input
                        id="companyLogo"
                        onChange={(e) => setCompnayLogo(e.target.files[0])}
                        name="companyLogo"
                        type="file"
                        className="block flex-1 border-0  text-[12px] bg-transparent py-1  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm: sm:leading-6"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Company KYC Form  start*/}

            <div className="bg-white  shadow-sm border rounded-sm pb-6 mt-6">
              <div className="bg-gray-100">
                <h2 className="text-base font-semibold mb-4 border-b py-3 px-6">
                  Company KYC
                </h2>
              </div>

              <div className="grid lg:grid-cols-3 gap-4 sm:grid-cols-2 grid-cols-1 mb-4 px-6">
    <div className="">
      <label className="block text-gray-800 mb-2 text-[12px] ">
        GST KYC Upload (pdf or jpg file)
      </label>
      <input
        type="file"
        onChange={(e) => {
          const file = e.target.files[0];
          if (file) {
            setImage1(file); // Update the state with the selected file
          }
        }}
        className="block w-full py-2 px-3 text-[12px] text-gray-900 border border-gray-300 rounded-sm cursor-pointer bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300 ease-in-out"
        accept="image/*,.pdf" // Allow both images and PDFs
      />
    </div>

    {/* Other input fields for images and KYC documents... */}

                <div className="">
                  <label className="block text-gray-800 mb-2 text-[12px] ">
                    Adhar Card Front (jpg file)
                  </label>
                  <input
                    type="file"
                    onChange={(e) => setImage2(e.target.files[0])}
                    className="block w-full py-2 px-3 text-[12px] text-gray-900 border border-gray-300 rounded-sm cursor-pointer bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300 ease-in-out"
                    accept="image/*"
                  />
                </div>
                <div className="">
                  <label className="block text-gray-800 mb-2 text-[12px] ">
                    Adhar Card Back (jpg file)
                  </label>
                  <input
                    type="file"
                    onChange={(e) => setImage9(e.target.files[0])}
                    className="block w-full py-2 px-3 text-[12px] text-gray-900 border border-gray-300 rounded-sm cursor-pointer bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300 ease-in-out"
                    accept="image/*"
                  />
                </div>

                <div className="">
                  <label className="block text-gray-800 mb-2 text-[12px] ">
                    Registration (jpg file / pdf )
                  </label>
                  <input
        type="file"
        onChange={(e) => {
          const file = e.target.files[0];
          if (file) {
            setImage3(file); // Update the state with the selected file
          }
        }}
        className="block w-full py-2 px-3 text-[12px] text-gray-900 border border-gray-300 rounded-sm cursor-pointer bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300 ease-in-out"
        accept="image/*,.pdf" // Allow both images and PDFs
      />
                </div>
                <div className="">
                  <label className="block text-gray-800 mb-2 text-[13px] ">
                    Pan Card (jpg file)
                  </label>
                  <input
                    type="file"
                    onChange={(e) => setImage4(e.target.files[0])}
                    className="block w-full py-2 px-3 text-[12px] text-gray-900 border border-gray-300 rounded-sm cursor-pointer bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300 ease-in-out"
                    accept="image/*"
                  />
                </div>

                <div className="">
                  <label className="block text-gray-800 mb-2 text-[13px] ">
                    Other (jpg file)
                  </label>
                  <input
                    type="file"
                    onChange={(e) => setImage5(e.target.files[0])}
                    className="block w-full py-2 px-3 text-[12px] text-gray-900 border border-gray-300 rounded-sm cursor-pointer bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300 ease-in-out"
                    accept="image/*"
                  />
                </div>
              </div>
            </div>

            {/* Appearance Details From Start */}

            <div className="bg-white  shadow-sm border rounded-sm pb-6 mt-6">
              <div className="bg-gray-100">
                <h2 className="text-base font-semibold mb-4 border-b py-3 px-6">
                  Appearance Details
                </h2>
              </div>

              <div className="grid lg:grid-cols-2 px-6 gap-4 sm:grid-cols-2 grid-cols-1 mb-4">
                <div className="">
                  <label className="block text-gray-800 mb-2 text-[13px] font-medium">
                    Upload Slide Image  :
                    Image 1
                  </label>
                  <input
                    type="file"
                    onChange={(e) => setImage6(e.target.files[0])}
                    className="block w-full py-2 px-3 text-[12px] text-gray-900 border border-gray-300 rounded-sm cursor-pointer bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300 ease-in-out"
                    accept="image/*"
                  />
                </div>

                <div className="">
                  <label className="block text-gray-800 mb-2 text-[13px] font-medium">
                    Upload Slide Image  :
                    Image 2
                  </label>
                  <input
                    type="file"
                    onChange={(e) => setImage7(e.target.files[0])}
                    className="block w-full py-2 px-3 text-[12px] text-gray-900 border border-gray-300 rounded-sm cursor-pointer bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300 ease-in-out"
                    accept="image/*"
                  />
                </div>
              </div>
              <div className="grid lg:grid-cols-2 px-6 gap-4 sm:grid-cols-2 grid-cols-1 mb-4">
                <div className="">
                  <label className="block text-gray-800 mb-2 text-[13px] font-medium">
                    Upload Slide Image  :
                    Image 3
                  </label>
                  <input
                    type="file"
                    onChange={(e) => setImage8(e.target.files[0])}
                    className="block w-full py-2 px-3 text-[12px] text-gray-900 border border-gray-300 rounded-sm cursor-pointer bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300 ease-in-out"
                    accept="image/*"
                  />
                </div>

                <div className=" text-[13px] font-medium">
                  <label className="block text-gray-800 mb-2">Video Link</label>
                  <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center px-4 border-r border-gray-300 text-gray-500">
                      Link
                    </span>
                    <input
                      type="text"
                      value={videoLink}
                      name="videoLink"
                      onChange={(e) => setVideoLink(e.target.value)}
                      className="w-full pl-[72px] py-[10px] pe-3 border border-gray-300 bg-gray-100 rounded-sm text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500"
                      placeholder="Enter Your Video link"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-6 mb-6 flex items-center justify-end border-t pt-5 border-gray-400 gap-x-6">
              <button
                type="button"
                className=" font-semibold leading-6 text-gray-900"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleSave}
                className="rounded-md bg-indigo-600 px-3 py-2  font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {loading ? <Spinner /> : "Save"}
              </button>
            </div>
            {/* Appearance Details From End */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default InvestorUpload;
