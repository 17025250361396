import React, { useEffect, useState } from "react";
import Spinner from "../../BaseFile/Spinner";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate} from "react-router";
import {
  clearAuthErrors,
  clearAuthMessage,
  loginAdmin,
} from "../../redux/authSlice";
import SuccessAlert from "../../BaseFile/SuccessAlert";
import ErrorAlert from "../../BaseFile/ErrorAlert";
import BannerSlider from "../../AdsComponents/BannerSlider";
import Footer from "../../HomeFiels/Comman/Footer";
import Header from "../../HomeFiels/Comman/Header"

export default function AdminLogin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const {admin, auth_loading, auth_error, auth_message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (auth_error) {
      const errorInterval = setInterval(() => {
        dispatch(clearAuthErrors());
      }, 3000);
      return () => clearInterval(errorInterval);
    }

    if (auth_message) {
      const messageInterval = setInterval(() => {
        dispatch(clearAuthMessage());
      }, 3000);
      return () => clearInterval(messageInterval);
    }
    if(admin){
      navigate("/admin/dashboard")
    }
  }, [values.pincode, auth_message, auth_error,admin]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(loginAdmin(values)).then(() => {
      setValues({
        email: "",
        password: "",
      });
    });
  };
  return (
    <>
      {auth_message && <SuccessAlert message={auth_message} />}
      {auth_error && <ErrorAlert error={auth_error} />}
      <Header/>
      <div className="bg-white pt-10">
      <div className="mt-5 px-4 lg:px-16 flex flex-col lg:flex-row h-auto max-w-6xl mx-auto rounded-xl bg-white">
        <div className="w-full lg:w-3/5">
          <BannerSlider />
        </div>
        <div className="w-full lg:w-2/5 pl-0 lg:pl-10 mt-6 lg:mt-0">
        <h2 className="text-left text-xl font-bold leading-9 tracking-tight text-gray-900">
            Admin Login
          </h2>
            <form onSubmit={handleSubmit} method="POST" className="space-y-6 mt-6">
              
              <div>
                <label
                  htmlFor="email"
                  className="block  font-medium leading-6 text-[13px] text-gray-900"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    placeholder="Enter your email address"
                    required
                    className="block w-full px-3  rounded-md border border-gray-400 text-[13px] py-1.5 text-gray-900 shadow-sm  sm: sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block  font-medium leading-6 text-[13px] text-gray-900"
                >
                  Password
                </label>
                <div className="mt-2">
                  <input
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    placeholder="Enter your password"
                    required
                    className="block w-full px-3 rounded-md border border-gray-400 text-[13px] py-1.5 text-gray-900 shadow-sm  sm: sm:leading-6"
                  />
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 rounded px-3 text-[13px] border-gray-900 text-indigo-600 "
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-3 block  leading-6  text-gray-900 text-[13px] "
                  >
                    Remember me
                  </label>
                </div>

                <div className=" leading-6">
                  <a
                    href="#"
                    className="font-semibold text-[13px] text-indigo-600 hover:text-indigo-500"
                  >
                    Forgot password?
                  </a>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center text-[13px] rounded-md bg-indigo-600 px-3 py-1.5  font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  {auth_loading ? <Spinner /> : "Sign in"}
                </button>
              </div>
            </form>
        </div>
      </div>
    </div>
    <Footer/>
    </>
  );
}
