import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const uploadService = createAsyncThunk(
  "auth/uploadAd",
  async (formData, thunkAPI) => {
    try {
        console.log(formData)
      const response = await fetch("https://api.way4franchise.com/api/v1/service/add", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
          },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getServiceRequest = createAsyncThunk(
  "auth/getServiceRequest",
  async (_, thunkAPI) => {
  try {
    const response = await fetch("https://api.way4franchise.com/api/v1/service/list");
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to fetch ads");
    }
    const data = await response.json();
    return data; // `data` field from response
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const getServiceRequestByID = createAsyncThunk(
  "auth/getServiceRequestByID",
  async (id, thunkAPI) => {
  try {
    const response = await fetch(`https://api.way4franchise.com/api/v1/service/servicebyid/${id}`);
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to fetch ads");
    }
    const data = await response.json();
    return data; // `data` field from response
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const updateService = createAsyncThunk(
  "staff/updateService",
  async ({ id, amount }, thunkAPI) => {
    try {
      console.log(id,amount)
      const response = await fetch(
        `https://api.way4franchise.com/api/v1/service/updateservice`,
        {
          method: "PUT", 
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id, amount }), 
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }
      const data = await response.json();
      return { Id: id, message: data.message, updatedAmount: data.updatedAmount };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);


export const servicePay = createAsyncThunk(
  "service/servicePay",
  async ({  id, amount, discount, paidAmount, franchise_id, coupanname  }, thunkAPI) => {
    try {
      // Your asynchronous logic to delete student here
      const response = await fetch(
        `https://api.way4franchise.com/api/v1/service/pay`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({  id, amount, discount, paidAmount, franchise_id, coupanname   }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }
      const data = await response.json();
      return { Id: id, message: data.message };
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue(error.message);

    }
  }
);

// export const adsStatus = createAsyncThunk(
//   "staff/adsStatus",
//   async ({ _id, action }, thunkAPI) => {
//     try {
//       // Your asynchronous logic to delete student here
//       const response = await fetch(
//         `https://api.way4franchise.com/api/v1/ad/update/status`,
//         {
//           method: "PUT",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({ _id, action }),
//         }
//       );

//       if (!response.ok) {
//         const errorData = await response.json();
//         throw new Error(errorData.message);
//       }
//       const data = await response.json();
//       return { Id: _id, message: data.message };
//     } catch (error) {
//       // Handle error
//       return thunkAPI.rejectWithValue(error.message);

//     }
//   }
// );

// export const adDelete = createAsyncThunk(
//   "staff/adDelete",
//   async ( {id, action} , thunkAPI) => {
//     try {
//       // Your asynchronous logic to delete student here
//       const response = await fetch(
//         `https://api.way4franchise.com/api/v1/ad/delete/${id}`,
//         {
//           method: "delete",
//           headers: {
//             "Content-Type": "application/json",
//           },
//         }
//       );

//       if (!response.ok) {
//         const errorData = await response.json();
//         throw new Error(errorData.message);
//       }
//       const data = await response.json();
//       return { Id: id, message: data.message };
//     } catch (error) {
//       // Handle error
//       return thunkAPI.rejectWithValue(error.message);

//     }
//   }
// );

// export const setAdCount = createAsyncThunk(
//   "staff/setAdCount",
//   async ( _id , thunkAPI) => {
//     try {
//       const response = await fetch(`https://api.way4franchise.com/api/v1/ad/count/${_id}`,
//         {
//           method: "PUT",
//           headers: {
//             "Content-Type": "application/json",
//           },
//         }
//       );

//       if (!response.ok) {
//         const errorData = await response.json();
//         throw new Error(errorData.message);
//       }

//       const data = await response.json();
//       return data;
//     } catch (error) {
//       return thunkAPI.rejectWithValue({ error: error.message });
//     }
//   }
// );

const initialState = {
 loading: false,
 error: null,
 message: null,
 services: null,
 userservices: null,
};

const serviceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadService.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(uploadService.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.message = action.payload.message;
      })
      .addCase(uploadService.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
      .addCase(getServiceRequest.pending, (state) => {
        state.loading = true;
        state.error = null; 
      })
      .addCase(getServiceRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.services = action.payload.services;
      })
      .addCase(getServiceRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getServiceRequestByID.pending, (state) => {
        state.loading = true;
        state.error = null; 
      })
      .addCase(getServiceRequestByID.fulfilled, (state, action) => {
        state.loading = false;
        state.userservices = action.payload.userservices;
      })
      .addCase(getServiceRequestByID.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateService.pending, (state) => {
        state.loading = true;
        state.error = null; 
      })
      .addCase(updateService.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload.message;
      })
      .addCase(updateService.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(servicePay.pending, (state) => {
        state.loading = true;
        state.error = null; 
      })
      .addCase(servicePay.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload.message;
      })
      .addCase(servicePay.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      
  },
});

export const { clearErrors, clearMessage } = serviceSlice.actions;

export default serviceSlice.reducer;
