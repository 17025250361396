import React from "react";
import Header from "../Comman/Header";
import Footer from "../Comman/Footer";
const Commerce = () => {
  return (
    <>
      <Header />
      <div id="about" className="relative bg-white overflow-hidden mt-16">
        <div className="max-w-7xl mx-auto">
          <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
            <svg
              className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon points="50,0 100,0 50,100 0,100" />
            </svg>
            <div className="pt-1" />
            <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left">
                <h2 className="my-6 text-2xl tracking-tight font-extrabold text-gray-900 sm:text-3xl md:text-4xl">
                  Commerce
                </h2>
                <p>
                  At Way4franchise, we help you grow your franchise by providing
                  eCommerce solutions designed to boost your online sales and
                  expand your business. Whether you’re just starting or looking
                  to improve your current setup, our services make it easier for
                  you to manage your franchise’s online transactions and connect
                  with more customers. We focus on creating seamless and
                  user-friendly experiences for both you and your customers, so
                  your franchise can thrive in the digital marketplace.
                </p>
              </div>
            </main>
          </div>
        </div>
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <img
            className="h-56 w-full object-cover object-top sm:h-72 md:h-96 lg:w-full lg:h-full"
            src="https://c8.alamy.com/comp/H2PFPF/commerce-symbols-meaning-ecommerce-corporate-and-sell-H2PFPF.jpg"
            alt
          />
        </div>
      </div>

      <div className="w-7xl lg:mx-10 mx-2 mt-16 px-2 mb-8">
        <div className="">
          <p className="mb-6 lg:text-3xl sm:text-xl text-lg font-semibold">
            Our commerce services include
          </p>
        </div>
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
          <div className="border shadow-md rounded-md ">
            <div className="border-b bg-gray-200 py-2">
              <h5 className="text-lg font-semibold  px-4 capitalize">
                {" "}
                eCommerce Website Development
              </h5>
            </div>
            <p className=" text-gray-600 px-6 text-justify pt-3 pb-2">
              We build custom eCommerce websites that are:
            </p>
            <ul className="px-10 pb-3 list-disc ">
              <li className="py-1 text-justify float-left">
                <span className="text-base font-semibold">Easy to Use :- </span>{" "}
                Simple navigation and checkout process to keep customers coming
                back.
              </li>
              <li className="py-1 text-justify float-left">
                <span className="text-base font-semibold"> Secure :- </span> We
                ensure your site is safe for transactions, protecting customer
                data.
              </li>
              <li className="py-1 text-justify float-left">
                <span className="text-base font-semibold">
                  {" "}
                  Mobile-Friendly :-{" "}
                </span>{" "}
                Optimized for all devices, so customers can shop from anywhere.
              </li>
            </ul>
          </div>

          <div className="border shadow-md rounded-md ">
            <div className="border-b bg-gray-200 py-2">
              <h5 className="text-lg font-semibold  px-4 capitalize">
                {" "}
                Online Store Setup
              </h5>
            </div>
            <p className=" text-gray-600 px-6 text-justify pt-3 pb-2">
              We help you set up and manage an online store for your franchise,
              ensuring that:
            </p>
            <ul className="px-10 pb-3 list-disc ">
              <li className="py-1 text-justify float-left">
                <span className="text-base font-semibold">
                  Products are listed clearly :-{" "}
                </span>{" "}
                Products are listed clearly with descriptions and pricing.
              </li>
              <li className="py-1 text-justify float-left">
                <span className="text-base font-semibold">
                  {" "}
                  Payments are processed smoothly :-{" "}
                </span>{" "}
                Payments are processed smoothly with multiple options for
                customers.
              </li>
              <li className="py-1 text-justify float-left mb-3">
                <span className="text-base font-semibold">
                  Inventory is tracked and updated :-{" "}
                </span>{" "}
                Inventory is tracked and updated in real-time to prevent
                over-selling.
              </li>
            </ul>
          </div>

          <div className="border shadow-md rounded-md ">
            <div className="border-b bg-gray-200 py-2">
              <h5 className="text-lg font-semibold  px-4 capitalize">
                {" "}
                Payment Integration
              </h5>
            </div>
            <p className=" text-gray-600 px-6 text-justify pt-3 pb-2">
              We integrate secure payment gateways, allowing your customers to
              pay easily through:
            </p>
            <ul className="px-10 pb-3 list-disc ">
              <li className="py-1 text-justify float-left">
                <span className="text-base font-semibold">
                  Credit/Debit Cards :-{" "}
                </span>{" "}
              </li>
              <li className="py-1 text-justify float-left">
                <span className="text-base font-semibold">
                  Digital Wallets (like PayPal, Apple Pay, etc.) :-{" "}
                </span>{" "}
              </li>
              <li className="py-1 text-justify float-left mb-3">
                <span className="text-base font-semibold">
                  {" "}
                  Bank Transfers :-{" "}
                </span>{" "}
              </li>
            </ul>
          </div>

          <div className="border shadow-md rounded-md ">
            <div className="border-b bg-gray-200 py-2">
              <h5 className="text-lg font-semibold  px-4 capitalize">
                {" "}
                Order Management
              </h5>
            </div>
            <p className=" text-gray-600 px-6 text-justify pt-3 pb-2">
              Our system allows you to manage orders efficiently, including:
            </p>
            <ul className="px-10 pb-3 list-disc ">
              <li className="py-1 text-justify float-left">
                <span className="text-base font-semibold">
                  Order Tracking :-{" "}
                </span>{" "}
                Follow orders from purchase to delivery.
              </li>
              <li className="py-1 text-justify float-left">
                <span className="text-base font-semibold">
                  Shipping Integration :-{" "}
                </span>{" "}
                Link with popular carriers for smooth shipping options.
              </li>
              <li className="py-1 text-justify float-left mb-3">
                <span className="text-base font-semibold">
                  {" "}
                  Customer Notifications :-{" "}
                </span>{" "}
                Keep customers informed about their order status.
              </li>
            </ul>
          </div>

          <div className="border shadow-md rounded-md ">
            <div className="border-b bg-gray-200 py-2">
              <h5 className="text-lg font-semibold  px-4 capitalize">
                {" "}
                Analytics and Reporting
              </h5>
            </div>
            <p className=" text-gray-600 px-6 text-justify pt-3 pb-2">
              We provide detailed reports on your sales performance, customer
              behavior, and product trends, helping you make data-driven
              decisions for your franchise.
            </p>
          </div>

          <div className="border shadow-md rounded-md ">
            <div className="border-b bg-gray-200 py-2">
              <h5 className="text-lg font-semibold  px-4 capitalize">
                {" "}
                Multi-Franchise Support
              </h5>
            </div>
            <p className=" text-gray-600 px-6 text-justify pt-3 pb-2">
              If you have multiple franchise locations, we help you manage them
              all under one system, allowing easy control of products, orders,
              and reports across locations.
            </p>
          </div>
        </div>
      </div>

      <div className="relative isolate overflow-hidden bg-gray-900">
        <div className="px-6 py-16 sm:px-6  lg:px-8">
          <div className="mx-auto max-w-3xl text-center">
            <h2 className="text-xl font-bold mb-3 tracking-tight text-white sm:text-3xl">
              Why Choose Our Commerce Solutions?
            </h2>
            <p className="mx-auto  text-lg leading-8 text-gray-300 ">
              At Way4franchise, our goal is to make online sales simple and
              effective for your franchise. Whether you're selling products,
              services, or managing multiple locations, we have the right tools
              to help your business grow.
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Commerce;
