import { CalendarDaysIcon, HandRaisedIcon } from "@heroicons/react/24/outline";

// export default function Newsletter() {
//   return (
//     <div className="relative isolate overflow-hidden bg-gray-900 py-16 sm:py-24 lg:py-32 mt-16">
//       <div className="mx-auto max-w-7xl px-6 lg:px-8">
//         <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
//           <div className="max-w-xl lg:max-w-lg">
//             <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Subscribe to our newsletter.</h2>
//             <p className="mt-4 text-lg leading-8 text-gray-300">
//               Nostrud amet eu ullamco nisi aute in ad minim nostrud adipisicing velit quis. Duis tempor incididunt
//               dolore.
//             </p>
//             <div className="mt-6 flex max-w-md gap-x-4">
//               <label htmlFor="email-address" className="sr-only">
//                 Email address
//               </label>
//               <input
//                 id="email-address"
//                 name="email"
//                 type="email"
//                 required
//                 placeholder="Enter your email"
//                 autoComplete="email"
//                 className="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm: sm:leading-6"
//               />
//               <button
//                 type="submit"
//                 className="flex-none rounded-md bg-indigo-500 px-3.5 py-2.5  font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
//               >
//                 Subscribe
//               </button>
//             </div>
//           </div>
//           <dl className="grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2 lg:pt-2">
//             <div className="flex flex-col items-start">
//               <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
//                 <CalendarDaysIcon aria-hidden="true" className="h-6 w-6 text-white" />
//               </div>
//               <dt className="mt-4 font-semibold text-white">Weekly articles</dt>
//               <dd className="mt-2 leading-7 text-gray-400">
//                 Non laboris consequat cupidatat laborum magna. Eiusmod non irure cupidatat duis commodo amet.
//               </dd>
//             </div>
//             <div className="flex flex-col items-start">
//               <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
//                 <HandRaisedIcon aria-hidden="true" className="h-6 w-6 text-white" />
//               </div>
//               <dt className="mt-4 font-semibold text-white">No spam</dt>
//               <dd className="mt-2 leading-7 text-gray-400">
//                 Officia excepteur ullamco ut sint duis proident non adipisicing. Voluptate incididunt anim.
//               </dd>
//             </div>
//           </dl>
//         </div>
//       </div>
//       <div aria-hidden="true" className="absolute left-1/2 top-0 -z-10 -translate-x-1/2 blur-3xl xl:-top-6">
//         <div
//           style={{
//             clipPath:
//               'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
//           }}
//           className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
//         />
//       </div>
//     </div>
//   )
// }

export default function Newsletter() {
  return (
    <div className="relative isolate overflow-hidden bg-gray-900 py-16  mt-16">
      <img
        alt=""
        src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-y=.8&w=2830&h=1500&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply"
        className="absolute inset-0 -z-10 h-full w-full object-cover"
      />
      <div
        aria-hidden="true"
        className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10  sm:block sm:transform-gpu sm:blur-3xl"
      >
        <div
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
        />
      </div>
      <div
        aria-hidden="true"
        className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
      >
        <div
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
        />
      </div>
      <div className="max-w-full mx-10 px-2">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
          <div className="max-w-3xl ">
            <p className="mt-4 text-lg leading-8 text-gray-300">
            Stay informed about the latest trends and opportunities in franchising.
            </p>
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Subscribe to our newsletter.
            </h2>
            <div className="mt-6 flex max-w-md gap-x-4">
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                required
                placeholder="Enter your email"
                autoComplete="email"
                className="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm: sm:leading-6"
              />
              <button
                type="submit"
                className="flex-none rounded-md bg-red-600 px-3.5 py-2.5  font-semibold text-white shadow-sm hover:bg-red-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              >
                Subscribe
              </button>
            </div>
          </div>
          <dl className="grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-1 lg:pt-2 lg:text-right">
            <div className="max-w-3xl ">
              <p className="mt-4 text-lg leading-8 text-gray-300">
                {/* Nostrud amet eu */}
              </p>
              <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Follow Way 4 Franchise
              </h2>
              <div className="mt-6 flex gap-x-4 lg:justify-end">
                <div class="flex justify-center space-x-5">
                  <a
                    href="https://www.facebook.com/profile.php?id=61566285699882"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="https://img.icons8.com/fluent/30/000000/facebook-new.png" />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/105216713/admin/dashboard/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="https://img.icons8.com/fluent/30/000000/linkedin-2.png" />
                  </a>
                  <a
                    href="https://www.instagram.com/way4franchise/  "
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="https://img.icons8.com/fluent/30/000000/instagram-new.png" />
                  </a>
                  
                  <a
                    href="https://x.com/FranchiseW43083"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="https://img.icons8.com/fluent/30/000000/twitter.png" />
                  </a>
                </div>
              </div>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
}
