import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


  export const fetchBuyDetailUserId = createAsyncThunk(
    "staff/fetchBuyDetailUserId",
    async (userId, thunkAPI) => {
      try {
        const response = await fetch(`https://api.way4franchise.com/api/v1/ownFranchises/user/${userId}`);
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message);
        }
  
        const data = await response.json();
        return data;
      } catch (error) {
        // Handle error
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );

  export const fetchBuyDetailByfranchiseId = createAsyncThunk(
    "staff/fetchBuyDetailByfranchiseId",
    async (fanchiseId, thunkAPI) => {
      try {
        const response = await fetch(`https://api.way4franchise.com/api/v1/ownFranchises/franchise/${fanchiseId}`);
  
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message);
        }
  
        const data = await response.json();
        return data;
      } catch (error) {
        // Handle error
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );

  export const fetchBuyDetail = createAsyncThunk(
    "staff/fetchBuyDetail",
    async (_, thunkAPI) => {
      try {
        const response = await fetch(`https://api.way4franchise.com/api/v1/ownFranchises/list`);
  
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message);
        }
  
        const data = await response.json();
        return data;
      } catch (error) {
        // Handle error
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );
  const initialState = {
    fanchise_own_user:null,
    user_own_franchise:null,
    buy_Detail:null,
    loading: false,
    error: null,
    message: null,
  };
  
  const ownFranchiseSlice = createSlice({
    name: "ownFranchise",
    initialState,
    reducers: {
      clearErrors: (state) => {
        state.error = null;
      },
      clearMessage: (state) => {
        state.message = null;
      },
    },
    extraReducers: (builder) => {
      builder
      .addCase(fetchBuyDetailUserId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBuyDetailUserId.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.user_own_franchise = action.payload.user_own_franchise;
      })
      .addCase(fetchBuyDetailUserId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
      .addCase(fetchBuyDetailByfranchiseId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBuyDetailByfranchiseId.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.fanchise_own_user = action.payload.fanchise_own_user;
      })
      .addCase(fetchBuyDetailByfranchiseId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
      .addCase(fetchBuyDetail.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBuyDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.buy_Detail = action.payload.buy_Detail;
      })
      .addCase(fetchBuyDetail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
    },
});

export const { clearErrors, clearMessage } = ownFranchiseSlice.actions;

export default ownFranchiseSlice.reducer;