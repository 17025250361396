import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBuyDetail } from "../redux/ownFranchiseSlice";
import { PencilIcon, TrashIcon, EyeIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function AdminBuyFranchise() {
  const [searchQuery, setSearchQuery] = useState("");
  const [allBuyer, setAllBuyer] = useState("");
  const dispatch = useDispatch();
  const { buy_Detail, loading, error, message } = useSelector(
    (state) => state.ownFranchises
  );
  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedPeople, setSelectedPeople] = useState([]);

  useEffect(() => {
    dispatch(fetchBuyDetail());
  }, []);

  useEffect(() => {
    setAllBuyer(
      buy_Detail?.filter((item) =>
        item?.franchiseId?.franchise_name
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery]);

  return (
    <div className="bg-white border shadow-sm rounded-sm px-4">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto pt-6">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Detail
          </h1>
          <p className="  text-gray-700 text-[13px]">
            A list of all the companies in your account including their name,
            title, email and role.
          </p>
        </div>
        <div className="mt-6 sm:ml-16  sm:flex-none">
          <input
            type="search"
            onChange={(e) => setSearchQuery(e.target.value)}
            className="border px-2 py-1.5 text-[12px] rounded-md border-gray-400"
            placeholder="Search"
          />
        </div>
      </div>
      <div className="mt-6 flow-root border shadow-sm rounded-sm overflow-hidden h-[530px]">
      <div className="h-[480px] overflow-y-auto no-scrollbar pb-7">
          <div className="inline-block min-w-full align-middle">
            <div className="relative">
              <div className="overflow-x-auto rounded-lg shadow-md">
                <table className="min-w-full table-auto divide-y divide-gray-300 rounded-lg">
                  <thead className="text-gray-900">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 px-4 text-[13px] uppercase text-left font-semibold min-w-[10rem] md:min-w-[12rem]"
                      >
                        Franchise Name & Type
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3.5 text-[13px] uppercase text-left font-semibold min-w-[10rem] md:min-w-[12rem]"
                      >
                        Buyer Name & Email
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3.5 text-[13px] uppercase text-left font-semibold min-w-[10rem] md:min-w-[10rem]"
                      >
                        Phone
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3.5 text-[13px] uppercase text-left font-semibold min-w-[10rem] md:min-w-[10rem]"
                      >
                     CreatedAt
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {(searchQuery ? allBuyer : buy_Detail)?.map(
                      (buy, index) => (
                        <tr
                          key={index}
                          className={
                            selectedPeople.includes(buy)
                              ? "bg-gray-50"
                              : undefined
                          }
                        >
                          <td
                            className={`whitespace-nowrap py-4 px-4 text-[12px] font-medium text-left capitalize ${
                              selectedPeople.includes(buy)
                                ? "text-indigo-600"
                                : "text-gray-900"
                            }`}
                          >
                            <Link
                              to={`/admin/franchsieprofile/${buy?.franchiseId?._id}`}
                            >
                              <div className="block ">
                              <span className="block capitalize">{buy?.franchiseId?.franchise_name}</span>
                              <span className="block capitalize">{buy?.franchiseId?.looking_for}</span>
                              </div>
                            </Link>
                          </td>
                          <td className="whitespace-nowrap px-4 py-4 text-[12px] text-gray-700 text-left capitalize">
                            <Link to={`/admin/userprofile/${buy?.userId?._id}`}>
                              <div className="block">
                                <span className="block capitalize">{buy?.userId?.username}</span>
                                <span className="block ">{buy?.userId?.email}</span>
                              </div>
                            </Link>
                          </td>
                          <td className="whitespace-nowrap px-4 py-4 text-[12px] text-gray-700 text-left capitalize">
                            <div className="block">
                              <span>{buy?.phone}</span>

                            </div>
                          </td>
                          <td className="whitespace-nowrap px-4 py-4 text-[12px] text-gray-700 text-left capitalize">
                            <div className="block">
                            <span>
                                {new Date(buy?.createdAt).toLocaleDateString(
                                  "en-US"
                                )}
                              </span>
                             
                            </div>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
