import { useState ,useEffect} from "react";
import { useDispatch,useSelector } from "react-redux";
import { addCoupon ,getAllCopupon,clearErrors,clearMessage,updateCoupon } from "../redux/couponSlice";
import SuccessAlert from "../BaseFile/SuccessAlert";
import ErrorAlert from "../BaseFile/ErrorAlert";
import Spinner from "../BaseFile/Spinner";
import { FaRegEdit } from "react-icons/fa";
import { ImCancelCircle } from "react-icons/im";
import { ImCheckmark } from "react-icons/im";

const AdminCoupon = () => {
  const dispatch = useDispatch();
  const {coupon, loading , error ,message} = useSelector((state) => state.coupon);
  const [editId, setEditId] = useState(null);
  const [updatedStatus, setUpdatedStatus] = useState("");

  useEffect(() => {
    dispatch(getAllCopupon())

    if (error) {
      const errorInterval = setInterval(() => {
        dispatch(clearErrors());
      }, 3000);
      return () => clearInterval(errorInterval);
    }

    if (message) {
      const messageInterval = setInterval(() => {
        dispatch(clearMessage());
      }, 3000);
      return () => clearInterval(messageInterval);
    }
  }, [dispatch, message, error, clearErrors, clearMessage]);
  const [formData, setFormData] = useState({
    code: "",
    service: "",
    discount: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(addCoupon(formData));
  };
  const handleReset = () => {
    setFormData({
        code: "",
        service: "",
        discount: "",
    })
  }
  const handleSave = async () => {
    dispatch(updateCoupon({ id:editId, status: updatedStatus }));
  setEditId(null);

};
  const handleCancel = () => {
    setEditId(null); 
  };

  const handleEdit = (id) => {
    setEditId(id);
  };

  return (
    <>
     {message && <SuccessAlert message={message} />}
     {error && <ErrorAlert error={error} />}
     
    <div className="lg:container lg:mx-auto border shadow-sm rounded-sm bg-white py-4">
      <div className="">
      <h2 className="text-lg font-semibold text-[13px]  px-4 mb-4 pt-1">coupon Form</h2>
      </div>
      <form onSubmit={handleSubmit} className="bg-white px-4">
        <div className="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-x-3">
        <div className="mb-4">
          <label htmlFor="code" className="block text-gray-700 font-semibold  text-[13px] mb-1">
            Coupon Code
          </label>
          <input
            type="text"
            id="code"
            name="code"
            value={formData.code}
            onChange={handleChange}
            className="w-full p-2 border rounded text-[13px] "
            placeholder="Enter code"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="service" className="block text-gray-700 font-semibold text-[13px] mb-1">
            Apply on Service
          </label>
          <select
            id="service"
            name="service"
            value={formData.service}
            onChange={handleChange}
            className="w-full p-2 border rounded text-[13px]"
            required
          >
            <option value="">-- Select a Service --</option>
            <option value="ads">Ads Service</option>
            <option value="software">Software Service</option>
            <option value="graphic">Graphic Service</option>
            <option value="it_other">Other IT Service</option>
            <option value="marketing">Marketing Service</option>
        </select>
        </div>
        <div className="mb-4">
          <label htmlFor="discount" className="block text-gray-700 font-semibold  text-[13px] mb-1">
          Discount
          </label>
          <input
            type="number"
            id="discount"
            name="discount"
            value={formData.discount}
            onChange={handleChange}
            className="w-full p-2 border rounded text-[13px]"
            placeholder="Enter discount"
            required
          />
        </div>
        </div>
        <div className="flex justify-end gap-3">
        <button
          type="submit"
          onClick={handleReset}
          className="bg-red-500 text-white py-2 px-7 font-semibold rounded text-sm hover:bg-red-600"
        >
          Reset
        </button>
        <button
          type="submit"
          className="bg-blue-500 text-white py-2 px-7 text-sm rounded font-semibold hover:bg-blue-600"
        >
        {loading ? <Spinner/> : 'Submit'}
        </button>
        </div>
      </form>
    </div>

    <div className="overflow-x-auto p-4">
      <table className="min-w-full bg-white border border-gray-200 text-sm">
        <thead className="bg-gray-100 font-light text-[12px]">
          <tr>
            <th className="px-4 py-2 border-b text-left">
              Coupon Code
            </th>
            <th className="px-4 py-2 border-b text-left">
            discount
            </th>
            <th className="px-4 py-2 border-b text-left">
            service
            </th>
            <th className="px-4 py-2 border-b text-left">usage</th>
            <th className="px-4 py-2 border-b text-left"> status</th>
            <th className="px-4 py-2 border-b text-left">cretaed at</th>
            <th className="px-4 py-2 border-b text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {coupon?.map((service, index) => (
            <tr
              key={index}
              className="odd:bg-white text-[12px] even:bg-gray-50"
            >
              <td className="px-4 py-2 border-b">{service?.code}</td>
              <td className="px-4 py-2 border-b">{service?.discount}</td>
              <td className="px-4 py-2 border-b">{service?.service}</td>
              <td className="px-4 py-2 border-b">{service?.usage}</td>

              <td className="px-4 py-2 border-b">{editId ? (
                <select
                id="service"
                name="service"
                value={updatedStatus}
                onChange={(e)=>setUpdatedStatus(e.target.value)}
                className="w-full p-2 border rounded text-[13px]"
                required
              >
                <option value="">-- Select a Status --</option>
                <option value="Expire">Expire</option>
                <option value="Active">Active</option>
            </select>
              ):(service?.status) }


              </td>
              <td className="px-4 py-2 border-b">{service?.createdAt}</td>
              <td className="px-4 py-2 border-b">
                <div className="flex gap-2">
                  {editId === service?._id ? (
                    <>
                      <button
                        onClick={() => handleSave()}
                        className="px-2 py-1"
                      >
                     <ImCheckmark className="w-5 h-5 text-green-500 hover:text-green-600" />
                      </button>
                      <button
                        onClick={handleCancel}
                        className="px-2 py-1"
                      >
                      <ImCancelCircle className="w-5 h-5 text-red-500 hover:text-red-600"/>
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        onClick={() => handleEdit(service?._id)}
                        className="px-2 py-1 "
                      >
                       <FaRegEdit className="w-5 h-5 text-indigo-500 hover:text-indigo-600"/>
                      </button>
                    
                    </>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </>
  );
};

export default AdminCoupon;
