import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import {  useParams } from "react-router-dom";
import { State, City } from "country-state-city";
import { VscUnverified } from "react-icons/vsc";
import { MdVerified } from "react-icons/md";
import SuccessAlert from "../BaseFile/SuccessAlert";
import ErrorAlert from "../BaseFile/ErrorAlert";
import { getUser } from "../redux/userSlice";


const InvestorClientProfile = () => {
  const {id}=useParams()
  const dispatch = useDispatch();
  const { singleuser } = useSelector((state) => state.allusers);
  const [states, setStates] = useState([]);

  useEffect(() => {
    if (id) {
      dispatch(getUser(id));
    }
  }, [id]);


  useEffect(() => {
    const indianStates = State.getStatesOfCountry("IN");
    setStates(indianStates);
  }, []);


  return (
    <>
 
      <main>
        <div className="w-full  lg:mb-0 mb-20">
          <div className="">
            <div className=" flex lg:w-full">
              <div className="flex h-full w-full bg-white shadow-sm">
                <div className="divide-y divide-gray-200 w-full">
                  <div className="pb-6">
                    <div className="h-24 bg-gray-700 sm:h-20 lg:h-28" />
                    <div className="-mt-12 flow-root px-4 sm:-mt-16 sm:flex sm:items-end sm:px-6 lg:-mt-16">
                      <div>
                        <div className="-m-1 flex">
                          <div className="inline-flex overflow-hidden rounded-lg border-4 border-white">
                            <img
                              alt=""
                              src={`/banner.jpg`}
                              className="h-24 w-24 flex-shrink-0 sm:h-40 sm:w-40 lg:h-48 lg:w-48"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mt-6 sm:ml-6 sm:flex-1 w-full">
                        <div>
                          <div className="flex items-center">
                            <h3 className="text-xl font-bold text-gray-900 sm:text-2xl capitalize ">
                              {singleuser?.username}
                            </h3>

                            {singleuser?.isVerified == "yes" ? (
                              <MdVerified className="text-green-500 text-2xl" />
                            ) : (
                              <VscUnverified className="text-red-500 text-2xl" />
                            )}
                          </div>
                          <p className="text-sm text-gray-500">
                            {" "}
                            {singleuser?.email}
                          </p>
                        </div>
                      </div>
                      <div className=" overflow-hidden border-4 border-white lg:mt-0 sm:mt-0 mt-6 ">
                        <div className="lg:w-48 lg:h-48 sm:w-48 sm:h-48 w-28 h-28 relative">
                          <div className="text-[12px] absolute -top-3 capitalize left-0 bg-black px-4 py-1 text-white mt-3 mr-3 font-medium hover:bg-black  transition duration-500 ease-in-out">
                            ads
                          </div>
                          <img src="/cyberminiads.png" />
                          {/* <div className=" mt-2">
                              <p className="text-sm text-gray-500"> kkkkk</p>
                            </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                 
                 
                    <div className="px-4 py-5 sm:px-0 sm:py-0">
                      <dl className="space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                        <div className="flex justify-between items-center">
                          <div>
                            <h2
                              id="franchise"
                              className="text-base uppercase font-semibold leading-6 text-gray-900 border-b p-5"
                            >
                              PROFILE DETAILS
                            </h2>
                          </div>
                         
                        </div>
                      
                          <div>
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                PROFILE
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                                <p>
                                  Enim feugiat ut ipsum, neque ut. Tristique mi
                                  id elementum praesent. Gravida in tempus
                                  feugiat netus enim aliquet a, quam
                                  scelerisque. Dictumst in convallis nec in
                                  bibendum aenean arcu.
                                </p>
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Name
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                                {singleuser?.username}
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Email
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                                <time dateTime="1982-06-23">
                                  {singleuser?.email}
                                </time>
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Phone No.
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                                <time dateTime="1982-06-23">
                                  {singleuser?.phone}
                                </time>
                              </dd>
                            </div>
                          </div>
                      </dl>
                    </div>
               
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default InvestorClientProfile;
