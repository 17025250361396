import { getAllFranchise } from "../redux/franchiseSlice";
import { updateLeadsDetail, getUserLeadById } from "../redux/leadUpdateSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getUserPlanByid } from "../redux/PlanSlice";
import ErrorAlert from "../BaseFile/ErrorAlert";
import { FaLock } from "react-icons/fa";
import { FaLockOpen } from "react-icons/fa";

export default function UserLeads({ singleuser }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const { auth } = useSelector((state) => state.auth);
  const { singleuserplan } = useSelector((state) => state.plans);
  const { singleuserlead } = useSelector((state) => state.leads);
  const { allfranchise, loading } = useSelector((state) => state.franchise);
  const filteredFranchises = allfranchise?.filter((franchise) =>
    singleuser?.interested_segment?.includes(franchise.industry)
  );

  function viewLeads(franchise_id) {
    const userId = auth?._id;
    const canUseLeads =
      (singleuserplan?.length === 0 && singleuserlead?.totalLeads > 0) ||
      singleuserlead?.length == 0 ||
      singleuserlead?.franchise_ids?.includes(franchise_id);

    if (canUseLeads) {
      if (userId && franchise_id) {
        dispatch(updateLeadsDetail({ userId, franchise_id })).then(() =>
          navigate(`/user/franchise/profile/${franchise_id}`)
        );
      }
    } else {
      setError("Please Buy Leads");
    }
  }

  useEffect(() => {
    dispatch(getAllFranchise());
    if (auth?._id) {
      const userId = auth?._id;
      const id = auth?._id;
      dispatch(getUserLeadById(userId));
      dispatch(getUserPlanByid(id));
    }
    if (error) {
      setInterval(() => {
        setError(null);
      }, [3000]);
    }
  }, [auth?._id, error]);

  return (
    <>
      {error && <ErrorAlert error={error} />}

      <div>
        <dl className="m-4 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4">
          {/* Total Leads */}
          <div className="flex flex-col bg-gray-700 p-8">
            <dt className="text-sm font-semibold leading-6 text-gray-300">
              Total Leads
            </dt>
            <dd className="order-first text-2xl font-semibold tracking-tight text-white">
              {singleuserlead?.totalLeads || "NA"}
            </dd>

            {/* Full Progress Bar for Total Leads */}
            <div className="relative w-full h-3 mt-4 bg-gray-300 rounded-full">
              <div className="absolute w-full h-full bg-indigo-600 rounded-full"></div>
            </div>
          </div>

          {/* Used Leads */}
          <div className="flex flex-col bg-gray-700 p-8">
            <dt className="text-sm font-semibold leading-6 text-gray-300">
              Used Leads
            </dt>
            <dd className="order-first text-2xl font-semibold tracking-tight text-white">
              {singleuserlead?.usedLeads || "NA"}
            </dd>

            {/* Progress Bar for Used Leads */}
            {singleuserlead?.totalLeads &&
              singleuserlead?.usedLeads !== undefined && (
                <div className="relative w-full h-3 mt-4 bg-gray-300 rounded-full">
                  <div
                    className="absolute h-full bg-yellow-500 rounded-full"
                    style={{
                      width: `${
                        (singleuserlead?.usedLeads /
                          singleuserlead?.totalLeads) *
                        100
                      }%`,
                    }}
                  ></div>
                </div>
              )}
          </div>

          {/* Remaining Leads */}
          <div className="flex flex-col bg-gray-700 p-8">
            <dt className="text-sm font-semibold leading-6 text-gray-300">
              Remaining Leads
            </dt>
            <dd className="order-first text-2xl font-semibold tracking-tight text-white">
              {singleuserlead?.totalLeads - singleuserlead?.usedLeads || "NA"}
            </dd>

            {/* Progress Bar for Remaining Leads */}
            {singleuserlead?.totalLeads &&
              singleuserlead?.usedLeads !== undefined && (
                <div className="relative w-full h-3 mt-4 bg-gray-300 rounded-full">
                  <div
                    className="absolute h-full bg-green-600 rounded-full"
                    style={{
                      width: `${
                        ((singleuserlead?.totalLeads -
                          singleuserlead?.usedLeads) /
                          singleuserlead?.totalLeads) *
                        100
                      }%`,
                    }}
                  ></div>
                </div>
              )}
          </div>

          {/* Active Plan */}
          <div className="flex flex-col bg-gray-700 p-8">
            <dt className="text-sm font-semibold leading-6 text-gray-300">
              Active Plan
            </dt>
            <dd className="order-first text-2xl font-semibold tracking-tight text-white">
              {singleuserplan?.planDetails?.name || "NA"}
            </dd>
          </div>
        </dl>
      </div>

      <div className="border-gray-300 mt-2">
        <div className="sm:flex sm:items-center p-4">
          <div className="sm:flex-auto">
            <h1 className=" font-semibold text-gray-900">Leads Detail</h1>
            <p className="mt-1  text-gray-700">A list of all the Leads .</p>
          </div>
        </div>
        <div className="mt-8 flow-root px-3">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full align-middle">
              <div className="relative">
                <div className="overflow-x-auto  shadow-md">
                  <table className="min-w-full text-sm table-auto divide-y divide-gray-300 rounded-lg">
                    <thead className="bg-gray-900 text-white ">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left font-semibold text-gray-100 text-[13px] uppercase"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left font-semibold text-gray-100 text-[13px] uppercase"
                        >
                          Floor_Area
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left font-semibold text-gray-100 text-[13px] uppercase"
                        >
                          Investment Range Tier 1 Cities
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left font-semibold text-gray-100 text-[13px] uppercase"
                        >
                          Investment Range Tier 2 Cities
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left font-semibold text-gray-100 text-[13px] uppercase"
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white capitalize">
                      {filteredFranchises
                        ?.filter((item) => item.isDeleted == "no")
                        ?.map((item, index) => (
                          <tr
                            key={index}
                            onClick={() => viewLeads(item?._id)}
                            className="hover:bg-gray-100 transition-colors cursor-pointer duration-150"
                          >
                            <td className="whitespace-nowrap px-6 py-4 text-gray-800">
                              {item?.franchise_name}
                            </td>
                            <td className="whitespace-nowrap px-6 py-4 text-gray-800">
                              {item?.floor_Area} sq.ft
                            </td>

                            <td className="whitespace-nowrap px-6 py-4 text-gray-800">
                              {item?.investment_range_tier_1}
                            </td>
                            <td className="whitespace-nowrap px-6 py-4 text-gray-800">
                              {item?.investment_range_tier_2}
                            </td>
                            <td className="whitespace-nowrap px-6 py-4 text-gray-800">
                              {singleuserlead?.franchise_ids?.includes(
                                item?._id
                              ) ? (
                                <FaLockOpen className=" text-green-600 transform transition duration-300 ease-in-out scale-100 hover:scale-125" />
                              ) : (
                                <FaLock className=" text-red-600 transform transition duration-300 ease-in-out scale-100 hover:scale-125" />
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
