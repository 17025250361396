import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllFranchise } from "../redux/franchiseSlice";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { RxCross2 } from "react-icons/rx";
import { FaRegEdit } from "react-icons/fa";

import {
  setContainer,
  getContainer,
  clearErrors,
  clearMessage,
} from "../redux/homeManageSlice";
import SuccessAlert from "../BaseFile/SuccessAlert";
import ErrorAlert from "../BaseFile/ErrorAlert";
import Loader from "../BaseFile/Loader";

export default function ManageHome() {
  const dispatch = useDispatch();
  const { allfranchise, loading } = useSelector((state) => state.franchise);
  const { containers, error, message } = useSelector(
    (state) => state.homemanage
  );

  const [searchQuery, setSearchQuery] = useState("all");
  const [editable, setEditable] = useState(false);
  const [cont, setCont] = useState(false);
  const [pos, setPos] = useState(false);
  const [selectedFranchise, setSelectedFranchise] = useState(null);
  const [selectedTime, setSelectedTime] = useState("1");

  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  // Filter the franchises based on the search term
  const filteredFranchises = allfranchise?.filter((item) =>
    item.franchise_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const containerAssignments = [
    { container: 1, position: 1 },
    { container: 1, position: 2 },
    { container: 1, position: 3 },
    { container: 1, position: 4 },
    { container: 2, position: 1 },
    { container: 2, position: 2 },
    { container: 2, position: 3 },
    { container: 2, position: 4 },
    { container: 3, position: 1 },
    { container: 3, position: 2 },
    { container: 3, position: 3 },
    { container: 3, position: 4 },
    { container: 3, position: 5 },
    { container: 3, position: 6 },
    { container: 3, position: 7 },
    { container: 3, position: 8 },
    { container: 3, position: 9 },
    { container: 3, position: 10 },
    { container: 3, position: 11 },
    { container: 3, position: 12 },
    { container: 3, position: 13 },
    { container: 3, position: 14 },
    { container: 4, position: 1 },
    { container: 4, position: 2 },
    { container: 4, position: 3 },
    { container: 5, position: 1 },
    { container: 5, position: 2 },
    { container: 5, position: 3 },
    { container: 5, position: 4 },
    { container: 6, position: 1 },
    { container: 6, position: 2 },
    { container: 6, position: 3 },
    { container: 6, position: 4 },
    { container: 6, position: 5 },
    { container: 6, position: 6 },
  ];

  useEffect(() => {
    dispatch(getAllFranchise());
    dispatch(getContainer());
    if (error) {
      const errorInterval = setInterval(() => {
        dispatch(clearErrors());
      }, 3000);
      return () => clearInterval(errorInterval);
    }

    if (message) {
      const messageInterval = setInterval(() => {
        dispatch(clearMessage());
      }, 3000);
      return () => clearInterval(messageInterval);
    }
  }, [dispatch, message, error, clearErrors, clearMessage]);

  function handleEdit(c, p) {
    setCont(c);
    setPos(p);
    setEditable(true);
  }

  function handleFranchiseChange(container, position, value) {
    setSelectedFranchise(value);
  }

  function handleTimeChange(container, position, value) {
    setSelectedTime(value);
  }

  function handlesave() {
    const containerAssignments = {
      container: cont,
      position: pos,
      franchise_id: selectedFranchise,
      time_period: selectedTime,
    };
    dispatch(setContainer(containerAssignments));
    setEditable(false);
  }
  function handleCancle() {
    setSelectedFranchise(null);
    setCont(null);
    setPos(null);
    setEditable(false);
  }
  if (loading)
    return <p className="text-center mt-8 text-gray-600">Loading...</p>;

  return (
    <>
      {message && <SuccessAlert message={message} />}
      {error && <ErrorAlert error={error} />}
      {loading ? (
        <Loader />
      ) : (
        <div className="bg-white shadow-sm border px-4 rounded-sm pb-8">
          <div className="sm:flex sm:items-center pt-4">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold text-gray-800">
                {" "}
                Franchise
              </h1>
              <p className="mt-2 text-[12px] text-gray-700">
                A list of all the companies in your account including their
                name, title, email, and role.
              </p>
            </div>
            <div className="mt-4 sm:ml-16 sm:mt-4 sm:flex-none">
              <select
                type="search"
                onChange={(e) => setSearchQuery(e.target.value)}
                className="border px-2 py-1.5 text-[12px] rounded-md border-gray-400"
                placeholder="Search"
              >
                <option value="all">Selected Container</option>
                <option value={1}>Container 1</option>
                <option value={2}>Container 2</option>
                <option value={3}>Container 3</option>
                <option value={4}>Container 4</option>
                <option value={5}>Container 5</option>
                <option value={6}>Container 6</option>
              </select>
            </div>
          </div>
          <div className="mt-6 flow-root border shadow-sm rounded-sm overflow-hidden h-[530px]">
          <div className="h-[480px] overflow-y-auto no-scrollbar">
              <div className="inline-block min-w-full align-middle">
                <div className="relative">
                  <div className="overflow-x-auto rounded-lg shadow-md">
                    <table className="min-w-full border table-auto divide-y divide-gray-300 rounded-lg">
                      <thead className="text-gray-900 border shadow-sm">
                        <tr>
                          <th className="py-3.5 px-2 text-left uppercase text-[12px] font-semibold border-b ">
                            Franchise Name
                          </th>
                          <th className="px-3 py-3.5  text-center text-[12px] uppercase font-semibold border-b ">
                            Container
                          </th>
                          <th className="py-3.5 px-2  text-center uppercase text-[12px] font-semibold border-b ">
                            Position
                          </th>
                          <th className="py-3.5 text-center px-3 uppercase  text-[12px] font-medium border-b ">
                            Time Period
                          </th>
                          <th className="px-3 py-3.5  text-center text-[12px] uppercase font-semibold border-b ">
                            Created_At
                          </th>
                          <th className="py-3.5 text-center px-3 uppercase  text-[12px] font-medium border-b ">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white ">
                        {(searchQuery !== "all"
                          ? containerAssignments?.filter(
                              (item) => item.container == searchQuery
                            )
                          : containerAssignments
                        )?.map((company, index) => (
                          <tr
                            key={index}
                            className="hover:bg-gray-100 transition-colors duration-150"
                          >
                            <td className="whitespace-nowrap capitalize py-4 px-2 text-[12px] text-gray-900">
                              <div className="flex items-center">
                                <div className="h-11 w-11 flex-shrink-0 border rounded-full">
                                  <img
                                    alt="company-logo"
                                    src={`/uploads/InvestorData/${
                                      // Filter the containers based on container and position
                                      (() => {
                                        const filteredContainers =
                                          containers?.filter(
                                            (item) =>
                                              item.container ===
                                                company.container &&
                                              item.position === company.position
                                          );

                                        // If there's a match, return the franchise name, otherwise return a default message
                                        return filteredContainers?.length > 0
                                          ? filteredContainers[0]
                                              .franchiseDetails?.companyLogo ||
                                              "#"
                                          : "#";
                                      })()
                                    }`}
                                    className="h-11 w-11 rounded-full"
                                  />
                                </div>
                                {/* <div className="ml-4">
                                  {editable &&
                                  company.container == cont &&
                                  company.position == pos ? (
                                    <select
                                      type="search"
                                      value={selectedFranchise || ""}
                                      onChange={(e) =>
                                        handleFranchiseChange(
                                          company.container,
                                          company.position,
                                          e.target.value
                                        )
                                      }
                                      className="border px-2 py-1.5 text-[12px] rounded-md border-gray-400"
                                    >
                                    
                                      <option value="all">
                                        Select Franchise
                                      </option>
                                      {allfranchise?.map((item, index) => (
                                        <option key={index} value={item?._id}>
                                          {item?.franchise_name}
                                        </option>
                                      ))}
                                    </select>
                                  ) : (
                                    <span className="block capitalize">
                                      {
                                        // Filter the containers based on container and position
                                        (() => {
                                          const filteredContainers =
                                            containers?.filter(
                                              (item) =>
                                                item.container ===
                                                  company.container &&
                                                item.position ===
                                                  company.position
                                            );

                                          // If there's a match, return the franchise name, otherwise return a default message
                                          return filteredContainers?.length > 0
                                            ? filteredContainers[0]
                                                .franchiseDetails
                                                ?.franchise_name ||
                                                "No Franchise Name"
                                            : "No Franchise Selected";
                                        })()
                                      }
                                    </span>
                                  )}
                                </div> */}

                                <div className="ml-4 w-full relative">
                                  {editable &&
                                  company.container === cont &&
                                  company.position === pos ? (
                                    <>
                                      <div
                                        className="border px-2 py-1.5 text-[12px] rounded-md border-gray-400 cursor-pointer"
                                        onClick={() => setIsOpen(!isOpen)}
                                      >
                                        {selectedFranchise
                                          ? allfranchise?.find(
                                              (item) =>
                                                item._id == selectedFranchise
                                            )?.franchise_name
                                          : "Select Franchise"}
                                      </div>

                                      {isOpen && (
                                        <div className="absolute z-10 w-full mt-1 border rounded-md bg-white shadow-lg">
                                          <input
                                            type="search"
                                            placeholder="Search"
                                            className="border px-2 py-1.5 text-[12px] rounded-md border-gray-400 mb-2 w-full"
                                            value={searchTerm}
                                            onChange={(e) =>
                                              setSearchTerm(e.target.value)
                                            }
                                          />
                                          <div className="max-h-60 overflow-y-auto">
                                            {filteredFranchises.length > 0 ? (
                                              filteredFranchises.map(
                                                (item, index) => (
                                                  <div
                                                    key={index}
                                                    onClick={() => {
                                                      setSelectedFranchise(
                                                        item._id
                                                      );
                                                      handleFranchiseChange(
                                                        company.container,
                                                        company.position,
                                                        item._id
                                                      );
                                                      setIsOpen(false); // Close dropdown after selection
                                                    }}
                                                    className="px-2 py-1 hover:bg-gray-200 cursor-pointer"
                                                  >
                                                    {item.franchise_name}
                                                  </div>
                                                )
                                              )
                                            ) : (
                                              <div className="px-2 py-1 text-gray-500">
                                                No franchises found
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  ) : (
                                    <span className="block capitalize">
                                      {
                                        // Filter the containers based on container and position
                                        (() => {
                                          const filteredContainers =
                                            containers?.filter(
                                              (item) =>
                                                item.container ===
                                                  company.container &&
                                                item.position ===
                                                  company.position
                                            );

                                          // If there's a match, return the franchise name; otherwise, return a default message
                                          return filteredContainers?.length > 0
                                            ? filteredContainers[0]
                                                .franchiseDetails
                                                ?.franchise_name ||
                                                "No Franchise Name"
                                            : "No Franchise Selected";
                                        })()
                                      }
                                    </span>
                                  )}
                                </div>
                              </div>
                            </td>
                            <td className="whitespace-nowrap py-4 px-2 text-center text-[12px] text-gray-900 capitalize">
                              {company.container}
                            </td>
                            <td className="whitespace-nowrap py-4 px-2 text-[12px] text-gray-900  text-center capitalize">
                              {company.position}
                            </td>
                            <td className="whitespace-nowrap py-4 px-2 text-[12px] text-gray-900 text-center capitalize">
                              {editable &&
                              company.container == cont &&
                              company.position == pos ? (
                                <select
                                  type="search"
                                  value={selectedTime || ""}
                                  onChange={(e) =>
                                    handleTimeChange(
                                      company.container,
                                      company.position,
                                      e.target.value
                                    )
                                  }
                                  className="border px-2 py-1.5 text-[12px] rounded-md border-gray-400"
                                >
                                  <option value="">Select Time Period</option>
                                  <option value="1">Monthly</option>
                                  <option value="3">3 Month</option>
                                  <option value="6">6 Month</option>
                                  <option value="9">9 Month</option>
                                  <option value="12">Yearly</option>
                                </select>
                              ) : (
                                <span className="block capitalize">
                                  {
                                    // Filter the containers based on container and position
                                    (() => {
                                      const filteredContainers =
                                        containers?.filter(
                                          (item) =>
                                            item.container ===
                                              company.container &&
                                            item.position === company.position
                                        );

                                      // If there's a match, return the franchise name, otherwise return a default message
                                      return filteredContainers?.length > 0
                                        ? filteredContainers[0]?.time_period ||
                                            "No Franchise Name"
                                        : "No Time Period Selected";
                                    })()
                                  }
                                </span>
                              )}
                            </td>
                            <td className="whitespace-nowrap  text-center py-4 px-2 text-[12px] text-gray-900 capitalize">
                              <span className="block capitalize">
                                {
                                  // Filter the containers based on container and position
                                  (() => {
                                    const filteredContainers =
                                      containers?.filter(
                                        (item) =>
                                          item.container ===
                                            company.container &&
                                          item.position === company.position
                                      );

                                    // If there's a match, return the franchise name, otherwise return a default message
                                    return filteredContainers?.length > 0
                                      ? filteredContainers[0].created_At ||
                                          "No Franchise Name"
                                      : "No Date yet";
                                  })()
                                }
                              </span>
                            </td>
                            <td className="whitespace-nowrap py-4 px-2 text-[12px] text-gray-900 text-center">
                              <div className="flex justify-center items-center">
                                {editable &&
                                company.container == cont &&
                                company.position == pos ? (
                                  <button
                                    className="text-blue-600 hover:text-blue-700 px-2 py-1 rounded-md"
                                    onClick={() => {
                                      // Add your save logic here
                                      handlesave();
                                    }}
                                  >
                                    Save
                                  </button>
                                ) : (
                                  <button
                                    className="text-blue-600 hover:text-blue-700 px-2 py-1 rounded-md"
                                    onClick={() =>
                                      handleEdit(
                                        company.container,
                                        company.position
                                      )
                                    }
                                  >
                                    <FaRegEdit className="h-5 w-5" />
                                  </button>
                                )}
                                {editable &&
                                  company.container == cont &&
                                  company.position == pos && (
                                    <button
                                      className="text-red-600 hover:text-red-700 px-2 py-1 rounded-md"
                                      onClick={() => handleCancle()}
                                    >
                                      <RxCross2 className="h-5 w-5" />
                                    </button>
                                  )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
