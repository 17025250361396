import React from "react";
import Header from "../Comman/Header";
import Footer from "../Comman/Footer";
import {
  ArrowPathIcon,
  CloudArrowUpIcon,
  Cog6ToothIcon,
  FingerPrintIcon,
  LockClosedIcon,
  ServerIcon,
} from "@heroicons/react/20/solid";

const features = [
  {
    name: "Reach a Targeted Audience",
    description:
      "Our platform attracts individuals who are actively searching for franchise opportunities, ensuring your ads are seen by people already interested in franchising.",
    icon: CloudArrowUpIcon, // You can replace this with a more relevant icon if desired
  },
  {
    name: "Boost Your Brand Visibility",
    description:
      "Advertising with Way4franchise puts your business in front of thousands of potential franchisees, increasing awareness and recognition across our website and marketing channels.",
    icon: LockClosedIcon,
  },
  {
    name: "Drive Quality Leads",
    description:
      "Our platform connects you with serious franchise prospects through targeted ad placements, helping you grow your franchise network with genuine inquiries.",
    icon: ArrowPathIcon,
  },
  {
    name: "Cost-Effective Marketing",
    description:
      "We offer advertising solutions for businesses of all sizes, providing cost-effective options to help you achieve your goals without breaking the bank.",
    icon: FingerPrintIcon,
  },
  {
    name: "Track Performance",
    description:
      "Monitor your ads’ performance with our analytics tools. Track views, clicks, and inquiries to make data-driven decisions and optimize your campaign.",
    icon: Cog6ToothIcon,
  },
  {
    name: "Enhance Credibility",
    description:
      "Advertising with Way4franchise adds credibility to your brand by associating with a trusted platform that connects users with reputable franchises.",
    icon: ServerIcon,
  },
];

export const Advertise = () => {
  return (
    <>
      <Header />

      <section className="bg-gray-100">
        <div className=" max-w-full lg:mx-10 mx-2 pt-16 px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-8">
          <div className="mt-12 md:mt-0">
              <img
                src="https://img.freepik.com/premium-photo/icon-digital-marketing-internet-advertising-sales-increase-business-technology_186616-886.jpg"
                alt="About Us Image"
                className="object-cover rounded-lg shadow-md"
              />
            </div>
            <div className="max-w-lg mx-auto">
              <h2 className="text-xl font-semibold text-gray-900 sm:text-3xl">
              Advertise with Us
              </h2>
              <p className="mt-4 text-gray-600 text-lg text-justify">
              At Way4franchise, we offer a powerful platform to promote your
              business to a large audience of franchise seekers and
              entrepreneurs. By advertising with us, you’ll be able to connect
              with potential franchisees, increase brand visibility, and drive
              targeted leads to your franchise opportunities.
              </p>
              
            </div>
            
          </div>
        </div>
      </section>

      <div className="bg-gray-100 py-20">
        <div className="lg:mx-10 mx-2 max-w-full px-6 ">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-base font-semibold leading-7 text-indigo-400">
              Benefits
            </h2>
            <p className="mt-1 text-xl font-semibold tracking-tight text-gray-800 sm:text-3xl">
              Benefits of Advertising with Us
            </p>
            {/* <p className="mt-6 text-lg leading-8 text-gray-800">
            
          </p> */}
          </div>
          <dl className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-8 text-base leading-7 text-gray-800 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-9">
                <dt className="inline font-semibold text-gray-800">
                  <feature.icon
                    aria-hidden="true"
                    className="absolute left-1 top-1 h-5 w-5 text-indigo-500"
                  />
                  {feature.name}
                </dt>{" "}
                <dd className="inline">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>

      <Footer />
    </>
  );
};
