import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { getFranchiseByid } from "../redux/franchiseSlice";
import { VscUnverified } from "react-icons/vsc";
import { MdVerified } from "react-icons/md";


const tabs = [
  { name: "PROFILE", href: "#", current: true },
  { name: "FRANCHISE", href: "#", current: false },
  { name: "BUSINESS", href: "#", current: false },
  { name: "COMPANY", href: "#", current: false },
  { name: "PROPERTY", href: "#", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const UserOwnedFranchiseProfile = () => {
  const {_id}=useParams()
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState("franchise");
  const { franchisee, loading } = useSelector((state) => state.franchise);
  const { auth } = useSelector((state) => state.auth);
  const [currentTab, setCurrentTab] = useState('PROFILE');
  useEffect(() => {
    if (_id) {
      dispatch(getFranchiseByid({ _id }));
    }
  }, [_id]);

  return (
    <>
      <main>
        <div className="w-full  lg:mb-0 mb-20">
          <div className="">
            <div className=" flex lg:w-full">
              <div className="flex h-full w-full bg-white shadow-sm">
                <div className="divide-y divide-gray-200 w-full">
                  <div className="pb-6">
                    <div className="h-24 bg-gray-700 sm:h-20 lg:h-28" />
                    <div className="-mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6 lg:-mt-16">
                      <div>
                        <div className="-m-1 flex">
                          <div className="inline-flex overflow-hidden rounded-lg border-4 border-white">
                            <img
                              alt=""
                              src={`/uploads/InvestorData/${franchisee?.companyLogo}`}
                              className="h-24 w-24 flex-shrink-0 sm:h-40 sm:w-40 lg:h-48 lg:w-48"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mt-6 sm:ml-6 sm:flex-1 w-full">
                        <div>
                          <div className="flex items-center">
                            <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">
                              {franchisee?.franchise_name}
                            </h3>

                            {franchisee?.isVerified == "yes" ? (
                              <MdVerified className="text-green-500 text-2xl" />
                            ) : (
                              <VscUnverified className="text-red-500 text-2xl" />
                            )}
                          </div>
                          <p className="text-sm text-gray-500">
                            {" "}
                            {franchisee?.ownerEmail}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-3 lg:px-0 py-3 lg:py-0 sm:px-0">
                    <div className="sm:hidden">
                      <label htmlFor="question-tabs" className="sr-only">
                        Select a tab
                      </label>
                      <select
                        id="question-tabs"
                        defaultValue={tabs.find((tab) => tab.current).name}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900  shadow-sm text-sm font-semibold px-1 ring-1 ring-inset "
                      >
                        {tabs.map((tab) => (
                          <option
                            onClick={() => setCurrentTab(tab.name)}
                            key={tab.name}
                          >
                            {tab.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="hidden sm:block">
                      <nav
                        aria-label="Tabs"
                        className="isolate flex divide-x divide-gray-200 rounded-lg shadow"
                      >
                        {tabs.map((tab, tabIdx) => (
                          <button
                            key={tab.name}
                            href={tab.href}
                            aria-current={tab.current ? "page" : undefined}
                            onClick={() => setCurrentTab(tab.name)}
                            className={classNames(
                              tab.name == currentTab
                                ? "text-gray-900"
                                : "text-gray-500 hover:text-gray-700",
                              tabIdx === 0 ? "rounded-l-lg" : "",
                              tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                              "group relative min-w-0 flex-1 overflow-hidden bg-white px-6 py-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
                            )}
                          >
                            <span>{tab.name}</span>
                            <span
                              aria-hidden="true"
                              className={classNames(
                                tab.name == currentTab
                                  ? "bg-rose-500"
                                  : "bg-transparent",
                                "absolute inset-x-0 bottom-0 h-0.5"
                              )}
                            />
                          </button>
                        ))}
                      </nav>
                    </div>
                  </div>

                  {currentTab == "PROFILE" && (
                    <div className="px-4 py-5 sm:px-0 sm:py-0">
                      <dl className="space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                        <h2
                          id="franchise"
                          className="text-base uppercase font-semibold leading-6 text-gray-900 border-b p-5"
                        >
                          PROFILE DETAILS
                        </h2>
                        {/* <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                          PROFILE
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <p>
                              Enim feugiat ut ipsum, neque ut. Tristique mi id
                              elementum praesent. Gravida in tempus feugiat
                              netus enim aliquet a, quam scelerisque. Dictumst
                              in convallis nec in bibendum aenean arcu.
                            </p>
                          </dd>
                        </div> */}
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Owner Name
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            {franchisee?.ownerName}
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Manager Name
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            {franchisee?.managerName}
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Email
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {franchisee?.ownerEmail}
                            </time>
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Manager Email
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {franchisee?.managerEmail}
                            </time>
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Phone No.
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {franchisee?.phone}
                            </time>
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Manager Phone No.
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {franchisee?.managerPhone}
                            </time>
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Pin Code
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {" "}
                              {franchisee?.pincode}
                            </time>
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Address
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {franchisee?.address}
                            </time>
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Website
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {franchisee?.website}
                            </time>
                          </dd>
                        </div>
                      </dl>
                    </div>
                  )}

                  {currentTab == "FRANCHISE" && (
                    <div className="px-4 py-5 sm:px-0 sm:py-0">
                      <dl className="space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                        <h2
                          id="franchise"
                          className="text-base uppercase font-semibold leading-6 text-gray-900 border-b p-5"
                        >
                          FRANCHISE DETAILS
                        </h2>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Brand Name
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            {franchisee?.franchise_name}
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Type
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            {franchisee?.looking_for}
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Unit BrandFee
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {franchisee?.unit_brandFee} Rs
                            </time>
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Unit Price
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {franchisee?.unit_price}
                            </time>
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Unit Commission
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                            {franchisee?.unit_commission || "N/A"} {franchisee?.unit_commission < 100 ? "%" : "Rs"}

                            </time>
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Selected City
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            {franchisee?.selectedCities &&
                              Object.entries(franchisee.selectedCities).map(
                                ([key, value]) => (
                                  <div key={key} className="mb-1">
                                    <strong>{key}: </strong>
                                    <span>{value.toString()}</span>
                                  </div>
                                )
                              )}
                          </dd>
                        </div>

                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Selected States
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            {franchisee?.selectedStates &&
                              Object.entries(franchisee.selectedStates).map(
                                ([key, value]) => (
                                  <div key={key} className="mb-1">
                                    <strong>{key} </strong>
                                    {/* <span>{value.toString()}</span> */}
                                  </div>
                                )
                              )}
                          </dd>
                        </div>
                      </dl>
                    </div>
                  )}

                  {currentTab == "BUSINESS" && (
                    <div className="px-4 py-5 sm:px-0 sm:py-0">
                      <dl className="space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                        <h2
                          id="franchise"
                          className="text-base uppercase font-semibold leading-6 text-gray-900 border-b p-5"
                        >
                          BUSINESS DETAILS
                        </h2>
                        {/* <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            BUSINESS
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <p>
                              Enim feugiat ut ipsum, neque ut. Tristique mi id
                              elementum praesent. Gravida in tempus feugiat
                              netus enim aliquet a, quam scelerisque. Dictumst
                              in convallis nec in bibendum aenean arcu.
                            </p>
                          </dd>
                        </div> */}
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Industry
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            {franchisee?.industry}
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Commenced
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            {franchisee?.Commenced}
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Current Outlets
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {" "}
                              {franchisee?.currentOutlets}
                            </time>
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Franchising On
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {" "}
                              {franchisee?.franchisingOn}
                            </time>
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Minimum investment range for Tier 1 cities
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {" "}
                              {franchisee?.investment_range_tier_1}
                            </time>
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Minimum investment range for Tier 2 cities
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {" "}
                              {franchisee?.investment_range_tier_2}
                            </time>
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Owned Outlets
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {" "}
                              {franchisee?.ownedOutlets}
                            </time>
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Retail Outlets
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {" "}
                              {franchisee?.retailOutlets}
                            </time>
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            sector
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {" "}
                              {franchisee?.sector}
                            </time>
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Service
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {" "}
                              {franchisee?.service}
                            </time>
                          </dd>
                        </div>
                      </dl>
                    </div>
                  )}

                  {currentTab == "COMPANY" && (
                    <div className="px-4 py-5 sm:px-0 sm:py-0">
                      <dl className="space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                        <h2
                          id="franchise"
                          className="text-base uppercase font-semibold leading-6 text-gray-900 border-b p-5"
                        >
                          COMPANY DETAILS
                        </h2>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            COMPANY
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <p>
                              Enim feugiat ut ipsum, neque ut. Tristique mi id
                              elementum praesent. Gravida in tempus feugiat
                              netus enim aliquet a, quam scelerisque. Dictumst
                              in convallis nec in bibendum aenean arcu.
                            </p>
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Company Name
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            {franchisee?.companyName}
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Owner Name
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            {franchisee?.ownerName}
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Email
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {franchisee?.ownerEmail}
                            </time>
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Phone No.
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {franchisee?.phone}
                            </time>
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Pin Code
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {" "}
                              {franchisee?.pincode}
                            </time>
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Address
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {franchisee?.address}
                            </time>
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Website
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {franchisee?.website}
                            </time>
                          </dd>
                        </div>
                      </dl>
                    </div>
                  )}

                  {currentTab == "PROPERTY" && (
                    <div className="px-4 py-5 sm:px-0 sm:py-0">
                      <dl className="space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                        <h2
                          id="franchise"
                          className="text-base uppercase font-semibold leading-6 text-gray-900 border-b p-5"
                        >
                          PROPERTY DETAILS
                        </h2>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            PROPERTY
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <p>
                              Enim feugiat ut ipsum, neque ut. Tristique mi id
                              elementum praesent. Gravida in tempus feugiat
                              netus enim aliquet a, quam scelerisque. Dictumst
                              in convallis nec in bibendum aenean arcu.
                            </p>
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Preferred location
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            {franchisee?.Preferred_location}
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Floor Area
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            {franchisee?.floor_Area}
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Property Type Required
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {franchisee?.property_type_requried}
                            </time>
                          </dd>
                        </div>
                      </dl>
                    </div>
                  )}

                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default UserOwnedFranchiseProfile;
