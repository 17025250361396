import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {ApplyForm} from "../../redux/userSlice";
import Spinner from "../../BaseFile/Spinner";
import getCityState from "../../BaseFile/getCityState";

export default function ApplyFormModel({closeModal,franchiseId ,singleuser}) {
  const dispatch = useDispatch();
  const [investment, setInvestment] = useState();
  const [terms, setTerms] = useState(false);
  const [pincode, setPincode] = useState();
  const [localError, setLocalError] = useState("");
  const [state, setState] = useState();
  const [address, setAddress] = useState();
  const [newsletter, setNewsletter] = useState(false);
  const [city, setCity] = useState();
  const { loading } = useSelector((state) => state.allusers);

  const handleSave = () => {
    const updatedValue = {
      franchiseId:franchiseId,
      userId: singleuser?._id,
      name: singleuser?.username,
      email: singleuser?.email,
      phone: singleuser?.phone,
      terms: terms,
      investment: investment,
      pincode: pincode,
      newsletter:newsletter,
      address: address,
    };
    dispatch(ApplyForm(updatedValue));
  };

  useEffect(() => {
    if (pincode) {
      getCityState(pincode)
        .then((location) => {
          if (location) {
            setState(location.state);
            setCity(location.city);
            setLocalError(null);
          } else {
            setLocalError("Could not fetch location details.");
          }
        })
        .catch((error) => console.error("Error:", error.message));
    }
  }, [pincode]);

  return (
    <>
      <div className="fixed inset-0  flex items-center justify-center bg-black bg-opacity-50 z-30">
        <div className="h-[550px] lg:h-auto overflow-y-scroll lg:overflow-y-hidden bg-white p-6 shadow-lg border rounded-md">
          <div className="">
            <div className="flex justify-between items-center mb-4 border-b border-gray-400 pb-4">
              <h2 className="text-lg font-bold ">INSTA APPLY</h2>
              <button onClick={closeModal}>
                <div class="group flex h-10 w-20 cursor-pointer items-center justify-center rounded-3xl ">
                  <div class="space-y-2">
                    <span class="block h-1 w-10 origin-center rounded-full bg-slate-500 transition-transform ease-in-out group-hover:translate-y-1.5 group-hover:rotate-45"></span>
                    <span class="block h-1 w-8 origin-center rounded-full bg-orange-500 transition-transform ease-in-out group-hover:w-10 group-hover:-translate-y-1.5 group-hover:-rotate-45"></span>
                  </div>
                </div>
              </button>
            </div>
            <form className="space-y-4">
              <div className="grid lg:grid-cols-2 grid-cols-1   gap-4">
                <input
                  type="text"
                  placeholder="Enter Name"
                  name="name"
                  disabled
                  defaultValue={singleuser?.username}
                  className="w-full border text-[12px] border-gray-300 p-2 rounded focus:outline-none focus:border-blue-500"
                />
                <input
                  type="email"
                  placeholder="Enter Email"
                  name="email"
                  disabled
                  value={singleuser?.email}
                  className="w-full border text-[12px] border-gray-300 p-2 rounded focus:outline-none focus:border-blue-500"
                />
                <input
                  type="text"
                  placeholder="Enter Mobile No"
                  name="phone"
                  disabled
                  value={singleuser?.phone}
                  className="w-full border text-[12px] border-gray-300 p-2 rounded focus:outline-none focus:border-blue-500"
                />
                <input
                  type="text"
                  placeholder="Enter Pincode"
                  name="pincode"
                  onChange={(e)=>setPincode(e.target.value)}
                  value={pincode}
                  className="w-full border border-gray-300 text-[12px] p-2 rounded focus:outline-none focus:border-blue-500"
                />
                <input
                  type="text"
                  id="state"
                  name="state"
                  disabled
                  value={state}
                  placeholder="Select State"
                  required
                  className="w-full border text-[12px] border-gray-300 p-2 rounded focus:outline-none focus:border-blue-500"
                />
                <input
                  type="text"
                  id="city"
                  name="city"
                  value={city}
                  disabled
                  placeholder="Select City"
                  required
                  className="w-full border text-[12px] border-gray-300 p-2 rounded focus:outline-none focus:border-blue-500"
                />
              </div>
              <select
                name="investment"
                onChange={(e)=>setInvestment(e.target.value)}
                className="w-full border border-gray-300 text-[12px] p-2 rounded focus:outline-none focus:border-blue-500"
              >
                <option value="">Select Investment Range</option>
                <option value="1000 - 2000">1000 - 2000</option>
                <option value="2000 - 5000">2000 - 5000</option>
                <option value="5000 - 10,000">5000 - 10,000</option>
                <option value="10,000 - 20000">10,000 - 20,000</option>
              </select>

              <textarea
                placeholder="Enter Address"
                name="address"
                onChange={(e)=>setAddress(e.target.value)}
                value={address}
                className="w-full border border-gray-300 text-[12px] p-2 rounded focus:outline-none focus:border-blue-500"
                rows="3"
              />

              <div className="text-start ">
                <label className=" text-[12px] mb-1 flex items-center">
                  <input
                    type="checkbox"
                    name="newsletter"
                    onChange={(e)=>setNewsletter(e.target.checked)}
                    checked={newsletter}
                    className="mr-2 text-[12px]"
                  />
                  Yes, I want to subscribe for weekly Newsletter
                </label>
                <label className="block text-[12px] flex items-center">
                  <input
                    type="checkbox"
                    name="terms"
                    onChange={(e) => setTerms(e.target.checked)}
                    checked={terms}
                    className="mr-2 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                  I agree to the Terms & Conditions
                </label>
              </div>
              <button
                type="button"
                disabled={!terms}
                onClick={handleSave}
                className={`text-[12px] text-white py-2 px-4 rounded w-full transition ${
                  terms
                    ? "bg-red-500 hover:bg-red-600"
                    : "bg-gray-400 cursor-not-allowed"
                }`}
              >
                {loading ? <Spinner /> : "Apply Now"}
              </button>
              <hr />
             
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
