import { getAllUsers } from "../redux/userSlice";
import { useDispatch, useSelector } from "react-redux";
import {useNavigate} from "react-router-dom";
import {updateInvestorLeads,getInvestorLeadById} from "../redux/leadUpdateSlice";
import { useSearchParams, Link } from "react-router-dom";
import {  getAllInvestorPlanByid } from "../redux/PlanSlice";
import ErrorAlert from "../BaseFile/ErrorAlert";
import { useEffect, useState } from "react";
import { FaLock } from "react-icons/fa";
import { FaLockOpen } from "react-icons/fa";


export default function InvestorLeads({ franchisee }) {
  const dispatch = useDispatch();
  const navigate=useNavigate()
  const [error ,setError] = useState(null)
  const { auth } = useSelector((state) => state.auth);
  const { singleinvestorplan } = useSelector((state) => state.plans);
  const { singleinvestorlead,loading, message } = useSelector((state) => state.leads);
  const { allusers} = useSelector((state) => state.allusers);

  const filteredUsers = allusers?.filter((user) =>
    user?.interested_segment?.includes(franchisee?.industry)
  );


  function viewLeads( userId) {
    const franchise_id=auth?._id
    const canUseLeads =
     (singleinvestorlead?.totalLeads > 0) ||
    (singleinvestorlead?.length == 0  ) ||
    singleinvestorlead?.user_ids?.includes(userId);

    if (canUseLeads) {
        if(userId && franchise_id){
        dispatch(updateInvestorLeads({franchise_id, userId  })).then(() =>
          navigate(`/investor/client/profile/${userId}`)
        )}
      } else {
        setError("Please Buy Leads");
      }
}

  useEffect(() => {
    dispatch(getAllUsers());
    if (auth?._id) {
        const franchise_id = auth?._id;
        const id = auth?._id;
        dispatch(getInvestorLeadById(franchise_id));
        dispatch(getAllInvestorPlanByid(id));
    }
    if(error){
        setInterval(()=>{
            setError(null)
        },[3000])
    }
}, [auth?._id,error]);
  return (
    <>
    {error && <ErrorAlert error={error}/> }

       <div className="border-gray-300 mt-2">
      <div className="sm:flex sm:items-center p-4">
        <div className="sm:flex-auto">
          <h1 className=" font-semibold text-gray-900">Leads Detail</h1>
          <p className="mt-1  text-gray-700">
            A list of all the Leads .
          </p>
        </div>
        <div className="mt-4 sm:flex-none">
          <input
            type="search"
            // onChange={(e) => setSearchQuery(e.target.value)}
            className="border px-2 py-1.5 text-[12px] border-gray-400"
            placeholder="Search"
          />
        </div>
      </div>
      <div className="mt-8 flow-root px-3">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="relative">
              <div className="overflow-x-auto  shadow-md">
                <table className="min-w-full text-sm table-auto divide-y divide-gray-300 rounded-lg">
                  <thead className="bg-gray-900 text-white ">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left font-semibold text-gray-100 text-[13px] uppercase"
                      >
                        Name
                      </th>
                     
                     
                      <th
                        scope="col"
                        className="px-6 py-3 text-left font-semibold text-gray-100 text-[13px] uppercase"
                      >
                        Investment Range
                      </th>
                     
                     
                     
                      <th
                        scope="col"
                        className="px-6 py-3 text-left font-semibold text-gray-100 text-[13px] uppercase"
                      >
                        View
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white capitalize">
                    {filteredUsers?.map(
                      (buy, index) => (
                        <tr
                          key={index}
                          onClick={() => viewLeads(buy?._id)}
                          className="hover:bg-gray-100 transition-colors duration-150"
                        >
                          <td className="whitespace-nowrap px-6 py-4 text-gray-800">
                              {buy?.username}
                          </td>
                          
                          <td className="whitespace-nowrap px-6 py-4 text-gray-800">
                            {buy?.investment}
                          </td>
                         
                          <td className="whitespace-nowrap px-6 py-4 text-gray-800">
                                {singleinvestorlead?.user_ids?.includes(buy?._id) ? <FaLockOpen className="text-green-600 transform transition duration-300 ease-in-out scale-100 hover:scale-125"/> : <FaLock className="text-red-600 transform transition duration-300 ease-in-out scale-100 hover:scale-125"/>}
                            </td>
                            
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
         
    </div>
    </>
  );
}
