import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { State, City } from "country-state-city";
import {
  getFranchiseByid,
  getDescriptionByid,
} from "../../redux/franchiseSlice";
import { VscUnverified } from "react-icons/vsc";
import { MdVerified } from "react-icons/md";
import Header from "../Comman/Header";
import { getUser, clearErrors, clearMessage } from "../../redux/userSlice";
import Footer from "../Comman/Footer";
import KycModel from "./KycModel";
import ApplyFormModel from "./ApplyFormModel";
import SuccessAlert from "../../BaseFile/SuccessAlert";
import ErrorAlert from "../../BaseFile/ErrorAlert";
import ImageGallery from "./ImageGallery";
import DescriptionDisplay from "./DecDisplay";
import { Ads } from "../../AdsComponents/Ads";
const BrandFriendly = () => {
  const { _id } = useParams();
  const dispatch = useDispatch();
  const [openKycModel, setOpenKycModel] = useState(false);
  const [openApplyModel, setOpenApplyModel] = useState(false);
  const { franchisee, description, loading } = useSelector(
    (state) => state.franchise
  );
  const { auth } = useSelector((state) => state.auth);
  const [des, setDes] = useState(""); // This will hold the current description
  const { singleuser, error, message } = useSelector((state) => state.allusers);

  useEffect(() => {
    if (_id) {
      dispatch(getFranchiseByid({ _id }));
      dispatch(getDescriptionByid({ _id }));
    }
    if (auth?._id) {
      const id = auth?._id;
      dispatch(getUser(id));
    }
  }, [_id]);

  useEffect(() => {
    if (description?.description) {
      setDes(description?.description); // Set fetched description to the editor
    }
  }, [description]);

  useEffect(() => {
    if (error) {
      const errorInterval = setInterval(() => {
        dispatch(clearErrors());
      }, 3000);
      return () => clearInterval(errorInterval);
    }

    if (message) {
      const messageInterval = setInterval(() => {
        dispatch(clearMessage());
      }, 3000);
      return () => clearInterval(messageInterval);
    }
  }, [message, error]);

  const closeModal = () => {
    setOpenKycModel(false);
    setOpenApplyModel(false);
  };

  const banners = [franchisee?.image6, franchisee?.image7, franchisee?.image8];
  return (
    <>
      {message && <SuccessAlert message={message} />}
      {error && <ErrorAlert error={error} />}
      <Header />
      {franchisee?.image6 && franchisee?.image7 && franchisee?.image8 && (
        <ImageGallery banner={banners} />
      )}
      <main>
        <div className="w-full  lg:mb-0 mb-10 mt-8">
          <div className="grid grid-cols-12 sm:mx-10  mx-4 gap-3">
            <div className="lg:col-span-9 col-span-12 border">
              <div className=" flex lg:w-full">
                <div className="flex h-full w-full bg-white shadow-md">
                  <div className="divide-y divide-gray-200 w-full">
                    <div className="pb-6">
                      <div className="h-24 bg-gray-700 sm:h-20 lg:h-28" />
                      <div className="-mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6 lg:-mt-16">
                        <div>
                          <div className="-m-1 flex">
                            <div className="inline-flex overflow-hidden rounded-lg border-4 border-white">
                              <img
                                alt=""
                                src={
                                  franchisee?.companyLogo
                                    ? `/uploads/InvestorData/${franchisee?.companyLogo}`
                                    : "/defaultlogo.png"
                                }
                                className="h-24 w-24 flex-shrink-0 sm:h-40 sm:w-40 lg:h-48 lg:w-48"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mt-6 sm:ml-6 flex gap-5 sm:flex-1 w-full">
                          <div>
                            <div className="flex items-center">
                              <h3 className="lg:text-xl sm:text-xl text-lg font-bold text-gray-700 capitalize">
                                {franchisee?.franchise_name}
                              </h3>

                              {franchisee?.isVerified == "yes" ? (
                                <MdVerified className="text-green-500 text-2xl" />
                              ) : (
                                <VscUnverified className="text-red-500 text-2xl" />
                              )}
                            </div>
                            {/* <p className="text-sm text-gray-500">
                              {franchisee?.ownerEmail}
                            </p> */}
                          </div>
                          <div>
                            <div>
                              {auth?.role == "investor" ? (
                                ""
                              ) : !auth && !singleuser ? (
                                ""
                              ) : auth && singleuser?.isVerified == "no" ? (
                                <button onClick={() => setOpenKycModel(true)}>
                                  <p className="mr-5 px-2 hover:bg-yellow-700 bg-yellow-600 w-20 py-1 rounded-md text-white">
                                    Verified First
                                  </p>
                                </button>
                              ) : auth &&
                                singleuser?.isVerified == "pending" ? (
                                <button
                                  onClick={() =>
                                    window.alert("No Action ,Wait For Approval")
                                  }
                                >
                                  <p className="mr-5 px-2 hover:bg-green-700 bg-green-600 w-20 py-1 rounded-md text-white">
                                    Pending Approval
                                  </p>
                                </button>
                              ) : (
                                <button
                                  onClick={() => setOpenApplyModel(true)}
                                  className=""
                                >
                                  <p className="mr-5 px-2 hover:bg-green-700 text-sm  bg-green-600 w-28 py-2 rounded-md text-white">
                                    Apply Now
                                  </p>
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* <div className=" overflow-hidden border-4 border-white">
                          <div className="lg:w-48 lg:h-48 sm:w-48 sm:h-48 w-28 h-28 relative">
                            <div className="text-[12px] absolute -top-3 capitalize left-0 bg-black px-4 py-1 text-white mt-3 mr-3 font-medium hover:bg-black  transition duration-500 ease-in-out">
                              ads
                            </div>
                            <img src="/cyberminiads1.png" />
                          </div>
                        </div> */}
                      </div>
                    </div>

                    {/* test */}
                    {/* <div>
                      <dl className="m-4 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4">
                        <div className="flex flex-col bg-gray-700 p-8">
                          <dt className="text-sm font-semibold leading-6 text-gray-300">
                            Min Investment for tier 1 cities
                          </dt>
                          <dd className="order-first text-2xl font-semibold tracking-tight text-white">
                            {franchisee?.investment_range_tier_1 || "NA"}
                          </dd>
                        </div>

                        <div className="flex flex-col bg-gray-700 p-8">
                          <dt className="text-sm font-semibold leading-6 text-gray-300">
                            Min Investment for tier 2 cities
                          </dt>
                          <dd className="order-first text-2xl font-semibold tracking-tight text-white">
                            {franchisee?.investment_range_tier_2 || "NA"}
                          </dd>
                        </div>

                        <div className="flex flex-col bg-gray-700 p-8">
                          <dt className="text-sm font-semibold leading-6 text-gray-300">
                            Area Required
                          </dt>
                          <dd className="order-first text-2xl font-semibold tracking-tight text-white">
                            {franchisee?.floor_Area
                              ? `${franchisee?.floor_Area} sq. ft.`
                              : "NA"}
                          </dd>
                        </div>

                        <div className="flex flex-col bg-gray-700 p-8">
                          <dt className="text-sm font-semibold leading-6 text-gray-300">
                            Current Outlets
                          </dt>
                          <dd className="order-first text-2xl font-semibold tracking-tight text-white">
                            {franchisee?.currentOutlets || "NA"}
                          </dd>
                        </div>
                      </dl>
                    </div> */}
                    {/* test */}

                    <div className="container mx-auto px-4 py-6">
                      <div className="grid grid-cols-1 lg:grid-cols-4 sm:grid-cols-2 gap-3">
                        {/* Card 1 */}
                        <div className="bg-white  border border-gray-300  rounded-lg shadow-sm p-3">
                          <div className="relative ">
                            <div className="absolute top-4 right-4 text-gray-400 opacity-10 text-[110px]">
                              <i className="fas fa-shopping-cart" />
                            </div>
                            <h5 className="text-[12px] font-semibold mb-4">
                              Min Investment for tier 1 cities
                            </h5>
                            <div className="flex items-center mb-2">
                              <div className="flex-1 text-base font-bold">
                                {franchisee?.investment_range_tier_1 || "NA"}
                              </div>
                            </div>
                            <div className="h-2 bg-gray-200 rounded-full mt-1 overflow-hidden">
                              <div className="h-full w-[25%] bg-gradient-to-r from-[#289cf5] to-[#84c0ec]" />
                            </div>
                          </div>
                        </div>
                        {/* Card 2 */}
                        <div className="bg-white text-black border border-gray-300  rounded-lg shadow-sm p-3">
                          <div className="relative">
                            <div className="absolute top-4 right-4 text-black opacity-10 text-[110px]">
                              <i className="fas fa-users" />
                            </div>
                            <h5 className="text-[12px] font-semibold mb-4">
                              Min Investment for tier 2 cities
                            </h5>
                            <div className="flex items-center mb-2">
                              <div className="flex-1 text-base font-bold">
                                {franchisee?.investment_range_tier_2 || "NA"}
                              </div>
                            </div>
                            <div className="h-2 bg-gray-200 rounded-full mt-1 overflow-hidden">
                              <div className="h-full w-[25%] bg-gradient-to-r from-[#23bdb8] to-[#43e794]" />
                            </div>
                          </div>
                        </div>
                        {/* Card 3 */}
                        <div className=" bg-white text-black border border-gray-300  rounded-lg shadow-sm p-3">
                          <div className="relative">
                            <div className="absolute top-4 right-4 text-black opacity-10 text-[110px]">
                              <i className="fas fa-ticket-alt" />
                            </div>
                            <h5 className="text-[12px] font-semibold mb-4">
                              Area Required
                            </h5>
                            <div className="flex items-center mb-2">
                              <div className="flex-1 text-base font-bold">
                                {franchisee?.floor_Area
                                  ? `${franchisee?.floor_Area} sq. ft.`
                                  : "NA"}
                              </div>
                            </div>
                            <div className="h-2 bg-gray-200 rounded-full mt-1 overflow-hidden">
                              <div className="h-full w-[25%] bg-gradient-to-r from-[#f9900e] to-[#ffba56]" />
                            </div>
                          </div>
                        </div>
                        {/* Card 4 */}
                        <div className="bg-white text-black border border-gray-300  rounded-lg shadow-sm p-3">
                          <div className="relative ">
                            <div className="absolute top-4 right-4 text-black opacity-10 text-[110px]">
                              <i className="fas fa-dollar-sign" />
                            </div>
                            <h5 className="text-[12px] font-semibold mb-4">
                              Current Outlets
                            </h5>
                            <div className="flex items-center mb-2">
                              <div className="flex-1 text-base font-bold">
                                {franchisee?.currentOutlets || "NA"}
                              </div>
                            </div>
                            <div className="h-2 bg-gray-200 rounded-full mt-1 overflow-hidden">
                              <div className="h-full w-[25%] bg-gradient-to-r from-[#289cf5] to-[#84c0ec]" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <>
                      <div className="py-5 border-b shadow-md sm:py-0 mb-4">
                        <div className="border-b border-green-400 bg-gray-200 py-2">
                          <h5
                            id="about-franchise"
                            className="text-lg font-semibold px-4 capitalize"
                          >
                            ABOUT THE FRANCHISE
                          </h5>
                        </div>
                        <p className="mt-4 text-[14px] text-gray-700 text-justify px-4 pb-4 leading-6">
                          {description?.description ? (
                            <div
                              className=" preview-container text-justify"
                              dangerouslySetInnerHTML={{ __html: des }}
                            />
                          ) : (
                            <>
                              <div className="">
                                The {franchisee?.franchise_name || "franchise"}{" "}
                                offers a unique opportunity to become part of an
                                established brand with a strong market presence.
                                By joining the{" "}
                                {franchisee?.industry || "industry"} sector,
                                franchisees will benefit from a proven business
                                model and extensive training and support to
                                ensure success.
                              </div>
                              <div className="mt-1 ">
                                This franchise is ideal for individuals looking
                                to invest in a business with a strong brand,
                                robust support systems, and high potential for
                                growth.
                              </div>
                            </>
                          )}
                        </p>
                      </div>

                      {/* Franchise Opportunities Section */}
                      <div className="py-5 border-b shadow-md sm:py-0 mb-4">
                        <div className="border-b border-green-400 bg-gray-200 py-2">
                          <h5
                            id="franchise-opportunities"
                            className="text-lg font-semibold px-4 capitalize"
                          >
                            FRANCHISE OPPORTUNITIES
                          </h5>
                        </div>
                        <p className="mt-4 text-[14px] text-gray-700 text-justify px-4 pb-4 leading-6">
                          <div className="">
                            {franchisee?.franchise_name || "This franchise"}{" "}
                            offers multiple franchise opportunities across
                            various regions, including tier 1 and tier 2 cities.
                            With flexible investment options starting from{" "}
                            {franchisee?.investment_range_tier_1 || "N/A"} Rs in
                            tier 1 cities and{" "}
                            {franchisee?.investment_range_tier_2 || "N/A"} Rs in
                            tier 2 cities, this franchise provides a profitable
                            and sustainable business model.
                          </div>
                          <div className="mt-1">
                            By becoming a franchisee, you’ll be able to leverage
                            the strength of the brand and its market position to
                            build a successful business.
                          </div>
                        </p>
                      </div>

                      {/* Franchisee Support Section */}

                      {/* old */}

                      <div className="py-5  sm:py-0 shadow-md mb-4">
                        <div className="border-b border-green-400 bg-gray-200 py-2">
                          <h5
                            id="franchise"
                            className="text-lg font-semibold  px-4 capitalize"
                          >
                            FRANCHISE DETAILS
                          </h5>
                        </div>
                        <p className="mt-4 text-[14px] text-gray-700 px-4 text-justify leading-6">
                          <div className="grid sm:flex justify-between items-center  ">
                            <sapn>The franchise's brand name</sapn>
                            <strong className="text-gray-700 font-bold capitalize">
                              {franchisee?.franchise_name || "N/A"}
                            </strong>
                          </div>
                          <div className="grid sm:flex justify-between items-center ">
                            <span>
                              They are actively seeking an owner for their
                            </span>
                            <strong className="text-gray-700 font-bold capitalize">
                              {franchisee?.looking_for || "N/A"}
                            </strong>
                          </div>
                          <div className="grid sm:flex justify-between items-center">
                            <span> Unit Brand Fee </span>
                            <strong className="text-gray-700 font-bold">
                              {franchisee?.unit_brandFee || "N/A"} Rs
                            </strong>
                          </div>
                          <div className="grid sm:flex justify-between items-center">
                            <span>Unit Price</span>
                            <strong className="text-gray-700 font-bold">
                              {franchisee?.unit_price || "N/A"} Rs
                            </strong>
                          </div>
                          <div className="grid sm:flex justify-between items-center">
                            <span>
                              Additionally, competitive Unit Commission{" "}
                            </span>
                            <strong className="text-gray-700 font-bold">
                              {franchisee?.unit_commission || "N/A"}{" "}
                              {franchisee?.unit_commission < 100 ? "%" : "Rs"}
                            </strong>
                          </div>
                          {/* This franchise is ideal for individuals looking to
                          invest in a well-established brand with a strong
                          market presence. */}

                          {franchisee?.state_price && (
                            <>
                              <div className="grid sm:flex justify-between items-center">
                                <span> State Brand Fee </span>
                                <strong className="text-gray-700 font-bold">
                                  {franchisee?.state_brandFee || "N/A"} Rs
                                </strong>
                              </div>
                              <div className="grid sm:flex justify-between items-center">
                                <span>State Price</span>
                                <strong className="text-gray-700 font-bold">
                                  {franchisee?.state_price || "N/A"} Rs
                                </strong>
                              </div>
                              <div className="grid sm:flex justify-between items-center">
                                <span>
                                  Additionally, competitive State Commission{" "}
                                </span>
                                <strong className="text-gray-700 font-bold">
                                  {franchisee?.state_commission || "N/A"}{" "}
                                  {franchisee?.state_commission < 100
                                    ? "%"
                                    : "Rs"}
                                </strong>
                              </div>
                            </>
                          )}
                            {franchisee?.country_price && (
                            <>
                              <div className="grid sm:flex justify-between items-center">
                                <span> Country Brand Fee </span>
                                <strong className="text-gray-700 font-bold">
                                  {franchisee?.country_brandFee || "N/A"} Rs
                                </strong>
                              </div>
                              <div className="grid sm:flex justify-between items-center">
                                <span>Country Price</span>
                                <strong className="text-gray-700 font-bold">
                                  {franchisee?.country_price || "N/A"} Rs
                                </strong>
                              </div>
                              <div className="grid sm:flex justify-between items-center">
                                <span>
                                  Additionally, competitive Country Commission{" "}
                                </span>
                                <strong className="text-gray-700 font-bold">
                                  {franchisee?.country_commission || "N/A"}{" "}
                                  {franchisee?.country_commission < 100
                                    ? "%"
                                    : "Rs"}
                                </strong>
                              </div>
                            </>
                          )}
                            {franchisee?.city_price && (
                            <>
                              <div className="grid sm:flex justify-between items-center">
                                <span> City Brand Fee </span>
                                <strong className="text-gray-700 font-bold">
                                  {franchisee?.city_brandFee || "N/A"} Rs
                                </strong>
                              </div>
                              <div className="grid sm:flex justify-between items-center">
                                <span>City Price</span>
                                <strong className="text-gray-700 font-bold">
                                  {franchisee?.city_price || "N/A"} Rs
                                </strong>
                              </div>
                              <div className="grid sm:flex justify-between items-center">
                                <span>
                                  Additionally, competitive City Commission{" "}
                                </span>
                                <strong className="text-gray-700 font-bold">
                                  {franchisee?.city_commission || "N/A"}{" "}
                                  {franchisee?.city_commission < 100
                                    ? "%"
                                    : "Rs"}
                                </strong>
                              </div>
                            </>
                          )}
                        </p>
                        {/* <div className="grid lg:grid-cols-2 grid-cols-1 px-4 gap-4 pb-6 "> */}
                        <div className="mt-4">
                          <div className="mt-4">
                            <div className="shadow-md border">
                              <div className="border-b border-green-400 bg-gray-100 py-2">
                                <h5 className="text-base font-semibold px-4 uppercase">
                                  States
                                </h5>
                              </div>
                              {franchisee?.selectedStates &&
                              Object.keys(franchisee?.selectedStates).length >
                                0 ? (
                                <p className="mt-4 text-[14px] text-gray-700 px-4 text-justify pb-4 ">
                                  <span className="block mb-3 border-b pb-3">
                                    They are targeting the following States:
                                  </span>
                                  <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-y-2 gap-1">
                                    {Object.entries(
                                      franchisee?.selectedStates
                                    )?.map(([key], index) => {
                                      const state =
                                        State?.getStateByCodeAndCountry(
                                          key,
                                          "IN"
                                        );
                                      const colors = [
                                        "bg-red-100",
                                        "bg-blue-100",
                                        "bg-green-100",
                                        "bg-yellow-100",
                                        "bg-purple-100",
                                        "bg-pink-100",
                                        "bg-teal-100",
                                        "bg-indigo-100",
                                      ];
                                      const colorClass =
                                        colors[index % colors.length];

                                      return (
                                        <span key={key}>
                                          <p
                                            className={`text-gray-700 w-auto sm:text-center font-medium px-4 py-1 border ${colorClass}`}
                                          >
                                            {state?.name || "N/A"}
                                          </p>{" "}
                                        </span>
                                      );
                                    })}
                                  </div>
                                  <div className="mt-3 border-t pt-2">
                                    These states were chosen based on their high
                                    potential for growth and consumer demand.
                                  </div>
                                </p>
                              ) : (
                                <p className="mt-4 text-[14px] text-gray-700 px-4 text-justify pb-4">
                                  No state information provided.
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* <div className="mt-4">
                          <div className="shadow-md border">
                            <div className="border-b border-green-400 bg-gray-100 py-2">
                              <h5 className="text-base font-semibold px-4 uppercase">
                                Cities
                              </h5>
                            </div>
                            {franchisee?.selectedCities &&
                            Object.keys(franchisee?.selectedCities)?.length >
                              0 ? (
                              <p className="mt-4 text-[14px] text-gray-700 px-4 text-justify pb-4">
                                <span className="block mb-3 border-b pb-3">
                                  They are targeting the following Cities:
                                </span>
                                {Object.entries(
                                  franchisee?.selectedCities
                                )?.map(([key], index) => {
                                  const city = City?.getStateByCodeAndCountry(
                                    key,
                                    "IN"
                                  ); // Assuming you have a `getCityByCodeAndCountry` function for cities.

                                  // Array of colors for each city
                                  const colors = [
                                    "bg-red-100",
                                    "bg-blue-100",
                                    "bg-green-100",
                                    "bg-yellow-100",
                                    "bg-purple-100",
                                    "bg-pink-100",
                                    "bg-teal-100",
                                    "bg-indigo-100",
                                  ];

                                  // Pick a color based on the index
                                  const colorClass =
                                    colors[index % colors.length];

                                  return (
                                    <span key={key}>
                                      <strong
                                        className={`text-gray-700 font-bold px-4 py-1 border ${colorClass}`}
                                      >
                                        {city?.name || "N/A"}
                                      </strong>{" "}
                                    </span>
                                  );
                                })}
                                <div className="mt-3 border-t pt-2">
                                  These cities were chosen based on their high
                                  potential for growth and consumer demand.
                                </div>
                              </p>
                            ) : (
                              <p className="mt-4 text-[14px] text-gray-700 px-4 text-justify pb-4">
                                No city information provided.
                              </p>
                            )}
                          </div>
                        </div> */}
                      </div>
                      {/* old */}

                      {/* Business Details Section */}
                      <div className=" py-5  sm:py-0 border shadow-md mb-4">
                        <div className="border-b border-green-400 bg-gray-200 py-2">
                          <h5
                            id="business"
                            className="text-lg font-semibold  px-4 capitalize"
                          >
                            BUSINESS DETAILS
                          </h5>
                        </div>

                        <p className="mt-4 text-[14px] text-gray-700 px-4 text-justify pb-4 leading-6">
                          <div className="grid sm:flex justify-between items-center">
                            <span>This business operates in the field of</span>
                            <strong className="text-gray-700 font-bold">
                              {franchisee?.industry || "N/A"}
                            </strong>{" "}
                          </div>
                          <div className="grid sm:flex justify-between items-center">
                            Industry and commenced on{" "}
                            <strong className="text-gray-700 font-bold">
                              {franchisee?.Commenced || "N/A"}
                            </strong>
                          </div>
                          <div className="grid sm:flex justify-between items-center">
                            Number of Currently Outlets they have{" "}
                            <strong className="text-gray-700 font-bold">
                              {franchisee?.currentOutlets || "N/A"}
                            </strong>{" "}
                          </div>
                          <div className="grid sm:flex justify-between items-center">
                            Operational outlets, and it has been franchising
                            since{" "}
                            <strong className="text-gray-700 font-bold">
                              {franchisee?.franchisingOn || "N/A"}
                            </strong>
                          </div>
                          <div className="grid sm:flex justify-between items-center">
                            For expansion in Tier 1 cities, the minimum
                            investment required is{" "}
                            <strong className="text-gray-700 font-bold">
                              {franchisee?.investment_range_tier_1 || "N/A"}
                            </strong>
                          </div>
                          <div className="grid sm:flex justify-between items-center">
                            For expansion in Tier 2 cities, the minimum
                            investment required is
                            <strong className="text-gray-700 font-bold">
                              {franchisee?.investment_range_tier_2 || "N/A"}
                            </strong>
                          </div>
                          <div className="grid sm:flex justify-between items-center">
                            Number of outlets company owns{" "}
                            <strong className="text-gray-700 font-bold">
                              {franchisee?.ownedOutlets || "N/A"}
                            </strong>{" "}
                          </div>
                          <div className="grid sm:flex justify-between items-center">
                            outlets and operates{" "}
                            <strong className="text-gray-700 font-bold">
                              {franchisee?.retailOutlets || "N/A"}
                            </strong>{" "}
                          </div>
                          <div className="grid sm:flex justify-between items-center">
                            It functions specific in sector of
                            <strong className="text-gray-700 font-bold">
                              {franchisee?.sector || "N/A"}
                            </strong>{" "}
                          </div>
                          <div className="grid sm:flex justify-between items-center">
                            Maily they provide service like{" "}
                            <strong className="text-gray-700 font-bold">
                              {franchisee?.service || "N/A"}
                            </strong>{" "}
                          </div>
                          <div className="mt-3  border-t pt-2">
                            As its main service. This business model is designed
                            to ensure franchisee profitability and
                            sustainability in a competitive market.
                          </div>
                        </p>
                      </div>

                      {/* Training Details Section */}
                      <div className="py-5 border-b  border-gray-400 sm:py-0 mb-4 shadow-md">
                        <div className="border-b border-green-400 bg-gray-200 py-2">
                          <h5
                            id="training"
                            className="text-lg font-semibold  px-4 capitalize"
                          >
                            TRAINING DETAILS
                          </h5>
                        </div>
                        <p className="mt-4 text-[14px] text-gray-700 px-4 text-justify pb-4 leading-6">
                          <div className="grid sm:flex justify-between items-end">
                            <span className="">
                              Availability of operating manuals for franchises.{" "}
                            </span>
                            <strong className="text-gray-700 font-bold capitalize">
                              {franchisee?.operating_manuals || "N/A"}
                            </strong>
                          </div>
                          <div className="grid sm:flex justify-between items-center">
                            The franchisee training takes place at{" "}
                            <strong className="text-gray-700 font-bold capitalize">
                              {franchisee?.franchise_training_location || "N/A"}
                            </strong>
                          </div>
                          <div className="grid sm:flex justify-between items-center">
                            Currently, the IT systems available for the
                            franchise include{" "}
                            <strong className="text-gray-700 font-bold capitalize">
                              {franchisee?.presently_IT_system || "N/A"}
                            </strong>
                          </div>
                          <div className="grid sm:flex justify-between items-center">
                            Field assistance is available through{" "}
                            <strong className="text-gray-700 font-bold capitalize">
                              {franchisee?.field_assistance_available || "N/A"}
                            </strong>
                          </div>
                          <div className="grid sm:flex justify-between items-center">
                            Additionally, the head office provides assistance as
                            noted in{" "}
                            <strong className="text-gray-700 font-bold capitalize">
                              {franchisee?.providing_assistance_byhead || "N/A"}
                            </strong>
                          </div>
                          <div className="grid sm:flex justify-between items-center">
                            Availability of franchise agreement{" "}
                            <strong className="text-gray-700 font-bold capitalize">
                              {franchisee?.franchise_agreement || "N/A"}
                            </strong>
                          </div>
                          <div className="grid sm:flex justify-between items-center">
                            The duration of the contract is{" "}
                            <strong className="text-gray-700 font-bold capitalize">
                              {franchisee?.contract_date || "N/A"}
                            </strong>
                          </div>
                          <div className="grid sm:flex justify-between items-center">
                            Franchise contract renewable or not ?{" "}
                            <strong className="text-gray-700 font-bold capitalize">
                              {franchisee?.renewable
                                ? "renewable"
                                : "not renewable"}
                            </strong>
                          </div>
                          <div className="mt-3  border-t pt-2">
                            This extensive training program is designed to equip
                            franchisees with all necessary skills to thrive.
                          </div>
                        </p>
                      </div>

                      {/* Property Details Section */}
                      <div className="py-5 border-b shadow-md sm:py-0">
                        <div className="border-b border-green-400 bg-gray-200 py-2">
                          <h5
                            id="property"
                            className="text-lg font-semibold px-4 capitalize"
                          >
                            Property Details
                          </h5>
                        </div>

                        <div className="mt-4 text-[14px] text-gray-700 px-4 pb-4 leading-6">
                          <div className="border-b pb-3">
                            The property details for this franchise include a
                            preferred location and the type of property that
                            meets the required criteria.
                          </div>

                          <div className="mt-3 grid sm:flex justify-between items-center">
                            <span className="sm:w-1/2 w-full">
                              Preferred location for this franchisee
                            </span>
                            <strong className="sm:w-1/2 w-full text-gray-700 font-bold sm:text-right">
                              {franchisee?.Preferred_location || "N/A"}
                            </strong>
                          </div>

                          <div className="mt-3 grid sm:flex justify-between items-center">
                            <span className="sm:w-1/2 w-full">
                              Required floor area in square feet
                            </span>
                            <strong className="sm:w-1/2 w-full text-gray-700 font-bold sm:text-right">
                              {franchisee?.floor_Area || "N/A"} sq ft
                            </strong>
                          </div>

                          <div className="mt-3 grid sm:flex justify-between items-center">
                            <span className="sm:w-1/2 w-full">
                              Type of property required:
                            </span>
                            <strong className="sm:w-1/2 w-full text-gray-700 font-bold sm:text-right">
                              {franchisee?.property_type_requried || "N/A"}
                            </strong>
                          </div>

                          <div className="mt-3 border-t pt-2">
                            <p>
                              Overall, the property meets the essential criteria
                              needed to ensure operational success and has been
                              selected based on its potential to attract
                              customers and enhance brand visibility.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="py-5 border-b shadow-md sm:py-0 mb-4">
                        <div className="border-b border-green-400 bg-gray-200 py-2">
                          <h5
                            id="franchisee-support"
                            className="text-lg font-semibold px-4 capitalize"
                          >
                            FRANCHISEE SUPPORT
                          </h5>
                        </div>
                        <p className="mt-4 text-[14px] text-gray-700 text-justify px-4 pb-4 leading-6">
                          <div className="">
                            The {franchisee?.franchise_name || "franchise"}{" "}
                            provides extensive support to franchisees, including
                            ongoing training, field assistance, and
                            comprehensive operational manuals. Franchisees will
                            also have access to marketing support, IT systems,
                            and a dedicated head office team.
                          </div>
                          <div className="mt-1">
                            This support is designed to ensure smooth operations
                            and help franchisees grow their business
                            successfully.
                          </div>
                        </p>
                      </div>

                      {/* Contact Information Section */}
                      <div className="py-5 border-b shadow-md sm:py-0 mb-4">
                        <div className="border-b border-green-400 bg-gray-200 py-2">
                          <h5
                            id="contact-info"
                            className="text-lg font-semibold px-4 capitalize"
                          >
                            CONTACT INFORMATION
                          </h5>
                        </div>
                        <p className="mt-4 text-[14px] text-gray-700 text-justify px-4 pb-4 leading-6">
                          <div className="">
                            If you are interested in learning more about this
                            franchise opportunity or have any questions, please
                            contact us at:
                          </div>
                          <div className="mt-1">
                            <div>
                              {auth?.role == "investor" ? (
                                ""
                              ) : !auth && !singleuser ? (
                                <p>
                                  Login First to apply{" "}
                                  <Link to="/login" className="text-indigo-500">
                                    Login Here
                                  </Link>
                                </p>
                              ) : auth && singleuser?.isVerified == "no" ? (
                                <button onClick={() => setOpenKycModel(true)}>
                                  <p className="text-indigo-500">
                                    Verified First to apply for franchise .
                                    Click here to uplaod your KYC.
                                  </p>
                                </button>
                              ) : auth &&
                                singleuser?.isVerified == "pending" ? (
                                <button
                                  onClick={() =>
                                    window.alert("No Action ,Wait For Approval")
                                  }
                                >
                                  <p className="text-red-500">
                                    Wait for approval your KYC
                                  </p>
                                </button>
                              ) : (
                                <button onClick={() => setOpenApplyModel(true)}>
                                  <p className="text-indigo-500">
                                    For More information contact us . Click here
                                    to contact
                                  </p>
                                </button>
                              )}
                            </div>
                          </div>
                        </p>
                      </div>
                    </>
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:col-span-3 col-span-12 border">
              <div className="grid lg:grid-cols-1 sm:grid-cols-2 grid-cols-1 ">
                <div className="grid lg:grid-cols-1 grid-cols-1 ">
                  {/* <div className="relative ">
                    <div className="text-[14px] absolute -top-3 capitalize left-0 bg-black px-4 py-2 text-white mt-3 mr-3 font-medium hover:bg-black  transition duration-500 ease-in-out">
                      ads
                    </div>
                    <img src="/Discoverad.png" className="w-full" />
                  </div>
                  <div className="relative mt-4">
                    <div className="text-[14px] absolute -top-3 capitalize -right-3 bg-black px-4 py-2 text-white mt-3 mr-3 font-medium hover:bg-black  transition duration-500 ease-in-out">
                      ads
                    </div>
                    <img src="/investoradd.png" className="w-full" />
                  </div> */}
                  <Ads adnum={1} />
                  <Ads adnum={3} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      {openKycModel && <KycModel closeModal={closeModal} />}
      {openApplyModel && (
        <ApplyFormModel
          closeModal={closeModal}
          franchiseId={_id}
          singleuser={singleuser}
        />
      )}
    </>
  );
};

export default BrandFriendly;
