import React, { useState, useEffect } from "react";
import {
  uploadService,
  getServiceRequestByID,
  clearErrors,
  clearMessage,
} from "../redux/serviceSlice";
import { RiCoupon2Fill } from "react-icons/ri";
import { useSelector, useDispatch } from "react-redux";
import SuccessAlert from "../BaseFile/SuccessAlert";
import ErrorAlert from "../BaseFile/ErrorAlert";
import Spinner from "../BaseFile/Spinner";
import { PayConfirmation } from "./PayConfirmation";
export default function Services() {
  const dispatch = useDispatch();
  const { userservices, loading, error, message } = useSelector(
    (state) => state.service
  );
  const { auth } = useSelector((state) => state.auth);
  const [selectedService, setSelectedService] = useState("");
  const [description, setDescription] = useState("");
  const [completionTime, setCompletionTime] = useState("");
  const [duration, setDuration] = useState("");
  const [marketingGoal, setMarketingGoal] = useState("");
  const [payId, setpayId] = useState(false);
  const [payamount, setpayamount] = useState(false);
  const [service, setService] = useState(false);

  useEffect(() => {
    if (auth?._id) {
      const id = auth?._id;
      dispatch(getServiceRequestByID(id));
    }
    if (error) {
      const errorInterval = setInterval(() => {
        dispatch(clearErrors());
      }, 3000);
      return () => clearInterval(errorInterval);
    }

    if (message) {
      const messageInterval = setInterval(() => {
        dispatch(clearMessage());
      }, 3000);
      return () => clearInterval(messageInterval);
    }
  }, [dispatch, error, message, clearErrors, clearMessage]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      franchise_id: auth?._id,
      service: selectedService,
      description,
      completionTime,
      duration: selectedService === "ads" ? duration : null,
      marketingGoal: selectedService === "marketing" ? marketingGoal : null,
    };
    dispatch(uploadService(formData));
  };

  function handlePay(id, amount,type) {
    console.log("try");
    setpayId(id);
    setpayamount(amount);
    setService(type);
  }
  return (
    <>
      {message && <SuccessAlert message={message} />}
      {error && <ErrorAlert error={error} />}
      <div className="">
        <h1 className="text-xl py-2 bg-gray-100 font-bold mb-4 text-center text-black">
          Request IT Service
        </h1>
        <div className="py-4 px-6">
          <form className="" onSubmit={handleSubmit}>
            {/* Service Selection */}
            <div className="grid grid-cols-2 gap-3">
              <div>
                <label className="block text-sm  text-gray-700 font-medium mb-2">
                  Select Service
                </label>
                <select
                  value={selectedService}
                  onChange={(e) => setSelectedService(e.target.value)}
                  className="w-full text-sm  px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                >
                  <option value="">-- Select a Service --</option>
                  <option value="ads">Ads Service</option>
                  <option value="software">Software Service</option>
                  <option value="graphic">Graphic Service</option>
                  <option value="it_other">Other IT Service</option>
                  <option value="marketing">Marketing Service</option>
                </select>
              </div>

              {/* Completion Time */}
              <div>
                <label className="block text-sm  text-gray-700 font-medium mb-2">
                  Time to Complete
                </label>
                <input
                  type="text"
                  value={completionTime}
                  onChange={(e) => setCompletionTime(e.target.value)}
                  placeholder="e.g., 2 weeks"
                  className="w-full px-3 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                />
              </div>

              {/* Ads Service Specific Field */}
              {selectedService === "ads" && (
                <div>
                  <label className="block text-sm  text-gray-700 font-medium mb-2">
                    Duration (in Months)
                  </label>
                  <input
                    type="number"
                    value={duration}
                    onChange={(e) => setDuration(e.target.value)}
                    placeholder="e.g., 3"
                    className="w-full px-3 text-sm  py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                  />
                </div>
              )}

              {/* Marketing Service Specific Field */}
              {selectedService === "marketing" && (
                <div>
                  <label className="block text-sm  text-gray-700 font-medium mb-2">
                    Marketing Goal
                  </label>
                  <input
                    type="text"
                    value={marketingGoal}
                    onChange={(e) => setMarketingGoal(e.target.value)}
                    placeholder="e.g., Increase brand awareness"
                    className="w-full px-3 py-2 text-sm  border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                  />
                </div>
              )}

              {/* Description */}
              <div>
                <label className="block text-sm  text-gray-700 font-medium mb-2">
                  Description
                </label>
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Describe your requirements"
                  className="w-full px-3 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                ></textarea>
              </div>

              {/* Submit Button */}
            </div>
            <button
              type="submit"
              className=" bg-blue-600 mt-3 text-sm text-white font-medium py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring focus:ring-blue-300"
            >
              {loading ? <Spinner /> : "Submit Request"}
            </button>
          </form>
        </div>
      </div>

      <div className="overflow-x-auto p-4">
        <table className="min-w-full bg-white border border-gray-200 text-sm">
          <thead className="bg-gray-100 font-normal text-[12px]">
            <tr>
              <th className="px-4 py-2 border-b text-left">
                Franchise & Service Name
              </th>
              <th className="px-4 py-2 border-b text-left">
                Duration (Months)
              </th>
              <th className="px-4 py-2 border-b text-left">
                Amount & Discount
              </th>
              <th className="px-4 py-2 border-b text-left">
                Completion Time & Status
              </th>
              <th className="px-4 py-2 border-b text-left">Description</th>
              <th className="px-4 py-2 border-b text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {userservices?.map((service, index) => (
              <tr
                key={index}
                className="odd:bg-white even:bg-gray-50 text-[12px]"
              >
                <td className="px-4 py-2 border-b">
                  <div className="flex gap-3">
                    <img
                      src={`/uploads/InvestorData/${service?.companyLogo}`}
                      alt={`${service?.franchise_name} logo`}
                      className="w-10 h-10 object-cover rounded-full"
                    />
                    <div>
                      <span className="block font-medium">
                        {service?.franchise_name}
                      </span>
                      <span className="block text-gray-600">
                        Service : {service?.service}
                      </span>
                    </div>
                  </div>
                </td>
                <td className="px-4 py-2 border-b">{service?.duration}</td>
                <td className="px-4 py-2 border-b">
                  <span className="block">
                    {" "}
                    {service?.amount !== "0" ? `Rs. ${service?.amount}` : "N/A"}
                  </span>
                  <span className="block">Dis. : {service?.discount}</span>
                </td>
                <td className="px-4 py-2 border-b">
                  <span className="block">
                    {" "}
                    {service?.completionTime} months
                  </span>
                  <span className="block bg-emerald-100 px-2 py-1 capitalize text-center rounded-full">
                    {service?.status}
                  </span>
                </td>
                <td className="px-4 py-2 border-b">{service?.description}</td>
                <td className="px-4 py-2 border-b">
                  <div className="flex justify-center">
                    <button
                      disabled={service?.amount<=0}
                      onClick={() => handlePay(service?._id, service?.amount,service?.service)}
                      className="px-3 py-1 bg-indigo-500 hover:bg-indigo-600 text-white text-[12px] rounded-md"
                    >
                      Pay Amount
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {payId && <PayConfirmation id={payId} isClose={() => setpayId(false)} amount={payamount} service={service}/>}
    </>
  );
}
