// authSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


export const addCoupon = createAsyncThunk(
  "coupon/addCoupon",
  async (formData, thunkAPI) => {
    try {
      // Your asynchronous logic to authenticate user here
      const response = await fetch(
        "https://api.way4franchise.com/api/v1/coupon/add",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const editUser = createAsyncThunk(
  "auth/editUser",
  async (userData, thunkAPI) => {
    try {
      const response = await fetch(
        "https://api.way4franchise.com/api/v1/auth/edit/user",
        {
          method: "put",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userData),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const getAllCopupon = createAsyncThunk(
    "coupon/getAllCopupon",
    async (_, thunkAPI) => {
      try {
        const response = await fetch("https://api.way4franchise.com/api/v1/coupon");
  
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message);
        }
  
        const data = await response.json();
        return data;
      } catch (error) {
        // Handle error
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );

export const updateCoupon = createAsyncThunk(
    "coupon/updateCoupon",
    async ({ id, status }, thunkAPI) => {
      try {
        const response = await fetch(
          `https://api.way4franchise.com/api/v1/coupon/update`,
          {
            method: "PUT", 
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ id, status }), 
          }
        );
  
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message);
        }
        const data = await response.json();
        return { Id: id, message: data.message, updatedAmount: data.updatedAmount };
      } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  );
  
const initialState = {
  coupon: null,
  loading: false,
  error: null,
  message: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addCoupon.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addCoupon.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.message = action.payload.message;
      })
      .addCase(addCoupon.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
      .addCase(getAllCopupon.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllCopupon.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.coupon = action.payload.coupon;
      })
      .addCase(getAllCopupon.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
      .addCase(updateCoupon.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCoupon.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.message = action.payload.message;
      })
      .addCase(updateCoupon.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
     
  },
});

export const { clearErrors, clearMessage } = authSlice.actions;

export default authSlice.reducer;
