

import React from "react";
import Header from "../Comman/Header";
import Footer from "../Comman/Footer";

const franchiseNews = [
    {
      title: "Fast Food Giant Expands into Emerging Markets",
      description: "Popular burger chain FoodMaster announces plans to open 100 new franchises across Southeast Asia in the next two years.",
    },
    {
      title: "Tech Startup Launches Innovative Franchise Model",
      description: "TechNow introduces a hybrid franchise system combining traditional storefronts with e-commerce integration.",
    },
    {
      title: "Fitness Franchise Sees Post-Pandemic Boom",
      description: "GymFit reports a 30% increase in new franchise applications as health-conscious consumers return to gyms.",
    },
    {
      title: "Sustainability-Focused Franchise Gains Traction",
      description: "EcoClean, an eco-friendly cleaning service, doubles its franchise locations in response to growing demand for green businesses.",
    },
    {
      title: "Franchise Expo 2024 Announces Record Attendance",
      description: "The annual International Franchise Expo sees a 25% increase in attendees, signaling strong interest in franchise opportunities.",
    },
    {
      title: "New Legislation Impacts Franchise Agreements",
      description: "Recent changes in labor laws prompt franchisors to review and update their franchise agreements nationwide.",
    },
    {
      title: "Home Services Franchise Introduces AI-Powered Scheduling",
      description: "HandyPro unveils a new AI system to optimize service scheduling and improve customer satisfaction across its franchise network.",
    },
    {
      title: "Education Franchise Adapts to Online Learning Trend",
      description: "TutorTime launches a hybrid learning model, combining in-person and virtual tutoring options for its franchisees.",
    },
    {
      title: "Restaurant Franchise Innovates with Ghost Kitchens",
      description: "FoodFusion partners with delivery apps to help franchisees expand reach through virtual restaurant concepts.",
    },
    {
      title: "Franchise Financing Options Expand",
      description: "Major banks announce new loan programs tailored specifically for franchise businesses, easing access to capital for entrepreneurs.",
    },
  ];
  
const News = () => {
  return (
    <>
    <Header/>
    <div className="max-w-full lg:mx-10 mx-2 px-2 pt-10">
      <h2 className="text-3xl font-semibold mb-1">
      Franchise News
      </h2>
      <p className="text-base mb-4">Latest Developments in the Franchise World</p>
      <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 ">
        {franchiseNews.map((news, index) => (
          <div
            key={index}
            className="bg-white p-6 rounded-lg border shadow-sm hover:shadow-lg transition-shadow duration-300"
          >
            <h3 className="text-xl font-semibold mb-3 text-gray-800">
              {news.title}
            </h3>
            <p className="text-gray-600">{news.description}</p>
          </div>
        ))}
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default News;
