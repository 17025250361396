import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const updateLeadsDetail = createAsyncThunk(
    "leads/updateLeadsDetail",
    async ({userId,franchise_id}, thunkAPI) => {
      try {
        const response = await fetch("https://api.way4franchise.com/api/v1/lead/user", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({userId,franchise_id}),
        });
  
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message);
        }
  
        const data = await response.json();
        return data;
      } catch (error) {
        // Handle error
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );
export const getUserLeadById = createAsyncThunk(
    "leads/getUserLeadById",
    async (userId, thunkAPI) => {
      try {
        const response = await fetch(`https://api.way4franchise.com/api/v1/lead/user/${userId}`);
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message);
        }
  
        const data = await response.json();
       
        return data;
      } catch (error) {
        // Handle error
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );
  export const addLeads = createAsyncThunk(
    "leads/addLeads",
    async ({userId,leads}, thunkAPI) => {
      try {
        const response = await fetch("https://api.way4franchise.com/api/v1/lead/adduserleads", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({userId,leads}),
        });
  
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message);
        }
  
        const data = await response.json();
        return data;
      } catch (error) {
        // Handle error
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );

  export const updateInvestorLeads = createAsyncThunk(
    "leads/updateInvestorLeads",
    async ({franchise_id,userId}, thunkAPI) => {
      try {
        const response = await fetch("https://api.way4franchise.com/api/v1/lead/inverstor", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({franchise_id,userId}),
        });
  
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message);
        }
  
        const data = await response.json();
        return data;
      } catch (error) {
        // Handle error
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );
  export const getInvestorLeadById = createAsyncThunk(
    "leads/getInvestorLeadById",
    async (franchise_id, thunkAPI) => {
      try {
        const response = await fetch(`https://api.way4franchise.com/api/v1/lead/inverstor/${franchise_id}`);
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message);
        }
  
        const data = await response.json();
       
        return data;
      } catch (error) {
        // Handle error
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );
  export const addInvestorLeads = createAsyncThunk(
    "leads/addInvestorLeads",
    async ({franchise_id,leads}, thunkAPI) => {
      try {
        const response = await fetch("https://api.way4franchise.com/api/v1/lead/addinvestorleads", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({franchise_id,leads}),
        });
  
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message);
        }
  
        const data = await response.json();
        return data;
      } catch (error) {
        // Handle error
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );

  export const getHotLeads = createAsyncThunk(
    "leads/getHotLeads",
    async (franchise_id, thunkAPI) => {
      try {
        const response = await fetch(`https://api.way4franchise.com/api/v1/lead/hotleads/${franchise_id}`);
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message);
        }
  
        const data = await response.json();
       
        return data;
      } catch (error) {
        // Handle error
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );


  export const buyLeads = createAsyncThunk(
    "pay/buyLeads",
    async (LeadData, thunkAPI) => {
      try {
        // Your asynchronous logic to update student here
        const response = await fetch(`https://api.way4franchise.com/api/v1/lead/buy`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(LeadData),
        });
  
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message);
        }
  
        const data = await response.json();
  
        return data;
      } catch (error) {
        // Handle error
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );
  const initialState = {
    singleuserlead: null,
    singleinvestorlead: null,
    hotleads: null,
    loading: false,
    error: null,
    message: null,
  };
  
  const leadUpdateSlice = createSlice({
    name: "leads",
    initialState,
    reducers: {
      clearErrors: (state) => {
        state.error = null;
      },
      clearMessage: (state) => {
        state.message = null;
      },
    },
    extraReducers: (builder) => {
      builder
      .addCase(updateLeadsDetail.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateLeadsDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.message = action.message;
      })
      .addCase(updateLeadsDetail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
      .addCase(getUserLeadById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserLeadById.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.singleuserlead = action.payload.singleuserlead;
      })
      .addCase(getUserLeadById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
      .addCase(addLeads.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addLeads.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.message = action.message;
      })
      .addCase(addLeads.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })

      .addCase(updateInvestorLeads.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateInvestorLeads.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.message = action.message;
      })
      .addCase(updateInvestorLeads.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
      .addCase(getInvestorLeadById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getInvestorLeadById.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.singleinvestorlead = action.payload.singleinvestorlead;
      })
      .addCase(getInvestorLeadById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
      .addCase(addInvestorLeads.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addInvestorLeads.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.message = action.message;
      })
      .addCase(addInvestorLeads.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
      .addCase(getHotLeads.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getHotLeads.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.hotleads = action.payload.hotleads;
      })
      .addCase(getHotLeads.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
      .addCase(buyLeads.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(buyLeads.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.message = action.payload.message;
      })
      .addCase(buyLeads.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
    },
});

export const { clearErrors, clearMessage } = leadUpdateSlice.actions;

export default leadUpdateSlice.reducer;