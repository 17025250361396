import React, { useState, useEffect } from "react";
import {  State, City } from "country-state-city";
import industriesData  from "../../BaseFile/CategoryData"
import { useNavigate } from "react-router-dom";

const SearchTabs = () => {
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState("Categories");
  const [selectedIndustry, setSelectedIndustry] = useState();
  const [selectedSector, setSelectedSector] = useState();
  const [selectedService, setSelectedService] = useState();
  const [investmentRange, setInvestmentRange] = useState();
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
      const countryStates = State.getStatesOfCountry("IN");
      setStates(countryStates);
      setCities([]);

    if (selectedState) {
      const stateCities = City.getCitiesOfState("IN", selectedState);
      setCities(stateCities);
      setSelectedCity("");
    }
  }, [selectedState]);

  const openTab = (tabName) => {
    setActiveTab(tabName);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (activeTab === "Categories" ) {
      if(selectedService){
      navigate(`/search-result/?tab=Categories&industry=${selectedIndustry}&sector=${selectedSector }&service=${selectedService}`)
    }
      else if(selectedSector){
        navigate(`/search-result/?tab=Categories&industry=${selectedIndustry}&sector=${selectedSector }`)
      }
      else if(selectedIndustry){
        navigate(`/search-result/?tab=Categories&industry=${selectedIndustry}`)
      }
    } 
    else if (activeTab === "Location" ) {
      if(selectedCity){
        navigate(`/search-result/?tab=Location&state=${selectedState}&city=${selectedCity}`);
      }
      else if(selectedState){
        navigate(`/search-result/?tab=Location&state=${selectedState}`);
      }
    } 
    else if (activeTab === "Industry" ) {
      if(investmentRange){
        navigate(`/search-result/?tab=Industry&industry=${ selectedIndustry}&investmentRange=${investmentRange}`);
      }
      else if(selectedIndustry){
        navigate(`/search-result/?tab=Industry&industry=${ selectedIndustry}`);
      }
    } 
    else {
      navigate("/");
    }
  };
  

  return (
    <div className="z-50 lg:max-w-6xl p-4 sm:max-w-6xl max-w-full lg:mx-auto  lg:py-8 lg:px-16  sm:p-5 shadow-2xl relative -top-[69px] bg-gray-800 rounded-2xl">
      <form onSubmit={handleSubmit}>
        <div className="flex flex-wrap space-x-4 mb-4">
          {["Categories", "Location", "Industry"].map((tab) => (
            <label
              key={tab}
              className="inline-flex items-center space-x-4 text-[13px] cursor-pointer"
            >
              <input
                type="radio"
                name="tabs"
                value={tab}
                checked={activeTab === tab}
                onChange={() => openTab(tab)}
                className="form-radio h-4 w-4 text-gray-100 focus:ring-[#fdc39f]"
              />
              <span
                className={`font-medium text-gray-100 ${
                  activeTab === tab ? "text-gray-100" : ""
                }`}
              >
                {tab}
              </span>
            </label>
          ))}
        </div>

        <div
          id="Categories"
          className={`tabcontent ${activeTab === "Categories" ? "" : "hidden"}`}
        >
          <div className="lg:flex sm:flex justify-between gap-2">
            <select
              className="w-full px-3 py-2 text-[13px] mb-3 border border-gray-300 rounded-lg text-black"
              value={selectedIndustry}
              onChange={(e) => setSelectedIndustry(e.target.value)}
            >
              <option>Select Industry</option>
              {Object.keys(industriesData)?.map((industry) => (
                <option key={industry} value={industry}>
                  {industry}
                </option>
              ))}
            </select>
            <select
              className="w-full px-3 py-2 text-[13px] mb-3 border border-gray-300 rounded-lg text-black"
              value={selectedSector}
              onChange={(e) => setSelectedSector(e.target.value)}
              disabled={selectedIndustry === "Select Industry"}
            >
              <option>Select Sector</option>
              {selectedIndustry !== "Select Industry" &&
                industriesData[selectedIndustry]?.sectors?.map((sector) => (
                  <option key={sector} value={sector}>
                    {sector}
                  </option>
                ))}
            </select>
            <select
              className="w-full px-3 py-2 border mb-3 text-[13px] border-gray-300 rounded-lg text-black"
              value={selectedService}
              onChange={(e) => setSelectedService(e.target.value)}
              disabled={selectedSector === "Select Sector"}
            >
              <option>Select Service/Product</option>
              {selectedSector !== "Select Sector" &&
                industriesData[selectedIndustry]?.products?.map((product) => (
                  <option key={product} value={product}>
                    {product}
                  </option>
                ))}
            </select>
            <button
              type="submit"
              className="px-3 py-2 lg:w-36 sm:w-36 w-16 mb-3 text-[13px] bg-[#f0ecea] text-gray-900 flex justify-center items-center rounded-lg"
            >
              <svg
                className="h-6 w-6 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"></path>
              </svg>
            </button>
          </div>
        </div>

        <div
          id="Location"
          className={`tabcontent ${activeTab === "Location" ? "" : "hidden"}`}
        >
          <div className="lg:flex sm:flex justify-between gap-2">
            <select
              className="w-full px-3 py-2 border text-[13px] mb-3 rounded-lg border-gray-300 text-black"
              value="IN"
            >
              <option>INDIA</option>
              
            </select>

            <select
              className="w-full px-3 py-2 text-[13px] border mb-3 rounded-lg border-gray-300 text-black"
              value={selectedState}
              onChange={(e) => setSelectedState(e.target.value)}
              disabled={!states.length}
            >
              <option>Select a State</option>
              {states.map((state) => (
                <option key={state.isoCode} value={state.isoCode}>
                  {state.name}
                </option>
              ))}
            </select>

            <select
              className="w-full px-3 py-2 text-[13px] border mb-3 rounded-lg border-gray-300 text-black"
              value={selectedCity}
              onChange={(e) => setSelectedCity(e.target.value)}
              disabled={!cities.length}
            >
              <option>Select a City</option>
              {cities.map((city) => (
                <option key={city.name} value={city.name}>
                  {city.name}
                </option>
              ))}
            </select>

            <button
              type="submit"
              className="px-3 py-2 lg:w-36 sm:w-36 w-16 mb-3 bg-[#f0ecea] text-[13px] text-gray-900 flex justify-center items-center rounded-lg"
            >
              <svg
                className="h-6 w-6 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"></path>
              </svg>
            </button>
          </div>
        </div>

        <div
          id="Industry"
          className={`tabcontent ${activeTab === "Industry" ? "" : "hidden"}`}
        >
          <div className="lg:flex sm:flex justify-between gap-2">
            <select
              className="w-full px-3 py-2 text-[13px] mb-3 border border-gray-300 rounded-lg text-black"
              value={selectedIndustry}
              onChange={(e) => setSelectedIndustry(e.target.value)}
            >
              <option>Select Industry</option>
              {Object.keys(industriesData)?.map((industry) => (
                <option key={industry} value={industry}>
                  {industry}
                </option>
              ))}
            </select>
            <select
              className="w-full px-3 py-2 border mb-3 text-[13px] rounded-lg border-gray-300 text-black"
              value={investmentRange}
              onChange={(e) => setInvestmentRange(e.target.value)}
            >
              <option>Select Min Investment</option>
              <option>$1,000</option>
              <option>$5,000</option>
              <option>$10,000</option>
              <option>$20,000</option>
            </select>
            
            <button
              type="submit"
              className="px-3 py-2 mb-3 lg:w-36 sm:w-36 w-16 bg-[#f0ecea] text-gray-900 text-[13px] flex justify-center items-center rounded-lg"
            >
              <svg
                className="h-6 w-6 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"></path>
              </svg>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
export default SearchTabs;
