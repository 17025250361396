import React from "react";
import Header from "../Comman/Header";
import Footer from "../Comman/Footer";

const Terms = () => {
  return (
    <>
      <Header />
      <div className="w-7xl mx-10 pt-10">
        <div>
          <h1 className="text-2xl uppercase font-semibold mb-4 ">
            Terms of Use
          </h1>
        </div>
        <div className="grid grid-cols-12 gap-3">
          <div className="lg:col-span-8 col-span-12">
            <div className="border shadow-md rounded-md">
              <div className="border-b bg-gray-200 py-2">
                <h5 className="text-lg font-semibold  px-4 capitalize">
                  {" "}
                  Acceptance of Terms
                </h5>
              </div>
              <p className="text-gray-700 px-4 py-3">
                Welcome to Wayforfranchise. By accessing and using this website,
                you accept and agree to be bound by the terms and provision of
                this agreement. If you do not agree to abide by the above,
                please do not use this service.
              </p>
            </div>
            <div className="border shadow-md rounded-md mt-4">
              <div className="border-b bg-gray-200 py-2">
                <h5 className="text-lg font-semibold  px-4 capitalize">
                  {" "}
                  Description of Service
                </h5>
              </div>
              <p className="text-gray-700 px-4 py-3">
                Wayforfranchise provides an online platform connecting potential
                franchisees with franchise opportunities and related services.
                We do not guarantee the accuracy, completeness, or usefulness of
                any information on the site and will not be liable for any
                reliance placed on such information.
              </p>
            </div>
            <div className="border shadow-md rounded-md mt-4">
              <div className="border-b bg-gray-200 py-2">
                <h5 className="text-lg font-semibold  px-4 capitalize">
                  {" "}
                  Registration and Account Security
                </h5>
              </div>
              <p className="text-gray-700 px-4 py-3">
                To access certain features of the site, you may be required to
                register for an account. You agree to provide accurate, current,
                and complete information during the registration process and to
                update such information to keep it accurate, current, and
                complete. You are responsible for safeguarding the password that
                you use to access the service and for any activities or actions
                under your password.
              </p>
            </div>
            <div className="border shadow-md rounded-md mt-4">
              <div className="border-b bg-gray-200 py-2">
                <h5 className="text-lg font-semibold  px-4 capitalize">
                  {" "}
                  Content
                </h5>
              </div>
              <p className="text-gray-700 px-4 py-3">
                Our service allows you to post, link, store, share and otherwise
                make available certain information, text, graphics, or other
                material ("Content"). You are responsible for the Content that
                you post on or through the service, including its legality,
                reliability, and appropriateness.
              </p>
            </div>
            <div className="border shadow-md rounded-md mt-4">
              <div className="border-b bg-gray-200 py-2">
                <h5 className="text-lg font-semibold  px-4 capitalize">
                  {" "}
                  Intellectual Property
                </h5>
              </div>
              <p className="text-gray-700 px-4 py-3">
                The service and its original content (excluding Content provided
                by users), features, and functionality are and will remain the
                exclusive property of Wayforfranchise and its licensors. The
                service is protected by copyright, trademark, and other laws of
                both the United States and foreign countries.
              </p>
            </div>
            <div className="border shadow-md rounded-md mt-4">
              <div className="border-b bg-gray-200 py-2">
                <h5 className="text-lg font-semibold  px-4 capitalize">
                  {" "}
                  Links To Other Web Sites
                </h5>
              </div>
              <p className="text-gray-700 px-4 py-3">
                Our service may contain links to third-party websites or
                services that are not owned or controlled by Wayforfranchise. We
                have no control over, and assume no responsibility for, the
                content, privacy policies, or practices of any third-party
                websites or services.
              </p>
            </div>
            <div className="border shadow-md rounded-md mt-4">
              <div className="border-b bg-gray-200 py-2">
                <h5 className="text-lg font-semibold  px-4 capitalize">
                  {" "}
                  Termination
                </h5>
              </div>
              <p className="text-gray-700 px-4 py-3">
                We may terminate or suspend your account and bar access to the
                service immediately, without prior notice or liability, under
                our sole discretion, for any reason whatsoever and without
                limitation, including but not limited to a breach of the Terms.{" "}
              </p>
            </div>
            <div className="border shadow-md rounded-md mt-4">
              <div className="border-b bg-gray-200 py-2">
                <h5 className="text-lg font-semibold  px-4 capitalize">
                  {" "}
                  Limitation of Liability
                </h5>
              </div>
              <p className="text-gray-700 px-4 py-3">
                In no event shall Wayforfranchise, nor its directors, employees,
                partners, agents, suppliers, or affiliates, be liable for any
                indirect, incidental, special, consequential or punitive
                damages, including without limitation, loss of profits, data,
                use, goodwill, or other intangible losses, resulting from your
                access to or use of or inability to access or use the service.
              </p>
            </div>
            <div className="border shadow-md rounded-md mt-4">
              <div className="border-b bg-gray-200 py-2">
                <h5 className="text-lg font-semibold  px-4 capitalize">
                  {" "}
                  Disclaimer
                </h5>
              </div>
              <p className="text-gray-700 px-4 py-3">
                Your use of the service is at your sole risk. The service is
                provided on an "AS IS" and "AS AVAILABLE" basis. The service is
                provided without warranties of any kind, whether express or
                implied, including, but not limited to, implied warranties of
                merchantability, fitness for a particular purpose,
                non-infringement or course of performance.
              </p>
            </div>
            <div className="border shadow-md rounded-md mt-4">
              <div className="border-b bg-gray-200 py-2">
                <h5 className="text-lg font-semibold  px-4 capitalize">
                  {" "}
                  Changes
                </h5>
              </div>
              <p className="text-gray-700 px-4 py-3">
                We reserve the right, at our sole discretion, to modify or
                replace these Terms at any time. If a revision is material we
                will provide at least 30 days' notice prior to any new terms
                taking effect. What constitutes a material change will be
                determined at our sole discretion.
              </p>
            </div>
          </div>
          <div className="lg:col-span-4 col-span-12">
            <div className="border shadow-md rounded-md">
              <div className="border-b bg-gray-200 py-2">
                <h5 className="text-lg font-semibold  px-4 capitalize">
                  {" "}
                  User Conduct
                </h5>
              </div>
              <ul className="px-10 py-4 list-disc ">
                <li className="py-1 text-justify">
                  For any unlawful purpose or to solicit the performance of any
                  illegal activity.
                </li>
                <li className="py-1 text-justify">
                  To impersonate other users or persons.
                </li>
                <li className="py-1 text-justify">
                  To upload or transmit viruses or any other type of malicious
                  code.
                </li>
                <li className="py-1 text-justify">
                  To interfere with or disrupt the integrity or performance of
                  the service.
                </li>
                <li className="py-1 text-justify">
                  To collect or track the personal information of others.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Terms;
