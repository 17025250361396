import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBuyDetailUserId } from '../redux/ownFranchiseSlice';
import getCityState from '../BaseFile/getCityState';
import { Link } from 'react-router-dom';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function UserOwnFranchise() {
  const dispatch = useDispatch();
  const { user_own_franchise, loading, error, message } = useSelector((state) => state.ownFranchises);
  const { auth } = useSelector((state) => state.auth);
  const [searchQuery, setSearchQuery] = useState('');
  const [allFranchise, setAllFranchise] = useState('');
  const [cityStateData, setCityStateData] = useState({}); // To store city and state for each franchise

  useEffect(() => {
    if (auth?._id) {
      const userId = auth?._id;
      dispatch(fetchBuyDetailUserId(userId));
    }
  }, [auth?._id]);

  useEffect(() => {
    setAllFranchise(
      user_own_franchise?.filter((item) =>
        item?.franchiseId?.franchise_name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, user_own_franchise]);

  useEffect(() => {
    // Fetch city and state for each franchise based on pincode
    const fetchCityStateData = async () => {
      const data = {};
      for (const buy of user_own_franchise) {
        if (buy?.pincode) {
          const cityState = await getCityState(buy.pincode);
          data[buy.pincode] = cityState || { city: 'N/A', state: 'N/A' }; // Fallback to 'N/A' if not found
        }
      }
      setCityStateData(data); // Update the state with city/state info for all franchises
    };
    if (user_own_franchise?.length > 0) {
      fetchCityStateData();
    }
  }, [user_own_franchise]);

  return (
    <div className="p-4">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className=" font-semibold leading-6 text-gray-900">Detail</h1>
          <p className="mt-1  text-gray-700">
            A list of all the companies in your account including their name, title, email and role.
          </p>
        </div>
        <div className="mt-4 sm:flex-none">
          <input
            type="search"
            onChange={(e) => setSearchQuery(e.target.value)}
            className="border px-2 py-1.5 text-[12px] rounded-md border-gray-400"
            placeholder="Search"
          />
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="relative">
              <div className="overflow-x-auto rounded-lg shadow-md">
                <table className="min-w-full text-[12px] table-auto divide-y divide-gray-300 rounded-lg">
                  <thead className="bg-gray-900 text-white">
                    <tr>
                      <th scope="col" className="min-w-[12rem] py-3.5 pr-3 text-left font-semibold text-gray-100 text-[12px] uppercase px-3">
                        Franchise Name
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left font-semibold text-gray-100 text-[13px] uppercase">
                        Type
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left font-semibold text-gray-100 text-[13px] uppercase">
                        Investment
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left font-semibold text-gray-100 text-[13px] uppercase">
                        Address
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left font-semibold text-gray-100 text-[13px] uppercase">
                        State
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left font-semibold text-gray-100 text-[13px] uppercase">
                        City
                      </th>
                      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3 text-gray-100 text-[13px] uppercase">
                        At
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white px-2">
                    {(searchQuery ? allFranchise : user_own_franchise)?.map((buy, index) => (
                      <tr key={index}>
                        <td className={classNames('px-2 whitespace-nowrap py-4 pr-3 font-medium', 'text-indigo-600')}>
                          <Link to={`/user/franchise/profile/${buy?.franchiseId?._id}`}>{buy?.franchiseId?.franchise_name}</Link>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-gray-500">{buy?.franchiseId?.looking_for}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-gray-500">{buy?.investment}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-gray-500">{buy?.address}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-gray-500">
                          {cityStateData[buy?.pincode]?.state || 'Loading...'}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-gray-500">
                          {cityStateData[buy?.pincode]?.city || 'Loading...'}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right font-medium sm:pr-3">
                          {buy?.createdAt}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
