// usersSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


export const setContainer = createAsyncThunk(
  "Plan/setContainer",
  async (containerAssignments, thunkAPI) => {

    try {      
      const response = await fetch(`https://api.way4franchise.com/api/v1/home/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(containerAssignments),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();

      return data;
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getContainer = createAsyncThunk(
  "staff/getAllUserPlan",
  async (_, thunkAPI) => {
    try {
      const response = await fetch("https://api.way4franchise.com/api/v1/home");

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getHomeDetailByid = createAsyncThunk(
  "staff/getHomeDetailByid",
  async ({_id}, thunkAPI) => {
    try {
      const response = await fetch(`https://api.way4franchise.com/api/v1/home/${_id}`);

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const initialState = {
  containers:null,
  HomeDetail:null,
  loading: false,
  error: null,
  message: null,
};

const homeManageSlice = createSlice({
  name: "homemanage",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setContainer.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(setContainer.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.message = action.payload.message;
      })
      .addCase(setContainer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
      .addCase(getContainer.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getContainer.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.containers = action.payload.containers;
      })
      .addCase(getContainer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
      .addCase(getHomeDetailByid.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getHomeDetailByid.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.HomeDetail = action.payload.HomeDetail;
      })
      .addCase(getHomeDetailByid.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
     
  },
});

export const { clearErrors, clearMessage } = homeManageSlice.actions;

export default homeManageSlice.reducer;

