// import React, { useEffect, useState } from 'react';



// const SliderSection = () => {
//   const [currentIndex, setCurrentIndex] = useState(0);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       nextSlide();
//     }, 5000);
//     return () => clearInterval(interval);
//   }, [currentIndex]);

//   const nextSlide = () => {
//     setCurrentIndex((prevIndex) => (prevIndex + 1) % slideData.length);
//   };
  

//   const previousSlide = () => {
//     setCurrentIndex(
//       (prevIndex) => (prevIndex - 1 + slideData.length) % slideData.length
//     );
//   };

//   return (
//     <>
//     <div className=" container mx-auto h-screen relative">
//       <div className="left-col w-full h-full relative overflow-hidden">
//         {slideData.map((slide, index) => (
//           <div
//             key={index}
//             className={`absolute top-0 left-0 w-full h-full bg-center bg-cover transition-all duration-1000 ${
//               currentIndex === index
//                 ? 'opacity-100  '
//                 : 'opacity-0 translate-y-full'
//             }`}
//             style={{ backgroundImage: `url(${slide.src})` }}
//           ></div>
//         ))}
//         <div className="caption absolute top-1/2 left-4 transform -translate-y-1/2 z-30">
//           <div className="caption-heading transition-all duration-700 ease-in-out">
//             <h1 className="text-2xl md:text-3xl font-light uppercase mb-6 tracking-widest text-white">
//               {slideData[currentIndex].title}
//             </h1>
//           </div>
//           <div className="caption-subhead text-white uppercase tracking-wider mb-8">
//             {slideData[currentIndex].copy}
//           </div>
//         </div>
//       </div>

//       {/* Hide Navigation buttons */}
//       <ul className="nav hidden">
//         <li className="slide-up">
//           <button onClick={previousSlide} aria-label="Previous slide">
//             {'<'}
//           </button>
//         </li>
//         <li className="slide-down">
//           <button onClick={nextSlide} aria-label="Next slide">
//             {'>'}
//           </button>
//         </li>
//       </ul>
//     </div>
   
//      </>
//   );
// };

// export default SliderSection;




import React, { useEffect, useState } from 'react';
const slideData = [
  {
    src: '/franchiseslider.png',
    // title: 'Find Your Perfect Franchise Among 10,000+ Options',
    // copy: 'Join the World’s Most Visited Franchise Network',
  },
  {
    src: '/franchiseslider1.png',
    // title: 'Find Your Perfect Franchise Among 10,000+ Options',
    // copy: 'Join the World’s Most Visited Franchise Network',
  },
  {
    src: '/franchise2.png',
    // title: 'Find Your Perfect Franchise Among 10,000+ Options',
    // copy: 'Join the World’s Most Visited Franchise Network',
  },
];
// const slideData = [
//   {
//     src: 'one.png',
//     // title: 'Find Your Perfect Franchise Among 10,000+ Options',
//     // copy: 'Join the World’s Most Visited Franchise Network',
//   },
//   {
//     src: 'one.png',
//     // title: 'Find Your Perfect Franchise Among 10,000+ Options',
//     // copy: 'Join the World’s Most Visited Franchise Network',
//   },
//   {
//     src: 'one.png',
//     // title: 'Find Your Perfect Franchise Among 10,000+ Options',
//     // copy: 'Join the World’s Most Visited Franchise Network',
//   },
// ];

const slideitem = [
  {
    src: '/rpv1.png',
    // title: 'Find Your Perfect Franchise Among 10,000+ Options',
    // copy: 'Join the World’s Most Visited Franchise Network',
  },
  {
    src: '/rpv2.png',
    // title: 'Find Your Perfect Franchise Among 10,000+ Options',
    // copy: 'Join the World’s Most Visited Franchise Network',
  },
  {
    src: '/rpv3.png',
    // title: 'Find Your Perfect Franchise Among 10,000+ Options',
    // copy: 'Join the World’s Most Visited Franchise Network',
  },
];

const SliderSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000);
    return () => clearInterval(interval);
  }, [currentIndex]);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slideData.length);
  };

  const previousSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + slideData.length) % slideData.length
    );
  };

  return (
    <>
      {/* Desktop Slider */}
      <div className="hidden lg:flex ">
        <div className="container mx-auto h-[450px] relative">
          <div className="left-col w-full h-full relative overflow-hidden">
            {slideData.map((slide, index) => (
              <div
                key={index}
                className={`absolute top-0 left-0 w-full h-full bg-center bg-cover transition-all duration-1000 ${
                  currentIndex === index
                    ? 'opacity-100'
                    : 'opacity-0 translate-y-full'
                }`}
                style={{ backgroundImage: `url(${slide.src})` }}
              ></div>
            ))}
            <div className="caption absolute top-1/2 left-4 transform -translate-y-1/2 z-30">
              <div className="caption-heading transition-all duration-700 ease-in-out">
                <h1 className="text-2xl md:text-3xl font-light uppercase mb-6 tracking-widest text-white">
                  {slideData[currentIndex].title}
                </h1>
              </div>
              <div className="caption-subhead text-white uppercase tracking-wider mb-8">
                {slideData[currentIndex].copy}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Slider */}
      <div className="lg:hidden  flex z-10">
  <div className="container mx-auto h-[440px] relative">
    <div className="left-col w-full min-h-[400px] h-full relative overflow-hidden">
      {slideitem.map((slide, index) => (
        <div
          key={index}
          className={`absolute top-0 left-0 w-full h-full z-10  transition-all duration-1000 ${
            currentIndex === index
              ? 'opacity-100'
              : 'opacity-0 translate-y-full'
          }`}
          style={{
            backgroundImage: `url(${slide.src})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '100%',
          }}
        ></div>
      ))}
      <div className="caption absolute top-1/2 left-4 transform -translate-y-1/2 z-30">
        <div className="caption-heading transition-all duration-700 ease-in-out">
          <h1 className="text-2xl md:text-3xl font-light uppercase mb-6 tracking-widest text-white">
            {slideitem[currentIndex].title}
          </h1>
        </div>
        <div className="caption-subhead text-white uppercase tracking-wider mb-8">
          {slideitem[currentIndex].copy}
        </div>
      </div>
    </div>
  </div>
</div>


    </>
  );
};

export default SliderSection;

