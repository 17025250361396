import React from "react";
import {Link} from "react-router-dom" 
import { Ads } from "../../AdsComponents/Ads";
const cardsData = [
  {
    id: 1,
    price: "101,000 INR",
    address: "Green Energy Corp",
    imgSrc: "/Zirakpur.png",
  },
  {
    id: 2,
    price: "200,000 INR",
    address: "Health Solutions LLC",
    imgSrc: "/card1.jpeg",
  },
  {
    id: 3,
    price: "190,000 INR",
    address: "EduTech Systems",
    imgSrc: "/logo10.png",
  },
  {
    id: 4,
    price: "100,000 INR",
    address: "FinTech Global",
    imgSrc: "/logo11.png",
  },
  {
    id: 5,
    price: "90,000 INR",
    address: "AgriGrowth Industries",
    imgSrc: "/logo12.png",
  },
  {
    id: 6,
    price: "210,000 INR",
    address: "SolarTech Enterprises",
    imgSrc: "/logo13.png",
  },
  {
    id: 7,
    price: "270,000 INR",
    address: "CyberSecure Networks",
    imgSrc: "/logo14.png",
  },
  {
    id: 8,
    price: "100,000 INR",
    address: "EcoTransport Inc",
    imgSrc: "/logo10.png",
  },
];

const properties = [
  {
    id: 1,
    imageUrl: "/card1.jpeg",
    price: " 140,000 INR",
    address: "Tech Innovations Ltd"
  },
  {
    id: 2,
    imageUrl: "/logo35.png",
    price: "440,000 INR",
    address: "NanoTech Solutions"
  },
  {
    id: 3,
    imageUrl: "/logo34.jpg",
    price: "260,000 INR",
    address: "NanoTech Solutions"
  },
  {
    id: 4,
    price: "300,000 INR",
    address: "EduTech Systems",
    imageUrl: "/logo32.png",
  },
  {
    id: 5,
    price: "20,000 INR",
    address: "Health Solutions LLC",
    imageUrl: "/logo31.png",
  },
  {
    id: 6,
    price: "540,000 INR",
    address: "Green Energy Corp",
    imageUrl: "/logo30.png",
  },
  
];
const TopFranchise = () => {
  return (
    <>
      <div className="max-w-full mx-10 mt-16  ">
        <div className="w-full">
          <div className="w-full pl-5 lg:pl-2 mb-4 ">
          <div className="border-b  flex justify-between text-sm ">
            <div className="text-indigo-600 flex items-center pb-2 pr-2 border-b-2 border-indigo-600 capitalize">
              <h2 className="font-semibold  lg:text-2xl sm:text-xl text-lg text-gray-800 inline-block">
              Other Important Franchising Business Opportunities
              </h2>
            </div>
            <Link to="/viewall?heading=Other Important Franchising" className="text-base text-blue-500">
              See All
            </Link>
          </div>
        </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
      {/* Card Grid Section */}
      <div className="col-span-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 h-full">
        {cardsData.map((card) => (
          <a
            key={card.id}
            href="#"
            className="block rounded-lg p-4 shadow-sm shadow-indigo-100 border"
          >
            <img
              alt="property"
              src={card.imgSrc}
              className="h-36 w-full rounded-md object-cover"
            />
            <div className="mt-2">
              <dl> 
                <div>
                  <dt className="sr-only">Price</dt>
                  <dd className="text-[12px] text-gray-500">{card.price}</dd>
                </div>
                <div>
                  <dt className="sr-only">Address</dt>
                  <dd className="font-medium text-sm">{card.address}</dd>
                </div>
              </dl>
            </div>
          </a>
        ))}
      </div>

      {/* Image Section */}
      <div className="grid grid-cols-1 p-4 rounded-lg  border">
        <div className="text-center w-full relative">
        <Ads adnum={3}/>
        </div>
      </div>
    </div>

    <div className="h-full mt-4">
      <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-6 gap-4">
        {properties.map((property) => (
          <a
            key={property.id}
            href="#"
            className="block rounded-lg p-4 shadow-sm shadow-indigo-100 border"
          >
            <img
              alt=""
              src={property.imageUrl}
              className="h-36 w-full rounded-md object-cover"
            />
            <div className="mt-2">
              <dl>
                <div>
                  <dt className="sr-only">Price</dt>
                  <dd className="text-[12px] text-gray-500">{property.price}</dd>
                </div>
                <div>
                  <dt className="sr-only">Address</dt>
                  <dd className="font-medium text-sm">{property.address}</dd>
                </div>
              </dl>
              <div className="mt-6 flex items-center gap-8 text-xs"></div>
            </div>
          </a>
        ))}
      </div>
    </div>
        </div>
      </div>
    </>
  );
};

export default TopFranchise;
