import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllFranchisePayments,
} from "../redux/paymentSlice";
import Loader from "../BaseFile/Loader";
export default function CompanyKycRequest() {
  const dispatch = useDispatch();
  const { allpayments, loading } = useSelector(
    (state) => state.buyPayment
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [Allpayments,setAllpayments]=useState("")

  useEffect(() => {
    dispatch(getAllFranchisePayments());
  }, [dispatch]);

    useEffect(() => {
        setAllpayments(
            allpayments?.filter((item) =>
          item?.franchise_name?.toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    }, [searchQuery]);

  return (
    <>
     
      {loading ? (
        <Loader />
      ) : (
        <div className="bg-white shadow-sm rounded-sm border px-4 py-5">
          <div className="sm:flex sm:items-center ">
            <div className="sm:flex-auto">
                <h1 className="text-base capitalize font-semibold text-gray-800">
                  Investor Payment History
                </h1>
              <p className="mt-2 text-sm text-gray-700">
                A list of all the Investor Payment History in your account including their
                name, title, email, and role.
              </p>
            </div>
            <div className="flex gap-5 mt-4 sm:ml-16 sm:mt-6 ">
              <input
                type="search"
                onChange={(e) => setSearchQuery(e.target.value)}
                className="border px-2 py-1.5 text-[12px] rounded-md border-gray-400"
                placeholder="Search"
              />
            </div>
          </div>
          <div className="mt-6 flow-root border shadow-sm rounded-sm overflow-hidden h-[530px]">
          <div className="h-[480px] overflow-y-auto no-scrollbar pb-7">
              <div className="inline-block min-w-full align-middle">
                <div className="relative">
                  <div className="overflow-x-auto rounded-lg shadow-md">
                    <table className="min-w-full table-auto divide-y divide-gray-300 rounded-lg">
                      <thead className="text-gray-900 ">
                        <tr>
                          <th className="py-3.5 px-2 text-left uppercase text-[12px] font-semibold border-b border-indigo-400">
                           Franchise Name
                          </th>
                          <th className="px-3 py-3.5 text-left text-[12px] uppercase font-semibold border-b border-indigo-400">
                            Company Name
                          </th>
                          <th className="px-3 py-3.5 text-left text-[12px] uppercase font-semibold border-b border-indigo-400">
                            Plan & Price 
                          </th>
                          <th className="px-3 py-3.5 text-left text-[12px] uppercase font-semibold border-b border-indigo-400">
                            Leads & Status
                          </th>
                          <th className="px-3 py-3.5 text-left text-[12px] uppercase font-semibold border-b border-indigo-400">
                            orderId 
                          </th>
                         
                          <th className="py-3.5 text-left px-3 uppercase  text-[12px] font-medium border-b border-indigo-400">
                            CreatedAtAT
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white ">
                        {(searchQuery ? Allpayments : allpayments)
                          ?.map((company,index) => (
                            <tr
                              key={index}
                              className="hover:bg-gray-100 transition-colors duration-150"
                            >
                              <td className="whitespace-nowrap capitalize py-4 px-2 text-[12px] text-gray-900">
                                <div className="flex items-center">
                                  <div className="h-11 w-11 flex-shrink-0 border rounded-full">
                                    <img
                                      alt="company-logo"
                                      src={`/uploads/InvestorData/${company?.companyLogo}`}
                                      className="h-11 w-11 rounded-full"
                                    />
                                  </div>
                                  <div className="ml-4">
                                    {company?.franchise_name}
                                  </div>
                                </div>
                              </td>
                              <td className="whitespace-nowrap px-3 capitalize py-3 text-[12px] text-gray-900">
                                {company?.companyName}
                              </td>
                              <td className="whitespace-nowrap px-3 capitalize py-3 text-[12px] text-gray-900">
                                <span className="block capitalize">{company?.planDetails?.name}</span>
                                <span className="block capitalize"> {company?.planDetails?.price}</span>
                              </td>
                              <td className="whitespace-nowrap px-3 capitalize py-3 text-[12px] text-gray-900">
                              <span className="block capitalize"> {company?.planDetails?.leads}</span>
                              <span className="block capitalize">{company?.status}</span>
                              </td>
                              <td className="whitespace-nowrap px-3 capitalize py-3 text-[12px] text-gray-900">
                                {company?.orderId}
                              </td>
                              <td className="whitespace-nowrap px-3 capitalize py-3 text-[12px] text-gray-900">
                                {new Date(company?.createdAt).toLocaleDateString(
                                  "en-US"
                                )}
                              </td>
                              
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    
      
    </>
  );
}
