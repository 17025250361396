import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaCheck, FaTimes } from "react-icons/fa";
import { GrEdit } from "react-icons/gr";
import {
  getAllUserKycData,
  UserKycAction,
  clearErrors,
  clearMessage,
} from "../redux/userSlice";
import Loader from "../BaseFile/Loader";
import SuccessAlert from "../BaseFile/SuccessAlert";
import ErrorAlert from "../BaseFile/ErrorAlert";
export default function UserKycRequest() {
  const dispatch = useDispatch();
  const { userKyc, loading, error, message } = useSelector(
    (state) => state.allusers
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [alluser, setAlluser] = useState("");
  const [previewImage, setPreviewImage] = useState();
  const [showItem, SetShowItem] = useState("pending");
  const [editable, setEditable] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [values, setValues] = useState();

  useEffect(() => {
    dispatch(getAllUserKycData());
    if (error) {
      const errorInterval = setInterval(() => {
        dispatch(clearErrors());
      }, 3000);
      return () => clearInterval(errorInterval);
    }

    if (message) {
      const messageInterval = setInterval(() => {
        dispatch(clearMessage());
      }, 3000);
      return () => clearInterval(messageInterval);
    }
  }, [dispatch, message, error, clearErrors, clearMessage]);

    useEffect(() => {
      setAlluser(
        userKyc?.filter((item) =>
          item?.userDetail?.[0]?.username?.toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    }, [searchQuery]);

  const handleImageClick = (imageName) => {
    setPreviewImage(`/uploads/userKyc/${imageName}`);
  };

  const handleClosePreview = () => {
    setPreviewImage(null);
  };

  const handleEdit = (item) => {
    setEditable(item);
    setEditMode(true);
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    setEditable(null);
  };
  const handleSaveChange = (id) => {
    if (editable) {
      dispatch(
        UserKycAction({
          userId: id,
          action: values,
        })
      );
      setEditMode(false);
      setEditable(null);
    }
  };
  return (
    <>
      {message && <SuccessAlert message={message} />}
      {error && <ErrorAlert error={error} />}
      {loading ? (
        <Loader />
      ) : (
        <div className="bg-white  p-4 border shadow-sm ">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
            <h1 className="text-base capitalize font-semibold text-gray-800">
                  {showItem} Kyc request
                </h1>
                <p className="mt-0 text-[12px] text-gray-700">
                A list of all the companies in your account including their
                name, title, email, and role.
              </p>
            </div>
            <div className="flex gap-5 mt-4 sm:ml-16 sm:mt-6 ">
                <select
                    className="border-2 border-gray-500 px-3 py-1 rounded-md text-sm w-full"
                    onChange={(e)=>SetShowItem(e.target.value)}
                >
                    <option value="pending">Pending</option>
                    <option value="inprogress">In Progress</option>
                    <option value="decline">Decline</option>
                    <option value="approved">Approved</option>
                </select>
              <input
                type="search"
                onChange={(e) => setSearchQuery(e.target.value)}
                className="border px-2 py-1.5 text-[12px] rounded-md border-gray-400"
                placeholder="Search"
              />
            </div>
          </div>
          <div className="mt-6 flow-root border shadow-sm rounded-sm overflow-hidden h-[530px]">
          <div className="h-[480px] overflow-y-auto no-scrollbar pb-7">
              <div className="inline-block min-w-full align-middle">
                <div className="relative">
                  <div className="overflow-x-auto rounded-lg shadow-md">
                    <table className="min-w-full table-auto divide-y divide-gray-300 rounded-lg">
                    <thead className="text-gray-900 ">
                        <tr>
                          <th className="py-3.5 px-2 text-left uppercase text-[12px] font-semibold border-b border-indigo-400">
                            Name & Email
                          </th>
                          <th className="px-3 py-3.5 text-left text-[12px] uppercase font-semibold border-b border-indigo-400">
                            Phone
                          </th>
                          <th className="px-3 py-3.5 text-left text-[12px] uppercase font-semibold border-b border-indigo-400">
                            Adhar
                          </th>
                          <th className="px-3 py-3.5 text-left text-[12px] uppercase font-semibold border-b border-indigo-400">
                            Pan
                          </th>
                          <th className="py-3.5 text-left px-3 uppercase  text-[12px] font-medium border-b border-indigo-400">
                            Other
                          </th>
                          <th className="py-3.5 text-left px-3 uppercase  text-[12px] font-medium border-b border-indigo-400">
                            Status
                          </th>
                          <th className="py-3.5 text-center px-3 uppercase  text-[12px] font-medium border-b border-indigo-400">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white ">
                        {(searchQuery ? alluser : userKyc)
                          ?.filter((item) => item?.status === showItem)
                          ?.map((user) => (
                            <tr
                              key={user?.email}
                              className="hover:bg-gray-100 transition-colors duration-150"
                            >
                              <td className="whitespace-nowrap capitalize py-4 px-2 text-[12px] text-gray-900">
                                <div className="flex items-center">
                                  {/* <div className="h-11 w-11 flex-shrink-0 border rounded-full">
                                    <img
                                      alt="user-logo"
                                      src={`/uploads/logo/${user?.userDetail?.logo}`}
                                      className="h-11 w-11 rounded-full"
                                    />
                                  </div> */}
                                  <div className="ml-4">
                                  <span className="block capitalize">{user?.userDetail?.[0]?.username}</span>
                                  <span className="block ">  {user?.userDetail?.[0]?.email}</span>
                                 
                                  </div>
                                </div>
                              </td>
                              <td className="whitespace-nowrap px-3 capitalize py-3 text-[12px] text-gray-900">
                                {user?.userDetail?.[0]?.phone}
                              </td>
                              <td className="whitespace-nowrap px-3 py-3 capitalize text-[12px] text-gray-900">
                                {user?.adharCard ? (
                                  <button
                                    onClick={() =>
                                      handleImageClick(user?.adharCard)
                                    }
                                    className="text-blue-500 hover:underline"
                                  >
                                    AdharCard
                                  </button>
                                ) : (
                                  "No Adhar Provided"
                                )}
                              </td>
                             
                              <td className="whitespace-nowrap px-3 py-3 capitalize text-[12px] text-gray-900">
                                {user?.panCard ? (
                                  <button
                                    onClick={() =>
                                      handleImageClick(user?.panCard)
                                    }
                                    className="text-blue-500 hover:underline"
                                  >
                                    PanCard
                                  </button>
                                ) : (
                                  "No Pancard Provided"
                                )}
                              </td>
                            
                              <td className="whitespace-nowrap px-3 py-3 capitalize text-[12px] text-gray-900">
                                {user?.otherDocument ? (
                                  <button
                                    onClick={() =>
                                      handleImageClick(user?.otherDocument)
                                    }
                                    className="text-blue-500 hover:underline"
                                  >
                                    Other doc
                                  </button>
                                ) : (
                                  "No Doc Provided"
                                )}
                              </td>
                              <td className="whitespace-nowrap px-3 py-3 capitalize text-[12px] text-gray-900">
                              {editMode && editable?.userId === user?.userId ? (
                                <select
                                    className="border-0 px-3 py-1 rounded-sm text-sm w-full"
                                    onChange={(e)=>setValues(e.target.value)}
                                    defaultValue={user?.status}
                                >
                                    <option value="pending">Pending</option>
                                    <option value="inprogress">In Progress</option>
                                    <option value="decline">Decline</option>
                                    <option value="approved">Approved</option>
                                </select>
                                ) : (
                                <div className="rounded-md px-2 py-1 text-xs">
                                    {user?.status}
                                </div>
                                )}
                              </td>
                              <td className="py-4 pl-2">
                                    <div className="flex items-center justify-center">
                                    {editMode && editable?.userId === user?.userId ? (
                                        <>
                                        <FaCheck
                                            className="h-4 w-4 text-green-700 cursor-pointer"
                                            onClick={() => handleSaveChange(user?.userId)}
                                        />
                                        <FaTimes
                                            className="h-4 w-4 text-red-700 cursor-pointer"
                                            onClick={handleCancelEdit}
                                        />
                                        </>
                                    ) : (
                                        <>
                                        <GrEdit
                                            className="h-4 w-4 text-blue-400 cursor-pointer"
                                            onClick={() => handleEdit(user)}
                                        />
                                        </>
                                    )}
                                    </div>
                                </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* {deletedId && (
    <Confirmation isClose={isClose} deletefunction={deleteuser} id={deletedId} action={"yes"} />
  )}
  {recoverId && (
    <Confirmation isClose={isClose} deletefunction={deleteuser} id={recoverId} action={"no"} />
  )} */}
      {previewImage && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50">
          <div className="relative">
            <img
              src={previewImage}
              alt="Preview"
              className="max-w-full max-h-screen object-contain"
            />
            <button
              onClick={handleClosePreview}
              className="absolute top-2 right-2 text-white text-xl bg-black p-2 rounded-full"
            >
              ×
            </button>
          </div>
        </div>
      )}
    </>
  );
}
