
import { LiaCashRegisterSolid } from "react-icons/lia";
import { MdOutlineRocketLaunch } from "react-icons/md";
export default function LaunchSection(){
    return(
        <>
        <div className="py-4 px-3 bg-[#ffffff] rounded-md shadow-lg border mt-0">
            <div className="flex flex-col lg:flex-row w-full gap-4">
              <div className="lg:w-2/3 w-full">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="p-6 bg-gray-900 rounded-md flex items-center justify-between shadow-sm">
                    <div className="w-full">
                      <h3 className="text-md text-white font-semibold flex items-center justify-between">
                        Launch Your Business Today
                        <span className="ml-2 p-2 bg-indigo-500 rounded-full">
                          <MdOutlineRocketLaunch className="text-white text-xl" />
                        </span>
                      </h3>
                      <p className="text-[13px] text-white">
                        (Investor Registration)
                      </p>
                    </div>
                  </div>
                  <div className="p-6 bg-gray-900 rounded-md flex items-center justify-between shadow-sm">
                    <div className="w-full">
                      <h3 className="text-md text-white font-semibold flex items-center justify-between">
                        Register as an Investor
                        <span className="ml-2 p-2 bg-green-500 rounded-full ">
                          <LiaCashRegisterSolid className="text-white text-xl" />
                        </span>
                      </h3>
                      <p className="text-[13px] text-white">
                        (Franchisor Registration)
                      </p>
                    </div>
                  </div>
                </div>

                <div className="mt-4 text-left">
                  <h3 className="text-md font-semibold">Why Register?</h3>
                  <ul className="mt-2 list-disc pl-5">
                    <li className="mt-2 text-gray-700 text-[14px]">
                      Register for a free account and start building your brand
                      now.
                    </li>
                    <li className="mt-2 text-gray-700 text-[14px]">
                      Access over 10,000+ franchise options to choose from.
                    </li>
                    <li className="mt-2 text-gray-700 text-[14px]">
                      Join a business community and get advice from experts to
                      help you grow through franchising.
                    </li>
                    <li className="mt-2 text-gray-700 text-[14px]">
                    Receive the latest news, trends, and best practices in the franchising industry.

                    </li>
                    <li className="mt-2 text-gray-700 text-[14px]">
                    Enjoy special discounts, promotions, and resources available only to registered members.

                    </li>
                    <li className="mt-2 text-gray-700 text-[14px]">
                    Save your favorite franchises, compare options, and track your progress.

                    </li>
                  </ul>
                </div>
              </div>
              <div className="lg:w-1/3 w-full">
                <div className="p-4 bg-gray-900 rounded-md shadow-sm">
                  <h3 className="text-md text-white font-semibold flex items-center space-x-1">
                    <span>Newly Launched Projects</span>
                  </h3>
                  <img
                    src="/wayadds.png"
                    alt="Project"
                    className="mt-2 rounded-md w-full object-cover"
                  />
                  <p className="mt-2 text-xs text-white">
                  Find exciting new franchise opportunities that have just entered the market. Stay updated on the latest trends in franchising and explore fresh business ideas.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
    )
}