// import { Children, useState } from 'react'
// import {Link} from "react-router-dom"
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import { signout } from "../redux/authSlice";

// import {
//   Dialog,
//   DialogBackdrop,
//   DialogPanel,
//   Menu,
//   MenuButton,
//   MenuItem,
//   MenuItems,
//   TransitionChild,
// } from '@headlessui/react'
// import {
//   Bars3Icon,
//   BellIcon,
//   CalendarIcon,
//   ChartPieIcon,
//   Cog6ToothIcon,
//   DocumentDuplicateIcon,
//   FolderIcon,
//   HomeIcon,
//   UsersIcon,
//   XMarkIcon,
// } from '@heroicons/react/24/outline'
// import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'

// const navigation = [
//   { name: 'Dashboard', to: '#', icon: HomeIcon, current: true },
//   { name: 'Plan', to: '/user/plan', icon: UsersIcon, current: false },
//   { name: 'Kyc', to: '/user/Kyc/detail', icon: FolderIcon, current: false },
//   { name: 'Own Franchise', to: '/user/own/fanchise', icon: CalendarIcon, current: false },
//   // { name: 'Documents', to: '#', icon: DocumentDuplicateIcon, current: false },
//   // { name: 'Reports', to: '#', icon: ChartPieIcon, current: false },
// ]

// const userNavigation = [
//   { name: 'Your profile', to: '#' },
//   { name: 'Sign out', to: '#' },
// ]

// function classNames(...classes) {
//   return classes.filter(Boolean).join(' ')
// }

// export default function UserMenu({Children}) {
//   const navigate=useNavigate()
//   const dispatch=useDispatch()
//   const [sidebarOpen, setSidebarOpen] = useState(false);
//   const [currenttab, setCurrentTab] = useState("Dashboard");
//   const { auth} = useSelector((state) => state.auth);

//   function handleSignOut(){
//     dispatch(signout())
//     navigate("/")
//   }
//   return (
//     <>
//       <div>
//         <Dialog open={sidebarOpen} onClose={setSidebarOpen} className="relative z-50 lg:hidden">
//           <DialogBackdrop
//             transition
//             className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
//           />

//           <div className="fixed inset-0 flex">
//             <DialogPanel
//               transition
//               className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full"
//             >
//               <TransitionChild>
//                 <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
//                   <button type="button" onClick={() => setSidebarOpen(false)} className="-m-2.5 p-2.5">
//                     <span className="sr-only">Close sidebar</span>
//                     <XMarkIcon aria-hidden="true" className="h-6 w-6 text-white" />
//                   </button>
//                 </div>
//               </TransitionChild>
//               {/* Sidebar component, swap this element with another sidebar if you like */}
//               <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4 ring-1 ring-white/10">
//                 <div className="flex h-16 shrink-0 items-center">
//                   <Link to="/">
//                   <img
//                     alt="Your Company"
//                     src="/logo.png"
//                     className="h-8 w-auto"
//                   />
//                   </Link>
//                 </div>
//                 <nav className="flex flex-1 flex-col">
//                   <ul role="list" className="flex flex-1 flex-col gap-y-7">
//                     <li>
//                       <ul role="list" className="-mx-2 space-y-1">
//                         {navigation.map((item) => (
//                           <li key={item.name}>
//                             <Link
//                               to={item.to}
//                               className={classNames(
//                                 item.current
//                                   ? 'bg-gray-800 text-white'
//                                   : 'text-gray-400 hover:bg-gray-800 hover:text-white',
//                                 'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
//                               )}
//                             >
//                               <item.icon aria-hidden="true" className="h-6 w-6 shrink-0" />
//                               {item.name}
//                             </Link>
//                           </li>

//                         ))}
//                         <li>
//                             <Link
//                               to={`/user/profile/${auth?._id}`}
//                               className={classNames(
//                                 // item.current
//                                   // ? 'bg-gray-800 text-white'
//                                    'text-gray-400 hover:bg-gray-800 hover:text-white',
//                                 'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
//                               )}
//                             >
//                               <UsersIcon aria-hidden="true" className="h-6 w-6 shrink-0" />
//                               Profile
//                             </Link>
//                           </li>
//                       </ul>
//                     </li>

//                     <li className="mt-auto">
//                       <button
//                         onClick={handleSignOut}
//                         className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
//                       >
//                         <Cog6ToothIcon
//                           aria-hidden="true"
//                           className="h-6 w-6 shrink-0"
//                         />
//                         LogOut
//                       </button>
//                     </li>
//                     <li className="mt-auto">
//                       <Link
//                         to="#"
//                         className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
//                       >
//                         <Cog6ToothIcon aria-hidden="true" className="h-6 w-6 shrink-0" />
//                         Settings
//                       </Link>
//                     </li>
//                   </ul>
//                 </nav>
//               </div>
//             </DialogPanel>
//           </div>
//         </Dialog>

//         {/* Static sidebar for desktop */}
//         <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
//           {/* Sidebar component, swap this element with another sidebar if you like */}
//           <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4">
//             <div className="flex h-16 shrink-0 items-center">
//               <Link to="/" >
//               <img
//                 alt="Your Company"
//                 src="/logo.png"
//                 className="h-8 w-auto"
//               />
//               </Link>
//             </div>
//             <nav className="flex flex-1 flex-col">
//               <ul role="list" className="flex flex-1 flex-col gap-y-7">
//                 <li>
//                   <ul role="list" className="-mx-2 space-y-1">
//                     {navigation.map((item) => (
//                       <li key={item.name}>
//                         <Link
//                           to={item.to}
//                           onClick={() => setCurrentTab(item.name)}
//                           className={classNames(
//                             item.name == currenttab
//                               ? 'bg-gray-800 text-white'
//                               : 'text-gray-400 hover:bg-gray-800 hover:text-white',
//                             'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
//                           )}
//                         >
//                           <item.icon aria-hidden="true" className="h-6 w-6 shrink-0" />
//                           {item.name}
//                         </Link>
//                       </li>
//                     ))}
//                      <li>
//                             <Link
//                               to={`/user/profile/${auth?._id}`}
//                               className={classNames(
//                                 // item.current
//                                   // ? 'bg-gray-800 text-white'
//                                    'text-gray-400 hover:bg-gray-800 hover:text-white',
//                                 'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
//                               )}
//                             >
//                               <UsersIcon aria-hidden="true" className="h-6 w-6 shrink-0" />
//                               Profile
//                             </Link>
//                           </li>
//                   </ul>
//                 </li>

//                 <li className="mt-auto">
//                       <button
//                         onClick={handleSignOut}
//                         className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
//                       >
//                         <Cog6ToothIcon
//                           aria-hidden="true"
//                           className="h-6 w-6 shrink-0"
//                         />
//                         LogOut
//                       </button>
//                     </li>
//                 <li className="mt-auto">
//                   <Link
//                     to="#"
//                     className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
//                   >
//                     <Cog6ToothIcon aria-hidden="true" className="h-6 w-6 shrink-0" />
//                     Settings
//                   </Link>
//                 </li>
//               </ul>
//             </nav>
//           </div>
//         </div>

//         <div className="lg:pl-72">
//           <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
//             <button type="button" onClick={() => setSidebarOpen(true)} className="-m-2.5 p-2.5 text-gray-700 lg:hidden">
//               <span className="sr-only">Open sidebar</span>
//               <Bars3Icon aria-hidden="true" className="h-6 w-6" />
//             </button>

//             {/* Separator */}
//             <div aria-hidden="true" className="h-6 w-px bg-gray-900/10 lg:hidden" />

//             <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
//               <form action="#" method="GET" className="relative flex flex-1">
//                 <label htmlFor="search-field" className="sr-only">
//                   Search
//                 </label>
//                 <MagnifyingGlassIcon
//                   aria-hidden="true"
//                   className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
//                 />
//                 <input
//                   id="search-field"
//                   name="search"
//                   type="search"
//                   placeholder="Search..."
//                   className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
//                 />
//               </form>
//               <div className="flex items-center gap-x-4 lg:gap-x-6">
//                 <button type="button" className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
//                   <span className="sr-only">View notifications</span>
//                   <BellIcon aria-hidden="true" className="h-6 w-6" />
//                 </button>

//                 {/* Separator */}
//                 <div aria-hidden="true" className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10" />

//                 {/* Profile dropdown */}
//                 <Menu as="div" className="relative">
//                   <MenuButton className="-m-1.5 flex items-center p-1.5">
//                     <span className="sr-only">Open user menu</span>
//                     <img
//                       alt=""
//                       src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
//                       className="h-8 w-8 rounded-full bg-gray-50"
//                     />
//                     <span className="hidden lg:flex lg:items-center">
//                       <span aria-hidden="true" className="ml-4 text-sm font-semibold leading-6 text-gray-900">
//                         Tom Cook
//                       </span>
//                       <ChevronDownIcon aria-hidden="true" className="ml-2 h-5 w-5 text-gray-400" />
//                     </span>
//                   </MenuButton>
//                   <MenuItems
//                     transition
//                     className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
//                   >
//                     {userNavigation.map((item) => (
//                       <MenuItem key={item.name}>
//                         <Link
//                           to={item.to}
//                           className="block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
//                         >
//                           {item.name}
//                         </Link>
//                       </MenuItem>
//                     ))}
//                   </MenuItems>
//                 </Menu>
//               </div>
//             </div>
//           </div>

//           <main className="py-10">
//             <div className="px-4 sm:px-2">{Children}</div>
//           </main>
//         </div>
//       </div>
//     </>
//   )
// }

import { getFranchiseByid } from "../redux/franchiseSlice";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Children, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signout } from "../redux/authSlice";
import Header from "../HomeFiels/Comman/Header";
import Footer from "../HomeFiels/Comman/Footer";
import { VscUnverified } from "react-icons/vsc";
import { MdVerified } from "react-icons/md";

const user = {
  name: "Tom Cook",
  email: "tom@example.com",
  imageUrl:
    "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
};


const navigation = [
  { name: "Profile", to: "/investor/profile", current: false },
  { name: "Kyc Detail", to: "/investor/Kyc/detail", current: false },
  { name: "Plan", to: "/investor/plan", current: false },
  { name: "Clients", to: "/investor/clients", current: false },
];
const userNavigation = [
  { name: "Your Profile", to: "#" },
  { name: "Settings", to: "#" },
  { name: "Sign out", to: "#" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function InvestorMenu({ Children }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { franchisee, loading, error, message } = useSelector(
    (state) => state.franchise
  );
  const { auth } = useSelector((state) => state.auth);

  function handleSignOut() {
    dispatch(signout());
    navigate("/");
  }

  return (
    <>
      <div className="min-h-full">
        <div className="">
          <Header />
          <div className="lg:flex gap-x-5 w-full px-8 py-5 lg:col-span-12 sm:col-span-12 col-span-12 border shadow-lg">
            <div className="lg:w-1/5 w-full border shadow-sm  mb-2 pb-6 ">
              <div className="grid lg:grid-cols-1 grid-cols-1 ">
                <div className="relative ">
                  <div className="text-base absolute -top-3 capitalize left-0 bg-black px-4 py-2 text-white mt-3 mr-3 font-medium hover:bg-black  transition duration-500 ease-in-out">
                    ads
                  </div>
                  <img src="/investorads.png" className="w-full" />
                </div>
                <div className="relative mt-4">
                  <div className="text-base absolute -top-3 capitalize -right-3 bg-black px-4 py-2 text-white mt-3 mr-3 font-medium hover:bg-black  transition duration-500 ease-in-out">
                    ads
                  </div>
                  <img src="/investoradd.png" className="w-full" />
                </div>
              </div>
            </div>
            <div className="lg:w-4/5 w-full border mb-2">
              <div>{Children}</div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
          
    </>
  );
}
