const filters = [
    {
      id: "investment_range",
      name: "Investment Range",
      options: [
        { value: "0-10000", label: "0-10000" },
        { value: "Rs. 10000 - 50000", label: "Rs. 10000 - 50000" },
        { value: "Rs. 50000 - 2lakh", label: "Rs. 50000 - 2lakh" },
        { value: "Rs. 2lakh - 5lakh", label: "Rs. 2lakh - 5lakh" },
        { value: "Rs. 5lakh - 10lakh", label: "Rs. 5lakh - 10lakh" },
        { value: "Rs. 10lakh - 20lakh", label: "Rs. 10lakh - 20lakh" },
        { value: "Rs. 20lakh - 30lakh", label: "Rs. 20lakh - 30lakh" },
        { value: "Rs. 30lakh - 50lakh", label: "Rs. 30lakh - 50lakh" },
        { value: "Rs. 50lakh - 1 Cr.", label: "Rs. 50lakh - 1 Cr." },
        { value: "Rs. 1 Cr. - 2 Cr.", label: "Rs. 1 Cr. - 2 Cr." },
        { value: "Rs. 2 Cr. - 5 Cr.", label: "Rs. 2 Cr. - 5 Cr." },
        { value: "Rs. 5 Cr. above", label: "Rs. 5 Cr. above" },
      ],
    },
    {
      id: "location",
      name: "Location",
      options: [
        { value: "AP", label: "Andhra Pradesh" },
        { value: "AR", label: "Arunachal Pradesh" },
        { value: "AS", label: "Assam" },
        { value: "BR", label: "Bihar" },
        { value: "CT", label: "Chhattisgarh" },
        { value: "GA", label: "Goa" },
        { value: "GJ", label: "Gujarat" },
        { value: "HR", label: "Haryana" },
        { value: "HP", label: "Himachal Pradesh" },
        { value: "JH", label: "Jharkhand" },
        { value: "KA", label: "Karnataka" },
        { value: "KL", label: "Kerala" },
        { value: "MP", label: "Madhya Pradesh" },
        { value: "MH", label: "Maharashtra" },
        { value: "MN", label: "Manipur" },
        { value: "ML", label: "Meghalaya" },
        { value: "MZ", label: "Mizoram" },
        { value: "NL", label: "Nagaland" },
        { value: "OR", label: "Odisha" },
        { value: "PB", label: "Punjab" },
        { value: "RJ", label: "Rajasthan" },
        { value: "SK", label: "Sikkim" },
        { value: "TN", label: "Tamil Nadu" },
        { value: "TG", label: "Telangana" },
        { value: "TR", label: "Tripura" },
        { value: "UP", label: "Uttar Pradesh" },
        { value: "UT", label: "Uttarakhand" },
        { value: "WB", label: "West Bengal" },
      ],
    },
    {
      id: "category",
      name: "Category",
      options: [
        { value: "franchise", label: "Franchise" },
        { value: "dealership", label: "Dealership" },
        { value: "distributor", label: "Distributor" },
        { value: "licensing", label: "Licensing" },
        { value: "investment-opportunity", label: "Investment Opportunity" },
      ],
    },
  ];


  export default filters;
  