import { useState ,useEffect} from 'react'
import { useSelector, useDispatch } from "react-redux";
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { SlWallet } from "react-icons/sl";
import {rechargeWallet,clearErrors,clearMessage} from "../redux/paymentSlice"
import SuccessAlert from "../BaseFile/SuccessAlert";
import ErrorAlert from "../BaseFile/ErrorAlert";

export default function RechargeWallet({ closeModel ,id}) {
    const dispatch=useDispatch()
  const [open, setOpen] = useState(true);
  const [amount, setAmount] = useState('');
  const [isAmountValid, setIsAmountValid] = useState(false);
  const { error,message } = useSelector((state) => state.buyPayment);

  const handleAmountChange = (e) => {
    const value = parseInt(e.target.value, 10);
    setAmount(value);
    setIsAmountValid(value >= 500);
  };

  useEffect(() => {
    if (error) {
      const errorInterval = setInterval(() => {
        dispatch(clearErrors());
      }, 3000);
      return () => clearInterval(errorInterval);
    }
    if (message) {
      const messageInterval = setInterval(() => {
        dispatch(clearMessage());
      }, 3000);
      return () => clearInterval(messageInterval);
    }
  }, [dispatch, message, error, clearErrors, clearMessage]);

  
  function handleSubmit(){
    if(id){
        const walletData={franchise_id:id , amount:amount}
        dispatch(rechargeWallet(walletData))
        // closeModel()
    }
  }
  return (

    <>
    {message && <SuccessAlert message={message} />}
    {error && <ErrorAlert error={error} />}
    <Dialog open={open} onClose={() => {}} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div>
              <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                <SlWallet aria-hidden="true" className="h-6 w-6 text-green-600" />
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <DialogTitle as="h3" className="text-base font-semibold text-gray-900">
                  Recharge Wallet
                </DialogTitle>
                <div className="mt-4">
                  <input 
                    type="number" 
                    min="500"
                    value={amount}
                    onChange={handleAmountChange}
                    placeholder="Enter amount (min 500)"
                    className="w-full rounded-md border-2 border-gray-800 p-2 focus:border-indigo-500 focus:ring-indigo-500"
                  />
                  {!isAmountValid && amount !== '' && (
                    <p className="mt-1 text-sm text-red-500">Amount must be at least 500.</p>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              <button
                type="button"
                onClick={()=>handleSubmit()}
                disabled={!isAmountValid}
                className={`inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm  ${
                  isAmountValid ? 'bg-indigo-600 hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600' : 'bg-gray-400 cursor-not-allowed'
                } sm:col-start-2`}
              >
                Recharge
              </button>
              <button
                type="button"
                data-autofocus
                onClick={() => closeModel()}
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
              >
                Cancel
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
    </>
  );
}
