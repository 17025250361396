import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";  
import { getFranchiseByid } from "../redux/franchiseSlice";
import { Country, State, City } from "country-state-city";
import { VscUnverified } from "react-icons/vsc";
import { MdVerified } from "react-icons/md";
import { Dialog } from "@headlessui/react";
import { PlusSmallIcon } from "@heroicons/react/24/outline";
import Spinner from "../BaseFile/Spinner";
import SuccessAlert from "../BaseFile/SuccessAlert";
import ErrorAlert from "../BaseFile/ErrorAlert";
import {
  editUser,
  clearAuthErrors,
  clearAuthMessage,
} from "../redux/authSlice";
import { getUser } from "../redux/userSlice";
import UserKycDetail from "./UserKycDetail";
import UserPlan from "./UserPlan";
import UserOwnFranchise from "./UserOwnFranchise";
import UserLeads from "./UserLeads";
const tabs = [
  { name: "DASHBOARD", href: "#", current: true },
  { name: "PROFILE", href: "#", current: false },
  { name: "KYC", href: "#", current: false },
  { name: "PLAN", href: "#", current: false },
  { name: "OWNEDFRANCHISE", href: "#", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const UserProfile = () => {
  const dispatch = useDispatch();
  const { singleuser } = useSelector((state) => state.allusers);
  const { auth, auth_loading, auth_error, auth_message } = useSelector(
    (state) => state.auth
  );
  const [currentTab, setCurrentTab] = useState("DASHBOARD");
  const [enableEdit, setEnableEdit] = useState(false);
  const [values, setValues] = useState();
  const [states, setStates] = useState([]);
  const [activeStateCode, setActiveStateCode] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [cities, setCities] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCities, setSelectedCities] = useState({});
  const [selectedStates, setSelectedStates] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (auth?._id) {
      const id = auth?._id;
      dispatch(getUser(id));
      // dispatch(getFranchiseByid(_id));
    }
  }, [auth?._id]);

  function handleEdit(tab) {
    setCurrentTab(tab);
    setEnableEdit(true);
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  useEffect(() => {
    const indianStates = State.getStatesOfCountry("IN");
    setStates(indianStates);
    if (auth_error) {
      const errorInterval = setInterval(() => {
        dispatch(clearAuthErrors());
      }, 3000);
      return () => clearInterval(errorInterval);
    }

    if (auth_message) {
      const messageInterval = setInterval(() => {
        dispatch(clearAuthMessage());
      }, 3000);
      return () => clearInterval(messageInterval);
    }
  }, [auth_message, auth_error, clearAuthErrors, clearAuthMessage]);

  const handleStateClick = (stateIsoCode) => {
    const isStateSelected = !!selectedStates[stateIsoCode];
    setSelectedStates((prev) => {
      const updatedStates = { ...prev };
      if (isStateSelected) {
        delete updatedStates[stateIsoCode];
      } else {
        updatedStates[stateIsoCode] = true;
        setSelectedCities((prevCities) => {
          const updatedCities = { ...prevCities };
          delete updatedCities[stateIsoCode];
          return updatedCities;
        });
      }
      return updatedStates;
    });
  };

  const handleCityClick = (stateIsoCode, cityName) => {
    setSelectedCities((prev) => {
      const updatedCities = { ...prev };
      const stateCities = updatedCities[stateIsoCode] || [];
      if (stateCities.includes(cityName)) {
        updatedCities[stateIsoCode] = stateCities.filter(
          (city) => city !== cityName
        );
      } else {
        updatedCities[stateIsoCode] = [...stateCities, cityName];
      }
      return updatedCities;
    });
  };

  const handleStateDisclosureClick = (stateIsoCode) => {
    if (!cities[stateIsoCode]) {
      const cityData = City.getCitiesOfState("IN", stateIsoCode);
      setCities((prevCities) => ({ ...prevCities, [stateIsoCode]: cityData }));
    }
    setActiveStateCode(stateIsoCode);
    setIsModalOpen(true);
  };
  const handleSegmentChange = (e) => {
    const { options } = e.target;
    const selectedValues = [];

    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedValues.push(options[i].value);
      }
    }

    if (selectedValues.length <= 5) {
      setValues({ ...values, interested_segment: selectedValues });
    } else {
      alert("You can only select up to 5 segments.");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const userData = {
      _id: auth?._id,
      ...values, // Spread the rest of the form values first
    };

    // Conditionally add `selectedCities` and `selectedStates` if both have values
    if (Object.keys(selectedCities).length) {
      userData.selectedCities = selectedCities;
    }
    if (Object.keys(selectedStates).length) {
      userData.selectedStates = selectedStates;
    }

    dispatch(editUser(userData));
    setEnableEdit(false);
  };

  return (
    <>
      {auth_message && <SuccessAlert message={auth_message} />}
      {auth_error && <ErrorAlert error={auth_error} />}
      <main>
        <div className="w-full  lg:mb-0 mb-20">
          <div className="">
            <div className=" flex lg:w-full">
              <div className="flex h-full w-full bg-white shadow-sm">
                <div className="divide-y divide-gray-200 w-full">
                  <div className="pb-6">
                    <div className="h-24 bg-gray-700 sm:h-20 lg:h-28 relative">
                      <div className="w-full h-28 relative ">
                        {/* <img
                          src="/cyberminiads.png"
                          className="bg-no-repeat bg-center bg-cover w-full lg:h-28 md:h-28 h-24"
                        />
                        <div className="text-[12px] absolute -top-3 capitalize left-0 bg-black px-4 py-1 text-white mt-3 mr-3 font-medium hover:bg-black transition duration-500 ease-in-out">
                          ads
                        </div> */}
                      </div>
                    </div>
                    <div className="-mt-12 flow-root px-4 sm:-mt-16 sm:flex sm:items-end sm:px-6 lg:-mt-16">
                      <div className="relative">
                        <div className="-m-1 flex">
                          <div className="inline-flex overflow-hidden rounded-lg border-4 border-white">
                            <img
                              alt=""
                              src={`/banner.jpg`}
                              className="h-24 w-24  flex-shrink-0 sm:h-40 sm:w-40 lg:h-48 lg:w-48"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mt-6 sm:ml-6 sm:flex-1 w-full">
                        <div>
                          <div className="flex items-center">
                            <h3 className="text-xl font-bold text-gray-900 sm:text-2xl capitalize ">
                              {singleuser?.username}
                            </h3>

                            {singleuser?.isVerified == "yes" ? (
                              <MdVerified className="text-green-500 text-2xl" />
                            ) : (
                              <VscUnverified className="text-red-500 text-2xl" />
                            )}
                          </div>
                          <p className="text-sm text-gray-500">
                            {" "}
                            {singleuser?.email}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-3 lg:px-0 py-3 lg:py-0 sm:px-0">
                    <div className="sm:hidden">
                      <label htmlFor="question-tabs" className="sr-only">
                        Select a tab
                      </label>
                      <select
                        id="question-tabs"
                        defaultValue={tabs.find((tab) => tab.current).name}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900  shadow-sm text-sm font-semibold px-1 ring-1 ring-inset "
                      >
                        {tabs.map((tab) => (
                          <option
                            onClick={() => setCurrentTab(tab.name)}
                            key={tab.name}
                          >
                            {tab.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="hidden sm:block">
                      <nav
                        aria-label="Tabs"
                        className="isolate flex divide-x divide-gray-200 rounded-lg shadow overflow-x-auto"
                      >
                        {tabs.map((tab, tabIdx) => (
                          <button
                            key={tab.name}
                            href={tab.href}
                            aria-current={tab.current ? "page" : undefined}
                            onClick={() => setCurrentTab(tab.name)}
                            className={classNames(
                              tab.name == currentTab
                                ? "text-gray-900"
                                : "text-gray-500 hover:text-gray-700",
                              tabIdx === 0 ? "rounded-l-lg" : "",
                              tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                              "group relative min-w-0 flex-1  bg-white px-4 overflow-hidden py-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
                            )}
                          >
                            <span>{tab.name}</span>
                            <span
                              aria-hidden="true"
                              className={classNames(
                                tab.name == currentTab
                                  ? "bg-rose-500"
                                  : "bg-transparent",
                                "absolute inset-x-0 bottom-0 h-0.5"
                              )}
                            />
                          </button>
                        ))}
                      </nav>
                    </div>
                  </div>

                  {currentTab == "PROFILE" && (
                    <div className="px-4 py-5 sm:px-0 sm:py-0">
                      <dl className="space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                        <div className="flex justify-between items-center">
                          <div>
                            <h2
                              id="franchise"
                              className="text-base uppercase font-semibold leading-6 text-gray-900 border-b p-5"
                            >
                              PROFILE DETAILS
                            </h2>
                          </div>
                          <div>
                            {enableEdit ? (
                              <>
                                <button
                                  onClick={(e) => handleSubmit(e)}
                                  className="mr-5  hover:bg-indigo-700 bg-indigo-600 w-20 py-1 rounded-md text-white"
                                >
                                  {" "}
                                  Save
                                </button>

                                <button
                                  onClick={(e) => setEnableEdit(false)}
                                  className="mr-5 hover:bg-red-700 bg-red-600 w-20 py-1 rounded-md text-white "
                                >
                                  {" "}
                                  Cancel
                                </button>
                              </>
                            ) : (
                              <button
                                onClick={(e) => handleEdit("PROFILE")}
                                className="mr-5 hover:bg-indigo-700 bg-indigo-600 w-20 py-1 rounded-md text-white "
                              >
                                {" "}
                                Edit
                              </button>
                            )}
                          </div>
                        </div>
                        {enableEdit && currentTab == "PROFILE" ? (
                          <div>
                            <form onSubmit={handleSubmit}>
                              <div className="relative mb-6 px-2"></div>
                              <div className="bg-white  shadow-sm rounded-sm pb-8">
                                <div className="px-4 lg:px-6 py-2">
                                  <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
                                    <div>
                                      <label
                                        htmlFor="username"
                                        className="block font-medium text-[13px] text-gray-900"
                                      >
                                        Username
                                      </label>
                                      <input
                                        type="text"
                                        id="username"
                                        name="username"
                                        defaultValue={singleuser.username}
                                        onChange={(e) => handleChange(e)}
                                        placeholder="Enter Your Username"
                                        className="w-full rounded-sm border text-[12px] py-2 px-3 border-gray-400"
                                      />
                                    </div>
                                  </div>
                                  <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 mt-4">
                                    <div>
                                      <label
                                        htmlFor="phone"
                                        className="block font-medium text-[13px] text-gray-900"
                                      >
                                        Mobile
                                      </label>
                                      <input
                                        onChange={(e) => handleChange(e)}
                                        name="phone"
                                        required
                                        defaultValue={singleuser.phone}
                                        type="text"
                                        id="phone"
                                        placeholder="Enter Your Phone Number"
                                        className="w-full rounded-sm border text-[12px] py-2 px-3 border-gray-400"
                                      />
                                    </div>
                                    <div>
                                      <label
                                        htmlFor="pincode"
                                        className="block font-medium text-[13px] text-gray-900"
                                      >
                                        Pin Code
                                      </label>
                                      <input
                                        type="text"
                                        onChange={(e) => handleChange(e)}
                                        id="pincode"
                                        defaultValue={singleuser.pincode}
                                        name="pincode"
                                        placeholder="Enter Your Pin Code"
                                        className="w-full rounded-sm border text-[12px] py-2 px-3 border-gray-400"
                                      />
                                    </div>
                                  </div>

                                  <div className="mt-3">
                                    <label
                                      htmlFor="address"
                                      className="block leading-6 text-[13px] text-gray-900"
                                    >
                                      Address
                                    </label>
                                    <div className="mt-1">
                                      <textarea
                                        id="address"
                                        onChange={(e) => handleChange(e)}
                                        defaultValue={singleuser.address}
                                        name="address"
                                        rows={4}
                                        placeholder="Please Enter Your Address"
                                        className="w-full rounded-sm border text-[12px] py-2 px-3 border-gray-400"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="bg-white  shadow-sm border rounded-sm pb-4 mt-5">
                                <div className="bg-gray-100">
                                  <h2 className="text-base font-semibold mb-4 border-b py-3 px-6">
                                    Interests & Resources
                                  </h2>
                                </div>
                                <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 px-6 ">
                                  <div className="">
                                    <label
                                      htmlFor="Investment"
                                      className="block font-medium text-[13px] text-gray-900"
                                    >
                                      Investment Range
                                    </label>
                                    <div className="flex rounded-sm shadow-sm ring-1 text-[12px] ring-inset ring-gray-300 focus-within:ring-inset focus-within:ring-indigo-600 ">
                                      <select
                                        name="investment"
                                        type="text"
                                        defaultValue={singleuser.investment}
                                        onChange={(e) => handleChange(e)}
                                        className="block flex-1 border-0 bg-transparent text-[12px] py-2.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6"
                                      >
                                        <option value="Rs. 10000 - 50000">
                                          Rs. 10000 - 50000
                                        </option>
                                        <option value="Rs. 50000 - 2lakh">
                                          Rs. 50000 - 2lakh
                                        </option>
                                        <option value="Rs. 2lakh - 5lakh">
                                          Rs. 2lakh - 5lakh
                                        </option>
                                        <option value="Rs. 5lakh - 10lakh">
                                          Rs. 5lakh - 10lakh
                                        </option>
                                        <option value="Rs. 10lakh - 20lakh">
                                          Rs. 10lakh - 20lakh
                                        </option>
                                        <option value="Rs. 20lakh - 30lakh">
                                          Rs. 20lakh - 30lakh
                                        </option>
                                        <option value="Rs. 30lakh - 50lakh">
                                          Rs. 30lakh - 50lakh
                                        </option>
                                        <option value="Rs. 50lakh - 1 Cr">
                                          Rs. 50lakh - 1 Cr.
                                        </option>
                                        <option value=" Rs. 1 Cr. - 2 Cr">
                                          {" "}
                                          Rs. 1 Cr. - 2 Cr.
                                        </option>
                                        <option value="Rs. 2 Cr. - 5 Cr">
                                          Rs. 2 Cr. - 5 Cr.
                                        </option>
                                        <option value="Rs. 5 Cr. above">
                                          Rs. 5 Cr. above
                                        </option>
                                      </select>
                                    </div>
                                  </div>

                                  <div className="">
                                    <label
                                      htmlFor="interested_segment"
                                      className="block font-medium text-[13px] text-gray-900"
                                    >
                                      Interested Segment
                                    </label>
                                    <div className="flex rounded-sm shadow-sm ring-1 text-[12px] ring-inset ring-gray-300 focus-within:ring-inset focus-within:ring-indigo-600">
                                      <select
                                        name="interested_segment"
                                        multiple
                                        defaultValue={
                                          singleuser.interested_segment
                                        }
                                        onChange={(e) => handleSegmentChange(e)}
                                        className="block flex-1 border-0 bg-transparent text-[12px] capitalize py-2.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6"
                                      >
                                        <option value="agents, dealers & distributors">
                                          agents, dealers & distributors
                                        </option>
                                        <option value="automotive franchise">
                                          automotive franchise
                                        </option>
                                        <option value="beauty franchise">
                                          beauty franchise
                                        </option>
                                        <option value="beverage franchise">
                                          beverage franchise
                                        </option>
                                        <option value="business services franchise">
                                          business services franchise
                                        </option>
                                        <option value="cleaning franchise">
                                          cleaning franchise
                                        </option>
                                        <option value="clothing franchise">
                                          clothing franchise
                                        </option>
                                        <option value="computer & internet franchise">
                                          computer & internet franchise
                                        </option>
                                        <option value="construction franchise">
                                          construction franchise
                                        </option>
                                        <option value="consultancy franchise">
                                          consultancy franchise
                                        </option>
                                        <option value="education franchise">
                                          education franchise
                                        </option>
                                        <option value="entertainment & leisure">
                                          entertainment & leisure
                                        </option>
                                        <option value="financial service franchise">
                                          financial service franchise
                                        </option>
                                        <option value="fitness franchise">
                                          fitness franchise
                                        </option>
                                        <option value="food franchise">
                                          food franchise
                                        </option>
                                        <option value="health care franchise">
                                          health care franchise
                                        </option>
                                        <option value="hotel franchise">
                                          hotel franchise
                                        </option>
                                        <option value="icecream franchise">
                                          icecream franchise
                                        </option>
                                        <option value="jewellery franchise">
                                          jewellery franchise
                                        </option>
                                        <option value="logistics franchise">
                                          logistics franchise
                                        </option>
                                        <option value="manufacturing franchise">
                                          manufacturing franchise
                                        </option>
                                        <option value="pet franchise">
                                          pet franchise
                                        </option>
                                        <option value="preschool franchise">
                                          preschool franchise
                                        </option>
                                        <option value="real estate franchise">
                                          real estate franchise
                                        </option>
                                        <option value="restaurant franchise">
                                          restaurant franchise
                                        </option>
                                        <option value="retail franchise">
                                          retail franchise
                                        </option>
                                        <option value="sports franchise">
                                          sports franchise
                                        </option>
                                        <option value="travel franchise">
                                          travel franchise
                                        </option>
                                        <option value="work from home">
                                          work from home
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>

                                <div className="bg-white  shadow-sm border rounded-sm mt-6">
                                  <div className="bg-gray-100">
                                    <h2 className="text-base font-semibold leading-7 border-b py-3 px-6 text-gray-900">
                                      Business Expansion
                                    </h2>
                                  </div>

                                  <div className="w-full py-2">
                                    <div className="mt-4 grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-x-12 gap-y-3 px-6 pb-4">
                                      {states.map((state) => (
                                        <div
                                          key={state.isoCode}
                                          className="flex justify-between items-start"
                                        >
                                          <div className="flex items-center mb-2">
                                            <input
                                              id={`${state.isoCode}State`}
                                              name="state"
                                              type="checkbox"
                                              checked={
                                                !!selectedStates[state.isoCode]
                                              }
                                              className="h-4 w-4 border-gray-300 text-green-600 focus:ring-green-600"
                                              onChange={() =>
                                                handleStateClick(state.isoCode)
                                              }
                                            />
                                            <label
                                              htmlFor={`${state.isoCode}State`}
                                              className="ml-3   text-[12px] font-semibold whitespace-pre-line break-all text-gray-900"
                                            >
                                              {state.name ==
                                              "Dadra and Nagar Haveli and Daman and Diu"
                                                ? "Dadar & Daman"
                                                : state.name}
                                            </label>
                                          </div>

                                          {!selectedStates[state.isoCode] && (
                                            <button
                                              type="button"
                                              onClick={() =>
                                                handleStateDisclosureClick(
                                                  state.isoCode
                                                )
                                              }
                                              className="flex justify-start gap-2  items-center  text-left text-gray-900 font-semibold text-lg"
                                            >
                                              <div className="flex items-center gap-2 bg-gray-800 px-1 py-[3px] rounded-sm">
                                                <span className="  text-white">
                                                  <PlusSmallIcon className="h-5 w-5 text-white" />
                                                </span>
                                                {/* <span className="text-[12px] text-white"> Cities</span> */}
                                              </div>
                                            </button>
                                          )}
                                        </div>
                                      ))}
                                    </div>
                                    <Dialog
                                      open={isModalOpen}
                                      onClose={() => setIsModalOpen(false)}
                                      className="fixed inset-0 z-10 flex items-center justify-center p-4"
                                    >
                                      <div
                                        className="fixed inset-0 bg-black bg-opacity-25"
                                        aria-hidden="true"
                                      />
                                      <Dialog.Panel className="relative bg-white rounded-lg max-w-2xl mx-auto p-6 h-[500px] overflow-y-scroll">
                                        <Dialog.Title className="text-lg  font-semibold text-gray-900 border-b pb-4 border-gray-400">
                                          Cities in{" "}
                                          {activeStateCode &&
                                            states.find(
                                              (s) =>
                                                s.isoCode === activeStateCode
                                            )?.name}
                                        </Dialog.Title>
                                        <Dialog.Description className="mt-2">
                                          {cities[activeStateCode] ? (
                                            cities[activeStateCode].length >
                                            0 ? (
                                              <div className="mt-4 grid grid-cols-4 gap-4">
                                                {cities[activeStateCode].map(
                                                  (city) => (
                                                    <div
                                                      key={city.name}
                                                      className="flex items-center"
                                                    >
                                                      <input
                                                        type="checkbox"
                                                        className="mr-2"
                                                        checked={
                                                          selectedCities[
                                                            activeStateCode
                                                          ]?.includes(
                                                            city.name
                                                          ) || false
                                                        }
                                                        onChange={() =>
                                                          handleCityClick(
                                                            activeStateCode,
                                                            city.name
                                                          )
                                                        }
                                                      />
                                                      <label className="text-gray-700 text-[12px] whitespace-pre-line break-all">
                                                        {city.name}
                                                      </label>
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            ) : (
                                              <p>No cities available</p>
                                            )
                                          ) : (
                                            <p>Loading cities...</p>
                                          )}
                                        </Dialog.Description>
                                        <div className="mt-4 justify-end flex border-t border-gray-400 pt-2">
                                          <button
                                            onClick={() =>
                                              setIsModalOpen(false)
                                            }
                                            className="mt-3 px-10  inline-flex justify-center rounded-md bg-indigo-600 text-[12px] py-2 text-white font-semibold shadow-sm hover:bg-indigo-500"
                                          >
                                            Close
                                          </button>
                                        </div>
                                      </Dialog.Panel>
                                    </Dialog>
                                  </div>
                                </div>
                              </div>
                              <div className="bg-white  flex items-center justify-end mt-6 gap-5">
                                <button
                                  type="submit"
                                  className=" bg-gradient-to-r from-blue-500 to-purple-500 text-white font-bold py-1.5 px-6 rounded-sm hover:from-purple-500 hover:to-pink-500 transition-transform transform hover:-translate-y-1 duration-300"
                                >
                                  {auth_loading ? <Spinner /> : "Save Changes"}
                                </button>
                              </div>
                            </form>
                          </div>
                        ) : (
                          <div>
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                PROFILE
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                                <p>
                                  Enim feugiat ut ipsum, neque ut. Tristique mi
                                  id elementum praesent. Gravida in tempus
                                  feugiat netus enim aliquet a, quam
                                  scelerisque. Dictumst in convallis nec in
                                  bibendum aenean arcu.
                                </p>
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Name
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                                {singleuser?.username}
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Email
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                                <time dateTime="1982-06-23">
                                  {singleuser?.email}
                                </time>
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Phone No.
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                                <time dateTime="1982-06-23">
                                  {singleuser?.phone}
                                </time>
                              </dd>
                            </div>
                          </div>
                        )}
                      </dl>
                    </div>
                  )}
                  {currentTab == "KYC" && <UserKycDetail />}
                  {currentTab == "DASHBOARD" && (
                    <UserLeads singleuser={singleuser} />
                  )}
                  {currentTab == "PLAN" && <UserPlan />}
                  {currentTab == "OWNEDFRANCHISE" && <UserOwnFranchise />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default UserProfile;
