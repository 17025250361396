// usersSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getAllUsers = createAsyncThunk(
  "staff/getAllUsers",
  async (_, thunkAPI) => {
    try {
      const response = await fetch("https://api.way4franchise.com/api/v1/users/list");

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getUser = createAsyncThunk(
  "staff/getUser",
  async (id, thunkAPI) => {
    try {
      const response = await fetch(`https://api.way4franchise.com/api/v1/users/${id}`);

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const deleteUsers = createAsyncThunk(
  "staff/deleteUsers",
  async ({id,action}, thunkAPI) => {
    try {
      // Your asynchronous logic to delete student here
      const response = await fetch(`https://api.way4franchise.com/api/v1/users/delete/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({action}),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }
      const data = await response.json();
      return { Id: id, message: data.message };
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const updateUsers = createAsyncThunk(
  "student/updateUsers",
  async ({ id, updatedData }, thunkAPI) => {
    try {
      // Your asynchronous logic to update student here
      const response = await fetch(`https://api.way4franchise.com/api/v1/users/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();

      return data;
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const uploadUserDocuments = createAsyncThunk(
  "auth/uploadUserDocuments",
  async ({ formData }, thunkAPI) => {
    try {
      const response = await fetch("https://api.way4franchise.com/api/v1/users/uploadUserkyc", {
        method: "POST",
        body: formData, 
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "File upload failed");
      }

      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const ApplyForm = createAsyncThunk(
  "auth/applyform",
  async (values, thunkAPI) => {
    try {
      const response = await fetch("https://api.way4franchise.com/api/v1/users/apply", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });
 
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }
 
      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);


export const getAllUserKycData = createAsyncThunk(
  "staff/getAllUserKycData",
  async (_, thunkAPI) => {
    try {
      const response = await fetch("https://api.way4franchise.com/api/v1/users/kyc/data");
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const UserKycAction = createAsyncThunk(
  "staff/UserKycAction",
  async ({userId,action}, thunkAPI) => {
    try {
      // Your asynchronous logic to delete student here
      const response = await fetch(`https://api.way4franchise.com/api/v1/users/update/kyc`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({userId,action}),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }
      const data = await response.json();
      return { Id: userId, message: data.message };
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getUserKycById = createAsyncThunk(
  "staff/getUserKycById",
  async (userId, thunkAPI) => {
    try {
      const response = await fetch(`https://api.way4franchise.com/api/v1/users/singlekyc/${userId}`);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const initialState = {
  allusers:null,
  userKyc:null,
  singleKyc:null,
  singleuser:null,
  loading: false,
  error: null,
  message: null,
};

const userSlice = createSlice({
  name: "alluser",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.allusers = action.payload.allusers;
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
      .addCase(getUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.singleuser = action.payload.singleuser;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
    
      .addCase(deleteUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.message = action.payload.message;
      })
      .addCase(deleteUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
      .addCase(updateUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.message = action.payload.message;
      })
      .addCase(updateUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })


      .addCase(uploadUserDocuments.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(uploadUserDocuments.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.message = action.payload.message;
      })
      .addCase(uploadUserDocuments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })

      .addCase(getAllUserKycData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllUserKycData.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.userKyc = action.payload.userKyc;
      })
      .addCase(getAllUserKycData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      }) 

      .addCase(getUserKycById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserKycById.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.singleKyc = action.payload.singleKyc;
      })
      .addCase(getUserKycById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      }) 

      .addCase(UserKycAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(UserKycAction.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.message = action.payload.message;
      })
      .addCase(UserKycAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      }) 

      .addCase(ApplyForm.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(ApplyForm.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.message = action.payload.message;
      })
      .addCase(ApplyForm.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
  },
});

export const { clearErrors, clearMessage } = userSlice.actions;

export default userSlice.reducer;

