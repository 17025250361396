import React from "react";
import Header from "../Comman/Header";
import Footer from "../Comman/Footer";

const CookiePolicy = () => {
  return (
    <>
      <Header />
      <div className="w-7xl mx-10 pt-10">
        <div>
          <h1 className="text-2xl uppercase font-semibold mb-4 ">
            Cookie Policy
          </h1>
        </div>
        <div className="grid grid-cols-12 gap-3">
          <div className="lg:col-span-8 col-span-12">
            <div className="border shadow-md rounded-md">
              <div className="border-b bg-gray-200 py-2">
                <h5 className="text-lg font-semibold  px-4 capitalize">
                  {" "}
                  Introduction
                </h5>
              </div>
              <div className="py-3">
              <p className="text-gray-700 px-4 pb-1">
                Wayforfranchise ("us", "we", or "our") uses cookies on our
                website (the "Service"). By using the Service, you consent to
                the use of cookies.
              </p>
              <p className="text-gray-700 px-4 ">
                Our Cookie Policy explains what cookies are, how we use cookies,
                how third-parties we may partner with may use cookies on the
                Service, your choices regarding cookies and further information
                about cookies.
              </p>
              </div>
            </div>
            <div className="border shadow-md rounded-md mt-4">
              <div className="border-b bg-gray-200 py-2">
                <h5 className="text-lg font-semibold  px-4 capitalize">
                  {" "}
                  What are cookies
                </h5>
              </div>
              <div className="py-3">
              <p className="text-gray-700 px-4 pb-1">
                Cookies are small pieces of text sent by your web browser by a
                website you visit. A cookie file is stored in your web browser
                and allows the Service or a third-party to recognize you and
                make your next visit easier and the Service more useful to you.
              </p>
              <p className="text-gray-700 px-4 ">
                Cookies can be "persistent" or "session" cookies.
              </p>
              </div>
            </div>
            <div className="border shadow-md rounded-md mt-4">
              <div className="border-b bg-gray-200 py-2">
                <h5 className="text-lg font-semibold  px-4 capitalize">
                  {" "}
                  Third-party cookies
                </h5>
              </div>
              <div className="py-3">
              <p className="text-gray-700 px-4 pb-1">
              In addition to our own cookies, we may also use various third-parties cookies to report usage statistics of the Service, deliver advertisements on and through the Service, and so on.

              </p>
             
            </div>
            </div>
            <div className="border shadow-md rounded-md mt-4">
              <div className="border-b bg-gray-200 py-2">
                <h5 className="text-lg font-semibold  px-4 capitalize">
                  {" "}
                  What are your choices regarding cookies
                </h5>
              </div>
              <div className="py-3">
              <p className="text-gray-700 px-4 py-3">
              If you'd like to delete cookies or instruct your web browser to delete or refuse cookies, please visit the help pages of your web browser.

              </p>
              <p className="text-gray-700 px-4 py-3">
              For any other web browser, please visit your web browser's official web pages.
              </p>
              </div>
            </div>
            <div className="border shadow-md rounded-md mt-4">
              <div className="border-b bg-gray-200 py-2">
                <h5 className="text-lg font-semibold  px-4 capitalize">
                  {" "}
                  Changes to this cookie policy

                </h5>
              </div>
              <div className="py-3">
              <p className="text-gray-700 px-4 py-3">
              We may update our Cookie Policy from time to time. We will notify you of any changes by posting the new Cookie Policy on this page.
              </p>
              <p className="text-gray-700 px-4 py-3">
              You are advised to review this Cookie Policy periodically for any changes. Changes to this Cookie Policy are effective when they are posted on this page.
               </p>
            </div>
            </div>
          </div>
          <div className="lg:col-span-4 col-span-12">
            <div className="border shadow-md rounded-md">
              <div className="border-b bg-gray-200 py-2">
                <h5 className="text-lg font-semibold  px-4 capitalize">
                  {" "}
                  How Wayforfranchise uses cookies
                 
                </h5>
              </div>
              <ul className="px-10 py-4 list-disc ">
                <li className="py-1 text-justify">
                To enable certain functions of the Service.
                </li>
                <li className="py-1 text-justify">
                To provide analytics.
                </li>
                <li className="py-1 text-justify">
                To store your preferences.
                </li>
                <li className="py-1 text-justify">
                Essential cookies. We may use essential cookies to authenticate users and prevent fraudulent use of user accounts.
                </li>
                <li className="py-1 text-justify">
                Preferences cookies. We may use preferences cookies to remember information that changes the way the Service behaves or looks, such as the "remember me" functionality of a registered user or a user's language preference.
                </li>
                <li className="py-1 text-justify">
                Analytics cookies. We may use analytics cookies to track information how the Service is used so that we can make improvements. We may also use analytics cookies to test new advertisements, pages, features or new functionality of the Service to see how our users react to them.
                </li>
                <li className="py-1 text-justify">
                Advertising cookies. These type of cookies are used to deliver advertisements on and through the Service and track the performance of these advertisements. These cookies may also be used to enable third-party advertising networks to deliver ads that may be relevant to you based upon your activities or interests.

                </li>
                
              </ul>
            </div>
            <div className="border shadow-md rounded-md mt-4">
              <div className="border-b bg-gray-200 py-2">
                <h5 className="text-lg font-semibold  px-4 capitalize">
                  {" "}
                  What are your choices regarding cookies
                </h5>
              </div>
              <ul className="px-10 py-4 list-disc ">
                <li className="py-1">
                All About Cookies:http://www.allaboutcookies.org/
                </li>
                <li className="py-1 ">
                Network Advertising Initiative:
                 http://www.networkadvertising.org/
                </li>
          
                
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CookiePolicy;
