import React, { useRef, useState } from 'react';

const ImageGallery = ({banner}) => {
  const galleryRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('');

  const handleImageClick = (src) => {
    setCurrentImage(src);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setCurrentImage('');
  };

  return (
    <div className="relative w-full max-w-[90%] mx-auto overflow-hidden py-4">
      {/* Responsive Grid Layout */}
      <div className="grid grid-cols-1 md:grid-cols-12 gap-4 h-full" ref={galleryRef}>
        {/* Main Image */}
        <div className="col-span-12 md:col-span-8 h-[510px]">
          <img
            src={`/uploads/InvestorData/${banner[0]}`}
            alt="Friendly Diamonds Banner"
            className="w-full h-full object-cover cursor-pointer"
            onClick={() => handleImageClick(`/uploads/InvestorData/${banner[0]}`)}
            data-index={1}
          />
        </div>

        {/* Right column: Small Images */}
        <div className="col-span-12 md:col-span-4 grid grid-cols-1 gap-2 h-full">
          <img
            src={`/uploads/InvestorData/${banner[1]}`}
            alt="Image 1"
            className="w-full h-[250px] object-cover cursor-pointer"  // Keep height proportional
            onClick={() => handleImageClick(`/uploads/InvestorData/${banner[1]}`)}
            data-index={2}
          />
          <img
            src={`/uploads/InvestorData/${banner[2]}`}
            alt="Image 2"
            className="w-full h-[250px] object-cover cursor-pointer"  // Keep height proportional
            onClick={() => handleImageClick(`/uploads/InvestorData/${banner[2]}`)}
            data-index={3}
          />
        </div>
      </div>
      {/* Modal for Previewing Images */}
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-50">
          <div className="relative">
            <img
              src={currentImage}
              alt="Preview"
              className="max-w-5xl max-h-screen object-contain"
            />
            <button 
              className="absolute top-4 right-4 text-white text-3xl bg-gray-800 rounded-full p-2"
              onClick={closeModal}
            >
              &times;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageGallery;
