import { useDispatch, useSelector } from "react-redux";
import {uploadUserDocuments} from "../../redux/userSlice";
import Spinner from "../../BaseFile/Spinner";
import React, { useState, useEffect } from "react";
import SuccessAlert from "../../BaseFile/SuccessAlert";
import ErrorAlert from "../../BaseFile/ErrorAlert";

export default function KycModel({closeModal}){
  const dispatch = useDispatch();
  const { loading} = useSelector((state) => state.allusers);
  const { admin,auth } = useSelector((state) => state.auth);

  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState(null);


  const handleDocumentChange1 = (e) => {
    setImage1(e.target.files[0]);
   }
    const handleDocumentChange2 = (e) => {
    setImage2(e.target.files[0]);
   }
    const handleDocumentChange3 = (e) => {
    setImage3(e.target.files[0]);
   }

   const handleSubmit = async (e) => {
    e.preventDefault();
    if(!image1 || !image2  ){
      alert("Please upload all images")
      return
    }
     
    const formData = new FormData();
    formData.append("image1", image1);
    formData.append("image2", image2);
    formData.append("image3", image3);
    formData.append("userId", auth?._id);
    dispatch(uploadUserDocuments({formData}))
     }
    return(
        <>
         
         <div className="fixed inset-0  flex items-center justify-center bg-black bg-opacity-50 z-30">
                <div className="lg:h-auto w-[400px] pb-12 overflow-y-scroll lg:overflow-y-hidden bg-white p-6 shadow-lg border rounded-md">
                  <div className="">
                    <div className="flex justify-between items-center mb-4 border-b border-gray-400 pb-6 pt-2">
                      <h2 className="text-lg font-bold ">User KYC</h2>
                      <button onClick={closeModal}>
                        <div class="group flex h-10 w-20 cursor-pointer items-center justify-center rounded-3xl ">
                          <div class="space-y-2">
                            <span class="block h-1 w-10 origin-center rounded-full bg-slate-500 transition-transform ease-in-out group-hover:translate-y-1.5 group-hover:rotate-45"></span>
                            <span class="block h-1 w-8 origin-center rounded-full bg-orange-500 transition-transform ease-in-out group-hover:w-10 group-hover:-translate-y-1.5 group-hover:-rotate-45"></span>
                          </div>
                        </div>
                      </button>
                    </div>
                    <form onSubmit={handleSubmit} className="">
                      <div className="mb-3">
                        <label className="block text-left text-gray-800 mb-2 text-[13px] font-medium">
                          Adhar Card <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="file"
                          onChange={handleDocumentChange1}
                          className="block w-full py-2 px-3 text-[12px] text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300 ease-in-out"
                          accept="image/*"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="block text-left text-gray-800 mb-2 text-[13px] font-medium">
                          Pan Card<span className="text-red-500">*</span>
                        </label>
                        <input
                          type="file"
                          onChange={handleDocumentChange2}
                          className="block w-full py-2 px-3 text-[12px] text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300 ease-in-out"
                          accept="image/*"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="block text-left text-gray-800 mb-2 text-[13px] font-medium">
                          Other
                        </label>
                        <input
                          type="file"
                          onChange={handleDocumentChange3}
                          className="block w-full py-2 px-3 text-[12px] text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300 ease-in-out"
                          accept="image/*"
                        />
                      </div>

                      <div className="col-span-2 justify-end flex mt-5">
                        <button
                          type="submit"
                          className="w-full px-6 py-2 text-sm bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-300"
                        >
                           {loading ? <Spinner /> : "Submit"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
        </>
    )
}