import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules"; // Correct Autoplay import
import "swiper/css"; // Correct Swiper CSS import

const FranchiseBanner = () => {
  const cardData = [
    {
      img: "/Diwalioffer.png",
    },
    {
      img: "/Diwalioffors2.png",
    },
    {
      img: "/Diwalioffors3.png",
    },
    {
      img: "/Diwalioffors4.png",
    },
  ];

  return (
    <div className="max-w-full mx-10 mt-10 py-10 px-4">
      <div className="mb-5 flex justify-between text-sm">
        <div className="text-indigo-600 flex items-center pb-2 pr-2 border-b-2 border-gray-800 capitalize ">
          <h2 className="font-semibold  lg:text-2xl sm:text-xl text-lg text-gray-800 inline-block">
            Offers 
          </h2>
        </div>
       
      </div>
      <Swiper
        spaceBetween={30}
        slidesPerView={1}
        loop={true}
        grabCursor={true}
        autoplay={{ delay: 3000, disableOnInteraction: false }} // Autoplay config
        modules={[Autoplay]} // Add Autoplay module
        className="w-full"
      >
        {cardData.map((card, index) => (
          <SwiperSlide key={index}>
            <div className=" w-full">
              <div className="h-full bg-gray-100 rounded shadow-xl hover:custom-shadow">
                <img
                  src={card.img}
                  className="w-full h-[400px] object-cover" // Use object-contain to fit the image within the height
                  alt={`Slide ${index + 1}`} // Added alt text for accessibility
                />
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default FranchiseBanner;
