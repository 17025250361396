import { useLayoutEffect, useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaCheck, FaTimes } from "react-icons/fa";
import { GrEdit } from "react-icons/gr";
import {
  getAllFranchise,
  getAllPromoted,
  promotFranchise,
  clearErrors,
  clearMessage,
} from "../redux/franchiseSlice";
import Loader from "../BaseFile/Loader";
import SuccessAlert from "../BaseFile/SuccessAlert";
import ErrorAlert from "../BaseFile/ErrorAlert";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function AdminMarketing() {
  const dispatch = useDispatch();
  const { allfranchise, allpromoted, loading, error, message } = useSelector(
    (state) => state.franchise
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [AllFranchise, setAllFranchise] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [loadingitem, setLoadingitem] = useState([]);
  useEffect(() => {
    dispatch(getAllFranchise());
    dispatch(getAllPromoted());
    if (error) {
      const errorInterval = setInterval(() => {
        dispatch(clearErrors());
      }, 3000);
      return () => clearInterval(errorInterval);
    }

    if (message) {
      const messageInterval = setInterval(() => {
        dispatch(clearMessage());
      }, 3000);
      return () => clearInterval(messageInterval);
    }
  }, [dispatch, message, error, clearErrors, clearMessage]);

  useEffect(() => {
    if (allfranchise) {
      if (searchQuery) {
        setAllFranchise(
          allfranchise.filter((item) =>
            item.franchise_name
              ?.toLowerCase()
              .includes(searchQuery.toLowerCase())
          )
        );
      } else {
        setAllFranchise(allfranchise);
      }
    }
  }, [searchQuery, allfranchise]);

  const handleCheckboxChange = (id) => (e) => {
    const isChecked = e.target.checked;
    setLoadingitem(id)
    dispatch(promotFranchise({ id: id, status: isChecked }));

    // Update checkedItems state
    if (isChecked) {
      setCheckedItems((prev) => [...prev, id]); // Add to checked items
    } else {
      setCheckedItems((prev) => prev.filter((itemId) => itemId !== id)); // Remove from checked items
    }
  };
  return (
    <>
      {/* {loading ? (
        <Loader />
      ) : ( */}
      <div className="px-4 border shadow-sm rounded-sm bg-white py-5">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Users
            </h1>
            <p className=" text-sm text-gray-700">
              A list of all the users in your account including their name,
              title, email and role.
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <input
              type="search"
              onChange={(e) => setSearchQuery(e.target.value)}
              className="border px-2 py-1.5 text-[12px] rounded-md border-gray-400"
              placeholder="Search"
            />
          </div>
        </div>
        <div className="mt-6 flow-root border bg-white shadow-sm rounded-sm overflow-hidden h-[530px]">
          <div className="overflow-x-auto h-[480px] overflow-y-auto no-scrollbar pb-7">
            <div className="inline-block min-w-full  align-middle">
              <div className="relative">
                <table className="min-w-full table-fixed divide-y divide-gray-300">
                  <thead className="text-gray-900 uppercase">
                    <tr>
                      <th
                        scope="col"
                        className=" py-3.5 pl-4 text-left text-[13px] font-semibold "
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-[13px] font-semibold "
                      >
                        Industry
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-[13px] font-semibold "
                      >
                        Current Outlets
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-[13px] font-semibold "
                      >
                        Floor Area
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {(searchQuery ? AllFranchise : allfranchise)?.map(
                      (company) => (
                        <tr
                          key={company?.email}
                          className={
                            allpromoted?.includes(company?._id)
                              ? "bg-gray-50 "
                              : undefined
                          }
                        >
                          <td className="relative px-7 sm:w-12 sm:px-6">
                            {allpromoted?.includes(company?._id) && (
                              <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                            )}
                            {loading && loadingitem==company?._id ? (
                              <img src="/loading.gif" className="h-6 absolute left-4 top-1/2 -mt-2"/>
                             
                            ) : (
                              <input
                                type="checkbox"
                                className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                value={company?._id}
                                checked={allpromoted?.includes(company?._id)} // Ensure this is a string comparison
                                onChange={handleCheckboxChange(company?._id)} // Pass the company as an argument
                              />
                            )}
                            <span
                              className={classNames(
                                "whitespace-nowrap py-4 pl-5 text-[13px] font-medium capitalize"
                                // selectedPeople.includes(company) ? "text-indigo-600" : "text-gray-900"
                              )}
                            >
                              {company?.franchise_name}
                            </span>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-[13px] text-gray-500">
                            {company?.industry}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-[13px] text-gray-500">
                            {company?.currentOutlets}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-[13px] text-gray-500">
                            {company?.floor_Area}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>





      </div>
      {/* )} */}
    </>
  );
}
