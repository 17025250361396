import React, { useState, useEffect } from "react";
import { getAllFranchise } from "../../redux/franchiseSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

const TopDealerShip = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState();
  const dispatch = useDispatch();
  const { allfranchise, loading, error, message } = useSelector(
    (state) => state.franchise
  );

  useEffect(() => {
    dispatch(getAllFranchise());
  }, []);
  function HandleHover(bool, ind) {
    setIsHovered(bool);
    setHoveredIndex(ind);
  }
  return (
    <>
      <div className="max-w-full mx-10 mt-12  px-4 ">
     

        <div className="w-full pl-5 lg:pl-2 mb-24  px-2">
          <div className="flex justify-between text-sm ">
            <div className="text-indigo-600 flex items-center pb-2 pr-2 border-b-2 border-gray-800 capitalize">
              <h2 className="font-semibold  lg:text-2xl sm:text-xl text-lg text-gray-800 inline-block">
                Best Seller Opportunities
              </h2>
            </div>
            <Link to="/viewall?heading=Best Seller Opportunities" className="text-base text-blue-500">
              See All
            </Link>
          </div>
        </div>

        <div className="grid xl:grid-cols-4 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-4 lg:space-y-0 md:space-y-20 sm:space-y-20 space-y-20 ">
          {allfranchise?.slice(11,15)?.map((item, index) => (
            <div
              key={index}
              className="flex flex-col bg-white relative border  shadow-xl transition duration-700 ease-in-out rounded-lg p-6"
            >
              <div className="absolute shadow-lg -top-20 left-1/2 transform -translate-x-1/2 p-3 border-8 border-white rounded-full bg-[#d17e7e]">
                <img
                   src={
                    item?.companyLogo
                      ? `/uploads/InvestorData/${item?.companyLogo}`
                      : "/defaultlogo.png"
                  }
                  className="rounded-full w-24 h-24 object-cover"
                  alt="Dealership"
                />
              </div>

              <div className="flex flex-col items-start mt-10">
                <p className="text-[12px]">Type : {item?.looking_for}</p>
                <h4 className="text-[13px] font-semibold">Name : {item?.franchise_name}</h4>
                <ul className="w-full ">
                    <li className="py-1">
                      <div className="text-[12px]">
                        <p className=" font-medium">Required area : {item?.floor_Area} Sq. ft</p>
                        <p className=" font-medium">Min Investment tier 1 :{item?.investment_range_tier_1}</p>
                        <p className=" font-medium">Min Investment tier 2 :{item?.investment_range_tier_2}</p>
                      </div>
                    </li>
                </ul>
                <Link  to={`/franchisedetail/${item.franchise_name.replace(
                            " ",
                            "_"
                          )}/${item?._id}`}
                  className="p-2 leading-none w-full text-center text-[13px] rounded mt-3 text-gray-100 font-semibold bg-gray-800  py-3  uppercase"
                  href="#"
                >
                  Know More
                  </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default TopDealerShip;
