import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMarketingDetail } from "../redux/franchiseSlice";

const people = [
  {
    name: "Lindsay Walton",
    title: "Front-end Developer",
    email: "lindsay.walton@example.com",
    role: "Member",
  },
  {
    name: "Lindsay Walton",
    title: "Front-end Developer",
    email: "lindsay.walton@example.com",
    role: "Member",
  },
  {
    name: "Lindsay Walton",
    title: "Front-end Developer",
    email: "lindsay.walton@example.com",
    role: "Member",
  },
];
const MarketingDetails = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state.auth);
  const { franchiseMarketingDetails } = useSelector((state) => state.franchise);
  useEffect(() => {
    if (auth?._id) {
      const _id = auth?._id;
      dispatch(getMarketingDetail({ _id }));
    }
  }, [auth?._id]);
  return (
    <>
      {franchiseMarketingDetails?.length == 0 ? (
        <div className="mt-4">
          <p className="py-2 px-4 bg-gray-100 border shadow-sm text-center font-semibold capitalize">
            No Marketing pack Activated
          </p>
        </div>
      ) : franchiseMarketingDetails?.status == false ? (
        <div className="mt-4">
          <p className="py-2 px-4 bg-gray-100 border shadow-sm text-center font-semibold capitalize">
            Marketing pack Expaired
          </p>
        </div>
      ) : (
        <>
          <div className="px-4 py-5 sm:px-0 sm:py-0">
            <dl className="space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200">
              <div className="flex justify-between items-center">
                <div className="">
                  <h2
                    id="marketing"
                    className="text-base uppercase font-semibold leading-6 text-gray-900 border-b p-5"
                  >
                    PLAN DETAILS
                  </h2>
                </div>
              </div>
              <div className="sm:flex sm:px-6 sm:py-5">
                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                  Total Target
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                  10 dealing
                </dd>
              </div>
              <div className="sm:flex sm:px-6 sm:py-5">
                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48 mt-1">
                  Selected Area
                </dt>
                <dd className=" text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                  <div className="flex gap-3">
                    <sapn className="bg-green-200 px-3 py-1 border rounded-sm">
                      {" "}
                      Delhi
                    </sapn>
                    <sapn className="bg-yellow-200 px-3 py-1 border rounded-sm">
                      {" "}
                      Punjab
                    </sapn>
                    <sapn className="bg-purple-200 px-3 py-1 border rounded-sm">
                      {" "}
                      Himachal pradesh
                    </sapn>
                  </div>
                </dd>
              </div>
              <div className="sm:flex sm:px-6 sm:py-5">
                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                  Achive Target
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                  <time dateTime="1982-06-23">4 Achived</time>
                </dd>
              </div>
              <div className="sm:flex sm:px-6 sm:py-5">
                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                  Remaining Target
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                  <time dateTime="1982-06-23">6 Remaining</time>
                </dd>
              </div>
              <div className="sm:flex sm:px-6 sm:py-5">
                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                  Month
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                  <time dateTime="1982-06-23">3 month</time>
                </dd>
              </div>
              <div className="sm:flex sm:px-6 sm:py-5">
                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                  Remaining Month
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                  <time dateTime="1982-06-23">1 Remaining Month</time>
                </dd>
              </div>
            </dl>
          </div>

          <div className=" px-4">
            <div className="mt-6">
              <h1 className="text-lg font-semibold capitalize  py-2 ">
                Achive Target Detail
              </h1>
            </div>
            <div className=" flow-root border mb-3 mt-0">
              <div className=" overflow-x-auto ">
                <div className="inline-block min-w-full align-middle">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-900 text-white">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold  sm:pl-6 lg:pl-8"
                        >
                          Id
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold  sm:pl-6 lg:pl-8"
                        >
                          State
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold "
                        >
                          Address
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold "
                        >
                          Create At
                        </th>
                        {/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Role
                  </th> */}
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white hover:bg-gray-50">
                      {people.map((person) => (
                        <tr key={person.email}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                            1
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                            {person.name}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {person.title}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {person.email}
                          </td>
                          {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.role}</td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default MarketingDetails;
