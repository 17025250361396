import React, { useState, useEffect, useRef } from "react";
import { State, City } from "country-state-city";
import { PlusSmallIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../BaseFile/Spinner";
import Header from "../../HomeFiels/Comman/Header";
import {
  sendOtp,
  signUpInvestor,
  clearAuthErrors,
  clearAuthMessage,
} from "../../redux/authSlice";
import { Dialog } from "@headlessui/react";
import SuccessAlert from "../../BaseFile/SuccessAlert";
import ErrorAlert from "../../BaseFile/ErrorAlert";
import Footer from "../../HomeFiels/Comman/Footer";
import InvestorOtpVerification from "./InvestorOtpVerification";
import industriesData from "../../BaseFile/CategoryData";

const faqData = [
  {
    question: "What is Way4franchise?",
    answer:
      "Way4franchise is a platform dedicated to connecting aspiring entrepreneurs with top franchise opportunities. We provide valuable information, resources, and support to help you start your own business.",
  },
  {
    question: "How does Way4franchise work?",
    answer:
      "You can explore various franchise options on our website. We offer detailed information about each opportunity, including investment requirements, support provided, and potential returns. You can also contact us for personalized assistance.",
  },
  {
    question: "Who can benefit from using Way4franchise?",
    answer:
      "Our services are designed for anyone interested in starting a franchise, including first-time entrepreneurs, experienced business owners, and investors looking for new opportunities.",
  },
  {
    question: "How do I find the right franchise for me?",
    answer:
      "Finding the right franchise involves considering your interests, budget, and long-term goals. You can browse our listings, use our filters to narrow your search, and reach out to our team for personalized guidance.",
  },
  {
    question: "Are there any fees associated with using Way4franchise?",
    answer:
      "No, using Way4franchise to explore franchise opportunities is free. However, there may be fees associated with specific franchises you choose to pursue, which will be outlined by the franchisor.",
  },
  {
    question: "How do I apply for a franchise?",
    answer:
      "Once you find a franchise that interests you, follow the application instructions provided in the franchise listing. If you need help, feel free to contact our support team.",
  },
  {
    question: "How can I contact Way4franchise for support?",
    answer:
      "You can reach our support team by email at info@way4franchise.com or by phone at +91-8053530300. Our office hours are Monday to Saturday, 9 AM to 6 PM.",
  },
  {
    question: "Can I submit my franchise opportunity to Way4franchise?",
    answer:
      "Yes, if you have a franchise opportunity that you would like to promote on our platform, please contact us at info@way4franchise.com for more details on how to submit your listing.",
  },
  {
    question: "How can I stay updated on new franchise opportunities?",
    answer:
      "To stay informed about the latest franchise opportunities and industry news, subscribe to our newsletter and follow us on social media.",
  },
];

const InvestorRegistration = () => {
  const dispatch = useDispatch();
  const { auth_loading, auth_error, auth_message } = useSelector(
    (state) => state.auth
  );
  const resetButtonRef = useRef(null);
  const [companyLogo, setCompnayLogo] = useState([]);
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState(null);
  const [image4, setImage4] = useState(null);
  const [image5, setImage5] = useState(null);
  const [looking_for, setLooking_for] = useState();
  const [partner_unit, setPartner_unit] = useState(false);
  const [partner_master, setPartner_master] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  const [country_wise, setCountry_wise] = useState(false);
  const [rigion_wise, setRigion_wise] = useState(false);
  const [state_wise, setState_wise] = useState(false);
  const [city_wise, setCity_wise] = useState(false);
  const [franchise_name, setFranchise_name] = useState("");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState({});
  const [selectedStates, setSelectedStates] = useState({});
  const [selectedCities, setSelectedCities] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeStateCode, setActiveStateCode] = useState(null);
  const [image6, setImage6] = useState(null);
  const [image7, setImage7] = useState(null);
  const [image8, setImage8] = useState(null);
  const [videoLink, setVideoLink] = useState("");
  const [values, setValues] = useState(false);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState();
  const [selectedIndustry, setSelectedIndustry] = useState("");
  const [availableSectors, setAvailableSectors] = useState([]);
  const [openQuestionIndex, setOpenQuestionIndex] = useState(null);
  const [availableProducts, setAvailableProducts] = useState([]);
  const [OTPverification, setOTPverification] = useState(false);
  const [tradefileds, setTradefileds] = useState([
    { channelType: "", investment: "", commission: "" },
  ]);
  const [distributorfields, setDistributorfields] = useState([
    { channelType: "", investment: "", commission: "" },
  ]);
  useEffect(() => {
    const stateData = State.getStatesOfCountry("IN");
    setStates(stateData);
    if (auth_error) {
      const errorInterval = setInterval(() => {
        dispatch(clearAuthErrors());
      }, 3000);
      return () => clearInterval(errorInterval);
    }
    if (auth_message && auth_message == "OTP verified successfully.") {
      setOTPverification(true);
    }
    if (auth_message) {
      const messageInterval = setInterval(() => {
        dispatch(clearAuthMessage());
      }, 3000);
      return () => clearInterval(messageInterval);
    }
  }, [auth_error, auth_message, clearAuthErrors, clearAuthMessage]);
  const AddTradeFields = () => {
    setTradefileds([
      ...tradefileds,
      { channelType: "", investment: "", commission: "" },
    ]);
  };
  const AddDistributorFields = () => {
    setDistributorfields([
      ...distributorfields,
      { channelType: "", investment: "", commission: "" },
    ]);
  };
  const RemoveTradeFields = (index) => {
    const updatedFields = [...tradefileds];
    updatedFields.splice(index, 1);
    setTradefileds(updatedFields);
  };
  const RemoveDitributorFields = (index) => {
    const updatedFields = [...distributorfields];
    updatedFields.splice(index, 1);
    setDistributorfields(updatedFields);
  };

  const handleStateClick = (stateIsoCode) => {
    const isStateSelected = !!selectedStates[stateIsoCode];
    setSelectedStates((prev) => {
      const updatedStates = { ...prev };
      if (isStateSelected) {
        delete updatedStates[stateIsoCode];
      } else {
        updatedStates[stateIsoCode] = true;
        setSelectedCities((prevCities) => {
          const updatedCities = { ...prevCities };
          delete updatedCities[stateIsoCode];
          return updatedCities;
        });
      }
      return updatedStates;
    });
  };
  const handleIndustryChange = (e) => {
    const selected = e.target.value;
    setSelectedIndustry(selected);

    if (industriesData[selected]) {
      setAvailableSectors(industriesData[selected].sectors);
      setAvailableProducts(industriesData[selected].products);
    } else {
      setAvailableSectors([]);
      setAvailableProducts([]);
    }
  };
  const handleCityClick = (stateIsoCode, cityName) => {
    setSelectedCities((prev) => {
      const updatedCities = { ...prev };
      const stateCities = updatedCities[stateIsoCode] || [];
      if (stateCities.includes(cityName)) {
        updatedCities[stateIsoCode] = stateCities.filter(
          (city) => city !== cityName
        );
      } else {
        updatedCities[stateIsoCode] = [...stateCities, cityName];
      }
      return updatedCities;
    });
  };

  const handleStateDisclosureClick = (stateIsoCode) => {
    if (!cities[stateIsoCode]) {
      const cityData = City.getCitiesOfState("IN", stateIsoCode);
      setCities((prevCities) => ({ ...prevCities, [stateIsoCode]: cityData }));
    }
    setActiveStateCode(stateIsoCode);
    setIsModalOpen(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const resetForm = () => {
    setCompnayLogo(null);
    setImage1(null);
    setImage2(null);
    setImage3(null);
    setImage4(null);
    setImage5(null);
    setLooking_for(null);
    setPartner_unit(false);
    setPartner_master(false);
    setSelectedOption(null);
    setCountry_wise(false);
    setRigion_wise(false);
    setState_wise(false);
    setCity_wise(false);
    setFranchise_name("");
    setSelectedStates({});
    setSelectedCities({});
    setImage6(null);
    setImage7(null);
    setImage8(null);
    setVideoLink("");
    setValues("");
    setSelectedIndustry("");
    setAvailableSectors([]);
    setAvailableProducts([]);
    setOTPverification(false);
    setTradefileds({ channelType: "", investment: "", commission: "" });
    setDistributorfields({ channelType: "", investment: "", commission: "" });
  };

  const handleTradeChange = (index, event) => {
    const updatedFields = [...tradefileds];
    updatedFields[index][event.target.name] = event.target.value;
    setTradefileds(updatedFields);
  };
  const handleDistributorChange = (index, event) => {
    const updatedFields = [...distributorfields];
    updatedFields[index][event.target.name] = event.target.value;
    setDistributorfields(updatedFields);
  };

  const handleVerify = (e) => {
    const email = values.ownerEmail;
    if (email.includes("yopmail")) {
      window.alert("Not a valid Email , yopmail is not allowed");
      setValues({ ownerEmail: "" });
      return;
    } else {
      setOpen(true);
      dispatch(sendOtp(email));
    }
  };
  const handleSave = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("looking_for", looking_for);
    formData.append("franchise_name", franchise_name);
    formData.append("videoLink", videoLink);
    formData.append("selectedStates", JSON.stringify(selectedStates));
    formData.append("selectedCities", JSON.stringify(selectedCities));
    formData.append("companyLogo", companyLogo);
    formData.append("industry", selectedIndustry);

    // Append images if they exist
    [image1, image2, image3, image4, image5, image6, image7, image8].forEach(
      (image, index) => {
        if (image) formData.append(`image${index + 1}`, image);
      }
    );
    Object.keys(values).forEach((key) => {
      formData.append(key, values[key]);
    });

    if (looking_for === "trade_partners") {
      tradefileds.forEach((field, index) => {
        Object.keys(field).forEach((key) => {
          formData.append(key, field[key]);
        });
      });
    } else if (looking_for === "distributor") {
      distributorfields.forEach((field, index) => {
        Object.keys(field).forEach((key) => {
          formData.append(key, field[key]);
        });
      });
    }

    if (OTPverification) {
      dispatch(signUpInvestor(formData));
      setOTPverification(false);
      resetButtonRef.current.click();
      resetForm();
    } else {
      window.alert("Verify Email First");
    }
  };
  const toggleQuestion = (index) => {
    setOpenQuestionIndex(openQuestionIndex === index ? null : index);
  };
  return (
    <>
      {auth_message && <SuccessAlert message={auth_message} />}
      {auth_error && <ErrorAlert error={auth_error} />}
      <Header />
      <div className="max-w-7xl mx-auto py-10">
        <div className="grid grid-cols-12 gap-4">
          <form
            className="lg:col-span-9 col-span-12 "
            onSubmit={(e) => handleSave(e)}
          >
            <div className="bg-white  shadow-sm border rounded-sm pb-8">
              <div className="bg-gray-100">
                <h2 className="text-base font-semibold mb-4 border-b py-3 px-6">
                  Company Detail{" "}
                  <span className="text-red-500"> * fields are required </span>
                </h2>
              </div>
              <div className="mt-3 px-6 grid lg:grid-cols-3 gap-x-6 gap-y-4 md:grid-cols-2 grid-cols-1">
                <div className="  ">
                  <label
                    htmlFor="companyName"
                    className="block  leading-6 text-[12px] text-gray-900"
                  >
                    Comapny Name <span className="text-red-500"> * </span>
                  </label>
                  <div className="mt-1">
                    <div className="flex rounded-sm  shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-inset focus-within:ring-indigo-600 md:max-w-md">
                      <input
                        id="companyName"
                        name="companyName"
                        required
                        type="text"
                        onChange={(e) => handleChange(e)}
                        placeholder="abc pvt ltd."
                        autoComplete="companyName"
                        className="block flex-1 border-0 bg-transparent text-[12px] px-3 py-1.5  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm: sm:leading-6"
                      />
                    </div>
                  </div>
                </div>

                <div className="">
                  <label
                    htmlFor="ownerName"
                    className="block  leading-6  text-[12px] text-gray-900"
                  >
                    CEO / MD / Owner Name{" "}
                    <span className="text-red-500"> * </span>
                  </label>
                  <div className="mt-1">
                    <div className="flex rounded-sm shadow-sm ring-1 ring-inset ring-gray-300  focus-within:ring-inset focus-within:ring-indigo-600 md:max-w-md">
                      <input
                        id="ownerName"
                        name="ownerName"
                        required
                        type="text"
                        onChange={(e) => handleChange(e)}
                        placeholder="Owner Name"
                        autoComplete="ownerName"
                        className="block flex-1 border-0 bg-transparent text-[12px] px-3 py-1.5  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm: sm:leading-6"
                      />
                    </div>
                  </div>
                </div>

                <div className="">
                  <label
                    htmlFor="ownerEmail"
                    className="block  leading-6  text-[12px] text-gray-900"
                  >
                    Email <span className="text-red-500"> * </span>
                  </label>
                  <div className="mt-1">
                    <div className="relative flex rounded-sm shadow-sm ring-1 ring-inset ring-gray-300  focus-within:ring-inset focus-within:ring-indigo-600 md:max-w-md">
                      <input
                        id="ownerEmail"
                        onChange={(e) => handleChange(e)}
                        name="ownerEmail"
                        type="text"
                        required
                        placeholder="Owner@abc.com"
                        autoComplete="ownerEmail"
                        className="block flex-1 border-0 text-[12px] px-3 bg-transparent py-1.5  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm: sm:leading-6"
                      />
                      <button
                        onClick={handleVerify}
                        disabled={!values?.ownerEmail}
                        className={`px-4 py-[9px] bg-indigo-500 text-white font-medium text-[12px] absolute right-0 rounded-r-sm ${
                          !values?.ownerEmail
                            ? "opacity-50 cursor-not-allowed"
                            : ""
                        }`}
                      >
                        Verify
                      </button>
                    </div>
                  </div>
                </div>

                <div className="  ">
                  <label
                    htmlFor="password"
                    className="block  leading-6  text-[12px] text-gray-900"
                  >
                    Password <span className="text-red-500"> * </span>
                  </label>
                  <div className="mt-1">
                    <div className="flex rounded-sm shadow-sm ring-1 ring-inset ring-gray-300  focus-within:ring-inset focus-within:ring-indigo-600 md:max-w-md">
                      <input
                        id="password"
                        name="password"
                        required
                        onChange={(e) => handleChange(e)}
                        type="text"
                        placeholder="password"
                        autoComplete="password"
                        className="block flex-1 border-0 bg-transparent text-[12px] px-3 py-1.5  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm: sm:leading-6"
                      />
                    </div>
                  </div>
                </div>

                <div className="  ">
                  <label
                    htmlFor="phone"
                    className="block  leading-6  text-[12px] text-gray-900"
                  >
                    Phone No. <span className="text-red-500"> * </span>
                  </label>
                  <div className="mt-1">
                    <div className="flex rounded-sm shadow-sm ring-1 ring-inset ring-gray-300  focus-within:ring-inset focus-within:ring-indigo-600 md:max-w-md">
                      <input
                        id="phone"
                        onChange={(e) => handleChange(e)}
                        name="phone"
                        required
                        type="number"
                        placeholder="123456789"
                        autoComplete="phone"
                        className="block flex-1 border-0 text-[12px] px-3 bg-transparent py-1.5  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm: sm:leading-6"
                      />
                    </div>
                  </div>
                </div>

                <div className="  ">
                  <label
                    htmlFor="managerName"
                    className="block  leading-6  text-[12px] text-gray-900"
                  >
                    Manager Name
                  </label>
                  <div className="mt-1">
                    <div className="flex rounded-sm shadow-sm ring-1 ring-inset ring-gray-300  focus-within:ring-inset focus-within:ring-indigo-600 md:max-w-md">
                      <input
                        id="managerName"
                        name="managerName"
                        onChange={(e) => handleChange(e)}
                        type="text"
                        placeholder="Manager Name"
                        autoComplete="managerName"
                        className="block flex-1 border-0 bg-transparent text-[12px] px-3 py-1.5  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm: sm:leading-6"
                      />
                    </div>
                  </div>
                </div>

                <div className="  ">
                  <label
                    htmlFor="managerEmail"
                    className="block  leading-6  text-[12px] text-gray-900"
                  >
                    Manager Email
                  </label>
                  <div className="mt-1">
                    <div className="flex rounded-sm shadow-sm ring-1 ring-inset ring-gray-300  focus-within:ring-inset focus-within:ring-indigo-600 md:max-w-md">
                      <input
                        id="managerEmail"
                        name="managerEmail"
                        onChange={(e) => handleChange(e)}
                        type="text"
                        placeholder="Manager Email"
                        autoComplete="managerEmail"
                        className="block flex-1 border-0 bg-transparent text-[12px] px-3 py-1.5  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm: sm:leading-6"
                      />
                    </div>
                  </div>
                </div>

                <div className="  ">
                  <label
                    htmlFor="managerPhone"
                    className="block  leading-6  text-[12px] text-gray-900"
                  >
                    Manager Phone
                  </label>
                  <div className="mt-1">
                    <div className="flex rounded-sm shadow-sm ring-1 ring-inset ring-gray-300  focus-within:ring-inset focus-within:ring-indigo-600 md:max-w-md">
                      <input
                        id="managerPhone"
                        name="managerPhone"
                        onChange={(e) => handleChange(e)}
                        type="text"
                        placeholder="Manager Phone"
                        autoComplete="managerPhone"
                        className="block flex-1 border-0 bg-transparent text-[12px] px-3 py-1.5  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm: sm:leading-6"
                      />
                    </div>
                  </div>
                </div>

                <div className="  ">
                  <label
                    htmlFor="website"
                    className="block  leading-6  text-[12px] text-gray-900"
                  >
                    Website
                  </label>
                  <div className="mt-1">
                    <div className="flex rounded-sm shadow-sm ring-1 ring-inset ring-gray-300  focus-within:ring-inset focus-within:ring-indigo-600 md:max-w-md">
                      <input
                        id="website"
                        name="website"
                        onChange={(e) => handleChange(e)}
                        type="text"
                        placeholder="abc.com"
                        autoComplete="website"
                        className="block flex-1 border-0 bg-transparent text-[12px] px-3 py-1.5  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm: sm:leading-6"
                      />
                    </div>
                  </div>
                </div>

                <div className="">
                  <label
                    htmlFor="pincode"
                    className="block  leading-6  text-[12px] text-gray-900"
                  >
                    Pincode <span className="text-red-500"> * </span>
                  </label>
                  <div className="mt-1">
                    <div className="flex rounded-sm shadow-sm ring-1 ring-inset ring-gray-300  focus-within:ring-inset focus-within:ring-indigo-600 md:max-w-md">
                      <input
                        id="pincode"
                        onChange={(e) => handleChange(e)}
                        name="pincode"
                        type="number"
                        required
                        placeholder="123456"
                        autoComplete="pincode"
                        className="block flex-1 border-0 bg-transparent text-[12px] px-3 py-1.5  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm: sm:leading-6"
                      />
                    </div>
                  </div>
                </div>

                <div className="  ">
                  <label
                    htmlFor="address"
                    className="block  leading-6  text-[12px] text-gray-900"
                  >
                    Address <span className="text-red-500"> * </span>
                  </label>
                  <div className="mt-1">
                    <div className="flex rounded-sm shadow-sm ring-1 ring-inset ring-gray-300  focus-within:ring-inset focus-within:ring-indigo-600 md:max-w-md">
                      <input
                        id="address"
                        name="address"
                        type="text"
                        required
                        onChange={(e) => handleChange(e)}
                        placeholder="Flat 01 ABC Street New York, USA"
                        autoComplete="address"
                        className="block flex-1 border-0 text-[12px] px-3 bg-transparent py-1.5  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm: sm:leading-6"
                      />
                    </div>
                  </div>
                </div>
                <div className="  ">
                  <label
                    htmlFor="companyLogo"
                    className="block  leading-6  text-[12px] text-gray-900"
                  >
                    Company Logo
                  </label>
                  <div className="mt-1">
                    <div className="flex rounded-sm shadow-sm px-1 ring-1 ring-inset ring-gray-300  focus-within:ring-inset focus-within:ring-indigo-600 md:max-w-md">
                      <input
                        id="companyLogo"
                        onChange={(e) => setCompnayLogo(e.target.files[0])}
                        name="companyLogo"
                        type="file"
                        accept="image/*"
                        className="block flex-1 border-0  text-[12px] bg-transparent py-1  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm: sm:leading-6"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Franchise Details Form  start*/}

            <div className="bg-white  shadow-sm border rounded-sm pb-6 mt-6">
              <div className="bg-gray-100">
                <h2 className="text-base font-semibold mb-4 border-b py-3 px-6">
                  Franchise Details
                </h2>
              </div>
              <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 px-6">
                <div className="">
                  <label
                    htmlFor="franchise_name"
                    className="block text-[12px]  font-bold  leading-6 text-gray-900"
                  >
                    Franchise Name <span className="text-red-500"> * </span>
                  </label>
                  <div className="mt-2 ">
                    <div className="flex w-full rounded-sm shadow-sm ring-1 ring-inset ring-gray-300  focus-within:ring-inset focus-within:ring-indigo-600 ">
                      <input
                        id="franchise_name"
                        name="franchise_name"
                        onChange={handleChange}
                        required
                        type="text"
                        placeholder="franchise_name"
                        autoComplete="franchise_name"
                        className="block flex-1  border-0 bg-transparent py-1.5 text-[12px] pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm: sm:leading-6"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <fieldset className="mt-4 px-6">
                <legend className=" text-[12px]  font-bold leading-6 mb-2 text-gray-900">
                  Are You Looking for ?{" "}
                  <span className="text-red-500"> * </span>
                </legend>
                <div className=" ">
                  <div className=" grid lg:grid-cols-3 gap-x-3  grid-cols-1 mb-4">
                    <div className="flex items-center gap-x-3">
                      <input
                        id="franchisee"
                        name="Looking_for"
                        required
                        onClick={(e) => setLooking_for("franchisee")}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                      <label
                        htmlFor="franchisee"
                        className="block text-[12px]  font-medium leading-6 text-gray-900"
                      >
                        Franchisee
                      </label>
                    </div>
                    <div className="flex items-center gap-x-3">
                      <input
                        id="trade_partners"
                        name="Looking_for"
                        required
                        type="radio"
                        onClick={(e) => setLooking_for("trade_partners")}
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                      <label
                        htmlFor="trade_partners"
                        className="block  text-[12px]  font-medium leading-6 text-gray-900"
                      >
                        Trade partners
                      </label>
                    </div>
                    <div className="flex items-center gap-x-3">
                      <input
                        id="distributor"
                        name="Looking_for"
                        required
                        onClick={(e) => setLooking_for("distributor")}
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                      <label
                        htmlFor="distributor"
                        className="block  text-[12px] font-medium leading-6 text-gray-900"
                      >
                        Distributor
                      </label>
                    </div>
                  </div>
                </div>
              </fieldset>
              <div className="px-6">
                {looking_for == "franchisee" ? (
                  <>
                    <fieldset>
                      <legend className="sr-only">Notifications</legend>
                      <div className=" grid lg:grid-cols-3 gap-x-3  grid-cols-1 mb-4">
                        <div className="relative flex items-start">
                          <div className="flex h-6 items-center">
                            <input
                              id="comments"
                              name="comments"
                              type="checkbox"
                              onClick={() => setPartner_unit(!partner_unit)}
                              aria-describedby="comments-description"
                              className="h-[15px] w-[15px]  rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                          </div>
                          <div className="ml-3 text-[12px] leading-6">
                            <label
                              htmlFor="comments"
                              className="font-medium text-[12px] text-gray-900"
                            >
                              Unit
                            </label>
                          </div>
                        </div>
                        <div className="relative flex items-start ">
                          <div className="flex h-6 items-center">
                            <input
                              id="candidates"
                              name="candidates"
                              type="checkbox"
                              onClick={() => setPartner_master(!partner_master)}
                              aria-describedby="candidates-description"
                              className="h-[15px] w-[15px] rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                          </div>
                          <div className="ml-3 text-[12px] leading-6">
                            <label
                              htmlFor="candidates"
                              className="font-medium text-gray-900"
                            >
                              Master / Multi Units
                            </label>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    {partner_unit && (
                      <div className=" grid lg:grid-cols-3 gap-x-6  grid-cols-1">
                        <div className="">
                          <label
                            htmlFor="unit_price"
                            className="block text-[12px] font-medium leading-6 text-gray-900"
                          >
                            price
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-sm shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                              <select
                                id="unit_price"
                                onChange={handleChange}
                                name="unit_price"
                                type="number"
                                placeholder="20000"
                                autoComplete="unit_price"
                                className="block flex-1 border-0 bg-transparent py-[9px] pl-1 text-[12px] text-gray-900 placeholder:text-gray-400 focus:ring-0  sm:leading-6"
                              >
                                <option>Rs. 10000 - 50000</option>
                                <option>Rs. 50000 - 2lakh</option>
                                <option>Rs. 2lakh - 5lakh</option>
                                <option>Rs. 5lakh - 10lakh</option>
                                <option>Rs. 10lakh - 20lakh</option>
                                <option>Rs. 20lakh - 30lakh</option>
                                <option>Rs. 30lakh - 50lakh</option>
                                <option>Rs. 50lakh - 1 Cr.</option>
                                <option> Rs. 1 Cr. - 2 Cr.</option>
                                <option>Rs. 2 Cr. - 5 Cr.</option>
                                <option>Rs. 5 Cr. above</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="">
                          <label
                            htmlFor="unit_brandFee"
                            className="block text-[12px] font-medium leading-6 text-gray-900"
                          >
                            Brand Fee
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-sm shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                              <input
                                id="unit_brandFee"
                                onChange={handleChange}
                                name="unit_brandFee"
                                type="number"
                                placeholder="unit_brandFee"
                                autoComplete="unit_brandFee"
                                className="block flex-1 border-0 bg-transparent py-1.5 text-[12px] pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0  sm:leading-6"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="">
                          <label
                            htmlFor="unit_commission"
                            className="block text-[12px] font-medium leading-6 text-gray-900"
                          >
                            commission
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-sm shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                              <input
                                id="unit_commission"
                                name="unit_commission"
                                onChange={handleChange}
                                type="number"
                                placeholder="unit_commission"
                                autoComplete="unit_commission"
                                className="block flex-1 border-0 bg-transparent py-1.5 text-[12px] pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0  sm:leading-6"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {partner_master && (
                      <fieldset className="mb-4 mt-6">
                        <legend className="sr-only">Notifications</legend>
                        <div className=" grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 ">
                          <div className="relative flex items-start">
                            <div className="flex h-6 items-center">
                              <input
                                id="comments"
                                name="comments"
                                type="checkbox"
                                onClick={() => setCountry_wise(!country_wise)}
                                aria-describedby="comments-description"
                                className="h-[15px] w-[15px] rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              />
                            </div>
                            <div className="ml-3 text-[12px] leading-6">
                              <label
                                htmlFor="comments"
                                className="font-medium text-[12px] text-gray-900"
                              >
                                Country Wise
                              </label>
                            </div>
                          </div>
                          <div className="relative flex items-start">
                            <div className="flex h-6 items-center">
                              <input
                                id="candidates"
                                name="candidates"
                                type="checkbox"
                                onClick={() => setRigion_wise(!rigion_wise)}
                                aria-describedby="candidates-description"
                                className="h-[15px] w-[15px] rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              />
                            </div>
                            <div className="ml-3 text-[12px] leading-6">
                              <label
                                htmlFor="candidates"
                                className="font-medium text-gray-900"
                              >
                                Region Wise
                              </label>
                            </div>
                          </div>
                          <div className="relative flex items-start">
                            <div className="flex h-6 items-center">
                              <input
                                id="comments"
                                name="comments"
                                type="checkbox"
                                onClick={() => setState_wise(!state_wise)}
                                aria-describedby="comments-description"
                                className="h-[15px] w-[15px] rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              />
                            </div>
                            <div className="ml-3 text-[12px] leading-6">
                              <label
                                htmlFor="comments"
                                className="font-medium text-gray-900"
                              >
                                State Wise
                              </label>
                            </div>
                          </div>
                          <div className="relative flex items-start">
                            <div className="flex h-6 items-center">
                              <input
                                id="candidates"
                                name="candidates"
                                type="checkbox"
                                onClick={() => setCity_wise(!city_wise)}
                                aria-describedby="candidates-description"
                                className="h-[15px] w-[15px] rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              />
                            </div>
                            <div className="ml-3 text-[12px] leading-6">
                              <label
                                htmlFor="candidates"
                                className="font-medium text-gray-900"
                              >
                                City Wise
                              </label>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    )}
                    <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-4">
                      {country_wise && (
                        <>
                          <div className="space-y-5">
                            <div>
                              <label
                                htmlFor="country_price"
                                className="block text-[12px] font-medium leading-6 text-gray-900"
                              >
                                Price
                              </label>
                              <select
                                id="country_price"
                                name="country_price"
                                onChange={handleChange}
                                defaultValue="price"
                                className="mt-2 block w-full rounded-sm border-0  text-[12px] py-[9px] pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300  focus:ring-indigo-600  sm:leading-6"
                              >
                                <option>Rs. 10000 - 50000</option>
                                <option>Rs. 50000 - 2lakh</option>
                                <option>Rs. 2lakh - 5lakh</option>
                                <option>Rs. 5lakh - 10lakh</option>
                                <option>Rs. 10lakh - 20lakh</option>
                                <option>Rs. 20lakh - 30lakh</option>
                                <option>Rs. 30lakh - 50lakh</option>
                                <option>Rs. 50lakh - 1 Cr.</option>
                                <option> Rs. 1 Cr. - 2 Cr.</option>
                                <option>Rs. 2 Cr. - 5 Cr.</option>
                                <option>Rs. 5 Cr. above</option>
                              </select>
                            </div>
                            <div>
                              <label
                                htmlFor="country_brandFee"
                                className="block text-[12px] font-medium leading-6 text-gray-900"
                              >
                                Brand Fee
                              </label>
                              <input
                                id="country_brandFee"
                                name="country_brandFee"
                                onChange={handleChange}
                                placeholder="Unit/Brand Fee"
                                className="mt-2 block w-full rounded-sm border-0  pl-3 pr-10  text-[12px] py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300  focus:ring-indigo-600  sm:leading-6"
                              />
                            </div>
                            <div>
                              <label
                                htmlFor="country_masterBrandFee"
                                className="block text-[12px] font-medium leading-6 text-gray-900"
                              >
                                Master Brand Fee
                              </label>
                              <input
                                id="country_masterBrandFee"
                                name="country_masterBrandFee"
                                onChange={handleChange}
                                placeholder="Master/Brand Fee"
                                className="mt-2 block w-full rounded-sm border-0  text-[12px] py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300  focus:ring-indigo-600  sm:leading-6"
                              />
                            </div>
                            <div>
                              <label
                                htmlFor="country_commission"
                                className="block text-[12px] font-medium leading-6 text-gray-900"
                              >
                                Commission
                              </label>
                              <input
                                id="country_commission"
                                name="country_commission"
                                onChange={handleChange}
                                placeholder="Royalty Commission %"
                                className="mt-2 block w-full rounded-sm border-0  text-[12px] py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300  focus:ring-indigo-600  sm:leading-6"
                              />
                            </div>
                          </div>
                        </>
                      )}

                      {rigion_wise && (
                        <>
                          <div className="space-y-5">
                            <div>
                              <label
                                htmlFor="rigion_price"
                                className="block text-[12px] font-medium leading-6 text-gray-900"
                              >
                                Price
                              </label>
                              <select
                                id="rigion_price"
                                name="rigion_price"
                                onChange={handleChange}
                                defaultValue="rigion_price"
                                className="mt-2 block w-full rounded-sm border-0  text-[12px] py-[9px] pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300  focus:ring-indigo-600  sm:leading-6"
                              >
                                <option>Rs. 10000 - 50000</option>
                                <option>Rs. 50000 - 2lakh</option>
                                <option>Rs. 2lakh - 5lakh</option>
                                <option>Rs. 5lakh - 10lakh</option>
                                <option>Rs. 10lakh - 20lakh</option>
                                <option>Rs. 20lakh - 30lakh</option>
                                <option>Rs. 30lakh - 50lakh</option>
                                <option>Rs. 50lakh - 1 Cr.</option>
                                <option> Rs. 1 Cr. - 2 Cr.</option>
                                <option>Rs. 2 Cr. - 5 Cr.</option>
                                <option>Rs. 5 Cr. above</option>
                              </select>
                            </div>
                            <div>
                              <label
                                htmlFor="rigion_brandFee"
                                className="block text-[12px] font-medium leading-6 text-gray-900"
                              >
                                Brand Fee
                              </label>
                              <input
                                id="rigion_brandFee"
                                name="rigion_brandFee"
                                onChange={handleChange}
                                placeholder="Unit / Brand Fee"
                                className="mt-2 block w-full rounded-sm border-0  text-[12px] py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300  focus:ring-indigo-600  sm:leading-6"
                              />
                            </div>
                            <div>
                              <label
                                htmlFor="rigion_masterBrandFee"
                                className="block text-[12px] font-medium leading-6 text-gray-900"
                              >
                                Master Brand Fee
                              </label>
                              <input
                                id="rigion_masterBrandFee"
                                name="rigion_masterBrandFee"
                                onChange={handleChange}
                                placeholder="Master / Brand Fee"
                                className="mt-2 block w-full rounded-sm border-0  text-[12px] py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300  focus:ring-indigo-600  sm:leading-6"
                              />
                            </div>
                            <div>
                              <label
                                htmlFor="rigion_commission"
                                className="block text-[12px] font-medium leading-6 text-gray-900"
                              >
                                Commission
                              </label>
                              <input
                                id="rigion_commission"
                                name="rigion_commission"
                                onChange={handleChange}
                                placeholder="Royalty / Commission %"
                                className="mt-2 block w-full rounded-sm border-0  text-[12px] py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300  focus:ring-indigo-600  sm:leading-6"
                              />
                            </div>
                          </div>
                        </>
                      )}

                      {state_wise && (
                        <>
                          <div className="space-y-5">
                            <div>
                              <label
                                htmlFor="state_price"
                                className="block text-[12px] font-medium leading-6 text-gray-900"
                              >
                                Price
                              </label>
                              <select
                                id="state_price"
                                name="state_price"
                                onChange={handleChange}
                                defaultValue="state_price"
                                className="mt-2 block w-full text-[12px] rounded-sm border-0 py-[9px] pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300  focus:ring-indigo-600  sm:leading-6"
                              >
                                <option>Rs. 10000 - 50000</option>
                                <option>Rs. 50000 - 2lakh</option>
                                <option>Rs. 2lakh - 5lakh</option>
                                <option>Rs. 5lakh - 10lakh</option>
                                <option>Rs. 10lakh - 20lakh</option>
                                <option>Rs. 20lakh - 30lakh</option>
                                <option>Rs. 30lakh - 50lakh</option>
                                <option>Rs. 50lakh - 1 Cr.</option>
                                <option> Rs. 1 Cr. - 2 Cr.</option>
                                <option>Rs. 2 Cr. - 5 Cr.</option>
                                <option>Rs. 5 Cr. above</option>
                              </select>
                            </div>
                            <div>
                              <label
                                htmlFor="state_brandFee"
                                className="block text-[12px] font-medium leading-6 text-gray-900"
                              >
                                Brand Fee
                              </label>
                              <input
                                id="state_brandFee"
                                name="state_brandFee"
                                onChange={handleChange}
                                placeholder="Unit / Brand Fee"
                                className="mt-2 block w-full rounded-sm border-0 text-[12px] py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300  focus:ring-indigo-600  sm:leading-6"
                              />
                            </div>
                            <div>
                              <label
                                htmlFor="state_masterBrandFee"
                                className="block text-[12px] font-medium leading-6 text-gray-900"
                              >
                                Master Brand Fee
                              </label>
                              <input
                                id="state_masterBrandFee"
                                name="state_masterBrandFee"
                                onChange={handleChange}
                                placeholder="Master / Brand Fee"
                                className="mt-2 block w-full rounded-sm border-0 text-[12px] py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300  focus:ring-indigo-600  sm:leading-6"
                              />
                            </div>
                            <div>
                              <label
                                htmlFor="state_commission"
                                className="block text-[12px] font-medium leading-6 text-gray-900"
                              >
                                Commission
                              </label>
                              <input
                                id="state_commission"
                                name="state_commission"
                                onChange={handleChange}
                                placeholder="Royalty / Commission %"
                                className="mt-2 block w-full rounded-sm border-0 text-[12px] py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300  focus:ring-indigo-600  sm:leading-6"
                              />
                            </div>
                          </div>
                        </>
                      )}

                      {city_wise && (
                        <>
                          <div className="space-y-5">
                            <div>
                              <label
                                htmlFor="city_price"
                                className="block text-[12px] font-medium leading-6 text-gray-900"
                              >
                                Price
                              </label>
                              <select
                                id="city_price"
                                name="city_price"
                                onChange={handleChange}
                                defaultValue="price"
                                className="mt-2 block w-full rounded-sm text-[12px] border-0 py-[9px] pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300  focus:ring-indigo-600  sm:leading-6"
                              >
                                <option>Rs. 10000 - 50000</option>
                                <option>Rs. 50000 - 2lakh</option>
                                <option>Rs. 2lakh - 5lakh</option>
                                <option>Rs. 5lakh - 10lakh</option>
                                <option>Rs. 10lakh - 20lakh</option>
                                <option>Rs. 20lakh - 30lakh</option>
                                <option>Rs. 30lakh - 50lakh</option>
                                <option>Rs. 50lakh - 1 Cr.</option>
                                <option> Rs. 1 Cr. - 2 Cr.</option>
                                <option>Rs. 2 Cr. - 5 Cr.</option>
                                <option>Rs. 5 Cr. above</option>
                              </select>
                            </div>
                            <div>
                              <label
                                htmlFor="city_brandFee"
                                className="block text-[12px] font-medium leading-6 text-gray-900"
                              >
                                Brand Fee
                              </label>
                              <input
                                id="city_brandFee"
                                name="city_brandFee"
                                onChange={handleChange}
                                placeholder="Unit / Brand Fee"
                                className="mt-2 block w-full text-[12px] rounded-sm border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1  ring-gray-300  focus:ring-indigo-600  sm:leading-6"
                              />
                            </div>
                            <div>
                              <label
                                htmlFor="city_masterBrandFee"
                                className="block text-[12px] font-medium leading-6 text-gray-900"
                              >
                                Master Brand Fee
                              </label>
                              <input
                                id="city_masterBrandFee"
                                name="city_masterBrandFee"
                                onChange={handleChange}
                                placeholder="Master / Brand Fee"
                                className="mt-2 block w-full text-[12px] rounded-sm border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1  ring-gray-300  focus:ring-indigo-600  sm:leading-6"
                              />
                            </div>
                            <div>
                              <label
                                htmlFor="city_commission"
                                className="block text-[12px] font-medium leading-6 text-gray-900"
                              >
                                Commission
                              </label>
                              <input
                                id="city_commission"
                                name="city_commission"
                                onChange={handleChange}
                                placeholder="Royalty / Commission %"
                                className="mt-2 block w-full text-[12px] rounded-sm border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300  focus:ring-indigo-600  sm:leading-6"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                ) : looking_for == "trade_partners" ? (
                  <div>
                    {tradefileds.map((field, index) => (
                      <div
                        key={index}
                        className="mt-2 grid grid-cols-2 gap-x-4  sm:grid-cols-10"
                      >
                        <div className="sm:col-span-3">
                          <label
                            htmlFor={`channelType_${index}`}
                            className="block font-medium leading-6 text-[12px] text-gray-900"
                          >
                            Channel Type
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-sm shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                              <select
                                id={`channelType_${index}`}
                                name="channelType"
                                type="text"
                                value={field.channelType}
                                onChange={(e) => handleTradeChange(index, e)}
                                className="block flex-1 border-0 bg-transparent text-[12px] py-2.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6"
                              >
                                <option>DSA</option>
                                <option>Reseller</option>
                                <option>MLM</option>
                                <option>C & F</option>
                                <option>Agent</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor={`investment${index}`}
                            className="block font-medium leading-6 text-[12px] text-gray-900"
                          >
                            Investment
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-sm shadow-sm ring-1 text-[12px] ring-inset ring-gray-300 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                              <select
                                id={`investment_${index}`}
                                name="investment"
                                type="text"
                                value={field.investment}
                                onChange={(e) => handleTradeChange(index, e)}
                                className="block flex-1 border-0 bg-transparent text-[12px] py-2.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6"
                              >
                                <option>Rs. 10000 - 50000</option>
                                <option>Rs. 50000 - 2lakh</option>
                                <option>Rs. 2lakh - 5lakh</option>
                                <option>Rs. 5lakh - 10lakh</option>
                                <option>Rs. 10lakh - 20lakh</option>
                                <option>Rs. 20lakh - 30lakh</option>
                                <option>Rs. 30lakh - 50lakh</option>
                                <option>Rs. 50lakh - 1 Cr.</option>
                                <option> Rs. 1 Cr. - 2 Cr.</option>
                                <option>Rs. 2 Cr. - 5 Cr.</option>
                                <option>Rs. 5 Cr. above</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor={`commission_${index}`}
                            className="block font-medium leading-6 text-[12px] text-gray-900"
                          >
                            Commission
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-sm shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                              <input
                                id={`commission_${index}`}
                                name="commission"
                                type="text"
                                placeholder="commission"
                                value={field.commission}
                                onChange={(e) => handleTradeChange(index, e)}
                                className="block flex-1 border-0 bg-transparent text-[12px] py-[7px] pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6"
                              />
                              {/* Add buttons inline with the last input */}
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center space-x-2 ml-2 mt-8">
                          {tradefileds.length - 1 == index ? (
                            <button
                              type="button"
                              onClick={AddTradeFields}
                              className="inline-flex justify-center rounded-sm bg-green-500 py-1 px-3 text-lg font-semibold text-white shadow-sm hover:bg-green-600"
                            >
                              +
                            </button>
                          ) : (
                            <button
                              type="button"
                              onClick={() => RemoveTradeFields(index)}
                              className="inline-flex justify-center rounded-sm bg-red-500 py-1 px-3 text-lg font-semibold text-white shadow-sm hover:bg-red-600"
                            >
                              -
                            </button>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : looking_for == "distributor" ? (
                  <div>
                    {distributorfields.map((field, index) => (
                      <div
                        key={index}
                        className="mt-2 grid grid-cols-2 gap-x-4  sm:grid-cols-10"
                      >
                        <div className="sm:col-span-3">
                          <label
                            htmlFor={`channelType_${index}`}
                            className="block font-medium leading-6 text-[12px] text-gray-900"
                          >
                            Channel Type
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-sm shadow-sm ring-1 ring-inset ring-gray-300  focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                              <select
                                id={`channelType_${index}`}
                                name="channelType"
                                type="text"
                                value={field.channelType}
                                onChange={(e) =>
                                  handleDistributorChange(index, e)
                                }
                                className="block flex-1 border-0 bg-transparent text-[12px] py-[9px] pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6"
                              >
                                <option>Distributor</option>
                                <option>Dealer</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor={`investment${index}`}
                            className="block font-medium leading-6 text-[12px] text-gray-900"
                          >
                            Investment
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-sm shadow-sm ring-1 ring-inset ring-gray-300  focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                              <select
                                id={`investment_${index}`}
                                name="investment"
                                type="text"
                                placeholder="investment"
                                value={field.investment}
                                onChange={(e) =>
                                  handleDistributorChange(index, e)
                                }
                                className="block flex-1 border-0 bg-transparent text-[12px] py-[9px] pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6"
                              >
                                <option>Rs. 10000 - 50000</option>
                                <option>Rs. 50000 - 2lakh</option>
                                <option>Rs. 2lakh - 5lakh</option>
                                <option>Rs. 5lakh - 10lakh</option>
                                <option>Rs. 10lakh - 20lakh</option>
                                <option>Rs. 20lakh - 30lakh</option>
                                <option>Rs. 30lakh - 50lakh</option>
                                <option>Rs. 50lakh - 1 Cr.</option>
                                <option> Rs. 1 Cr. - 2 Cr.</option>
                                <option>Rs. 2 Cr. - 5 Cr.</option>
                                <option>Rs. 5 Cr. above</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor={`commission_${index}`}
                            className="block font-medium leading-6 text-[12px] text-gray-900"
                          >
                            Commission
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-sm shadow-sm ring-1 text-[12px] ring-inset ring-gray-300  focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                              <input
                                id={`commission_${index}`}
                                name="commission"
                                type="text"
                                placeholder="commission"
                                value={field.commission}
                                onChange={(e) =>
                                  handleDistributorChange(index, e)
                                }
                                className="block flex-1 border-0 bg-transparent py-[6px] pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6"
                              />
                              {/* Add buttons inline with the last input */}
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center space-x-2 ml-2 mt-8">
                          {distributorfields.length - 1 == index ? (
                            <button
                              type="button"
                              onClick={AddDistributorFields}
                              className="inline-flex justify-center rounded-sm bg-green-500 py-1 px-3 text-lg font-semibold text-white shadow-sm hover:bg-green-600"
                            >
                              +
                            </button>
                          ) : (
                            <button
                              type="button"
                              onClick={() => RemoveDitributorFields(index)}
                              className="inline-flex justify-center rounded-sm bg-red-500 py-1 px-3 text-lg  font-semibold text-white shadow-sm hover:bg-red-600"
                            >
                              -
                            </button>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            {/* Business Detail Form  Start*/}

            <div className=" bg-white  shadow-sm border rounded-sm pb-8 mt-6 ">
              <div className="bg-gray-100">
                <h2 className="text-base font-semibold leading-7 text-gray-900  border-b px-6  py-3">
                  Business Detail
                </h2>
              </div>
              <div className="grid lg:grid-cols-3 gap-4 grid-cols-1"></div>
              <div className="px-6 py-4 ">
                <div className="grid lg:grid-cols-3 gap-4 grid-cols-1 ">
                  <div>
                    <label
                      htmlFor="industry"
                      className="block text-[12px] font-sm leading-6 text-gray-900"
                    >
                      Industry<span className="text-red-500">*</span>
                    </label>
                    <select
                      id="industry"
                      name="industry"
                      required
                      value={selectedIndustry}
                      onChange={handleIndustryChange}
                      className="mt-1 block w-full rounded-sm border-0 py-[9px] pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-indigo-600 text-[12px] sm:leading-6"
                    >
                      <option value="">Select Industry</option>
                      {Object.keys(industriesData).map((industry) => (
                        <option key={industry} value={industry}>
                          {industry}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Sector Selection */}
                  <div>
                    <label
                      htmlFor="sector"
                      className="block text-[12px] font-sm leading-6 text-gray-900"
                    >
                      Sector<span className="text-red-500">*</span>
                    </label>
                    <select
                      id="sector"
                      required
                      name="sector"
                      onChange={handleChange}
                      className="mt-1 block w-full rounded-sm border-0 py-[9px] pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-indigo-600 text-[12px] sm:leading-6"
                    >
                      <option value="">Select Sector</option>
                      {availableSectors.map((sector, index) => (
                        <option key={index} value={sector}>
                          {sector}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Product Selection */}
                  <div>
                    <label
                      htmlFor="service"
                      className="block text-[12px] font-sm leading-6 text-gray-900"
                    >
                      Service / Product<span className="text-red-500">*</span>
                    </label>
                    <select
                      id="service"
                      required
                      name="service"
                      onChange={handleChange}
                      className="mt-1 block w-full rounded-sm border-0 py-[9px] pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-indigo-600 text-[12px] sm:leading-6"
                    >
                      <option value="">Select Service / Product</option>
                      {availableProducts.map((product, index) => (
                        <option key={index} value={product}>
                          {product}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label
                      htmlFor="Commenced"
                      className="block text-[12px] font-sm leading-6 text-gray-900"
                    >
                      Year Commenced Operations
                      <span className="text-red-500">*</span>
                    </label>
                    <select
                      id="Commenced"
                      required
                      name="Commenced"
                      value={values.name}
                      onChange={(e) => handleChange(e)}
                      className="mt-1 block w-full rounded-sm border-0 py-[9px] pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300  focus:ring-indigo-600 text-[12px] sm:leading-6"
                    >
                      <option value="">Select Operations Year</option>
                      <option>2008</option>
                      <option>2009</option>
                      <option>2010</option>
                      <option>2011</option>
                      <option>2012</option>
                      <option>2013</option>
                      <option>2014</option>
                      <option>2015</option>
                      <option>2016</option>
                      <option>2017</option>
                      <option>2018</option>
                      <option>2019</option>
                      <option>2019</option>
                      <option>2020</option>
                      <option>2021</option>
                      <option>2022</option>
                      <option>2023</option>
                      <option>2024</option>
                      <option>2025</option>
                    </select>
                  </div>
                  <div>
                    <label
                      htmlFor="location"
                      className="block text-[12px] font-sm leading-6 text-gray-900"
                    >
                      Franchising Commenced On
                      <span className="text-red-500">*</span>
                    </label>
                    <select
                      id="franchisingOn"
                      name="franchisingOn"
                      required
                      value={values.name}
                      onChange={(e) => handleChange(e)}
                      className="mt-1 block w-full rounded-sm border-0 py-[9px] pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300  focus:ring-indigo-600 text-[12px] sm:leading-6"
                    >
                      <option value="">Select Franchising Years</option>
                      <option>2008</option>
                      <option>2009</option>
                      <option>2010</option>
                      <option>2011</option>
                      <option>2012</option>
                      <option>2013</option>
                      <option>2014</option>
                      <option>2015</option>
                      <option>2016</option>
                      <option>2017</option>
                      <option>2018</option>
                      <option>2019</option>
                      <option>2019</option>
                      <option>2020</option>
                      <option>2021</option>
                      <option>2022</option>
                      <option>2023</option>
                      <option>2024</option>
                      <option>2025</option>
                    </select>
                  </div>
                  <div>
                    <label
                      htmlFor="currentOutlets"
                      className="block text-[12px] font-sm leading-6 text-gray-900"
                    >
                      No Of Current Outlets
                      <span className="text-red-500">*</span>
                    </label>
                    <select
                      id="currentOutlets"
                      name="currentOutlets"
                      required
                      value={values.name}
                      onChange={(e) => handleChange(e)}
                      className="mt-1 block w-full rounded-sm border-0 py-[9px] pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300  focus:ring-indigo-600 text-[12px] sm:leading-6"
                    >
                      <option value="">Select No of Franchise Outlets</option>
                      <option>Less than 10</option>
                      <option>10-20</option>
                      <option>20-50</option>
                      <option>50-100</option>
                      <option>100-200</option>
                      <option>200-500</option>
                      <option>500-1000</option>
                      <option>1000-10000</option>
                      <option>More than 10000</option>
                    </select>
                  </div>
                  <div>
                    <label
                      htmlFor="retailOutlets"
                      className="block text-[12px] font-sm leading-6 text-gray-900"
                    >
                      No of Retail Outlets
                      <span className="text-red-500">*</span>
                    </label>
                    <select
                      id="retailOutlets"
                      name="retailOutlets"
                      required
                      value={values.name}
                      onChange={(e) => handleChange(e)}
                      className="mt-1 block w-full rounded-sm border-0 py-[9px] pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300  focus:ring-indigo-600 text-[12px] sm:leading-6"
                    >
                      <option value="">Select No of Retail Outlets</option>
                      <option>Less than 10</option>
                      <option>10-20</option>
                      <option>20-50</option>
                      <option>50-100</option>
                      <option>100-200</option>
                      <option>200-500</option>
                      <option>500-1000</option>
                      <option>1000-10000</option>
                      <option>More than 10000</option>
                    </select>
                  </div>
                  <div>
                    <label
                      htmlFor="ownedOutlets"
                      className="block text-[12px] font-sm leading-6 text-gray-900"
                    >
                      No. Of Company Owned Outlets
                      <span className="text-red-500">*</span>
                    </label>
                    <select
                      id="ownedOutlets"
                      name="ownedOutlets"
                      required
                      value={values.name}
                      onChange={(e) => handleChange(e)}
                      className="mt-1 block w-full rounded-sm border-0 py-[9px] pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300  focus:ring-indigo-600 text-[12px] sm:leading-6"
                    >
                      <option value="">
                        Select No. Of Company Owned Outlets
                      </option>
                      <option>Less than 10</option>
                      <option>10-20</option>
                      <option>20-50</option>
                      <option>50-100</option>
                      <option>100-200</option>
                      <option>200-500</option>
                      <option>500-1000</option>
                      <option>1000-10000</option>
                      <option>More than 10000</option>
                    </select>
                  </div>
                  <div>
                    <label
                      htmlFor="investment_range_tier_1"
                      className="block text-[12px] font-sm leading-6 text-gray-900"
                    >
                      Min Investment Range for tier 1 city
                      <span className="text-red-500">*</span>
                    </label>
                    <select
                      id="investment_range_tier_1"
                      required
                      name="investment_range_tier_1"
                      value={values.investment_range_tier_1}
                      onChange={(e) => handleChange(e)}
                      className="mt-1 block w-full rounded-sm border-0 py-[9px] pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300  focus:ring-indigo-600 text-[12px] sm:leading-6"
                    >
                      <option value="">Select Min Investment Range</option>
                      <option value="Rs. 10000 - 50000">
                        Rs. 10000 - 50000
                      </option>
                      <option value="Rs. 50000 - 2lakh">
                        Rs. 50000 - 2lakh
                      </option>
                      <option value="Rs. 2lakh - 5lakh">
                        Rs. 2lakh - 5lakh
                      </option>
                      <option value="Rs. 5lakh - 10lakh">
                        Rs. 5lakh - 10lakh
                      </option>
                      <option value="Rs. 10lakh - 20lakh">
                        Rs. 10lakh - 20lakh
                      </option>
                      <option value="Rs. 20lakh - 30lakh">
                        Rs. 20lakh - 30lakh
                      </option>
                      <option value="Rs. 30lakh - 50lakh">
                        Rs. 30lakh - 50lakh
                      </option>
                      <option value="Rs. 50lakh - 1 Cr">
                        Rs. 50lakh - 1 Cr.
                      </option>
                      <option value=" Rs. 1 Cr. - 2 Cr">
                        {" "}
                        Rs. 1 Cr. - 2 Cr.
                      </option>
                      <option value="Rs. 2 Cr. - 5 Cr">
                        Rs. 2 Cr. - 5 Cr.
                      </option>
                      <option value="Rs. 5 Cr. above">Rs. 5 Cr. above</option>
                    </select>
                  </div>

                  <div>
                    <label
                      htmlFor="investment_range_tier_2"
                      className="block text-[12px] font-sm leading-6 text-gray-900"
                    >
                      Min Investment Range for tier 2 city
                      <span className="text-red-500">*</span>
                    </label>
                    <select
                      id="investment_range_tier_2"
                      name="investment_range_tier_2"
                      value={values.investment_range_tier_2}
                      required
                      onChange={(e) => handleChange(e)}
                      className="mt-1 block w-full rounded-sm border-0 py-[9px] pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300  focus:ring-indigo-600 text-[12px] sm:leading-6"
                    >
                      <option value="">Select Min Investment Range</option>
                      <option value="Rs. 10000 - 50000">
                        Rs. 10000 - 50000
                      </option>
                      <option value="Rs. 50000 - 2lakh">
                        Rs. 50000 - 2lakh
                      </option>
                      <option value="Rs. 2lakh - 5lakh">
                        Rs. 2lakh - 5lakh
                      </option>
                      <option value="Rs. 5lakh - 10lakh">
                        Rs. 5lakh - 10lakh
                      </option>
                      <option value="Rs. 10lakh - 20lakh">
                        Rs. 10lakh - 20lakh
                      </option>
                      <option value="Rs. 20lakh - 30lakh">
                        Rs. 20lakh - 30lakh
                      </option>
                      <option value="Rs. 30lakh - 50lakh">
                        Rs. 30lakh - 50lakh
                      </option>
                      <option value="Rs. 50lakh - 1 Cr">
                        Rs. 50lakh - 1 Cr.
                      </option>
                      <option value=" Rs. 1 Cr. - 2 Cr">
                        {" "}
                        Rs. 1 Cr. - 2 Cr.
                      </option>
                      <option value="Rs. 2 Cr. - 5 Cr">
                        Rs. 2 Cr. - 5 Cr.
                      </option>
                      <option value="Rs. 5 Cr. above">Rs. 5 Cr. above</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            {/* Business Detail Form  end*/}

            {/* Business Expansion Form  start*/}

            <div className="bg-white  shadow-sm border rounded-sm mt-6">
              <div className="bg-gray-100">
                <h2 className="text-base font-semibold leading-7 border-b py-3 px-6 text-gray-900">
                  Business Expansion
                </h2>
              </div>

              <div className="w-full py-2">
                <div className="mt-4 grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-x-12 gap-y-3 px-6 pb-4">
                  {states.map((state) => (
                    <div
                      key={state.isoCode}
                      className="flex justify-between items-start"
                    >
                      <div className="flex items-center mb-2">
                        <input
                          id={`${state.isoCode}State`}
                          name="state"
                          type="checkbox"
                          checked={!!selectedStates[state.isoCode]}
                          className="h-4 w-4 border-gray-300 text-green-600 focus:ring-green-600"
                          onChange={() => handleStateClick(state.isoCode)}
                        />
                        <label
                          htmlFor={`${state.isoCode}State`}
                          className="ml-3   text-[12px] font-semibold whitespace-pre-line break-all text-gray-900"
                        >
                          {state.name ==
                          "Dadra and Nagar Haveli and Daman and Diu"
                            ? "Dadar & Daman"
                            : state.name}
                        </label>
                      </div>

                      {!selectedStates[state.isoCode] && (
                        <button
                          type="button"
                          onClick={() =>
                            handleStateDisclosureClick(state.isoCode)
                          }
                          className="flex justify-start gap-2  items-center  text-left text-gray-900 font-semibold text-lg"
                        >
                          <div className="flex items-center gap-2 bg-gray-800 px-1 py-[3px] rounded-sm">
                            <span className="  text-white">
                              <PlusSmallIcon className="h-5 w-5 text-white" />
                            </span>
                            {/* <span className="text-[12px] text-white"> Cities</span> */}
                          </div>
                        </button>
                      )}
                    </div>
                  ))}
                </div>
                <Dialog
                  open={isModalOpen}
                  onClose={() => setIsModalOpen(false)}
                  className="fixed inset-0 z-10 flex items-center justify-center p-4"
                >
                  <div
                    className="fixed inset-0 bg-black bg-opacity-25"
                    aria-hidden="true"
                  />
                  <Dialog.Panel className="relative bg-white rounded-lg max-w-2xl mx-auto p-6 h-[500px] overflow-y-scroll">
                    <Dialog.Title className="text-lg  font-semibold text-gray-900 border-b pb-4 border-gray-400">
                      Cities in{" "}
                      {activeStateCode &&
                        states.find((s) => s.isoCode === activeStateCode)?.name}
                    </Dialog.Title>
                    <Dialog.Description className="mt-2">
                      {cities[activeStateCode] ? (
                        cities[activeStateCode].length > 0 ? (
                          <div className="mt-4 grid grid-cols-4 gap-4">
                            {cities[activeStateCode].map((city) => (
                              <div
                                key={city.name}
                                className="flex items-center"
                              >
                                <input
                                  type="checkbox"
                                  className="mr-2"
                                  checked={
                                    selectedCities[activeStateCode]?.includes(
                                      city.name
                                    ) || false
                                  }
                                  onChange={() =>
                                    handleCityClick(activeStateCode, city.name)
                                  }
                                />
                                <label className="text-gray-700 text-[12px] whitespace-pre-line break-all">
                                  {city.name}
                                </label>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <p>No cities available</p>
                        )
                      ) : (
                        <p>Loading cities...</p>
                      )}
                    </Dialog.Description>
                    <div className="mt-4 justify-end flex border-t border-gray-400 pt-2">
                      <button
                        onClick={() => setIsModalOpen(false)}
                        className="mt-3 px-10  inline-flex justify-center rounded-md bg-indigo-600 text-[12px] py-2 text-white font-semibold shadow-sm hover:bg-indigo-500"
                      >
                        Close
                      </button>
                    </div>
                  </Dialog.Panel>
                </Dialog>
              </div>
            </div>

            {/* Property Details Form Start */}

            <div className="bg-white  shadow-sm border rounded-sm pb-6 mt-6">
              <div className="bg-gray-100">
                <h2 className="text-base font-semibold mb-4 border-b py-3 px-6">
                  Property Details
                </h2>
              </div>
              <div className="mt-5 bg-white px-6">
                <h2 className="text-lg font-semibold mb-4 border-b border-gray-400 pb-2">
                  Property Details
                </h2>
                <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
                  <div className="mb-0">
                    <label className="block text-[12px] text-gray-700 font-medium mb-2">
                      What type of property is required for this franchise opp.{" "}
                      <span className="text-red-500">*</span>
                    </label>
                    <div className="relative">
                      <select
                        className="w-full  text-[12px] py-2 px-3 border border-gray-300 bg-gray-100 rounded-sm text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        onChange={handleChange}
                        required
                        name="property_type_requried"
                      >
                        <option value="">Select property type</option>
                        <option value="Commercial">Commercial</option>
                        <option value="Non Commercial">Non Commercial</option>
                        <option value="Commercial & Non Commercial">
                          Both
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="mb-0">
                    <label className="block text-[12px] text-gray-700 font-medium mb-2">
                      Minimum Floor Area Requirements
                      <span className="text-red-500">*</span>
                    </label>
                    <div className="flex border border-gray-300  rounded-sm text-gray-900">
                      <input
                        type="number"
                        name="floor_Area"
                        required
                        onChange={handleChange}
                        className="form-input text-[12px] w-full  bg-gray-100 py-[7px] px-2 "
                        placeholder={1}
                      />
                    </div>
                  </div>
                  <div className="mb-0">
                    <label className="block text-[12px] text-gray-700 font-medium mb-2">
                      Preferred location <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="Preferred_location"
                      required
                      onChange={handleChange}
                      className="form-input text-[12px] w-full border border-gray-300 bg-gray-100 py-2 px-2 rounded-sm text-gray-900"
                      placeholder="Enter Preferred Location"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white shadow-sm border rounded-sm pb-6 mt-6 ">
              <div className="bg-gray-100">
                <h2 className="text-base font-semibold mb-4 border-b py-3 px-6">
                  Training Details
                </h2>
              </div>

              <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 px-6">
                <div className="">
                  <label className="block text-[12px] text-gray-700 font-medium mb-2">
                    Is there exclusive territorial rights given to a unit
                    franchise?
                    <span className="text-red-500">*</span>
                  </label>
                  <div className="flex items-center space-x-10">
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        name="territorial_rights"
                        value="yes"
                        onChange={handleChange}
                        className="form-radio text-blue-600"
                        required
                      />
                      <span className="ml-2 text-[12px]">Yes</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        name="territorial_rights"
                        value="no"
                        onChange={handleChange}
                        className="form-radio text-blue-600"
                        required
                      />
                      <span className="ml-2 text-[12px]">No</span>
                    </label>
                  </div>
                </div>

                <div className="mb-4">
                  <label className="block text-[12px] text-gray-700 font-medium mb-2">
                    Do you currently have detailed operating manuals for
                    franchises?
                    <span className="text-red-500">*</span>
                  </label>
                  <div className="flex items-center space-x-10">
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        name="operating_manuals"
                        onChange={handleChange}
                        value="yes"
                        className="form-radio text-blue-600"
                        required
                      />
                      <span className="ml-2 text-[12px]">Yes</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        name="operating_manuals"
                        onChange={handleChange}
                        value="no"
                        className="form-radio text-blue-600"
                        required
                      />
                      <span className="ml-2 text-[12px]">No</span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="mb-3 grid lg:grid-cols-2 grid-cols-1 gap-4 px-6">
                <div>
                  <label className="block text-[12px] text-gray-700 font-medium mb-2">
                    Where is the franchisee training done?{" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      onChange={handleChange}
                      name="franchise_training_location"
                      className="w-full text-[12px] py-2 px-3 border border-gray-300 bg-gray-100 rounded-md text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500"
                      placeholder="Please Specify"
                      required
                    />
                  </div>
                </div>

                <div className="mb-4">
                  <label className="block text-[12px] text-gray-700 font-medium mb-2">
                    What IT systems do you presently have that will be included
                    in the franchise?
                    <span className="text-red-500">*</span>
                  </label>
                  <div className="flex items-center space-x-10">
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        name="presently_IT_system"
                        onChange={handleChange}
                        value="yes"
                        className="form-radio text-blue-600"
                        required
                      />
                      <span className="ml-2 text-[12px]">Yes</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        name="presently_IT_system"
                        onChange={handleChange}
                        value="no"
                        className="form-radio text-blue-600"
                        required
                      />
                      <span className="ml-2 text-[12px]">No</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="mb-4 grid lg:grid-cols-2 grid-cols-1 gap-4 px-6">
                <div className="mb-4">
                  <label className="block text-[12px] text-gray-700 font-medium mb-2">
                    Is field assistance available for franchises?
                    <span className="text-red-500">*</span>
                  </label>
                  <div className="flex items-center space-x-10">
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        name="field_assistance_available"
                        onChange={handleChange}
                        required
                        value="yes"
                        className="form-radio text-blue-600"
                      />
                      <span className="ml-2 text-[12px]">Yes</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        name="field_assistance_available"
                        onChange={handleChange}
                        required
                        value="no"
                        className="form-radio text-blue-600"
                      />
                      <span className="ml-2 text-[12px]">No</span>
                    </label>
                  </div>
                </div>

                <div className="mb-4">
                  <label className="block text-[12px] text-gray-700 font-medium mb-2">
                    Will the head office be providing assistance to the
                    franchisee?<span className="text-red-500">*</span>
                  </label>
                  <div className="flex items-center space-x-10">
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        onChange={handleChange}
                        name="providing_assistance_byhead"
                        value="yes"
                        required
                        className="form-radio text-blue-600"
                      />
                      <span className="ml-2 text-[12px]">Yes</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        name="providing_assistance_byhead"
                        onChange={handleChange}
                        required
                        value="no"
                        className="form-radio text-blue-600"
                      />
                      <span className="ml-2 text-[12px]">No</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="mt-5 bg-white px-6">
                <h2 className="text-lg font-semibold uppercase mb-4 border-b border-gray-400 pb-2">
                  Agreements Details
                </h2>
                <div className="mb-0 grid lg:grid-cols-2 grid-cols-1 gap-4">
                  <div className="mb-4">
                    <label className="block text-[12px] text-gray-700 font-medium mb-2">
                      Do you have a standard franchise agreement?
                      <span className="text-red-500">*</span>
                    </label>
                    <div className="flex items-center space-x-10">
                      <label className="inline-flex items-center">
                        <input
                          type="radio"
                          name="franchise_agreement"
                          onChange={handleChange}
                          required
                          value="yes"
                          className="form-radio text-blue-600"
                        />
                        <span className="ml-2 text-[12px]">Yes</span>
                      </label>
                      <label className="inline-flex items-center">
                        <input
                          type="radio"
                          name="franchise_agreement"
                          required
                          onChange={handleChange}
                          value="no"
                          className="form-radio text-blue-600"
                        />
                        <span className="ml-2 text-[12px]">No</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="mb-4 grid lg:grid-cols-2 grid-cols-1 gap-4 ">
                  <div className="mb-6">
                    <label className="block text-[12px] text-gray-700 font-medium mb-2">
                      Duration of the Contract{" "}
                      <span className="text-red-500">*</span>
                    </label>
                    <div className="flex items-center  space-x-4">
                      <label className="flex items-center text-gray-700">
                        <input
                          type="radio"
                          value="lifetime"
                          name="contract_date"
                          required
                          onChange={handleChange}
                          className="form-radio  text-blue-600 focus:ring-blue-500"
                        />
                        <span className="ml-2 text-[12px]">Lifetime</span>
                      </label>

                      <label className="flex items-center text-gray-700">
                        <input
                          type="radio"
                          name="contract_date"
                          required
                          onChange={() => setSelectedOption("date")}
                          className="form-radio  text-blue-600 focus:ring-blue-500"
                        />
                        <span className="ml-2 text-[12px]">Date</span>
                      </label>

                      {selectedOption === "date" && (
                        <div className="w-full">
                          {/* <label className="block text-gray-700 font-medium mb-2">Select Date</label> */}
                          <input
                            type="date"
                            onChange={handleChange}
                            name="contract_date"
                            className="w-full py-2 px-3 text-[12px] border border-gray-300 bg-gray-100 rounded-md text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500"
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="mb-4">
                    <label className="block text-[12px] text-gray-700 font-medium mb-2">
                      Is the term renewable?
                      <span className="text-red-500">*</span>
                    </label>
                    <div className="flex items-center space-x-10">
                      <label className="inline-flex items-center">
                        <input
                          type="radio"
                          name="renewable"
                          required
                          onChange={handleChange}
                          value="yes"
                          className="form-radio text-blue-600"
                        />
                        <span className="ml-2 text-[12px]">Yes</span>
                      </label>
                      <label className="inline-flex items-center">
                        <input
                          type="radio"
                          name="renewable"
                          required
                          onChange={handleChange}
                          value="no"
                          className="form-radio text-blue-600"
                        />
                        <span className="ml-2 text-[12px]">No</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Appearance Details From Start */}

            <div className="bg-white  shadow-sm border rounded-sm pb-6 mt-6 ">
              <div className="bg-gray-100">
                <h2 className="text-base font-semibold mb-4 border-b py-3 px-6">
                  Appearance Details
                </h2>
              </div>

              <div className="grid lg:grid-cols-2 gap-4 sm:grid-cols-2 grid-cols-1 mb-4 px-6">
                <div className="">
                  <label className="block text-gray-800 mb-2 text-[13px] font-medium">
                    Upload Slide Image <span className="text-red-500">*</span> :
                    Image 1
                  </label>
                  <input
                    type="file"
                    onChange={(e) => setImage6(e.target.files[0])}
                    className="block w-full py-2 px-3 text-[12px] text-gray-900 border border-gray-300 rounded-sm cursor-pointer bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300 ease-in-out"
                    accept="image/*"
                  />
                </div>

                <div className="">
                  <label className="block text-gray-800 mb-2 text-[13px] font-medium">
                    Upload Slide Image <span className="text-red-500">*</span> :
                    Image 2
                  </label>
                  <input
                    type="file"
                    onChange={(e) => setImage7(e.target.files[0])}
                    className="block w-full py-2 px-3 text-[12px] text-gray-900 border border-gray-300 rounded-sm cursor-pointer bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300 ease-in-out"
                    accept="image/*"
                  />
                </div>
              </div>
              <div className="grid lg:grid-cols-2 gap-4 sm:grid-cols-2 grid-cols-1 mb-4 px-6">
                <div className="">
                  <label className="block text-gray-800 mb-2 text-[13px] font-medium">
                    Upload Slide Image <span className="text-red-500">*</span> :
                    Image 3
                  </label>
                  <input
                    type="file"
                    onChange={(e) => setImage8(e.target.files[0])}
                    className="block w-full py-2 px-3 text-[12px] text-gray-900 border border-gray-300 rounded-sm cursor-pointer bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300 ease-in-out"
                    accept="image/*"
                  />
                </div>

                <div className=" text-[13px] font-medium">
                  <label className="block text-gray-800 mb-2">Video Link</label>
                  <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center px-4 border-r border-gray-300 text-gray-500">
                      Link
                    </span>
                    <input
                      type="text"
                      value={values.videoLink}
                      name="videoLink"
                      onChange={handleChange}
                      className="w-full pl-[72px] py-[10px] pe-3 border border-gray-300 bg-gray-100 rounded-sm text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500"
                      placeholder="Enter Your Video link"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-6 mb-6 flex items-center  justify-end border-t pt-5 border-gray-400 gap-x-4">
              {/* <input type="reset" value="Reset"/> */}
              <button
                type="reset"
                ref={resetButtonRef}
                className=" rounded-md bg-red-600 px-5 py-1.5 font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Reset
              </button>
              <button
                type="submit"
                disabled={!OTPverification}
                className={`px-4 py-[9px] bg-indigo-500 text-white font-medium text-[12px] right-0 rounded-r-sm ${
                  !OTPverification ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                {auth_loading ? <Spinner /> : "Save"}
              </button>
            </div>
          </form>

          <div className="lg:col-span-3 col-span-12">
            <section className="mb-4 ">
              <div className="">
                <div className="bg-gray-100  rounded-t-sm border">
                  <h2 className="text-base font-semibold leading-tight text-black mb-3 px-2 py-2">
                    Frequently Asked Questions (FAQs)
                  </h2>
                </div>
                <div className="">
                  {faqData.map((faq, index) => (
                    <div
                      key={index}
                      className="transition-all duration-300 my-2 bg-white border-2 border-transparent bg-clip-padding rounded-sm  shadow-md hover:shadow-lg hover:border-transparent  "
                    >
                      <button
                        type="button"
                        className="flex items-center justify-between w-full p-2 "
                        onClick={() => toggleQuestion(index)}
                      >
                        <span className="flex text-sm font-semibold text-left text-black">
                          {faq.question}
                        </span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          className={`w-6 h-6 text-gray-400 transition-transform duration-200 ${
                            openQuestionIndex === index
                              ? "rotate-180"
                              : "rotate-0"
                          }`}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </button>
                      {openQuestionIndex === index && (
                        <div className="px-3 text-[13px] pb-5  sm:pb-6 ">
                          <p>{faq.answer}</p>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                <p className="text-center text-gray-600 text-base mt-6">
                  Thank you for visiting Way4franchise!
                  {/* <span className="cursor-pointer font-medium text-tertiary transition-all duration-200 hover:text-tertiary hover-underline">
                    Contact our support
                  </span> */}
                </p>
              </div>
            </section>
          </div>
        </div>
      </div>
      <Footer />

      {open && (
        <InvestorOtpVerification
          open={open}
          setOpen={() => setOpen(false)}
          email={values.ownerEmail}
        />
      )}
    </>
  );
};

export default InvestorRegistration;
