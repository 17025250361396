import React, { useState } from "react";
import Header from "../Comman/Header";
import SearchTabs from "./SearchTabs";
import LaunchSection from "./LaunchSection";
import LeadingFranchise from "./LeadingFranchise";
import TopDealerShip from "./TopDealerShip";
import FranchiseBanner from "./FranchiseBanner";
import TopInternatinal from "./TopInternational";
import TopFranchise from "./TopFranchise";
import Newsletter from "./NewsLetters";
import SliderSection from "./SliderSection";
import PremiumOpportunities from "./PremiumOpportunities";
import Footer from "../Comman/Footer";
import BestBusinessOpportunities from "./BestBusinessOpportunities";
import BlogSection from "./BlogSection";

export default function Home() {
  return (
    <div className="bg-white">
      <div className="bg-[#ffff]">
        <Header />
        <SliderSection />
      </div>
      <div className="bg-[#ffff]">
        <div className="px-3  max-w-full lg:mx-10 sm:mx-10 mx-1">
          <SearchTabs />
          <LaunchSection />
        </div>
      </div>
      <LeadingFranchise />
      <TopFranchise />
      <TopInternatinal />
      <TopDealerShip />
      <PremiumOpportunities />
      <FranchiseBanner />
      <BestBusinessOpportunities />
      <BlogSection />
      <Newsletter />
      <Footer />
    </div>
  );
}
