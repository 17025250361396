import React from "react";
import Header from "../Comman/Header";
import Footer from "../Comman/Footer";
export const Analytics = () => {
  return (
    <>
    <Header />
      <div id="about" className="relative bg-white overflow-hidden my-16 ">
        <div className="max-w-full mx-10">
          <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
            <svg
              className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon points="50,0 100,0 50,100 0,100" />
            </svg>
            <div className="pt-1" />
            <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left">
                <h2 className="my-6 text-2xl tracking-tight font-extrabold text-gray-900 sm:text-3xl md:text-4xl">
                Analytics

                </h2>
                <p>
                At Way4franchise, we believe that understanding your business performance is the key to making smart decisions. That’s why we offer detailed analytics services to help you track and measure the success of your franchise. With the right data, you can make better choices and improve your marketing efforts, website performance, and customer engagement.
Our analytics services provide clear insights into how your website and marketing campaigns are performing. We help you gather and understand data so you can take action and grow your franchise.

                </p>
              </div>
            </main>
          </div>
        </div>
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <img
            className="h-56 w-full object-cover object-top sm:h-72 md:h-96 lg:w-full lg:h-full"
            src="https://t3.ftcdn.net/jpg/03/54/00/74/360_F_354007466_mm4QilA3n92YWPseqs82gbWxbb06R1i4.jpg"
            alt
          />
        </div>
      </div>
      <div className=" dark:bg-gray-800 border-y">
        <div className="items-center max-w-screen-7xl px-4 py-8 lg:mx-10 mx-2 lg:grid lg:grid-cols-4 lg:gap-16  lg:px-6">
          <div className="col-span-2 mb-8">
            <p className="text-lg font-medium text-purple-500 dark:text-purple-500">
            Our analytics
            </p>
            <h2 className="mt-3 mb-4 text-3xl font-semibold tracking-tight text-gray-900 md:text-3xl dark:text-white">
            Our analytics services include
            </h2>
            <div className="pt-6 mt-6 space-y-4 border-t border-gray-200 dark:border-gray-700">
              <div>
                <div className="text-white">
                    <h3 className="font-semibold ">Marketing Campaign Insights</h3>
                    <p className="text-[15px]">Get detailed reports on how your marketing campaigns are performing. We track things like</p>
                </div>
                <ul className="px-10 pb-3 list-disc text-gray-400">
                    <li>How many people see your ads.</li>
                    <li>How many people click on your ads.</li>
                    <li>How many leads or customers you get from your campaigns.</li>
                </ul>
              </div>
              
            </div>
          </div>
          <div className="col-span-2 space-y-8 md:grid md:grid-cols-2 md:gap-12 md:space-y-0">
            <div>
              
              <h3 className="mb-2 text-base font-semibold dark:text-white">
              Website Traffic Analysis

              </h3>
              <p className="text-base text-gray-500 dark:text-gray-400">
              We track how many people visit your website, where they come from, and what they do while they’re there. This helps you understand your audience and improve their experience.

              </p>
            </div>
            <div>
             
              <h3 className="mb-2 text-base font-semibold dark:text-white">
              Conversion Tracking

              </h3>
              <p className="text-base text-gray-500 dark:text-gray-400">
              Our team helps you see how well your website turns visitors into leads or franchise partners. We identify the parts of your website that need improvement to increase conversions.

              </p>
            </div>
            <div>
            
              <h3 className="mb-2 text-base font-semibold dark:text-white">
              Franchisee Engagement Metrics
              </h3>
              <p className="text-base text-gray-500 dark:text-gray-400">
              We track how potential franchisees are interacting with your brand. This includes how often they visit your website, engage with your content, or respond to your marketing campaigns.
              </p>
            </div>
            <div>
             
              <h3 className="mb-2 text-base font-semibold dark:text-white">
              Real-Time Reporting

              </h3>
              <p className="text-base text-gray-500 dark:text-gray-400">
              With real-time reports, you’ll always have up-to-date information about how your website and marketing campaigns are performing. This allows you to make quick decisions and adapt your strategy as needed.

              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};
